
import Vue from 'vue';
import dayjs from 'dayjs';
import i18n from '@/i18n';

export const OPPORTUNITY_DEFAULT_STAGE = [
    {
        id: 'open',
        name: 'Open',
        rank: 1,
        probability: 10,
        isDefault: true,
        parameters: {
            color: '#6CC3D5'
        }
    },
    {
        id: 'inprogress',
        name: 'Inprogress',
        rank: 2,
        probability: 30,
        isDefault: false,
        parameters: {
            color: '#bc7cde'
        }
    },
    // {
    //     id: 'validation',
    //     name: 'Validation',
    //     rank: 3,
    //     probability: 70,
    //     isDefault: false,
    //     parameters: {
    //         color: '#f2da3a'
    //     }
    // },
    // {
    //     id: 'blocked',
    //     name: 'Blocked',
    //     rank: 4,
    //     probability: 60,
    //     isDefault: false,
    //     parameters: {
    //         color: '#ff4242'
    //     }
    // },
    {
        id: 'won',
        name: 'Won',
        rank: 3,
        probability: 100,
        isDefault: false,
        parameters: {
            color: '#56CC9D'
        }
    },
    {
        id: 'lost',
        name: 'Lost',
        rank: 4,
        probability: 0,
        isDefault: false,
        parameters: {
            color: '#FF7851'
        }
    },
];

export const OPPORTUNITY_DEFAULT_STAGE_VALUES = {
    OPEN: 'open',
    INPROGRESS: 'inprogress',
    VALIDATION: 'validation',
    BLOCKED: 'blocked',
    WON: 'won',
    LOST: 'lost'
};

export const OPPORTUNITY_MIN_STAGE = {
    OPEN: 'open',
    WON: 'won',
    LOST: 'lost'
}

export const OPPORTUNITY_END_STAGE = {
    WON: 'won',
    LOST: 'lost'
}

export const OPPORTUNITY_PRIORITIES = [
    {
        id: 'null',
        name: "None"
    },
    {
        id: 1,
        name: "Low"
    },
    {
        id: 5,
        name: "High"
    },
    {
        id: 10,
        name: "Critical"
    },
];

const state = {
  fetched: false,
  fetchedPipelines: false,
  pipelinesStats: {},
  opportunity: [],
  opportunityPipelines: [],
  opportunityComments: {},
  stats: {}
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
    return state.opportunityPipelines;
  },
  getAllOpportunity: (state, getters, store, rootGetters) => {
    return state.opportunity;
  },
  getAllOpportunityByStage: (state, getters, store, rootGetters) => (stage) => {
    return state.opportunity.filter(t => {
        return t.stage === stage;
    });
  },
  getAllForCustomerId: (state, getters, store, rootGetters) => customerId => {
    return state.opportunity.filter(t => t.customerId === customerId);
},
  getFromId: (state) => (id)=> state.opportunity.find(a => a.id === id),
  getPipelineFromId: (state) => (id)=> state.opportunityPipelines.find(a => a.id === id),
  getAllPipelines: (state, getters, store, rootGetters) => {
    return state.opportunityPipelines;
  },
  getPipelineStatsFromId: (state) => (id)=> state.pipelinesStats[id],
  getCommentsFromOpportunityId: (state) => (opportunityId)=> state.opportunityComments[opportunityId] || [],
  getStats: (state) => (pipelineId) => {
    return state.stats[pipelineId];
  },
  getStages: () => {
    return Object.values(OPPORTUNITY_DEFAULT_STAGE_VALUES);
  },
  getMinStages: () => {
    return Object.values(OPPORTUNITY_MIN_STAGE);
  },
  getEndStages: () => {
    return Object.values(OPPORTUNITY_END_STAGE);
  },
  getStagesVariant: () => (stage) => {
    switch (stage) {
        case 'open': return 'warning';
        case 'won': return 'success';
        case 'blocked': return 'danger';
        case 'lost': return 'danger';
        case 'inprogress': return 'primary';
        default: return '';
    }
  },
  getStagesDefault: () => OPPORTUNITY_DEFAULT_STAGE,
  getPrioritiesDefault: () => OPPORTUNITY_PRIORITIES,
  getFilterTime: () => (filterTime) => {
        if (filterTime === "week") {
            return {
                    type: "daterange",
                    value: [
                        dayjs().startOf("week").toDate(),
                        dayjs().endOf("week").toDate()
                    ],
                };
        } 
        else if (filterTime === "month") {
        return {
                type: "daterange",
                value: [
                    dayjs().startOf("month").toDate(),
                    dayjs().endOf("month").toDate(),
                ],
            };
        } 
        else if (filterTime === "overdue") {
            return {
                operator: "lt",
                value: dayjs().format("YYYY-MM-DD"),
            };
        }
        else if (filterTime === "current") {
            return {
                operator: "lte",
                value: dayjs().endOf("week").toDate()
            };
        }
    },
};

const actions = {

  async ensureOpportunities({ dispatch, state }) {
    if (!state.fetched) {
      await dispatch('fetchOpportunities');
    }
  },
  async ensureOpportunityId({ dispatch, state }, { opportunityId }) {
    if (!state.opportunity.find(a => a.id === opportunityId)) {
        await dispatch('fetchOpportunityById', { opportunityId });
    }
    return state.opportunity.find(a => a.id === opportunityId);
  },
  async ensureOpportunityStats({ dispatch, state }, { pipelineId } = {}) {
    if (!pipelineId) return;
    if (!state.stats[pipelineId]) {
      await dispatch('fetchOpportunityStats', { pipelineId });
    }
  },
  async ensureOpportunityPipelines({ dispatch, state }, { ctx }) {
    if (!state.fetchedPipelines) {
      await dispatch('fetchOpportunityPipelines', {
        ctx: {
            ...ctx,
            page: 1,
            size: 50
        }
      });
    }
  },
  async ensureOpportunityPipelineId({ dispatch, state }, { pipelineId }) {
      const pipeline = state.opportunityPipelines.find(p => p.id === pipelineId);
    if (!pipeline) {
      await dispatch('fetchOpportunityPipelineById', { pipelineId });
    }
  },
  async fetchOpportunityPipelines({ commit }, { ctx, ref, skipStore }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      if (ref) {
        query.params = { ref };
      }
      if (ctx?.display) {
        query.params = query.params || {};
        query.params.display = ctx.display;
      }
      const { metadata, data } = await this.getters.api.get(`/opportunity-pipelines`, query);
      const opportunityPipelines = data.map(fromAPIPipeline);

      if (!skipStore) {
        commit('fetchedPipelines', { value: true });
        commit('setOpportunityPipelines', { data: opportunityPipelines });   
      }
      
      return {
        total: metadata.pagination.total || 0,
        page: metadata.pagination.page || 1,
        data: opportunityPipelines
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunityPipelineById({ commit }, { pipelineId }) {
    try {
      const data = await this.getters.api.get(`/opportunity-pipelines/${pipelineId}`);
      const opportunityPipeline = fromAPIPipeline(data);

      commit('setOpportunityPipeline', { data: opportunityPipeline });
      
      return opportunityPipeline;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunityPipelineStatsById({ commit }, { pipelineId, ctx }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      const stats = await this.getters.api.get(`/opportunity-pipelines/${pipelineId}/stats`, query);
      commit('setOpportunityPipelineStats', { pipelineId, data: stats });
      return stats;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunities({ commit }, { ctx, ref }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      if (ref) {
        query.params = { ref };
      }
      const { metadata, data } = await this.getters.api.get(`/opportunity`, query);
      const opportunity = data.map(fromAPI);
      commit('fetched', { value: true });
      commit('setOpportunities', { data: opportunity });
    //   const pipelines = [];
    //   for( const group of opportunity.filter(t => !!t.group)) {
    //       if (!pipelines.find(g => g.id === group.id)) pipelines.push(group);
    //   }
    //   if (pipelines.length) {
    //     commit('setOpportunityPipelines', { data: pipelines });
    //   }
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: opportunity
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunityByStage({ commit }, { pipelineId, customerId, ctx, stage }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
        if (pipelineId) query.pagination.filters.pipelineId = {
            type: "equals",
            value: pipelineId
        };
        query.pagination.filters.stage = {
            type: "equals",
            value: stage
        };
      }
      const { metadata, data } = await this.getters.api.get(customerId ? `/customers/${customerId}/opportunity` : `/opportunity`, query);
      const opportunity = data.map(fromAPI);
      commit('setOpportunityByStage', { stage, data: opportunity });
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: opportunity
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunityById({ commit }, { opportunityId }) {
    try {
      const opportunity = fromAPI(await this.getters.api.get(`/opportunity/${opportunityId}`));
      commit('setOpportunity', { data: opportunity });
      return opportunity;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunityComments({ commit }, { opportunityId, ctx } = {}) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      const data = await this.getters.api.get(`/opportunity/${opportunityId}/comments`, query);
      const opportunityComments = data.map(fromAPIComment);

      commit('setOpportunityComments', { opportunityId, data: opportunityComments });
      
      return {
        data: opportunityComments
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunityStats({ dispatch, commit }, { pipelineId } = {}) {
    try {
      const data = await this.getters.api.get(`/opportunity/stats/${pipelineId}`);
      commit('setOpportunityStats', { pipelineId, data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { opportunity }) {
    try {
        const data = fromAPI(await this.getters.api.post(`/opportunity`, { data: toAPI(opportunity) }));
        data.nbComments = 0;
        data.nbAttachements = 0;
        commit('setOpportunity', { data });
        return data;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ dispatch, commit, getters }, { opportunityId, opportunity}) {
    try {
        const oldOpportunity = getters.getFromId(opportunityId);
        await this.getters.api.patch(`/opportunity/${opportunityId}`, { data: toAPI(opportunity) });
        if (opportunity.stage && oldOpportunity.stage !== opportunity.stage) {
            const newStage = getters.getPipelineFromId(opportunity?.pipelineId)?.stages?.find(s => s.id === opportunity.stage);
            opportunity.probability = newStage.probability;
            opportunity.estimateAmount = Math.round(opportunity.amount * newStage.probability / 100);
        }
        commit('setOpportunity', { data: opportunity });
        return opportunity;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { pipelineId, opportunityId }) {
    try {
        await this.getters.api.delete(`/opportunity/${opportunityId}`);
        commit('removeOpportunity', { opportunityId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async addPipeline({ commit }, { pipeline }) {
    try {
        const data = await this.getters.api.post(`/opportunity-pipelines`, { data: toAPIPipeline(pipeline) });
        const pipelineObj = fromAPIPipeline(data);
        commit('setOpportunityPipeline', { data: pipelineObj });
        return pipeline;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updatePipeline({ commit }, { pipelineId, pipeline }) {
    try {
        const data = await this.getters.api.patch(`/opportunity-pipelines/${pipelineId}`, { data: toAPIPipeline(pipeline) });
        // TODO remove
        pipeline.id = pipelineId;
        commit('setOpportunityPipeline', { data: pipeline });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removePipeline({ commit }, { pipelineId }) {
    try {
        await this.getters.api.delete(`/opportunity-pipelines/${pipelineId}`);
        commit('removeOpportunityPipeline', { pipelineId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async addComment({ commit }, { opportunityId, opportunityComment }) {
    try {
        const data = await this.getters.api.post(`/opportunity/${opportunityId}/comments`, { data: toAPIComment(opportunityComment) });
        const comment = fromAPIComment(data);
        commit('setOpportunityComment', { opportunityId, data: comment });
        commit('updateOpportunityCommentCount', { opportunityId, interval: 1 });
        return comment;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateComment({ commit }, { opportunityId, commentId, opportunityComment }) {
    try {
        const data = await this.getters.api.patch(`/opportunity/${opportunityId}/comments/${commentId}`, { data: toAPIComment(opportunityComment) });
        // TODO remove
        opportunityComment.id = commentId;
        commit('setOpportunityComment', { opportunityId, data: opportunityComment });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeComment({ commit }, { opportunityId, commentId }) {
    try {
        await this.getters.api.delete(`/opportunity/${opportunityId}/comments/${commentId}`);
        commit('removeOpportunityComment', { opportunityId, commentId });
        commit('updateOpportunityCommentCount', { opportunityId, interval: -1 });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { value }) {
    state.fetched = value;
  },
  fetchedPipelines(state, { value }) {
    state.fetchedPipelines = value;
  },
  setOpportunityPipelineStats(state, { pipelineId, data }) {
    state.pipelinesStats[pipelineId] = data;
  },
  setOpportunity(state, { data }) {
    const index = state.opportunity.findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.opportunity, index, { ...data});
    }
    else {
      state.opportunity.push(data);
    }
    // Vue.set(state.opportunity[customerId], state.opportunity[clientId][customerId].length, data);
  },
  removeOpportunity(state, { opportunityId }) {
    const index = state.opportunity.findIndex(c => c.id === opportunityId);
    if (index > -1) {
        state.opportunity.splice(index, 1);
    }
  },
  setOpportunities(state, { data }) {
    Vue.set(state, 'opportunity', data);
  },
  setOpportunityByStage(state, { stage, data }) {
    state.opportunity = state.opportunity.filter(t => t.stage !== stage);
    state.opportunity.push(...data);
  },
  updateOpportunityCommentCount(state, { opportunityId, interval }) {
    const opportunity = state.opportunity.find(c => c.id === opportunityId);
    if (opportunity) {
        opportunity.nbComments += interval;
    }
  },
  setOpportunityStats(state, { pipelineId, data }) {
    state.stats[pipelineId] = state.stats[pipelineId] || {};
    Vue.set(state.stats, pipelineId, data);
  },
  setOpportunityPipeline(state, { data }) {
    const index = state.opportunityPipelines.findIndex(c => c.id === data.id);
    if (index > -1) {// Update pipeline opportunity
        Vue.set(state.opportunityPipelines, index, Object.assign(state.opportunityPipelines[index], {...data }));
    }
    else {
      state.opportunityPipelines.unshift(data);
    }
    // Vue.set(state.opportunity[customerId], state.opportunity[clientId][customerId].length, data);
  },
  removeOpportunityPipeline(state, { pipelineId }) {
    const index = state.opportunityPipelines.findIndex(c => c.id === pipelineId);
    if (index > -1) {
        state.opportunityPipelines.splice(index, 1);
    }
  },
  setOpportunityPipelines(state, { data }) {
    state.opportunityPipelines = data;
  },
  setOpportunityComments(state, { opportunityId, data }) {
      Vue.set(state.opportunityComments, opportunityId, data);
  },
  setOpportunityComment(state, { opportunityId, data }) {
    if (!state.opportunityComments[opportunityId]) Vue.set(state.opportunityComments, opportunityId, []);
    const index = state.opportunityComments[opportunityId].findIndex(c => c.id === data.id);
    if (index > -1) {// Update pipeline opportunity customerId
        Vue.set(state.opportunityComments[opportunityId], index, { ...data });
    }
    else {
      state.opportunityComments[opportunityId].push(data);
    }
  },
  removeOpportunityComment(state, { opportunityId, commentId }) {
    const index = state.opportunityComments[opportunityId]?.findIndex(c => c.id === commentId);
    if (index > -1) {
        state.opportunityComments[opportunityId].splice(index, 1);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (!json.priority) json.priority = null;
    if (json.dueDate) json.dueDate = dayjs(json.dueDate).toDate();
    if (json.endAt) json.endAt = dayjs(json.endAt).toDate();
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    if (json.users) json.users = json.users.map(u => {
        // If allready an object -> Return object immediately
        if (typeof u === 'object') return { ...u };
        // Return object type user by default
        return { type: "USER", value: u };
    });
    json.nbComments = json.nbComments || 0;
    json.nbAttachements = json.nbAttachements || 0;
    return json;
}

function toAPI(opportunity) {
    const json = { ...opportunity };
    // if (json.dueDate) json.dueDate = dayjs(json.dueDate).format('YYYY-MM-DD');
    json.customerId = json.customerId || null;
    json.userId = json.userId || null;
    if (json.pipeline) {
        json.pipelineId = json.pipeline.id;
        delete json.pipeline;
    }
    delete json.dueTime;
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

function fromAPIPipeline(json) {
    if (json.opportunity) {
        json.opportunity = json.opportunity.map(fromAPI);
    }
    else {
      json.opportunity = [];
    }
    json.nbOpportunity = json.opportunity.length;
    
    json.opportunityStage = initOpportunityPipelineStage(json);
    return json;
}

function toAPIPipeline(pipeline) {
    const json = { ...pipeline };
    delete json.nbOpen;
    delete json.nbWon30d;
    delete json.nbLost30d;
    delete json.amountStats;
    return json;
}

function fromAPIComment(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPIComment(comment) {
    const json = { ...comment };
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

function initOpportunityPipelineStage(pipeline) {
    let opportunityStage = pipeline.opportunity.reduce((prev, cur) => {
        prev[cur.stage] = prev[cur.stage] || 0;
        prev[cur.stage]++;
        return prev;
    }, {});
    return opportunityStage;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
