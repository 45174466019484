import router from '@/router';

export function getTypeIcon (type) {
  switch(type) {
    case 'CUSTOMER':
      return 'icon-audience';
    case 'CONTACT':
      return 'icon-campaign';
    case 'USER':
      return 'icon-activate';
    case 'INTERACTION':
      return 'icon-action';
    case 'AGREEMENT':
      return 'icon-seeds';
    case 'TASK':
      return 'icon-seeds';
  }
  return '';
}

export function getTypeLink (partitionId, result) {
  let resolved = null;
  switch(result.type) {
    case 'CUSTOMER':
      resolved = router.resolve({
        name: 'customerOverview',
        params: {
            customerId: result.id
        }
      });
      break;
    case 'CONTACT':
        resolved = router.resolve({
          name: 'customerContacts',
          params: {
            customerId: result.customerId
          },
          query: {
              ref: result.id
          }
        });
        break;
    case 'USER':
        resolved = router.resolve({
          name: `users`,
          params: {
          },
          query: {
            ref: result.id
          }
        });
        break;
    case 'INTERACTION':
        resolved = router.resolve({
          name: 'customerInteractions',
          params: {
            customerId: result.customerId
          },
          query: {
            ref: result.id
          }
        });
        break;
    case 'AGREEMENT':
        resolved = router.resolve({
          name: 'customerAgreement',
          params: {
            customerId: result.customerId
          }
        });
        break;
    case 'TASK':
        resolved = router.resolve({
          name: 'tasks',
          params: {
            customerId: result.customerId
          },
          query: {
            ref: result.id
          }
        });
        break;
  }
  return resolved;
}
