import dayjs from 'dayjs';
import Vue from 'vue';
import i18n from '@/i18n';
import EventsBus from '@/libs/EventsBus';
import { parseDataType } from '@/store/modules/customs.js';

const state = {
  fetched: {},
  contacts: [],
  contactsIds: {},
  stats: {}
};

const getters = {
  getAll: (state, getters, store, rootGetters) => customerId => {
      return customerId ? state.contacts.filter(c => c.customerId === customerId) : state.contacts;
  },
  getAllByIds: (state, getters, store, rootGetters) => {
    return state.contactsIds;
  },
  getFromId: (state, getters) => ( id ) => {
      return state.contactsIds[id];
  },
  getStats: (state) => customerId => {
    return state.stats[customerId];
  },
  getNameFromId: (state, getters) => id =>  {
    const contact  = getters.getFromId(id);
    if (!contact) return '';
    return contact.firstName && contact.lastName ? `${contact.firstName} ${contact.lastName}` : `${contact.email}`;
  },
  getAvatarFromId: (state, getters) => id => getters.getFromId(id)?.avatar || '/faces/anonymous.png',
};

const actions = {

  async ensureContacts({ dispatch, state }, { customerId }) {
    if (!state.fetched[customerId]) {
      await dispatch('fetchContacts', { customerId });
    }
  },
  async ensureContactsIds({ dispatch, state }, { customerId, ids }) {
    const toLoadIds = ids.filter(i => !!i && !state.contactsIds[i]);
    if (toLoadIds.length) {
      let i,j, chunk = 50;
      const contactCalls = [];
      for (i=0,j=toLoadIds.length; i<j; i+=chunk) {
        contactCalls.push(toLoadIds.slice(i,i+chunk));
            // do whatever
      }
      await Promise.all(contactCalls.map(tmpIds => {
        return dispatch('fetchContactsByIds', { customerId, ids: tmpIds });
      }));
    }
  },
  async ensureContactsStats({ dispatch, state }, { customerId }) {
    if (!Object.keys(state.stats).length) {
      await dispatch('fetchContactsStats', { customerId });
    }
  },
  async fetchContacts({ dispatch, commit }, { customerId, ctx, ref }) {
    try {
      const query = {
          cache: true
      };
      if (ctx?.page) {
        query.pagination = ctx;
      }
      if (ref) {
          query.params = { ref };
      }
      if (ctx?.display) {
        query.params = query.params || {};
        query.params.display = ctx.display;
      }
      const results = await this.getters.api.get(customerId ? `/customers/${customerId}/contacts` : `/contacts`, query);
      const contacts = ctx ? results.data.map(fromAPI(customerId)) : results.map(fromAPI(customerId));
      commit('setContacts', { data: contacts });
      commit('fetched', { customerId, value: true });
      return ctx ? {
          total: results.metadata.pagination.total || 0,
          page: results.metadata.pagination.page || 1,
          data: contacts
      } : contacts;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchContactsByIds({ dispatch, commit }, { ids }) {
    try {
      const query = {
        params: {
            ids: ids.join(',')
        }
      };
      const data = (await this.getters.api.get(`/contacts`, query)).map(fromAPI());
      commit('setContactsIds', { data });
      return data; 
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchContactByEmail({ dispatch, commit }, { customerId, email }) {
    try {
      const query = {
        params: {
            name: email
        }
      };
      const data = (await this.getters.api.get(customerId ? `/customers/${customerId}/contacts` : `/contacts`, query)).map(fromAPI(customerId));
      commit('setContactsIds', { data });
      return data?.[0]; 
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchContactsStats({ dispatch, commit }, { customerId }) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/contacts/stats`);
      commit('setContactsStats', { customerId, data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { customerId, contact }) {
    try {
        const data = fromAPI(customerId)(await this.getters.api.post(`/customers/${customerId}/contacts`, {
            data: toAPI(contact),
            resetCache: [`/customers/${customerId}/contacts`, `/contacts`],
        }));
        data.customerId = customerId;
        commit('setContact', { customerId, data });
        return data;
      } catch (error) {
        if (error.response?.status === 400 && error.response.data?.data?.code === 'ERR_VALIDATION') {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.error'),
                variant: 'danger',
                autoHideDelay: 10000,
                text: i18n.t(`contacts.ope.created.failed.${error.response.data.data.data[0]}`)
            });
        }
        if (error.response?.status === 500 && error.response.data?.data?.message?.includes('Email already exists in team members')) {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.error'),
                variant: 'danger',
                autoHideDelay: 10000,
                text: i18n.t(`contacts.ope.created.failed.email-team`)
            });
        }
        throw 'A server error has occurred';
      }
  },
  async update({ commit, state, rootGetters }, { customerId, contactId, contact }) {
    try {
        const old = state.contacts.find(c => c.id === contactId) || getters.getFromId(contactId);
        const customs = rootGetters['customs/getAllByEntity']('CONTACT');
        if (customs.length) {
            for (const custom of customs) {
                if (contact[custom.id] === old[custom.id]) {
                    delete contact[custom.id];
                }
            }
        }
        const data = await this.getters.api.patch(`/customers/${customerId}/contacts/${contactId}`, {
            data: toAPI(contact),
            resetCache: [`/customers/${customerId}/contacts`, `/contacts`]
        });
        // TODO remove
        contact.id = contactId;
        contact.customerId = customerId;
        contact.updatedAt = new Date();
        commit('setContact', { customerId, data: contact });
        return contact;
      } catch (error) {
        if (error.response?.status === 400 && error.response.data?.data?.code === 'ERR_VALIDATION') {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.error'),
                variant: 'danger',
                autoHideDelay: 10000,
                text: i18n.t(`contacts.ope.created.failed.${error.response.data.data.data[0]}`)
            });
        }
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { customerId, contactId }) {
    try {
        await this.getters.api.delete(`/customers/${customerId}/contacts/${contactId}`, { resetCache: [`/customers/${customerId}/contacts`, `/contacts`] });
        commit('removeContact', { contactId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async export({ dispatch, commit }, { ctx }) {
    try {
      const query = {
          cache: false,
          pagination: ctx
      };
      const results = await this.getters.api.post(`/contacts/export`, query);
      const exportId = results.data?.id;
      if (exportId) {
        EventsBus.emit('beginExport', {
            ...results.data
        });
        const result = await this.dispatch('exports/waitExport', { exportId });
        console.info(result);
        EventsBus.emit('endExport', {
            id: exportId,
            result
        });
        return result;
      }
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async updateScore({ commit }, { customerId, contactId, npsScore }) {
    try {
        const data = await this.getters.api.patch(`/customers/${customerId}/contacts/${contactId}/score`, {
            data: { npsScore: npsScore },
            resetCache: [`/customers/${customerId}/contacts`, `/contacts`]
        });
        commit('setContact', { customerId, data: { id: contactId, customerId, npsScore, updatedAt: new Date() } });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async import({ dispatch, rootGetters }, { contacts }) {
    const result = {
        count: 0,
        created: 0,
        updated: 0,
        deleted: 0,
        error: 0
    };
    try {
        const customs = rootGetters['customs/getAllByEntity']('CONTACT');

        const customers = await this.dispatch('customers/fetchCustomers');
        let total = 0;
        const contactsByCustomer = contacts.reduce((prev, cur) => {
            const found = customers.find(c => (cur.customer?.length && c.refId?.toLowerCase() === cur.customer?.toLowerCase()) || (cur.customer?.length && c.domain?.toLowerCase() === cur.customer?.toLowerCase()) || (cur.customer?.length && c.name?.toLowerCase() === cur.customer?.toLowerCase()));
            if (found) {
                prev[found.id] = prev[found.id] || [];
                prev[found.id].push(cur);
                total++;
            }
            return prev;
        }, {});

        EventsBus.emit('contacts/import/start', {
            count: total
        });

        for(const customerId of Object.keys(contactsByCustomer)) {
            const size = 100;
            let page = 1;
            let results = await dispatch('fetchContacts', { customerId, ctx: { page, size, display: 'import' } });
            const actualContacts = results.data;
            while(results.total > (size  * page)) {
                page++;
                results = await dispatch('fetchContacts', { customerId, ctx: { page, size, display: 'import' } });
                Array.prototype.push.apply(actualContacts, results.data);   
            }

            // Upset contacts
            for (const contact of contactsByCustomer[customerId].filter(c => !c.deleted)) {
                contact.source = 'IMPORT';
                const found = actualContacts.find(c => c.email?.toLowerCase() === contact.email?.toLowerCase() || (contact.refId?.length && c.refId?.length && contact.refId?.toLowerCase() === c.refId?.toLowerCase()));
                if (found) {
                    const updated = { ...found };
                    if (contact.email?.length) updated.email = contact.email;
                    if (contact.refId?.length) updated.refId = contact.refId;
                    if (contact.additionalEmails) updated.additionalEmails = contact.additionalEmails;
                    if (contact.phone) updated.phone = contact.phone;
                    if (contact.description) updated.description = contact.description;
                    if (contact.firstName) updated.firstName = contact.firstName;
                    if (contact.lastName) updated.lastName = contact.lastName;
                    if (contact.language) updated.language = contact.language;
                    if (contact.role) updated.role = contact.role;
                    if (contact.npsScore) updated.npsScore = contact.npsScore;
                    if (contact.avatar) updated.role = contact.avatar;
                    if (contact.jobTitle) updated.jobTitle = contact.jobTitle;
                    if (contact.gender) updated.gender = contact.gender;
                    if (contact.tags && typeof contact.tags !== 'undefined') updated.tags = contact.tags;
                    else delete updated.tags;
                    // Update all custom defined
                    if (customs.length) {
                        for (const custom of customs) {
                            if (typeof contact[custom.id] !== 'undefined') {
                                updated[custom.id] = parseDataType(contact, custom);
                            }
                        }
                    }
                    try {
                        await dispatch('update', { customerId, contactId: updated.id, contact: updated });
                        if(typeof contact.npsScore !== 'undefined') await dispatch('updateScore', { customerId, contactId: updated.id, npsScore: contact.npsScore });
                        result.updated++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                else {
                    try {
                        // Delete all custom undefined
                        if (customs.length) {
                            for (const custom of customs) {
                                if (typeof contact[custom.id] === 'undefined') {
                                    delete contact[custom.id];
                                } else {
                                    contact[custom.id] = parseDataType(contact, custom);
                                }
                            }
                        }
                        const data = await dispatch('add', { customerId, contact });
                        if(typeof contact.npsScore !== 'undefined') await dispatch('updateScore', { customerId, contactId: data.id, npsScore: contact.npsScore });
                        result.created++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                result.count++;
                console.info(contact)
                EventsBus.emit('contacts/import/process', contact);
            }
            // Delete contacts
            for (const contact of contactsByCustomer[customerId].filter(c => !!c.deleted)) {
                const found = actualContacts.find(c => c.email?.toLowerCase() === contact.email?.toLowerCase() || (contact.refId && c.refId && contact.refId?.toLowerCase() === c.refId?.toLowerCase()));
                if (found) {
                    try {
                        await dispatch('remove', { customerId, contactId: found.id });
                        result.deleted++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                result.count++;
                EventsBus.emit('contacts/import/process', contact);
            }
        }
        // Raz server redis cache
        if (total > 0) {
            this.dispatch('cache/resetServer');
        }    
      } catch (error) {
        throw 'A server error has occurred';
      }
      return result;
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, {  customerId, value }) {
    state.fetched[customerId] = value;
  },
  setContact(state, { data }) {
    const index = state.contacts.findIndex(c => c.id === data.id);
    if (index > -1) {
        Vue.set(state.contacts, index, { ...state.contacts[index], ...data});
    }
    else {
        state.contacts.unshift(data);
    }
    if (data.email || state.contactsIds[data.id]) {
        Vue.set(state.contactsIds, data.id, {...state.contactsIds[data.id], ...data });
    }
  },
  setContacts(state, { data }) {
    Vue.set(state, 'contacts', data);
  },
  setContactsIds(state, { data }) {
    for(const contact of data) {
        Vue.set(state.contactsIds, contact.id, contact);
    }
  },
  removeContact(state, {  contactId }) {
    const index = state.contacts.findIndex(c => c.id === contactId);
    if (index > -1) {
        state.contacts.splice(index, 1);
    }
    if (state.contactsIds[contactId]) {
        delete state.contactsIds[contactId];
    }
  },
  setContactsStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId, data);
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(customerId) {
    return function (json) {
        if (customerId) json.customerId = customerId;
        if (json.lastTouchTs) json.lastTouchTs = dayjs(json.lastTouchTs).toDate();
        if (json.lastUserTouchTs) json.lastUserTouchTs = dayjs(json.lastUserTouchTs).toDate();
        if (json.lastActivityTs) json.lastActivityTs = dayjs(json.lastActivityTs).toDate();
        if (json.renewalDate) json.renewalDate = dayjs(json.renewalDate).toDate();
        if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
        if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
        if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
        return json;
    }  
}

function toAPI(contact) {
    const json = {...contact};
    delete json.customer;
    delete json.npsScore;
    delete json.customerId;
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    delete json.lastTouchTs;
    delete json.lastUserTouchTs;
    delete json.lastActivityTs;
    delete json['30dActivityVisits']
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
