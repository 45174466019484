import './google-analytics';
import './click-outside-directive';
import './paste-directive';
import './vuelidate-validator';
import './internal';
import './role';
import './feature';
import './client';
import './user';
import './currency';
import './env';
import './debug';
import './lang';
import './customer';

