
import Vue from "vue";
import dayjs from 'dayjs';
import EventsBus from '@/libs/EventsBus';
import { currencySymbols, defaultCurrencySymbols } from '@/libs/utils/Currency';

const state = {
//   currentClientId: 'b86a996521',
  currentClientId: null,
  currentClient: null,
  currentRole: 0,
  clients: null
};

const getters = {
    currentClientId: (state) => state.currentClientId,
    currentRole: (state) => state.currentRole,
    currentClient: (state) => {
        return state.currentClient;
    },
    currency: (state) => {
        return currencySymbols[state.currentClient?.currency] || defaultCurrencySymbols;
    },
    getAll: state => {
        return state.clients;
    },
    getFromId: (state, getters) => id => getters.getAll.find(a => a.id === id)
};

const actions = {
    async setCurrentClientId({ commit, getters }, { clientId }) {
        const client = getters.getAll?.find(c => c.id === clientId);
        if (client) {
            commit("setCurrentClient", { client });
            this.commit('users/reset');
            this.commit('customers/reset');
            this.commit('interactions/reset');
        }
         
    },
    async ensureClients({ dispatch, state }) {
        if (!state.clients) {
            await dispatch("fetchClients");
        }
    },
    async fetchClients({ dispatch, commit }) {
        try {
            const data = await this.getters.api.get("/clients");
            if (!data?.length) {
                return this.dispatch('users/logout');
            }
            commit("setClients", { data });
            const lastClientId = localStorage.getItem('clientId');
            if (lastClientId) {
                const lastClient = data.find(c => c.id === lastClientId);
                commit("setCurrentClient", { client: lastClient || data[0] });
            }
            else {
                commit("setCurrentClient", { client: data[0] });
            }
            return data;
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async add({ commit }, { client }) {
        try {
            const data = await this.getters.api.post(`/clients`, { data: client });
            commit("setClient", { client: data });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async update({ commit }, { name, currency, seats }) {
        try {
            const data = {
                name,
                currency,
                seats
            };
            if (!data.name) delete data.name;
            if (!data.currency) delete data.currency;
            if (!data.seats) delete data.seats;

            const clientId = this.getters.currentClientId;
            await this.getters.api.patch(`/clients/${clientId}`, {
                data
            });
            commit("setClientProp", data);
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async remove({ commit }, { clientId }) {
        try {
            await this.getters.api.delete(`/clients/${clientId}`);
            commit("removeClient", { clientId });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async activateTrial({ commit, getters }, { feature }) {
        try {
            const clientId = this.getters.currentClientId;
            await this.getters.api.post(`/clients/${clientId}/activate-trial`, {
                data: {
                    feature
                }
            });
            commit("setClientProp", {
                additionalFeatures: [...(getters.currentClient.additionalFeatures || []), { name: feature, expire: dayjs().add(30, 'day').toDate() }]
            });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
};

const mutations = {
    setCurrentClient(state, { client }) {
        const hasChanged = state.currentClientId !== client.id;
        state.currentClientId = client.id;
        state.currentClient = client;
        state.currentRole = client.role;
        if (localStorage) {
            localStorage.setItem('clientId', client.id);
            localStorage.setItem('clientName', client.domain);
            localStorage.setItem('clientRole', client.role);
        }
        if (hasChanged) {
            EventsBus.emit('setCurrentClient', { client });
            EventsBus.emit('setCurrency', { currency: client.currency });
        }
    },
    setClients(state, { data }) {
        state.clients = data;
    },
    setClient(state, { client }) {
        const index = state.clients.findIndex(c => c.id === client.id);
        if (index > -1) {
            Vue.set(state.clients, index, { ...state.clients[index], ...client });
        } else {
            state.clients.push(client);
        }
    },
    setCurrentRole(state, { role }) {
        state.currentRole = role;
        if (localStorage) {
            localStorage.setItem('clientRole', role);
        }
    },
    setClientProp(state, data) {
        for (const prop of Object.keys(data)) {
            Vue.set(state.currentClient, prop, data[prop]);
        }
    },
    removeClient(state, { clientId }) {
        const index = state.clients.findIndex(c => c.id === clientId);
        if (index > -1) {
            state.clients.splice(index, 1);
        }
    }
};

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
