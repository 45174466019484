
import Dexie from 'dexie';

const STORE_VERSION = 2;
let db = null;
let initialized = false;

export function initDb() {
    if (initialized) return db;
    initialized = true;
    try {
        db = new Dexie('SkalinUI');
        db.version(STORE_VERSION).stores({
            stages: 'name,clientId,createdAt,updatedAt',
            profiles: 'id,clientId,createdAt,updatedAt',
            tags: '[id+entity],clientId,createdAt,updatedAt',
            customers: 'id,clientId,createdAt,updatedAt',
            customersKpi: 'customerId,clientId,createdAt,updatedAt'
        });
        return db;
    }
    catch(ex) {
        console.info(ex);
    }
}

export function getDb() {
    return db;
}