export function downloadAsBase64(url) {
    return new Promise((resolve, reject) => {
        let reader = new window.FileReader();
        fetch(url)
        .then(response => response.blob())
        .then(myBlob => reader.readAsDataURL(myBlob))
        .then(myBase64 => {
          resolve(myBase64);
        })
        .catch(error => {
          reject(error);
        });
    });
}

export function getFileFromPasteEvent(event) {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === 'file') {
        return item.getAsFile();
      }
    }
  }

export function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}