import api from 'api-client';

export default class {

  constructor() {}

  async get (url, params = { skipPartition: false, skipVersion: false }) {
    return await api.get(url, params);
  }

  async post (url, params = { skipPartition: false, skipVersion: false }) {
    return await api.post(url, params);
  }

  async delete (url, params = { skipPartition: false, skipVersion: false }) {
    return await api.delete(url, params);
  }

  async put(url, params = { skipPartition: false, skipVersion: false }) {
    return await api.put(url, params);
  }

  async patch(url, params = { skipPartition: false, skipVersion: false }) {
    return await api.patch(url, params);
  }
}
