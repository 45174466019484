var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    staticClass: "vcs__arrow",
    class: _vm.direction,
    style: { borderColor: _vm.borderColor }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }