var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentClientId && _vm.clients && _vm.clients.length > 1
    ? _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("div", { staticClass: "header-btn-lg pr-0" }, [
            _c("div", { staticClass: "widget-content p-0" }, [
              _c("div", { staticClass: "widget-content-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "widget-content-left" },
                  [
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          "toggle-class": "",
                          "menu-class": "p-1",
                          variant: "link",
                          right: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "widget-heading" }, [
                                    _vm._v(_vm._s(_vm.clientName))
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3715609151
                        )
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "m-0",
                          attrs: {
                            options: _vm.clients,
                            placeholder: "Client"
                          },
                          on: { change: _vm.handleChangeClient },
                          model: {
                            value: _vm.clientId,
                            callback: function($$v) {
                              _vm.clientId = $$v
                            },
                            expression: "clientId"
                          }
                        }),
                        _vm.$isInternal()
                          ? _c(
                              "div",
                              { staticClass: "text-center mt-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      size: "sm",
                                      variant: "outline-primary"
                                    },
                                    on: { click: _vm.handleCreateClient }
                                  },
                                  [_vm._v("Create a client")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("create-client-modal", { on: { ok: _vm.onClientSave } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }