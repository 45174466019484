import dayjs from 'dayjs';
import Vue from 'vue';
import i18n from "@/i18n";
import _ from 'lodash';
import { paginator } from "@/libs/utils/Array";
import EventsBus from '@/libs/EventsBus';
import SseService from '@/libs/SseService';

export const notificationsType = {
    NEW: 'NEW',
    ALL: 'ALL',
    ARCHIVED: 'ARCHIVED'
};

function createPaginator(notificaitonsType, isClientPagination = false) {
    const newpaginator = paginator(10, {isClientPagination: isClientPagination});
    switch (notificaitonsType) {
        case 'NEW':
            newpaginator.filters.readed = {
                type: "eq",
                value: false
            };
            break;
        case 'ALL':
            newpaginator.filters.archived = {
                type: "eq",
                value: false
            };
            break;
        case 'ARCHIVED':
            newpaginator.filters.archived = {
                type: "eq",
                value: true
            };
            break;
        default:
            break;
        }
    return newpaginator;
}

const state = {
    stats: {},
    newNotifications: [],
    allNotifications: [],
    archivedNotifications: [],
    filters: [
        {text: i18n.t("notifications.filters.label.mentions"), value: { column: 'eventType', value: ['MENTION'], label: 'evenement'}, checked: true},
        {text: i18n.t("notifications.filters.label.mentions.interaction"), value: { column: 'notificationType', value: ['INTERACTION_DESC', 'INTERACTION_COMMENT'], label: 'type'}, checked: true},
        {text: i18n.t("notifications.filters.label.tasks"), value: { column: 'notificationType', value: ['TASK_DESC', 'TASK_COMMENT'], label: 'type'}, checked: true},

    ],
    hasFilter: false,
    newNotificationPaginator: createPaginator(notificationsType.NEW),
    allNotificationPaginator: createPaginator(notificationsType.ALL),
    archivedNotificationPaginator: createPaginator(notificationsType.ARCHIVED),
    statsPaginator: createPaginator(null, true),
    allLoadednewNotification: false,
    allLoadedallNotification: false,
    allLoadedarchivedNotification: false,

};

const getters = {
    getUserStats: (state) => state.stats || {},
    getAllNotifications: (state) => state.allNotifications,
    getArchivedNotifications: (state) => state.archivedNotifications,
    getNewNotifications: (state) => state.newNotifications,
    getdefaultFilters: (state) => state.filters,
    getHasFilters: (state) => state.hasFilter,
    isAllLoadedNotifications: (state) => (notificaitonsType) => state[`allLoaded${notificaitonsType.toLowerCase()}Notification`],
};

const actions = {
    async fetchUserStats({ commit }) {
        try {
            const query = {};

            const paginator = state.statsPaginator;
            let ctx = null; 
            if (Object.keys(paginator.filters).length > 0) {
                    ctx = paginator.toCtx();
                    query.pagination = ctx;
            }

            let data = await this.getters.api.get(`/notifications/stats`, query);

            data = fromAPIStats(ctx ? data.data : data);

            commit('setStats',data);


        } catch (error) {
            throw 'A server error has occurred';
        }
    },
    async updateArchived({ commit, dispatch }, notification) {
        try {
            await this.getters.api.patch(`/notifications/${notification.id}/archived`, {data: notification});
            if (notification.userNotification.archived) {
                commit('addNotification', { notification: notification , notificationsType: 'ARCHIVED'});
                commit('deleteNotification', { notification: notification, notificationsType: 'ALL'});
                if (!notification.userNotification.readed) commit('deleteNotification', { notification: notification, notificationsType: 'NEW'});
            }
            else {
                commit('deleteNotification', { notification: notification, notificationsType: 'ARCHIVED'});
                commit('addNotification', { notification: notification , notificationsType: 'ALL'});
                if (!notification.userNotification.readed) commit('addNotification', { notification: notification , notificationsType: 'NEW'});
            }
            dispatch('fetchUserStats');
        } catch (error) {
            throw 'A server error has occurred';
        }
    },
    async updateReaded({ commit, dispatch }, notification) {
        try {
            await this.getters.api.patch(`/notifications/${notification.id}/readed`, {data: notification});

            if (notification.userNotification.readed) {
                commit('deleteNotification', { notification: notification, notificationsType: 'NEW'});
            }
            else {
                commit('addNotification', { notification: notification , notificationsType: 'NEW'});
            }
            commit('updateNotification', { notification: notification , notificationsType: 'ARCHIVED'});
            commit('updateNotification', { notification: notification , notificationsType: 'ALL'});

            dispatch('fetchUserStats');
        } catch (error) {
            throw 'A server error has occurred';
        }
    },
    async ensureNotificationId({ state }, notificationId) {
        let allReadyExist = true;
        let notification = state.allNotifications.find((n) => n.id === notificationId);
        if (!notification) {
            allReadyExist = false;
            try {
                notification = await this.getters.api.get(`/notifications/${notificationId}`);
                
            } catch (error) {
                throw 'A server error has occurred';
            }
        }
        const newNotification = fromAPI(notification);
        return { newNotification, allReadyExist};
    },
    async addNewNotification({ commit, dispatch }, notification) {
        const { newNotification , allReadyExist } = await dispatch('ensureNotificationId', notification.notificationId);
        if (!allReadyExist) {
            if (!newNotification.userNotification.readed) {
                commit('addNotification', { notification: newNotification, notificationsType: 'NEW' });
                EventsBus.emit('notification/user/create');
            }

            if (newNotification.userNotification.archived) {
                commit('addNotification', { notification: newNotification, notificationsType: 'ARCHIVED' });
            }
            else {
                commit('addNotification', { notification: newNotification, notificationsType: 'ALL' });
            }
            dispatch('fetchUserStats');

        }

    },
    async delete({ commit, dispatch }, notification) {
        try {
            await this.getters.api.delete(`/notifications/${notification.id}`);

            commit('deleteNotification', { notification: notification , notificationsType: 'ARCHIVED' });
            commit('deleteNotification', { notification: notification , notificationsType: 'NEW' });
            commit('deleteNotification', { notification: notification , notificationsType: 'ALL' });

            dispatch('fetchUserStats');
        } catch (error) {
            throw 'A server error has occurred';
        }
    },
    async fetchNotifications({ commit, state }, { notificationsType }) {
        try {
            const query = {
                cache: false,
            };
            let ctx = null;

            if (notificationsType) {
                const paginator = state[`${notificationsType.toLowerCase()}NotificationPaginator`];
                ctx = paginator.toCtx();
            }

            if (ctx?.page) {
              query.pagination = ctx;
            }
            if (ctx?.display) {
              query.params = query.params || {};
              query.params.display = ctx.display;
            }

            const { metadata, data } = await this.getters.api.get(`/notifications`, query);
            const notifications = data.map(fromAPI);
            commit('setNotifications', { notifications: notifications, notificationsType: notificationsType });
            commit('setPaginatorPagination', { metadata: metadata, notificationsType: notificationsType, data: data });

            return {
              total: metadata.pagination.total || 0,
              page: metadata.pagination.page || 1,
              data: notifications
            };
        } catch (error) {
            throw 'A server error has occurred';
        }
    },
    setFilters({ commit, dispatch }, filter) {
        commit('setFilters', filter);
        commit('setPaginatorFilters');
        commit('setHasFilters');
        commit('resetNotifications');
        commit('resetCurrentPagePaginator');   
        dispatch('fetchNotifications', { notificationsType: 'ALL' });
        dispatch('fetchNotifications', { notificationsType: 'NEW' });
        dispatch('fetchNotifications', { notificationsType: 'ARCHIVED' });
        dispatch('fetchUserStats');
    }
};

export const mutations = {
    setStats(state, stats) {
        Vue.set(state, 'stats', stats);
    },
    setNotifications(state, { notifications, notificationsType }) {
        if (!notificationsType) return;
        let array = state[`${notificationsType.toLowerCase()}Notifications`];
        array = array.concat(notifications);
        array = Array.from(
            new Map(array.map(obj => [obj.id, obj])).values()
        );
        array = array.sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)));
        Vue.set(state, `${notificationsType.toLowerCase()}Notifications`, array);
    },
    updateNotification(state, { notification, notificationsType }) {
        if (!notificationsType) return;
        const index = state[`${notificationsType.toLowerCase()}Notifications`].findIndex((n) => n.id === notification.id);
        if (index !== -1) {
            Vue.set(state[`${notificationsType.toLowerCase()}Notifications`], index, notification);
        }
    },
    deleteNotification(state, { notification, notificationsType }) {
        if (!notification) return;
        const index = state[`${notificationsType.toLowerCase()}Notifications`].findIndex((n) => n.id === notification.id);
        if (index !== -1) {
            Vue.delete(state[`${notificationsType.toLowerCase()}Notifications`], index);
            if (state[`${notificationsType.toLowerCase()}Notifications`].length > 0) {
                state[`${notificationsType.toLowerCase()}NotificationPaginator`].currentPage = Math.floor(state[`${notificationsType.toLowerCase()}Notifications`].length / 10) + 1;
            } 
        }
    },
    addNotification(state, { notification, notificationsType }) {
        if (!notificationsType) return;
        state[`${notificationsType.toLowerCase()}Notifications`].push(notification);
        state[`${notificationsType.toLowerCase()}Notifications`] = state[`${notificationsType.toLowerCase()}Notifications`].sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)));
    },
    resetNotifications(state) {
        Vue.set(state, 'newNotifications', []);
        Vue.set(state, 'allNotifications', []);
        Vue.set(state, 'archivedNotifications', []);
    },
    setFilters(state, filter) {
        Vue.set(state, 'filters', _.cloneDeep(filter));
    },
    setPaginatorFilters(state) {
        const paginatorList = [state.newNotificationPaginator, state.allNotificationPaginator, state.archivedNotificationPaginator, state.statsPaginator];
        for (let filter of state.filters) {
            if (!filter.checked) {
                try {
                    const paginatorFilterAddOrCreate = (paginator) => {
                        if (paginator.filters[filter.value.column]) {
                            for (const newval in filter.value.value) {
                                if (!paginator.filters[filter.value.column].exclude.includes(filter.value.value[newval])) {
                                    paginator.filters[filter.value.column].exclude.push(filter.value.value[newval]);
                                }
                            } 
                        }
                        else {
                            paginator.filters[filter.value.column] = {
                                type: "enum",
                                exclude: filter.value.value,
                                operator: 'OR'
                            };
                        }
                    }; 
                    for (let paginator of paginatorList) {
                        paginatorFilterAddOrCreate(paginator);
                    }

                } catch (error) {
                    throw 'A server error has occurred';
                }
            } else {
                try {
                    const paginatorFilterRemoveOrDelete = (paginator) => {
                        if (paginator.filters?.[filter.value.column]) {
                            paginator.filters[filter.value.column].exclude = paginator.filters[filter.value.column].exclude.filter((value) => !filter.value.value.includes(value));
                            if (paginator.filters[filter.value.column].exclude.length === 0) {
                                delete paginator.filters[filter.value.column];
                            }
                        }
                    };
                    
                    for (let paginator of paginatorList) {
                        paginatorFilterRemoveOrDelete(paginator);
                    }
                } catch (error) {
                    throw 'A server error has occurred';
                }
            }
        }
    },
    resetCurrentPagePaginator(state) {
        for (const type of Object.keys(notificationsType)) {
            state[`${type.toLowerCase()}NotificationPaginator`].currentPage = 1;
            state[`allLoaded${type.toLowerCase()}Notification`] = false;
        }
    },
    setPaginatorPagination(state, { metadata, notificationsType }) {
        try {
            state[`${notificationsType.toLowerCase()}NotificationPaginator`].set({ data: [], total: metadata.pagination.total });
            if (state[`${notificationsType.toLowerCase()}NotificationPaginator`].currentPage < state[`${notificationsType.toLowerCase()}NotificationPaginator`].totalPages) {
                state[`${notificationsType.toLowerCase()}NotificationPaginator`].currentPage = state[`${notificationsType.toLowerCase()}NotificationPaginator`].currentPage + 1;
            }
            else if (state[`${notificationsType.toLowerCase()}NotificationPaginator`].totalPages === state[`${notificationsType.toLowerCase()}NotificationPaginator`].currentPage && state[`${notificationsType.toLowerCase()}NotificationPaginator`].currentPage > 0) {
                state[`allLoaded${notificationsType.toLowerCase()}Notification`] = true;
            }
        }
        catch (error) {
            throw 'A server error has occurred';
        }
    },
    setHasFilters(state) {
        const lengthFiltersTrue = state.filters.filter((filter) => filter.checked === true).length;
        Vue.set(state, 'hasFilter', lengthFiltersTrue > 0 && lengthFiltersTrue < state.filters.length);
    },
};

function fromAPIStats(json) {
    if (typeof json?.new === 'string') {
        json.new = parseInt(json.new);
    }
    return json;
}

function fromAPI(json) {
    json.userNotification.readedAt = json.userNotification?.readedAt ? dayjs(json.userNotification?.readedAt).toDate() : json.userNotification?.readedAt;
    json.userNotification.archivedAt = json.userNotification?.archivedAt ? dayjs(json.userNotification?.archivedAt).toDate() : json.userNotification?.archivedAt;
    json.userNotification.deletedAt = json.userNotification?.deletedAt ? dayjs(json.userNotification?.deletedAt).toDate() : json.userNotification?.deletedAt;
    return json;
}

export default {
  namespaced: true,
  init: async (context) => {
    SseService.on('notification/user/create', async (data) => {
        await context?.dispatch('addNewNotification', data);
    });
  },
  state,
  getters,
  actions,
  mutations
};