
import Vue from 'vue';
import dayjs from 'dayjs';

export const CUSTOM_TYPE = {
    STATE: 'STATE',
    HISTORICAL: 'HISTORICAL'
};
export const CUSTOM_ENTITY = {
    CUSTOMER: 'CUSTOMER',
    CONTACT: 'CONTACT',
    AGREEMENT: 'AGREEMENT'
}
export const CUSTOM_DATA_TYPE = {
    STRING: 'STRING',
    NUMBER: 'NUMBER',
    CURRENCY: 'CURRENCY',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    BOOLEAN: 'BOOLEAN',
    PERCENT: 'PERCENT',
    URL: 'URL',
    USER: 'USER',
    ENUM: 'ENUM',
    SCORE: 'SCORE'
}

const state = {
  fetched: {},
  customs: {}
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
      return state.customs[rootGetters.currentClientId] || [];
  },
  getFromId: (state, getters) => id => getters.getAll.find(a => a.id === id),
  getAllByEntity: (state, getters) => entity => getters.getAll.filter(a => a.entity === entity).sort((a, b) => {
      if (a.type !== b.type) return ('' + b.type).localeCompare(a.type);
      return ('' + a.name).localeCompare(b.name);
  }),
  getDataTypes: () => {
    return Object.keys(CUSTOM_DATA_TYPE).map(t => {
        return {
            id: t,
            type: CUSTOM_DATA_TYPE[t]
        };
    })
  }
};

const actions = {

  async ensureCustoms({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetched[clientId]) {
      return await dispatch('fetchCustoms');
    }
    return state.customs[clientId];
  },
  async fetchCustoms({ dispatch, commit }, { ctx = null } = {}) {
    try {
        const query = {}

        if (ctx) {
            query.pagination = ctx;
        }

        const clientId = this.getters.currentClientId;
        const results = await this.getters.api.get('/customs', query);
        const data = ctx ? results.data.map(fromAPI) : results.map(fromAPI);

        commit('fetched', { clientId, value: true });
        commit('setCustoms', { clientId, data });

        return data;
    } catch (error) {
        console.error(error);
        throw 'A server error has occurred';
    }
  },
  async fetchCustomsEntity({ dispatch, commit }, { entity }) {
    try {
      const clientId = this.getters.currentClientId;
      const data = (await this.getters.api.get(`/customs/${entity}`)).map(fromAPI);
      commit('setCustomsEntity', { clientId, entity, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { custom }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.post(`/customs`, { data: toAPI(custom) });
        const model = fromAPI(data);
        commit('setCustom', { clientId, data: model });
        return model;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { customId, custom }) {
    try {
        const clientId = this.getters.currentClientId;
        const dataCustom = toAPI(custom);
        delete dataCustom.type;
        delete dataCustom.entity;
        delete dataCustom.dataType;
        const data = await this.getters.api.patch(`/customs/${customId}`, { data: dataCustom });
        // TODO remove
        custom.id = customId;
        commit('setCustom', { clientId, data: fromAPI(custom) });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { customId }) {
    try {
        const clientId = this.getters.currentClientId;
        await this.getters.api.delete(`/customs/${customId}`);
        commit('removeCustom', { clientId, customId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { clientId, value }) {
    state.fetched[clientId] = value;
  },
  setCustom(state, { clientId, data }) {
    const index = state.customs[clientId].findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.customs[clientId], index, { ...state.customs[clientId][index], ...data});
    }
    else {
        state.customs[clientId].push(data);
    }
  },
  setCustoms(state, { clientId, data }) {
    Vue.set(state.customs, clientId, data);
  },
  setCustomsEntity(state, { clientId, entity, data }) {
    Vue.set(state.customs, clientId, state.customs.filter(c => c.entity !== entity));
    state.customs[clientId].push(data);
  },
  removeCustom(state, { clientId, customId }) {
    const index = state.customs[clientId].findIndex(c => c.id === customId);
    if (index > -1) {
        state.customs[clientId].splice(index, 1);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPI(custom) {
    const json = {...custom};
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export function parseDataType(entity, customInfos) {
    let value = entity[customInfos.id];
    switch(customInfos.dataType) {
        case 'BOOLEAN':
            return [true, 'true', 1, '1'].includes(value?.toLowerCase?.());
        case 'NUMBER':
        case 'CURRENCY':
        case 'PERCENT':
            return value.length ? parseFloat(value) : null;
        case 'DATE':
            return (Object.prototype.toString.call(value) === '[object Date]' || value?.length) && dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : null;
        case 'DATETIME':
            return (Object.prototype.toString.call(value) === '[object Date]' || value?.length) && dayjs(value).isValid() ? dayjs(value).toDate() : null;
        default:
            return value;
    }
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
