/* eslint-disable */
import dayjs from 'dayjs';
import { BaseInterceptor } from './BaseInterceptor';
export class TransformInterceptor extends BaseInterceptor {


  getType() {
    return 'TransformInterceptor';
  }

  responseInterceptor() {
    return {
      response: response => {
        if (response.data?.data) {
            const data = response.data.data;
            if (Array.isArray(data)) {
                return response.data?.metadata ? { metadata: response.data.metadata, data: data.map(this.transform) } : data.map(this.transform);
            }
            else {
                return response.data?.metadata ? { metadata: response.data.metadata, data: this.transform(data) } : this.transform(data);
            }
        }
        return response;
      }
    };
  }

  transform(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}
}
