var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClose,
          expression: "handleClose"
        }
      ],
      staticClass: "vcs",
      class: { "-inline": _vm.inline },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.handleClose.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "vcs__picker",
          attrs: { tabindex: "0" },
          on: {
            click: function() {
              return _vm.handleOpen(false)
            },
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return function() {
                return _vm.handleOpen(true)
              }.apply(null, arguments)
            }
          }
        },
        [
          _c("input", {
            attrs: {
              disabled: "",
              title: _vm.value,
              placeholder: _vm.placeholder
            },
            domProps: { value: _vm.value }
          }),
          _c("transition", { attrs: { name: "vcs__fade" } }, [
            _vm.value && _vm.clearable
              ? _c("div", { staticClass: "vcs__cross" }, [
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("clear")
                        }
                      }
                    },
                    [_vm._v("\n          ×\n        ")]
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "vcs__arrow-container" },
            [
              _c("Arrow", {
                attrs: {
                  borderColor: "#ccc",
                  direction: _vm.isOpen ? "up" : "down"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "vcs__fade" } },
        [
          _vm.isOpen
            ? _c(
                "SelectMenu",
                {
                  attrs: {
                    onSelect: _vm.handleSelect,
                    options: _vm.options,
                    withKeyboard: _vm.withKeyboard
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function(_, name) {
                        return {
                          key: name,
                          fn: function(slotData) {
                            return [_vm._t(name, null, null, slotData)]
                          }
                        }
                      })
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._l(_vm.$slots, function(_, name) {
                    return _vm._t(name)
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }