import Vue from 'vue';
import VueGtm from 'vue-gtm';
import router from '@/router/index';

// Fix undefined
Vue.config.globalProperties = Vue.config.globalProperties || {};
// Google Analytics vue-gtm
// FIXE ME true or false / dev or prod
// PROD : enabled: true, debug: false
// DEV: enabled: false, debug: true
try {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM_ID, // Your GTM ID
        enabled: process.env.VUE_APP_GTM_ENABLED === 'true', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        vueRouter: router // Pass the router instance to automatically sync with router (optional)
        // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
      });
}
catch(ex) {
    console.error(ex);
}
