<template>
    <b-toast id="AgreementsViewToast" variant="info" solid :no-auto-hide="true" :visible="visible">
        <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">{{  $t('news.toast.common.feature') }}</strong>
          <small class="text-muted ml-2 mr-2">{{ $t('news.AgreementsViewToast.description') }}</small>
        </div>
      </template>
      <router-link :to="{ name: 'agreements' }">
          <b-img-lazy src="assets/images/news/agreements-view.png" width="500" thumbnail @click="visible = false" />
      </router-link>
    </b-toast>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    setup() {
        
    },
    data() {
        return {
            visible: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.visible = !localStorage.getItem(`news-AgreementsViewToast`);
            localStorage.setItem(`news-AgreementsViewToast`, true);
        }, 10000);
    }
})
</script>
report-team