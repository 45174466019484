import Vue from "vue";
import { Auth0Client } from "@auth0/auth0-spa-js";
import EventsBus from '@/libs/EventsBus';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        returnTo: null
      };
    },
    methods: {
      async initialize() {

      },
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          EventsBus.emit('authenticate', { authService: instance, newToken: true });
          this.error = null;
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
          this.error = e;
        } finally {
          this.popupOpen = false;
        }
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        this.isAuthenticated = false;
        this.user = {};
        if (window?.localStorage) Object.keys(window.localStorage).filter(k => k.indexOf('@@auth0spajs@@') > -1).forEach(k => window.localStorage.removeItem(k));
        setTimeout(() => {
            return this.auth0Client.loginWithRedirect(o);
        }, 500);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout() {
        this.isAuthenticated = false;
        this.user = {};
        setTimeout(() => {
            if (window?.localStorage) Object.keys(window.localStorage).filter(k => k.indexOf('@@auth0spajs@@') > -1).forEach(k => window.localStorage.removeItem(k));
            return this.auth0Client?.logout({
                localOnly: false,
                returnTo: this.returnTo
            });
        }, 50);
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      this.returnTo = options.returnTo;
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = new Auth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri
      });

      try {
        await this.auth0Client.checkSession();
      }
      catch(e) {
          this.error = e;
          console.error(e);
          return this.logout({ appState: { targetUrl: '/' } });
      }

      try {
        // If the user is returning to the app after authentication.
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();
          this.error = null;
        //   EventsBus.emit('authenticate', { authService: instance, newToken: true });
          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
        console.error(e);
      } finally {
          try {
            // Initialize our internal authentication state
            this.isAuthenticated = await this.auth0Client.isAuthenticated();
            this.user = await this.auth0Client.getUser();
            if (this.isAuthenticated) {
                EventsBus.emit('authenticate', { authService: instance, newToken: false });
            }
          }
          catch(ex) {
            this.error = e;
            console.error(e);
          }
          finally {
              this.loading = false;
          }
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};