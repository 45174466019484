var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exporter", attrs: { id: "exporter" } },
    [
      _c(
        "b-toast",
        {
          attrs: {
            id: "processing-export",
            variant: "info",
            solid: "",
            "no-auto-hide": true,
            "no-close-button": true
          },
          scopedSlots: _vm._u([
            {
              key: "toast-title",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-grow-1 align-items-baseline" },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "download" }
                      }),
                      _c("strong", { staticClass: "mr-auto" }, [
                        _vm._v(
                          _vm._s(_vm.$t("commons.export.inprogress.title"))
                        )
                      ]),
                      _c("small", { staticClass: "text-muted mr-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$tc(
                              "commons.period.ago.second",
                              _vm.secondsAgo,
                              { count: _vm.secondsAgo }
                            )
                          )
                        )
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.exporting,
            callback: function($$v) {
              _vm.exporting = $$v
            },
            expression: "exporting"
          }
        },
        [
          _c("b-spinner", {
            staticClass: "mr-2",
            attrs: { type: "grow", small: true }
          }),
          _vm._v(
            " " +
              _vm._s(
                _vm.$tc("commons.export.inprogress", _vm.exports.length, {
                  count: _vm.exports.length
                })
              ) +
              "\n  "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }