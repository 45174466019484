/* eslint-disable no-console */

const { register } = require('register-service-worker');

console.info(`ENV MODE : ${process.env.NODE_ENV}`);

if (['production'].includes(process.env.NODE_ENV)) {
  register(`${process.env.BASE_URL}sw.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n'
      );
    },
    cached () {
      console.log('Content has been cached for offline use.');
    },
    updated (registration) {
      console.log('New content is available; please refresh.');
      document.dispatchEvent(new CustomEvent('appUpdated', { detail: registration }));
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
    }
  });
}
