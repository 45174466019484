var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "HomepageModal",
            title: _vm.$t("global.modal.homepage.title"),
            size: "lg",
            "ok-title": _vm.$t("global.modal.homepage.confirm"),
            "hide-footer": ""
          },
          on: { show: _vm.resetModal, hidden: _vm.resetModal, ok: _vm.handleOk }
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v(_vm._s(_vm.$t("global.modal.homepage.desscription")))
          ]),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center mb-3" },
            _vm._l(_vm.homepages, function(page) {
              return _c(
                "div",
                {
                  key: page.name,
                  staticClass: "btn btn-outline-primary mr-3",
                  class: { selected: page.name === _vm.homepage },
                  on: {
                    click: function($event) {
                      return _vm.selectHomepage(page.name)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "heading" }, [
                    _vm._v(_vm._s(page.label))
                  ]),
                  _c("div", { staticClass: "subheading" }, [
                    _vm._v(_vm._s(page.resume))
                  ]),
                  _c("img", {
                    attrs: {
                      width: "150",
                      src: require("@/assets/images/icons/from-blank.svg"),
                      alt: ""
                    }
                  })
                ]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }