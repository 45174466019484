
import Vue from 'vue';
import dayjs from 'dayjs';
import EventsBus from '@/libs/EventsBus';
import i18n  from '@/i18n';

export const AgreementStatusEnum = Object.freeze({
    ACTIVE: 'ACTIVE',
    VALIDATED: 'VALIDATED',
    FINISHED: 'FINISHED',
    PENDING: 'PENDING',
    PAUSED: 'PAUSED',
    CANCELLED: 'CANCELLED',
});

const state = {
  fetched: {},
  agreements: [],
  stats: {},
  listStatus: {
    [AgreementStatusEnum.ACTIVE]: {
        color: 'success',
        icon: 'caret-right-fill',
        label: i18n.t("customers.agreements.status.active.label"),
        statusModifier: [AgreementStatusEnum.PAUSED, AgreementStatusEnum.CANCELLED, AgreementStatusEnum.PENDING]
    },
    [AgreementStatusEnum.VALIDATED]: {
        color: 'success',
        icon: 'check-circle-fill',
        label: i18n.t("customers.agreements.status.validated.label"),
        statusModifier: [AgreementStatusEnum.PAUSED, AgreementStatusEnum.CANCELLED, AgreementStatusEnum.PENDING]
    },
    [AgreementStatusEnum.FINISHED]: {
        color: 'success',
        icon: 'bookmark-check-fill',
        label: i18n.t("customers.agreements.status.finished.label"),
        statusModifier: []
    },
    [AgreementStatusEnum.PENDING]: {
        color: 'warning',
        icon: 'hourglass-split',
        label: i18n.t("customers.agreements.status.pending.label"),
        statusModifier: [AgreementStatusEnum.ACTIVE, AgreementStatusEnum.VALIDATED, AgreementStatusEnum.CANCELLED]
    },
    [AgreementStatusEnum.PAUSED]: {
        color: 'warning',
        icon: 'pause-circle-fill',
        label: i18n.t("customers.agreements.status.paused.label"),
        statusModifier: [AgreementStatusEnum.ACTIVE, AgreementStatusEnum.VALIDATED, AgreementStatusEnum.CANCELLED, AgreementStatusEnum.PENDING]
    },
    [AgreementStatusEnum.CANCELLED]: {
        color: 'danger',
        icon: 'x-circle-fill',
        label: i18n.t("customers.agreements.status.cancelled.label"),
        statusModifier: [AgreementStatusEnum.ACTIVE, AgreementStatusEnum.VALIDATED, AgreementStatusEnum.PAUSED]
    }
    },
    lastSort: {
        sortName: null,
        sortDirection: "ascending",
        page: 1
    },
};

const getters = {
  getAll: (state, getters, store, rootGetters) => () => {
      return state.agreements || [];
  },
  getFromId: (state, getters) => ( id ) => {
      return getters.getAll().find(a => a.id === id)
  },
  getStats: (state) => customerId => {
    return state.stats[customerId];
 },
 getListStatus: (state) => {
    return state.listStatus;
 },
 getLastSort: (state) => {
    return state.lastSort;
  },
};

const actions = {
  async ensureAgreements({ dispatch, state }, { customerId }) {
    // TODO need to be update not use Now 
    if (!state.agreements[customerId]) {
        await dispatch('fetchAgreements', { customerId });
    }
  },
  async ensureAgreement({ dispatch, state }, { customerId, agreementId }) {
    if (!state.agreements?.find(a => a.id === agreementId)) {
        const data = await dispatch('fetchAgreement', { customerId, agreementId });
        return data;
    }
    else {
        return state.agreements.find(a => a.id === agreementId);
    }
  },
  async ensureAgreementsStats({ dispatch, state }, { customerId }) {
    if (!state.stats[customerId]) {
      await dispatch('fetchAgreementsStats', { customerId });
    }
  },
  async fetchAgreement({ dispatch, commit }, { customerId = null, agreementId , ctx = null, standalone = false }) {
    try {
      var data = null;
        if(customerId !== null) {
            data = await this.getters.api.get(`/customers/${customerId}/agreements/${agreementId}`);
        }
        else {
            data = await this.getters.api.get(`/agreements/${agreementId}`);
        }
      commit('setAgreement', { data: fromAPI(data), standalone });
      return ctx ? {
        total: results.metadata.pagination.total || 0,
        page: results.metadata.pagination.page || 1,
        data: data
        } : data;
    } catch (error) {
        console.error(error)
      throw 'A server error has occurred';
    }
    },
  async fetchAgreements({ dispatch, commit }, { customerId = null , ctx }) {
    try {        
        const query = {
            cache: true
        }

        if (ctx?.page) {
            query.pagination = ctx;
        }
        let results = null;
        let data = [];
        if(customerId !== null) {
            results = (await this.getters.api.get(`/customers/${customerId}/agreements`, query))
            data = ctx ? results.data.map(fromAPI) : results.map(fromAPI);
            commit('fetched', { customerId, value: true });
        }
        else {
            results = (await this.getters.api.get(`/agreements`, query))
            data = ctx ? results.data.map(fromAPI) : results.map(fromAPI);
        }
    
      commit('setAgreements', { data });
      return ctx ? {
        total: results.metadata.pagination.total || 0,
        page: results.metadata.pagination.page || 1,
        data: data
        } : data;
    } catch (error) {
       console.error(error)
      throw 'A server error has occurred';
    }
  },
  async fetchAgreementsStats({ dispatch, commit }, { customerId = null }) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/agreements/stats`);
      commit('setAgreementsStats', { customerId, data });
    } catch (error) {
        console.error(error)
      throw 'A server error has occurred';
    }
  },
  async add({ commit, dispatch }, { customerId, agreement }) {
    try {
        const data = await this.getters.api.post(`/customers/${customerId}/agreements`, { 
            data: toAPI(agreement),
            resetCache: [`/customers/${customerId}/agreements`, `/agreements`],});
        const result = fromAPI(data);
        commit('setAgreement', { data: result });
        await this.dispatch('customers/fetchCustomer', { customerId });
        return result;
      } catch (error) {
        console.error(error)
        throw 'A server error has occurred';
      }
  },
  async update({ commit, dispatch }, { customerId, agreementId, agreement }) {
    try {
        agreement = await dispatch('validateStatut', { agreement });
        const data = await this.getters.api.patch(`/customers/${customerId}/agreements/${agreementId}`, { 
            data:  toAPI(agreement),
            resetCache: [`/customers/${customerId}/agreements`,`/agreements`], });
        // TODO remove
        agreement.id = agreementId;
        commit('setAgreement', { data: fromAPI(agreement) });
        await this.dispatch('customers/fetchCustomer', { customerId });
      } catch (error) {
        console.error(error)
        throw 'A server error has occurred';
      }
  },
  async validateStatut({ commit }, { agreement }) {
    try {
        // Méthode de vérification si l'accord est terminé dans le cas ou la date de fin
        // est dépassée aucun autre statut ne peut être attribué
        const isFinished = (agreement) => {

            if(dayjs(agreement.endDate).isBefore(dayjs()) && ![AgreementStatusEnum.FINISHED, AgreementStatusEnum.CANCELLED, AgreementStatusEnum.PAUSED].includes(agreement.status)) {
                agreement.status = AgreementStatusEnum.FINISHED
            }
            return agreement
        }

        // Espace logique de modification du statut pour chaque type de statut 
        const actionList = {
            ACTIVE: (agreement, isFinished) => {
               agreement = isFinished(agreement)
               if(agreement.status !== AgreementStatusEnum.FINISHED) {
                   agreement.status = dayjs(agreement.startDate).isAfter(dayjs()) ? AgreementStatusEnum.VALIDATED : AgreementStatusEnum.ACTIVE
                   agreement.finishedAt = null
                }
               return agreement
            },
            VALIDATED: (agreement, isFinished) => {
                agreement = isFinished(agreement)
                if(agreement.status !== AgreementStatusEnum.FINISHED) {
                    agreement.status = dayjs(agreement.startDate).isAfter(dayjs()) ? AgreementStatusEnum.VALIDATED : AgreementStatusEnum.ACTIVE
                    agreement.finishedAt = null
                }
                return agreement
            },
            PENDING: (agreement, isFinished) => {
                agreement = isFinished(agreement)
                if(agreement.status !== AgreementStatusEnum.FINISHED) {
                    agreement.finishedAt = null
                }
                return agreement
            },
            PAUSED: (agreement, isFinished) => {
                agreement = isFinished(agreement)
                if(agreement.status !== AgreementStatusEnum.FINISHED && agreement.finishedAt === null) {
                    agreement.finishedAt = dayjs().toDate()
                }
                return agreement
            },
            CANCELLED: (agreement, isFinished) => {
                agreement = isFinished(agreement)
                if(agreement.status !== AgreementStatusEnum.FINISHED && agreement.finishedAt === null) {
                    agreement.finishedAt = dayjs().toDate()
                }
                return agreement
            },
        }

        agreement = actionList.hasOwnProperty(agreement.status) ? actionList[agreement.status](agreement, isFinished) : agreement;

        return agreement
    } catch (error) {
        console.error(error)
    }
  },
  async remove({ commit }, { customerId, agreementId }) {
    try {
        await this.getters.api.delete(`/customers/${customerId}/agreements/${agreementId}`,
        { resetCache: [`/customers/${customerId}/agreements`, `/agreements`] }
        );
        commit('removeAgreement', { customerId, agreementId });
        await this.dispatch('customers/fetchCustomer', { customerId });
      } catch (error) {
        console.error(error)
        throw 'A server error has occurred';
      }
  },
  async import({ dispatch }, { agreements }) {
    const result = {
        count: 0,
        created: 0,
        updated: 0,
        deleted: 0,
        error: 0
    };
    try {
        const customers = await this.dispatch('customers/fetchCustomers');
        let total = 0;
        const agreementsByCustomer = agreements.reduce((prev, cur) => {
            const found = customers.find(c => (cur.customer?.length && c.refId?.toLowerCase() === cur.customer?.toLowerCase()) || (cur.customer?.length && c.domain?.toLowerCase() === cur.customer?.toLowerCase()) || (cur.customer?.length && c.name?.toLowerCase() === cur.customer?.toLowerCase()));
            if (found) {
                prev[found.id] = prev[found.id] || [];
                prev[found.id].push(cur);
                total++;
            }
            return prev;
        }, {});

        EventsBus.emit('agreements/import/start', {
            count: total
        });

        for(const customerId of Object.keys(agreementsByCustomer)) {
            const actualAgreements = await dispatch('fetchAgreements', { customerId });
            // Upset contacts
            for (const agreement of agreementsByCustomer[customerId].filter(c => !c.deleted)) {
                agreement.source = 'IMPORT';
                const found = actualAgreements?.find(c => (agreement.refId?.length && c.refId?.length && agreement.refId?.toLowerCase() === c.refId?.toLowerCase()) || (c.type === agreement.type && dayjs(agreement.startDate, 'YYYY/MM/DD').format('YYYY/MM/DD') === dayjs(c.startDate).format('YYYY/MM/DD')));
                if (found) {
                    const updated = { ...found };
                    if (agreement.mrr !== null) updated.mrr = agreement.mrr;
                    if (agreement.fee !== null) updated.fee = agreement.fee;
                    if (agreement.engagement !== null) {
                        updated.engagement = agreement.engagement;
                        delete updated.endDate;
                    }
                    if (agreement.engagementPeriod) {
                        updated.engagementPeriod = agreement.engagementPeriod;
                        delete updated.endDate;
                    }
                    if (agreement.notice !== null) {
                        updated.notice = agreement.notice;
                        delete updated.renewalDate;
                    }
                    if (agreement.noticePeriod) {
                        updated.noticePeriod = agreement.noticePeriod;
                        delete updated.renewalDate;
                    }
                    if (agreement.plan) updated.plan = agreement.plan;
                    if (agreement.status) updated.status = agreement.status?.toUpperCase();
                    updated.autoRenew = agreement.autoRenew;
            
                    try {
                        await dispatch('update', { customerId, agreementId: updated.id, agreement: updated });
                        result.updated++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                else {
                    try {
                        await dispatch('add', { customerId, agreement });
                        result.created++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                result.count++;
                console.info(agreement)
                EventsBus.emit('agreements/import/process', agreement);
            }
        }
        // Raz server redis cache
        if (total > 0) {
            this.dispatch('cache/resetServer');
        }
      } catch (error) {
        console.error(error)
        throw 'A server error has occurred';
      }
      return result;
  },
  async export({ dispatch, commit }, { ctx }) {
    try {
      const query = {
          cache: false,
          pagination: ctx
      };
      const results = await this.getters.api.post(`/agreements/export`, query);
      const exportId = results.data?.id;
      if (exportId) {
        EventsBus.emit('beginExport', {
            ...results.data
        });
        const result = await this.dispatch('exports/waitExport', { exportId });
        console.info(result);
        EventsBus.emit('endExport', {
            id: exportId,
            result
        });
        return result;
      }
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  },
  getListStatus({ state }) {
    return {...state.listStatus};
  },
};

const mutations = {
  fetched(state, {  customerId, value }) {
    state.fetched[customerId] = value;
  },
  setAgreement(state, { data, standalone = false }) {
      const index = state.agreements.findIndex(c => c.id === data.id);
      if (index > -1) {
        Vue.set(state.agreements, index, { ...state.agreements[index], ...data});
      }
      if (standalone) {
        state.agreements = [data];
      }
  },
  setAgreements(state, { data }) {
    state.agreements = data;
  },
  setLastSort(state, { sortName, sortDirection, page }) {
    Vue.set(state, 'lastSort', {
        sortName,
        sortDirection,
        page
    });
  },
  removeAgreement(state, { agreementId }) {
    const index = state.agreements.findIndex(c => c.id === agreementId);
    if (index > -1) {
        state.agreements.splice(index, 1);
    }
    state.agreements.filter(a => a.next === agreementId).forEach(a => a.next = null);
  },
  setAgreementsStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId, data);
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  },
};

function fromAPI(json) {
    if (json.startDate) json.startDate = dayjs(json.startDate).toDate();
    if (json.endDate) json.endDate = dayjs(json.endDate).toDate();
    if (json.renewalDate) json.renewalDate = dayjs(json.renewalDate).toDate();
    return json;
}

function toAPI(json) {
    json.engagement = parseInt(json.engagement, 10);
    json.notice = parseInt(json.notice, 10);
    json.mrr = parseInt(json.mrr, 10);
    json.fee = parseInt(json.fee, 10);
    if (json.status) json.status = json.status?.toUpperCase();
    if (json.startDate) json.startDate = dayjs(json.startDate).format('YYYY/MM/DD');
    if (json.endDate) json.endDate = dayjs(json.endDate).format('YYYY/MM/DD');
    if (json.renewalDate) json.renewalDate = dayjs(json.renewalDate).format('YYYY/MM/DD');

    delete json.customer;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
