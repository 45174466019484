var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "profile",
            title: _vm.$t("settings.profile.title"),
            size: "lg",
            "ok-title": _vm.$t("commons.action.save"),
            "input-class": "dd",
            "hide-footer": true
          },
          on: {
            show: _vm.resetModal,
            hidden: _vm.resetModal,
            ok: _vm.handleOk,
            close: function($event) {
              return _vm.$emit("close", $event)
            }
          }
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-3 nav-justified" },
                    [
                      _c(
                        "b-form",
                        {
                          ref: "form",
                          on: {
                            submit: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmit.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "input-group-1",
                                "label-cols-sm": "3",
                                label: _vm.$t(
                                  "settings.profile.modal.form.email.label"
                                ),
                                "label-for": "input-1"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  disabled: "",
                                  id: "input-member-name",
                                  type: "email",
                                  size: "lg",
                                  state: _vm.$v.form.email.$dirty
                                    ? !_vm.$v.form.email.$invalid
                                    : null
                                },
                                model: {
                                  value: _vm.user.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "email", $$v)
                                  },
                                  expression: "user.email"
                                }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "input-member-name-feedback" } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.profile.modal.form.email.invalid"
                                        )
                                      ) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "input-group-firstname",
                                "label-cols-sm": "3",
                                label: _vm.$t(
                                  "settings.profile.modal.form.firstname.label"
                                ),
                                "label-for": "input-member-firstname"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-member-firstname",
                                  required: "",
                                  placeholder: _vm.$t(
                                    "settings.profile.modal.form.firstname.label"
                                  )
                                },
                                model: {
                                  value: _vm.form.firstName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "firstName", $$v)
                                  },
                                  expression: "form.firstName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "input-group-lastname",
                                "label-cols-sm": "3",
                                label: _vm.$t(
                                  "settings.profile.modal.form.lastname.label"
                                ),
                                "label-for": "input-member-lastname"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-member-lastname",
                                  required: "",
                                  placeholder: _vm.$t(
                                    "settings.profile.modal.form.lastname.label"
                                  )
                                },
                                model: {
                                  value: _vm.form.lastName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "lastName", $$v)
                                  },
                                  expression: "form.lastName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: { click: _vm.handleSaveProfile }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("settings.profile.save.button"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-3 nav-justified",
                      attrs: {
                        title: _vm.$t("settings.profile.modal.mail.title")
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "settings.profile.modal.mail.weekly.global"
                            ),
                            "label-cols-sm": "10"
                          }
                        },
                        [
                          _c("b-form-checkbox", {
                            attrs: { size: "lg", switch: "" },
                            model: {
                              value: _vm.email.global,
                              callback: function($$v) {
                                _vm.$set(_vm.email, "global", $$v)
                              },
                              expression: "email.global"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "settings.profile.modal.mail.weekly.owner"
                            ),
                            "label-cols-sm": "10"
                          }
                        },
                        [
                          _c("b-form-checkbox", {
                            attrs: { size: "lg", switch: "" },
                            model: {
                              value: _vm.email.owned,
                              callback: function($$v) {
                                _vm.$set(_vm.email, "owned", $$v)
                              },
                              expression: "email.owned"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.handleSaveEmailConfig }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.profile.save.emails.button")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-3 nav-justified",
                      attrs: {
                        title: _vm.$t("settings.profile.modal.ui.title")
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-locale",
                            "label-cols-sm": "3",
                            label: _vm.$t(
                              "settings.profile.modal.form.locale.label"
                            ),
                            "label-for": "input-locale"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-locale",
                              options: _vm.languages,
                              required: "",
                              placeholder: "Locale"
                            },
                            model: {
                              value: _vm.language,
                              callback: function($$v) {
                                _vm.language = $$v
                              },
                              expression: "language"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mb-4",
                          attrs: { variant: "primary" },
                          on: { click: _vm.selectLanguage }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.profile.save.locale.button")
                            )
                          )
                        ]
                      ),
                      _vm.$isInternal()
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "input-group-theme",
                                "label-cols-sm": "3",
                                label: _vm.$t(
                                  "settings.profile.modal.form.theme.label"
                                ),
                                "label-for": "input-theme"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "input-theme",
                                  options: _vm.themes,
                                  required: "",
                                  placeholder: "Theme"
                                },
                                model: {
                                  value: _vm.theme,
                                  callback: function($$v) {
                                    _vm.theme = $$v
                                  },
                                  expression: "theme"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$isInternal()
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mb-4",
                              attrs: { variant: "primary" },
                              on: { click: _vm.selectTheme }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("settings.profile.save.theme.button")
                                )
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-homepage",
                            "label-cols-sm": "3",
                            label: _vm.$t(
                              "settings.profile.modal.form.homepage.label"
                            ),
                            "label-for": "input-theme"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-left-center mb-3" },
                            _vm._l(_vm.homepages, function(page) {
                              return _c(
                                "div",
                                {
                                  key: page.name,
                                  staticClass: "btn btn-outline-primary mr-3",
                                  class: {
                                    "bg-primary": page.name === _vm.homepage,
                                    "text-white": page.name === _vm.homepage
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectHomepage(page.name)
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "heading" }, [
                                    _vm._v(_vm._s(page.label))
                                  ]),
                                  _c("div", { staticClass: "subheading" }, [
                                    _vm._v(_vm._s(page.resume))
                                  ]),
                                  _c("img", {
                                    attrs: {
                                      width: "150",
                                      src: require("@/assets/images/icons/from-blank.svg"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.saveHomepage }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.profile.save.homepage.button")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }