import Vue from 'vue';
import VueI18n from 'vue-i18n';
import EventsBus from '@/libs/EventsBus';

Vue.use(VueI18n);

const languages = ['en', 'fr'];

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[a-z0-9]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const dateTimeFormats = {
  en: {
    dayMonth: {
      day: 'numeric', month: 'short'
    },
    monthYear: {
      month: 'short', year: 'numeric'
    },
    dayMonthYear: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    middle: {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  fr: {
    dayMonth: {
      day: 'numeric', month: 'short'
    },
    monthYear: {
      month: 'short', year: 'numeric'
    },
    dayMonthYear: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    middle: {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
};

const numberFormatsI18n = {
  currency: {
    style: 'currency',
    currency: 'EUR'
  },
  longCurrency: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  },
  shortCurrency: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  },
  percent: {
    style: 'percent',
    minimumFractionDigits: 2
  },
  longNumber: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  },
  number: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }
};
const localLanguage = window.localStorage.getItem('language') || 'en';

const i18n = new VueI18n({
    dateTimeFormats,
    numberFormats: {
      fr: numberFormatsI18n,
      en: numberFormatsI18n
    },
    locale: localLanguage.toLowerCase(),
    fallbackLocale: localLanguage.toLowerCase(),
    messages: loadLocaleMessages()
});
// Listen to language changed
EventsBus.on('language', language => {
    if (localLanguage === language) return;
    if (language && !languages.includes(language)) {
        throw new Error(`"${language}" has not been defined as a language.`);
    }
    // Change i18n locale with new language
    // this.$root.$i18n.locale = this.language;
    // Store language to localStorage
    localStorage.setItem("language", language);
    // i18n._vm.$root.$i18n.locale = language;
    i18n._vm.$root.locale = language;
    // Store language to localStorage
    i18n._vm.$forceUpdate();
    // Reload page
    setTimeout(() => {
        location.reload();
    }, 50);
});
// Listen to currency changed
EventsBus.on('setCurrency', async ({ currency }) => {
    // Override i18n numberFormat
    for (const locale of Object.keys(i18n._vm.numberFormats)) {
        for (const format of Object.keys(i18n._vm.numberFormats[locale])) {
            if (i18n._vm.numberFormats[locale][format].currency) {
                i18n._vm.numberFormats[locale][format].currency = currency;
            }
        }
    }
    i18n._vm.$forceUpdate();
});
// Listen to dateformat changed
EventsBus.on('setDatelocale', async ({ datelocale }) => {
    i18n.dateLocale = datelocale;
    i18n._sk_datelocale = datelocale;
    i18n._vm.$forceUpdate();
});
// Override datetime formater to change current locale
i18n._d = (function(base) {
    return function(value, locale, key) {
        // Use settings datelocale if exist
        return base.apply(this, [value, ['short', 'long'].includes(key) ? (i18n._sk_datelocale || locale) : locale, key]);
    }
})(i18n._d);
export default i18n;
