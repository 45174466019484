/* eslint-disable */
import { BaseInterceptor } from './BaseInterceptor';

export class VersionInterceptor extends BaseInterceptor {

  getType() {
    return 'VersionInterceptor';
  }

  requestInterceptor() {
    return {
      request: config => {
        config = this.setVersion(config);
        return config;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  responseInterceptor() {
    return {
      response: response => {
        return response;
      },
      error: async error => {
        throw error;
      }
    };
  }
  setVersion(config) {
    const version = process.env.VUE_APP_VERSION;
    if (version) {
        config.params = config.params || {};
        config.params.v = version;
    }
    return config;
  }
}
