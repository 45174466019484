var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-link",
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
        }
      }
    },
    [
      !_vm.editor.isActive("link")
        ? _c(
            "button",
            {
              staticClass: "menubar__button",
              class: { "is-active": _vm.editor.isActive("link") },
              attrs: { title: _vm.$t("commons.editor.command.link") },
              on: { click: _vm.showLinkModal }
            },
            [_c("icon", { attrs: { name: "link" } })],
            1
          )
        : _vm._e(),
      _vm.editor.isActive("link")
        ? _c(
            "button",
            {
              staticClass: "menubar__button",
              attrs: { title: _vm.$t("commons.editor.command.unlink") },
              on: { click: _vm.updateLink }
            },
            [_c("icon", { attrs: { name: "unlink" } })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
            }
          }
        },
        [
          _c("link-infos", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.exit,
                expression: "exit"
              }
            ],
            ref: "info",
            attrs: { "can-unlink": _vm.canRemove },
            on: { select: _vm.setLink, cancel: _vm.exit },
            model: {
              value: _vm.url,
              callback: function($$v) {
                _vm.url = $$v
              },
              expression: "url"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }