const state = {
  context: {},
  currentItem: '',
  univers: [
    {
      name: 'decide',
      icon: 'univers-decide',
      color: 'var(--color-univers-insight)',
      subitem: [
        {
          name: 'acquire',
          icon: 'acquire',
          link: '/decide/acquire',
          state: 'decide.acquire',
          activesStates: ['decide.acquire'],
          authorisation: {
            feature: 'scenario',
            action: 'read'
          }
        },
        {
          name: 'fidelize',
          icon: 'fidelize',
          link: '/decide/fidelize',
          state: 'decide.fidelize',
          activesStates: ['decide.fidelize'],
          authorisation: {
            feature: 'scenario',
            action: 'read'
          }
        },
        {
          name: 'convert',
          icon: 'convert',
          link: '/decide/convert',
          state: 'decide.convert',
          activesStates: ['decide.convert'],
          authorisation: {
            feature: 'scenario',
            action: 'read'
          }
        },
        {
          name: 'intentions',
          icon: 'intent',
          link: '/decide/intentions',
          state: 'decide.intentions',
          activesStates: ['decide.intentions', 'decide.intentions.dashboard', 'decide.intentions.potential', 'decide.intentions.behaviour', 'decide.intentions.network'],
          authorisation: {
            feature: 'intention',
            action: 'read'
          }
        },
      ]
    },
    {
      name: 'understand',
      icon: 'understand',
      color: 'var(--color-univers-understand)',
      subitem: [
        {
          name: 'goals',
          icon: 'goal',
          link: '/reports/campaigns/goal',
          state: 'reports.campaigns.goal',
          activesStates: ['reports.campaigns.goal'],
          authorisation: {
            feature: 'goal',
            action: 'read'
          }
        }
      ]
    }
  ],
  settings: [
    {
      name: 'administration',
      icon: 'settings',
      color: 'var(--color-univers-setup)',
      subitem: [{
        name: 'user-schema',
        icon: 'user',
        link: '/manage/schemas/user',
        state: 'manage.schemas.user',
        activesStates: ['manage.schemas.user'],
        authorisation: {
          feature: 'schema',
          action: 'write'
        }
      }]
    }],
  globals: [{
    name: 'dashboard',
    icon: 'dashboard',
    link: '/dashboard',
    state: 'dashboard'
  }, {
    name: 'account',
    icon: 'account',
    link: '/my-account',
    state: 'my-account'
  }, {
    name: 'logout',
    icon: 'logout',
    state: 'logout'
  }]
};

export function isMenuItemAuthorised(item, rootGetters) {
  if (!item.hasOwnProperty('authorisation')) {
    return true;
  }
  if (item.authorisation.onlyInternal && !rootGetters.isUserInternal()) {
    return false;
  }
  if (item.authorisation.allowInternal && rootGetters.isUserInternal()) {
    return true;
  }
  if (item.authorisation.level && rootGetters.getUserPartitionLevel() < item.authorisation.level) {
    return false;
  }
  return rootGetters.isUserAllowed(item.authorisation.feature, item.authorisation.action);
}

const getters = {
  universes: (state, getters, rootState, rootGetters) => {
    const res = [];

    for (const universe of state.univers) {
      const filteredUniverse = { ...universe };
      filteredUniverse.subitem = universe.subitem.filter(item => isMenuItemAuthorised(item, rootGetters));
      if (filteredUniverse.subitem.length > 0) {
        res.push(filteredUniverse);
      }
    }

    return res;
  },
  settings: (state, getters, rootState, rootGetters) => {
    const res = [];

    for (const setting of state.settings) {
      const filteredSetting = { ...setting };
      filteredSetting.subitem = setting.subitem.filter(item => isMenuItemAuthorised(item, rootGetters));
      if (filteredSetting.subitem.length > 0) {
        res.push(filteredSetting);
      }
    }

    return res;
  },
  context: state => state.context,
  currentItem: state => state.currentItem
};

const actions = {
  setCurrentItem(store, value) {
    store.commit('setCurrentItem', value);
  },
  setContext(store, { state, value }) {
    store.commit('setContext', { stateKey: state, value });
  },
  clearContext(store) {
    store.commit('clearContext');
  }
};

export const mutations = {
  setCurrentItem(state, value) {
    state.currentItem = value;
  },
  setContext(state, { stateKey, value }) {
    state.context[stateKey] = value;
  },
  clearContext(state) {
    state.context = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
