import Vue from 'vue';
import './registerServiceWorker';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import './dayjs';
import router from './router';
import store from './store';
import i18n from './i18n';
import VueCompositionAPI from '@vue/composition-api';
import EventsBus from '@/libs/EventsBus';
import ErrorService from "@/libs/ErrorService";
import SseService from '@/libs/SseService';

import { register } from './libs/interceptors/interceptor';
import { sync } from 'vuex-router-sync';

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import PortalVue from 'portal-vue';
import Vuelidate from 'vuelidate';
import { Auth0Plugin } from "./auth";
import { domain, clientId, issuer, returnTo } from "../auth_config.json";

import svgSpriteLoader from '@/libs/utils/SvgSpriteLoader';

import './plugins';
import './globalComponents';
import { initDb } from '@/libs/db';

import App from './App';

const __svg__ = { path: './assets/images/icons/*.svg', name: 'assets/images/[hash].sprite.svg' }
svgSpriteLoader(__svg__.filename);

Vue.config.productionTip = false;

ErrorService.initHandler();
// Handle all Vue errors
Vue.config.errorHandler = (error) => ErrorService.onError(error);

initDb();

Vue.use(VueCompositionAPI);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(PortalVue);
Vue.use(Vuelidate);

Vue.use(Auth0Plugin, {
    domain,
    issuer,
    clientId,
    returnTo,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    onRedirectCallback: appState => {
        const url = new URL(location.href);
        const params = new URLSearchParams(url.search);
        let pathname = appState && appState.targetUrl ? appState.targetUrl : window.location.pathname;
        if (pathname === '/logout') pathname = '/';
        // Delete the foo parameter.
        params.delete('code');
        params.delete('state');
        // const newUrl = params.keys().length ? `${url.origin}/#${pathname}?${params.toString()}` : `${url.origin}/#${pathname}`;
        const newUrl = params.keys().length ? `/#${pathname}?${params.toString()}` : `/#${pathname}`;

        // Use replaceState to redirect the user away and remove the querystring parameters
        window.history.replaceState({}, document.title, newUrl);

        // setTimeout(() => {
        //     location.href = newUrl;
        // }, 500);
        
    //   router.push(pathname);
    }
  });

sync(store, router);

// Interceptors register
register({store, router, i18n});

// Init Sentry
if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
    Sentry.init({
        Vue,
        release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        beforeSend: (event, hint) => {
            // if (IS_DEBUG) {
            const error = hint.originalException || hint.syntheticException;
            // eslint-disable-next-line no-console
            console.error(error);
            
            if (error.message?.match(/Unexpected token '<'/i)) {
                return null;
            }
            return event;
        },
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "skalin.io", /^\//],
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACE_RATE)
    });
    EventsBus.on('setCurrentClient', async ({ client }) => {
        Sentry.setTag("clientId", client?.id);
    });
}

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app');

EventsBus.on('authenticate', async ({ authService, newToken }) => {
    const user = authService.user;
    user.firstName = user.given_name;
    user.lastName = user.family_name;
    user.language = user.locale;
    user.avatar = user.picture;
    const token = (await authService.getIdTokenClaims()).__raw;
    //   console.info(await authService.getTokenSilently())
    //   console.info((await authService.getIdTokenClaims()).__raw)

    EventsBus.on('setCurrentUser', async ({ clientId, sessionId, user }) => {
        SseService.init({ clientId, userId: user.id, sessionId,  token});
    });
      
    await store.dispatch('users/grantUser', { user, token });

    if (newToken) {
        // router.go(router.currentRoute);
    }
});

// eslint-disable-next-line no-console
console.info(`App ${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION} initialized`);