<template>
  <div id="cached" class="cached">
    <b-alert
      v-if="showAlert"
      show
      class="position-fixed fixed-bottom rounded-0 alert-cached"
      style="z-index: 2000;"
      variant="light"
      dismissible
    >
        <div class="d-flex flex-row align-items-center justify-content-center">
            <div class="wrapper-img d-flex">
                <div class="spinner-border text-primary"></div>
            </div>
            <span class="ml-3 install">{{ message }}</span>
        </div>
    </b-alert>
  </div>
</template>
<script>
import EventsBus from '@/libs/EventsBus';

export default {
    components: {
    },
    data() {
        return {
            // refresh variables
            showAlert: false,
            pendings: []
        }
    },
    props: {
    },
    computed: {
        message() {
            if (!this.pendings.length) return '';
            return this.pendings[0].message;
        }
    },
    created() {
        EventsBus.on('init_start', ({ key, message, priority }) => {
            this.pendings.push({
                key,
                message,
                priority: priority || 1000
            });
            this.pendings = this.pendings.sort((a, b) => a.priority > b.priority ? 1 : -1);
            this.showAlert = true;
        });
        EventsBus.on('init_stop', (key) => {
            this.pendings = this.pendings.filter(p => p.key !== key);
            this.showAlert = this.pendings.length > 0;
        });
    }
}
</script>

<style lang="scss">
@import "../../assets/layout/_layout-variables.scss";

.alert-cached {
  margin-left: $app-sidebar-width;
  min-height: $app-header-height;
  line-height: $app-header-height / 2 + 5;
}
.closed-sidebar {
  .alert-cached {
      margin-left: $app-sidebar-width-collapsed;
  }
}

#cached {
    .alert {
        margin-bottom: 0;
        // padding-bottom: 5px;
        // padding-top: 5px;
    }

    .spinner-border {
        // position:absolute;
        width: 20px;
        height: 20px;
        margin: 0 !important;
    }
}
.modal-footer.cached-footer {
    display: none;
}
</style>
