var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "menu",
      staticClass: "vcs__select-menu",
      class: { "vcs__select-menu__not-main": _vm.notMain },
      on: { mousewheel: _vm.onMouseWheel }
    },
    [
      _vm._l(_vm.options, function(option, index) {
        return _c(
          "Option",
          {
            key: option.value,
            ref: "options",
            refInFor: true,
            attrs: {
              active: option.value === _vm.nextMenu.value,
              disabled: option.disabled,
              focused: index === _vm.selectedOption,
              index: index,
              label: option.label,
              description: option.description,
              onSelect: _vm.onSelect,
              options: option.options,
              option: option,
              selectable: option.selectable,
              value: option.value
            },
            on: {
              openMenu: _vm.handleOpenNextMenu,
              closeMenu: _vm.handleCloseMenu,
              nextOption: function($event) {
                return _vm.moveOption(true)
              },
              prevOption: function($event) {
                return _vm.moveOption(false)
              }
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function(_, name) {
                  return {
                    key: name,
                    fn: function(slotData) {
                      return [_vm._t(name, null, null, slotData)]
                    }
                  }
                })
              ],
              null,
              true
            )
          },
          [
            _vm._l(_vm.$slots, function(_, name) {
              return _vm._t(name)
            })
          ],
          2
        )
      }),
      _c(
        "transition",
        { attrs: { name: "vcs__fade" } },
        [
          _vm.nextMenu.isOpen
            ? _c(
                "SelectMenu",
                {
                  ref: "childMenu",
                  attrs: {
                    notMain: true,
                    onSelect: _vm.onSelect,
                    options: _vm.nextMenu.options,
                    withKeyboard: _vm.nextMenu.withKeyboard
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function(_, name) {
                        return {
                          key: name,
                          fn: function(slotData) {
                            return [_vm._t(name, null, null, slotData)]
                          }
                        }
                      })
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._l(_vm.$slots, function(_, name) {
                    return _vm._t(name)
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }