import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import i18n from '@/i18n';

dayjs.extend(relativeTime);

export const dateFormatters = {
    fromNow(date, withouSufix = false, noTime = false) {
        if (!date) return '-';
        const djDate = dayjs(date);
        const diff = dayjs().diff(djDate, 'days');
        if (noTime && diff > -1 && diff < 1 && djDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) return i18n.t('commons.date.today');
        if (diff < 1) return dayjs(djDate).fromNow(withouSufix);
        return dayjs(djDate.hour(dayjs().hour())).fromNow(withouSufix);

        // if (diff > -1 && diff < 1 && djDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) return i18n.t('commons.date.today');
        // if (diff > -1 && diff < 1 && djDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) return dayjs(djDate).fromNow(withouSufix);
        // return dayjs(djDate.format('YYYY-MM-DD')).from(dayjs().format('YYYY-MM-DD'), withouSufix);
    },
    toNow(date, withouSufix = false) {
        if (!date) return '-';
        const djDate = dayjs(date);
        const diff = dayjs().diff(djDate, 'days');
        if (diff > -1 && diff < 1 && djDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) return i18n.t('commons.date.today');
        return dayjs(djDate.format('YYYY-MM-DD')).to(dayjs().format('YYYY-MM-DD'), withouSufix);
    }
};

export const timeFormater = function(num = 0) {
    const days = Math.floor(num / 1440);
    const hours = Math.floor(((num - (days >= 1 ? (days * 1440) : 0)) / 60));
    const minutes = Math.abs(num % 60);
    return `${days >= 1 ? (days + 'd ') : ''}${hours}h${minutes.toString().padStart(2, '0')}`;         
  }

export const editorCheckboxFormater = function(value) {
    if (value?.length && !value?.includes('<input type="checkbox"')) {
        value = value.replace(/data-checked="true" data-type="taskItem">/gi, 'data-checked="true" data-type="taskItem"><input type="checkbox" checked="checked" />');
        value = value.replace(/data-checked="false" data-type="taskItem">/gi, 'data-checked="false" data-type="taskItem"><input type="checkbox" />');
        value = value.replace(/data-type="taskItem" data-checked="true">/gi, 'data-type="taskItem" data-checked="true"><input type="checkbox" checked="checked" />');
        value = value.replace(/data-type="taskItem" data-checked="false">/gi, 'data-type="taskItem" data-checked="false"><input type="checkbox" />');
        value = value.replace(/li data-type="taskItem">/gi, 'li data-type="taskItem"><input type="checkbox" />');
    }
    return value;
}

export const editorCheckboxHandler = function(element, handler, disabled) {
    const changeHandler = (event) => {
        let targetedNode = event.target?.parentNode;
        if(targetedNode?.tagName?.toLowerCase() !== 'li') {
            targetedNode = targetedNode?.parentNode;
        }
        if (targetedNode?.tagName?.toLowerCase() !== 'li') return;
        // targetedNode must be defined
        if (disabled) {
            event.target.checked = !event.target.checked;
            return event.preventDefault();
        }
        targetedNode.dataset.checked = event.target.checked;
        if (event.target.checked) event.target.setAttribute('checked', 'checked');
        else event.target.removeAttribute('checked');
        handler(element.innerHTML);
    };
    setTimeout(() => {
        element?.addEventListener('change', changeHandler);
    }, 150);
    return function removeHandler() {
        element?.removeEventListener('change', changeHandler);
    }
}

export const customFieldsFormater = function (dataType) {
    return function (value) {
        if (value === null || value === undefined || value === '') {
            if (dataType === 'BOOLEAN') return i18n.t('commons.boolean.false');
            return '';
        }
        switch(dataType) {
            case 'BOOLEAN':
                return i18n.t(value === true ? 'commons.boolean.true' : 'commons.boolean.false');
            case 'NUMBER':
                return parseFloat(value);
            case 'CURRENCY':
                return i18n.n(parseFloat(value), 'currency');
            case 'PERCENT':
                return `${parseFloat(value)} %`;
            case 'DATE':
                return i18n.d(dayjs(value).toDate(), 'short');
            case 'DATETIME':
                return i18n.d(dayjs(value).toDate(), 'long');
            default:
                return value;
        }
    };
}