var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        _vm.layout,
        { tag: "component" },
        [
          !_vm.initialized ? _c("body-skeleton") : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          )
        ],
        1
      ),
      _c("portal-target", { attrs: { name: "panel-right", multiple: "" } }),
      _vm.connectionProblem
        ? _c(
            "b-alert",
            {
              staticClass:
                "position-fixed fixed-top rounded-0 alert-connection",
              staticStyle: { "z-index": "2000" },
              attrs: { show: "", variant: "danger", dismissible: "" }
            },
            [
              _c("i18n", {
                attrs: {
                  path: "settings.integration.alert.message",
                  tag: "span"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "name",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.connectionProblem.name) +
                              "\n              "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "link",
                      fn: function() {
                        return [
                          _c(
                            "a",
                            { attrs: { href: _vm.connectionProblem.link } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "settings.integration.alert.message.link"
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2748200485
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("cached-warning"),
      _c("homepage-modal"),
      _c("updater"),
      _c("exporter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }