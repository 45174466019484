var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-header header-shadow" }, [
    _c("div", { staticClass: "logo-src" }),
    _c("div", { staticClass: "app-header__content" }, [
      _c(
        "div",
        { staticClass: "app-header-left" },
        [_c("h1", [_vm._v(_vm._s(_vm.title))]), _c("SearchBox")],
        1
      ),
      _c(
        "div",
        { staticClass: "app-header-right" },
        [_c("ClientsArea"), _c("UserArea")],
        1
      )
    ]),
    _c("div", { staticClass: "app-header__mobile-menu" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "hamburger close-sidebar-btn hamburger--elastic",
            class: { "is-active": !_vm.isCollapsed },
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.toggleMobile("closed-sidebar-open")
              }
            }
          },
          [_vm._m(0)]
        )
      ])
    ]),
    _c("div", { staticClass: "app-header__menu" }, [
      _c(
        "span",
        [
          _c(
            "b-button",
            {
              staticClass: "btn-icon btn-icon-only",
              class: { active: _vm.isOpenMobileMenu },
              attrs: { variant: "primary", size: "sm" },
              on: {
                click: function($event) {
                  return _vm.toggleMobile2("header-menu-open")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "btn-icon-wrapper" },
                [_c("font-awesome-icon", { attrs: { icon: "ellipsis-v" } })],
                1
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }