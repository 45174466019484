import Vue from 'vue';
import Vuex from 'vuex';
import { generateUID } from '@/libs/utils/String';

import menu from './modules/menu';
import features from './modules/features';
import users from './modules/users';
import usersTeams from './modules/usersTeams';
import clients from './modules/clients';
import configs from './modules/configs';
import search from './modules/search';
import dashboard from './modules/dashboard';
import customers from './modules/customers';
import customerStages from './modules/customerStages';
import customerProfiles from './modules/customerProfiles';
import paymentStages from './modules/paymentStages';
import tags from './modules/tags';
import exports from './modules/exports';
import customs from './modules/customs';
import interactions from './modules/interactions';
import contacts from './modules/contacts';
import activity from './modules/activity';
import agreements from './modules/agreements';
import attachments from './modules/attachments';
import reports from './modules/reports';
import connections from './modules/connections';
import tasks from './modules/tasks';
import alerts from './modules/alerts';
import playbooks from './modules/playbooks';
import onboarding from './modules/onboarding';
import tools from './modules/tools';
import tokens from './modules/tokens';
import cache from './modules/cache';
import operators from './modules/operators';
import webhooks from './modules/webhooks';
import outputs from './modules/outputs';
import customerPlans from './modules/customerPlans';
import goals from './modules/goals';

import api from './api';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const ROLES = {
    ADMIN: 7,
    MANAGER: 6,
    CSM: 5,
    CSM_RESTRICTED: 3,
    READONLY: 1
};

const NAMESPACES = {
    menu,
    features,
    users,
    usersTeams,
    clients,
    configs,
    search,
    dashboard,
    customers,
    customerStages,
    customerProfiles,
    paymentStages,
    tags,
    exports,
    customs,
    interactions,
    contacts,
    activity,
    agreements,
    attachments,
    reports,
    connections,
    tasks,
    alerts,
    onboarding,
    playbooks,
    tools,
    tokens,
    cache,
    operators,
    webhooks,
    outputs, 
    customerPlans,
    goals
};

const store = new Vuex.Store({
  plugins: [
  ],
  strict: debug,
  state: {
    api: null,
    env: null,
    sessionId: generateUID(16),
    breadcrumbLinks: [],
    breadcrumbValue: null
  },
  getters: {
    api: state => state.api === null ? new api() : state.api,
    currentRoutePath: state => state.route.path,
    breadcrumbLinks: state => state.breadcrumbLinks,
    currentUserId: state => state.users.currentUserId,
    sessionId: state => state.sessionId,
    currentClientId: (state, getters, rootState, rootGetters) => {
        return rootGetters['clients/currentClientId'];
    },
    currentRole: (state, getters, rootState, rootGetters) => {
        return rootGetters['clients/currentRole'];
    },
    breadcrumbValue: state => state.breadcrumbValue,
    isDebug: (state) => {
        return state.env?.toUpperCase() === 'DEBUG';
    },
    isUserAllowed: (state, getters, rootState, rootGetters) => ({ feature, action }) => {
      return rootGetters['features/hasUserAuthorisation']({ feature, action });
    },
    hasRole: (state, getters, rootState, rootGetters) => ({ minRole }) => {
        return rootGetters['clients/currentRole'] >= minRole;
    },
    isUserInternal: (state, getters, rootState, rootGetters) => () => {
      return !!(rootGetters['entities/users/currentUser']?.internal) || false;
    },
    isFeatureVisible: (state, getters, rootState, rootGetters) => ({ feature }) => {
        return rootGetters['features/isFeatureVisible']({ feature });
    },
    isFeatureCanActivate: (state, getters, rootState, rootGetters) => ({ feature }) => {
        return rootGetters['features/isFeatureCanActivate']({ feature });
    },
    getUserPartitionLevel: (state, getters, rootState, rootGetters) => () => {
      return rootGetters['entities/users/levelForPartition'];
    },
    canEditCustomer: (state, getters, rootState, rootGetters) => ({ customerId }) => {
        let customer = null;
        switch(rootGetters['clients/currentRole']) {
            case ROLES.ADMIN: return true;
            case ROLES.MANAGER: return true;
            case ROLES.CSM:
            case ROLES.CSM_RESTRICTED:
                customer = rootGetters['customers/getFromId'](customerId);
                return rootGetters['customers/isNotOwned'](customer) || rootGetters['customers/isFollowed'](customer);
            case ROLES.READONLY: return false;
        }
        return false;
    },
    env: state => state.env,
    roles: () => ROLES
  },
  actions: {
    reset() {
    },
    updateEnv({ commit }, { env }) {
        commit('setEnv', env);
    }
  },
  modules: NAMESPACES,
  mutations: {
    setBreadcrumb(state, { links = [], value = null }) {
      state.breadcrumbLinks = links;
      state.breadcrumbValue = value;
    },
    setBreadcrumbValue(state, value) {
      state.breadcrumbValue = value;
    },
    setEnv(state, value) {
        state.env = value;
    }
  }
});

// Init stores if exist "init" method
Object.keys(NAMESPACES).forEach(key => {
  if (typeof NAMESPACES[key].init === 'function') {
    const ctx = store._modules.root._children[key];
    NAMESPACES[key].init.apply(ctx.state, [ctx.context, store]);
  }
});

store.ROLES = ROLES;

// Hack el-tooltip create new Vue instance and don't add $store to components instances
Vue.prototype.$store = store;

export default store;
