
import i18n from "@/i18n";
import EventsBus from '@/libs/EventsBus';
import Vue from 'vue';
import dayjs from 'dayjs';
import { debounce } from '@/libs/utils/Promise';
import { getDb } from '@/libs/db';

const state = {
  fetched: {},
  profiles: {},
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
      return state.profiles[rootGetters.currentClientId] || [];
  },
  getFromId: (state, getters) => id => getters.getAll.find(a => a.id === id),
}

const actions = {

  async ensureProfiles({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetched[clientId]) {
      return await dispatch('fetchProfiles');
    }
    return state.profiles[clientId];
  },
  async fetchProfiles({ dispatch, commit }) {
    try {
        return await debounce('fetchProfiles', async () => {
            const clientId = this.getters.currentClientId;
            const loadData = async () => {
                const data = (await this.getters.api.get('/customers/profiles')).map(fromAPI);
                EventsBus.emit('init_stop', 'profiles');
                return await initData(data, false);
            };
            const initData = async (data, fromDb) => {
                commit('fetched', { clientId, value: true });
                commit('setProfiles', { clientId, data, fromDb });
                return data;
            };
            try {
                const cachedProfiles = await getDb()?.profiles?.where({ clientId })?.toArray();
                if (cachedProfiles?.length) {
                    const parsed = cachedProfiles.map(c => c.data);
                    setTimeout(loadData, 0);
                    EventsBus.emit('init_start', {
                        key: 'profiles',
                        message: i18n.t('cached.profiles.refresh.pending'),
                        priority: 3
                    });
                    return await initData(parsed, true);
                }
            }
            catch(ex) {
                console.error(ex);
            }
            return await loadData();
        });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { profile }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.post(`/customers/profiles`, { data: toAPI(profile) });
        commit('setProfile', { clientId, data: fromAPI(data) });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { profileId, profile }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.patch(`/customers/profiles/${profileId}`, { data: toAPI(profile) });
        // TODO remove
        profile.id = profileId;
        commit('setProfile', { clientId, data: profile });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { profileId }) {
    try {
        const clientId = this.getters.currentClientId;
        await this.getters.api.delete(`/customers/profiles/${profileId}`);
        commit('removeProfile', { clientId, profileId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { clientId, value }) {
    state.fetched[clientId] = value;
  },
  setProfile(state, { clientId, data }) {
    const index = state.profiles[clientId].findIndex(c => c.id === data.id);
    if (data.default) {
        state.profiles[clientId].forEach(s => {
            s.default = false;
            getDb()?.profiles?.update(s.id, {
                data: { ...s },
                updatedAt: new Date()
            });
        });
    }
    if (index > -1) {
      Vue.set(state.profiles[clientId], index, { ...state.profiles[clientId][index], ...data});
      getDb()?.profiles?.update(data.id, {
        data: { ...state.profiles[clientId][index], ...data},
        updatedAt: new Date()
      });
    }
    else {
        state.profiles[clientId].push(data);
        getDb()?.profiles?.add({
            id: data.id,
            clientId,
            data,
            createdAt: new Date(),
            updatedAt: new Date()
        });
    }
  },
  setProfiles(state, { clientId, data, fromDb }) {
    if (!fromDb) {
        getDb()?.profiles?.clear()?.then(() => {
            getDb()?.profiles?.bulkAdd(data.map(d => {
                return {
                    id: d.id,
                    clientId,
                    data: d,
                    createdAt: new Date(),
                    updatedAt: new Date()
                };
            }));
        });
    }
    Vue.set(state.profiles, clientId, data);
  },
  removeProfile(state, { clientId, profileId }) {
    const index = state.profiles[clientId].findIndex(c => c.id === profileId);
    let isDefault = false;
    if (index > -1) {
        isDefault = state.profiles[clientId][index].default;
        state.profiles[clientId].splice(index, 1);
        getDb()?.profiles?.delete(profileId);
    }
    if (isDefault && state.profiles[clientId].length) {
        state.profiles[clientId][0].default = true;
        getDb()?.profiles?.update(state.profiles[clientId][0].id, {
            data: { ...state.profiles[clientId][0] },
            updatedAt: new Date()
        });
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPI(profile) {
    const json = {...profile};
    json.interactionWeight = parseInt(json.interactionWeight, 10);
    json.usageWeight = parseInt(json.usageWeight, 10);
    json.csmWeight = parseInt(json.csmWeight, 10);
    json.agreementWeight = parseInt(json.agreementWeight, 10);
    json.childrenWeight = parseInt(json.childrenWeight, 10);
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
