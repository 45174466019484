var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column skeleton-wrapper" },
    [
      _c("b-skeleton", { staticClass: "mb-3", attrs: { height: "115px" } }),
      _c(
        "b-row",
        { staticClass: "body" },
        [
          _c(
            "b-col",
            [
              _c("b-skeleton", { staticClass: "skeleton-body mb-3" }),
              _c("div", { attrs: { id: "btn-refresh-wrapper" } }, [
                _c(
                  "button",
                  { attrs: { id: "btn-refresh", onclick: "refreshApp()" } },
                  [_vm._v("\n                Reload Skalin\n                ")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }