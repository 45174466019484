import Vue from 'vue';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';

import { AgGridVue } from '@ag-grid-community/vue';

import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
// import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';

import { LicenseManager } from "@ag-grid-enterprise/core";

import Default from './Layout/Wrappers/baseLayout.vue';
import Pages from './Layout/Wrappers/pagesLayout.vue';

LicenseManager.setLicenseKey("[01][v31_MjcwMTA3MzA3NTIwOQ==]cfb404ffe4e2787c77e13148f2341582");
ModuleRegistry.registerModules([ClientSideRowModelModule, ServerSideRowModelModule, RowGroupingModule, MenuModule, MasterDetailModule]);

Vue.component('default-layout', Default);
Vue.component('userpages-layout', Pages);
Vue.component('ag-grid', AgGridVue);

