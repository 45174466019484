import i18n from '@/i18n';

export default [
  {
    path: '/alerts',
    name: 'alerts',
    meta: {
        title: () => {
            return i18n.t('global.alerts.title');
        },
        breadcrumb: []
    },
    props: { category: 'automation' },
    component: () => import('@/modules/alerts/AlertsView.vue'),
  }
];
