<template>
    <div 
    class="item d-flex align-items-center flex-row p-2 mt-2 -pointer position-relative" 
    :class="[ { 'readed': notification.userNotification.readed, 'unreaded': !notification.userNotification.readed }]"
    @click='handleClick'
    >
        <div class="mr-2 position-relative" v-if="!notification.parameters.component">
            <user-avatar v-if="notification.emitterUserId !== 'SYSTEM'" :userId="notification.emitterUserId" :showLabel="false" width="40"/>
            <b-avatar 
                v-else
                :style="{ 'background-color': '#FFFFFF' , color: 'rgb(1,1,1)' }"  
                size="md" 
                icon="gear-fill">
            </b-avatar>
            <div class="upperbadge" v-if="!notification.userNotification.readed"><div class="mybadge"></div></div>
        </div>
        <div v-if="notification.parameters.component" class="d-flex flex-column flex-grow-1">
            <component v-if="resolvedComponent" :is="resolvedComponent"></component>
            <p v-else class="my-auto ml-2">{{$t("notifications.message.unknown")}}</p>
        </div>
        <div v-else class="d-flex flex-column flex-grow-1">
            <span>{{ getMessage() }}</span>
            <span class="subtitle">{{ getReaded() }} • {{ getHourLeft() }}</span>
        </div>
        <b-avatar 
            icon="three-dots-vertical" 
            size="md" 
            :id="`notification-item-${_uid}`"
            class="parameter-badge"
            @click.native.stop
        />
        <b-popover :target="`notification-item-${_uid}`" placement="bottomleft" custom-class="notification-popover m-0 p-0" :show.sync="showed">
            <b-list-group v-click-outside="onClickOutside">
                <b-list-group-item button v-if="!notification.userNotification.readed" class="py-2 px-3" @click="onSaveReaded(true)">
                    <b-icon icon="envelope-open" class="mr-2"/> {{ $t("notifications.popover.actions.markasread") }}
                </b-list-group-item>
                <b-list-group-item v-else button class="py-2 px-3" @click="onSaveReaded(false)">
                    <b-icon icon="envelope" class="mr-2"/> {{ $t("notifications.popover.actions.markasunread") }}
                </b-list-group-item>
                <b-list-group-item v-if="notification.userNotification.archived" button class="py-2 px-3" @click="onSaveArchived(false)">
                    <b-icon icon="save" class="mr-2 rotate-180 "/> {{ $t("notifications.popover.actions.unarchive") }}
                </b-list-group-item>
                <b-list-group-item v-else button class="py-2 px-3" @click="onSaveArchived(true)">
                    <b-icon icon="save" class="mr-2"/> {{ $t("notifications.popover.actions.archive") }}
                </b-list-group-item>
                <b-list-group-item button class="py-2 px-3 text-danger" @click="onDeleteNotification">
                    <b-icon icon="trash" class="mr-2"/> {{ $t("notifications.popover.actions.delete") }}
                </b-list-group-item>
            </b-list-group>
        </b-popover>
        <b-icon icon="bookmark-fill" class="bookmark" v-if="notification.userNotification.archived"/>
    </div> 
</template>

<script>
import Vue from 'vue';
import UserAvatar from '../../../modules/commons/components/UserAvatar.vue';
import { dateFormatters } from "@/libs/formatters";

export default {
    data() {
        return {
            showed: false,
            emitterUser: null,
            taskObject: null,
            interactionObject: null,
            resolvedComponent: null,
        };
    },
    components: {
        UserAvatar
    },
    props: {
        notification: Object,
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        showed(value) {
            this.$emit('update:show', value);
            
        },
        notification: {
            handler: 'fetchData',
            immediate: true // Fetch users immediately on component mount
        }
    },
    methods: {
        async fetchData() {
            try {
                this.emitterUser = await this.$store.dispatch('users/ensureUser', this.notification.emitterUserId);
                switch (this.notification.notificationType) {
                    case 'TASK_DESC':
                        this.taskObject = await this.$store.dispatch('tasks/ensureTaskId', {taskId: this.notification?.parameters?.targetTypeId});
                        break;
                    case 'TASK_COMMENT':
                        this.taskObject = await this.$store.dispatch('tasks/ensureTaskId', {taskId: this.notification?.parameters?.parentId});
                        break;
                    case 'INTERACTION_DESC':
                        this.interactionObject = await this.$store.dispatch('interactions/ensureInteractionId', {customerId: this.notification?.parameters?.customerId , interactionId: this.notification?.parameters?.targetTypeId});
                        break;
                    case 'INTERACTION_COMMENT':
                        this.interactionObject = await this.$store.dispatch('interactions/ensureInteractionId', {customerId: this.notification?.parameters?.customerId , interactionId: this.notification?.parameters?.parentId});
                        break;
                }

                if (this.notification.parameters.component) {
                    try {
                        const componentModule = await import(`@/${this.notification.parameters.component}.vue`);
                        this.resolvedComponent = componentModule.default;
                    }
                    catch (error) {
                        throw 'A server error has occurred';
                    }
                }
                
            } catch (error) {
                this.emitterUser = null;
            }
        },
        getReaded() {
            return this.notification.userNotification.readed ? this.$t("notifications.read") : this.$t("notifications.unread");
        },
        getHourLeft() {
            let date = this.notification.createdAt;
            if (!date) return '-';
            return dateFormatters.fromNow(date);
        },
        async onSaveArchived(value) {
            await this.$root.$emit('bv::hide::popover', `notification-item-${this._uid}`);
            setTimeout(() => {
                Vue.set(this.notification.userNotification, 'archived', value);
                this.$store.dispatch('notifications/updateArchived', this.notification);
            }, 300); 
        },
        onSaveReaded(value) {
            this.$root.$emit('bv::hide::popover', `notification-item-${this._uid}`);
            setTimeout(() => {
                Vue.set(this.notification.userNotification, 'readed', value);
                this.$store.dispatch('notifications/updateReaded', this.notification);
            }, 300);
        },
        onDeleteNotification() {
            this.$root.$emit('bv::hide::popover', `notification-item-${this._uid}`);
            setTimeout(() => {
                this.$store.dispatch('notifications/delete', this.notification);
            }, 300);
        },
        getMessage() {
            const key = `${this.notification.notificationType}_${this.notification.eventType}`;
            try {
                switch (key) {
                case 'TASK_DESC_MENTION':
                    return this.$t("notifications.message.mention.task", { emitterUserName: this.emitterUser.firstName, emitterUserLastName: this.emitterUser.lastName, taskName: this.taskObject.title });
                case 'TASK_COMMENT_MENTION':
                    return this.$t("notifications.message.mention.task.comment", { emitterUserName: this.emitterUser.firstName, emitterUserLastName: this.emitterUser.lastName, taskName: this.taskObject.title });
                case 'INTERACTION_DESC_MENTION':
                    return this.$t("notifications.message.mention.interaction", { emitterUserName: this.emitterUser.firstName, emitterUserLastName: this.emitterUser.lastName, interactionName: this.interactionObject.title });
                case 'INTERACTION_COMMENT_MENTION':
                    return this.$t("notifications.message.mention.interaction.comment", { emitterUserName: this.emitterUser.firstName, emitterUserLastName: this.emitterUser.lastName, interactionName: this.interactionObject.title });
                }
            }
            catch (error) {
                return this.$t("notifications.message.unknown");
            }
            return this.$t("notifications.message.unknown");
            
        },
        onClickOutside() {
            //fermer le popover
            this.$root.$emit('bv::hide::popover', `notification-item-${this._uid}`);
        },
        handleClick() {
            this.$emit('disabled-popover');
            this.onSaveReaded(true);
            this.showed = false;   
            if (this.$route.fullPath !== this.notification.urlLink) {
                this.$router.replace(this.notification.urlLink);
            }
        }
    }

};
</script>
<style lang="scss"> 
.notification-popover {
    & .popover-body {
        padding: 0;
    }

    & .arrow{
    visibility: hidden;
    }
}
</style>
<style lang="scss" scoped>
.item {
    border-radius: 10px;
    transition: background-color 0.3s; 
    &:hover {
        background-color: rgba(1, 1, 1, 0.06);
        .upperbadge::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-color: rgba(1, 1, 1, 0.06);
        }
    }
}

.parameter-badge {
    background-color: transparent;
    &:hover {
        background-color: rgba(1, 1, 1, 0.06);
    }
}

.upperbadge{
    
    background-color: #FFFFFF;
    width: 13px;
    height: 13px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
    border-radius: 100%;
    &::before {
        transition: background-color 0.3s; 
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: rgba(1, 1, 1, 0.03);
    }
}

.mybadge{
    background: var(--app-link-color-hex);
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.bookmark {
    position: absolute;
    top: 0;
    left: 10px;
    color: var(--app-link-color-hex);
    font-size: 15px;
}


.subtitle {
  font-size: 12px;
  color: #666;
}

.readed {
  background-color: rgba(1, 1, 1, 0.015);
}

.unreaded {
  background-color: rgba(1, 1, 1, 0.03);
}

.rotate-180 {
    transform: rotate(180deg);
}
</style>
