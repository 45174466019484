<template>
    <div class="avatar-input">
        <!-- <b-card class="bg-white"> -->
            <img style="" :src="image" alt="" />
            <b-form-file class="ml-5 file-selector custom-select" :placeholder="$t('component.csvimport.file.label')" :drop-placeholder="$t('component.csvimport.file.drop')" accept="image/*" @change="handleImage" ></b-form-file>
        <!-- </b-card> -->
    </div>
</template>
<script>
export default {
    components: {
    },
    props: {
        value: String
    },
    data: () => ({
        image: ''
    }),
    computed: {

    },
    watch: {
        value() {
            this.image = this.value || '';
        }
    },
    mounted() {
        this.image = this.value || '';
    },
    methods: {
        handleImage(e) {
            const selectedImage = e.target.files[0];
            this.createBase64Image(selectedImage);
        },
        createBase64Image(fileObject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result;
                this.$emit('input', this.image);
                this.$emit('change', this.image);
            };
            reader.readAsDataURL(fileObject);
        }
    }
}
</script>
<style lang="scss" scoped>
.avatar-input {
    & > div {
        width: 100%;
    }
    .file-selector {
        width: 80px;
    }
}
img {
    height: 100%;
}
</style>