<template>
    <div class="button-link" @click.prevent.stop>
        <button
            v-if="!editor.isActive('link')"
            @click="showLinkModal "
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('link') }"
            :title="$t('commons.editor.command.link')"
        >
          <icon name="link" />
        </button>
        <button
            v-if="editor.isActive('link')"
            @click="updateLink"
            class="menubar__button"
            :title="$t('commons.editor.command.unlink')"
        >
          <icon name="unlink" />
        </button>
          <div @click.prevent.stop>
              <link-infos ref="info" v-click-outside="exit"
                v-model="url"
                :can-unlink="canRemove"
                @select="setLink"
                @cancel="exit"
              />
        </div>
    </div>
</template>

<script>
import Icon from '@/modules/commons/components/Icon';
import LinkInfos from '@/libs/tiptap/link/LinkInfos';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default {
    components: {
        Icon,
        LinkInfos
    },
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      url: null,
      tip: null,
      canRemove: false
    }
  },
  mounted() {
      this.init();
  },
  methods: {
    init() {
        this.tip = tippy(this.$el, {
          getReferenceClientRect: () => {
            return this.$el?.getBoundingClientRect();
          },
          appendTo: () => document.body,
          content: this.$refs.info?.$el,
          showOnCreate: false,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom',
          maxWidth: 550,
          theme: 'light',
          delay: [200, 0],
        });
    },
    showLinkModal() {
        this.url = '';
        this.$emit('show');
        this.canRemove = false;
        this.tip?.show();
    },
    updateLink() {
        this.$emit('show');
        this.canRemove = true;
        this.url = this.editor.getAttributes('link')?.href;
        this.tip?.show();
        // console.info(this.editor.getAttributes('link').href);
        // editor.chain().focus().unsetLink().run()
    },
    setLink(url) {
            // const previousUrl = this.editor.getAttributes('link').href
            // const url = window.prompt('URL', previousUrl)
            this.exit();
            // cancelled
            if (url === null) {
                return;
            }

            // empty
            if (url === '') {
                this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run();

                return;
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run();
        },
    exit() {
        this.$emit('hide');
        this.tip?.hide();
        // this.$refs.info?.reset();
    }
  },
}
</script>

<style lang="scss">
.button-link {
    display: flex;
    flex-direction: column;
    // width: 80px;

    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
}

.menu-item-var {
    display: flex;
    flex-direction: column;

    .menu-item-var-description {
        font-weight: 200;
        font-size: 12px;
    }
}
</style>