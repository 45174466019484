import Vue from 'vue';
import store from '../store';


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useInternal = () => {

    return function () {
        return store.getters['users/currentUser']?.internal || false;
    }
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const InternalPlugin = {
    install(Vue, options) {
      Vue.prototype.$isInternal = useInternal(options);
    }
};

Vue.use(InternalPlugin);
