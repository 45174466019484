
import Vue from 'vue';
import dayjs from 'dayjs';
import i18n from '@/i18n';
import EventsBus from '@/libs/EventsBus';
import { handleErrors } from '@/libs/utils/uri';

const state = {
  fetched: {},
  taskFetched: {},
  opportunityFetched: {},
  interactionFetched: {},
  attachments: {},
  taskAttachments: {},
  opportunityAttachments: {},
  interactionAttachments: {}
};

const getters = {
  getAll: (state) => customerId => {
      return state.attachments[customerId] || [];
  },
  getFromId: (state, getters) => (customerId, id ) => {
      return getters.getAll(customerId).find(a => a.id === id)
  },
  getAllTaskAttachments: (state) => taskId => {
    return state.taskAttachments[taskId] || [];
  },
  getTaskAttachmentFromId: (state, getters) => (taskId, id) => {
    return getters.getAllOpportunityAttachments(taskId).find(a => a.id === id);
  },
  getAllOpportunityAttachments: (state) => taskId => {
    return state.opportunityAttachments[taskId] || [];
  },
  getOpportunityAttachmentFromId: (state, getters) => (taskId, id) => {
    return getters.getAllTaskAttachments(taskId).find(a => a.id === id);
  },
  getAllInteractionAttachments: (state) => interactionId => {
    return state.interactionAttachments[interactionId] || [];
  },
  getInteractionAttachmentFromId: (state, getters) => (interactionId, id) => {
    return getters.getAllInteractionAttachments(interactionId).find(a => a.id === id);
  },
  getImageByType: () => (type) => {
        let path = "assets/images/mimes/pdf-svgrepo-com.svg";
        switch(type) {
            case 'PDF':
                path = 'assets/images/mimes/pdf-svgrepo-com.svg';
                break;
            case 'DOC':
                path = 'assets/images/mimes/doc-svgrepo-com.svg';
                break;
            case 'XLS':
                path = 'assets/images/mimes/xls-svgrepo-com.svg';
                break;
            case 'TEXT':
                path = 'assets/images/mimes/text.svg';
                break;
            case 'AUDIO':
                path = 'assets/images/mimes/audio.svg';
                break;
            case 'VIDEO':
                path = 'assets/images/mimes/video.svg';
                break;
            case 'ARCHIVE':
                path = 'assets/images/mimes/zip-svgrepo-com.svg';
                break;
            case 'PPT':
                path = 'assets/images/mimes/ppt-svgrepo-com.svg';
                break;
            default: 
                path = "";
                break;
        }
        return path;
  }
};

const actions = {

  async ensureAttachments({ dispatch, state }, { customerId }) {
    if (!state.fetched[customerId]) {
      await dispatch('fetchAttachments', { customerId });
    }
  },
  async ensureTaskAttachments({ dispatch, state }, { taskId }) {
    if (!state.taskFetched[taskId]) {
      await dispatch('fetchTaskAttachments', { taskId });
    }
  },
  async ensureOpportunityAttachments({ dispatch, state }, { opportunityId }) {
    if (!state.opportunityFetched[opportunityId]) {
      await dispatch('fetchOpportunityAttachments', { opportunityId });
    }
  },
  async ensureInteractionAttachments({ dispatch, state }, { customerId, interactionId }) {
    if (!state.interactionFetched[interactionId]) {
      await dispatch('fetchInteractionAttachments', {customerId, interactionId });
    }
  },
  async fetchAttachments({ dispatch, commit }, { customerId }) {
    try {
        const query = {
            params: {
                uri: true
            }
        }
      const data = (await this.getters.api.get(`/customers/${customerId}/attachments`, query)).map(fromAPI);
      commit('fetched', { customerId, value: true });
      commit('setAttachments', { customerId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTaskAttachments({ dispatch, commit }, { taskId }) {
    try {
        const query = {
            params: {
                uri:true
            }
        }
      const data = (await this.getters.api.get(`/tasks/${taskId}/attachments`, query)).map(fromAPI);
      commit('taskFetched', { taskId, value: true });
      commit('setTaskAttachments', { taskId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunityAttachments({ dispatch, commit }, { opportunityId }) {
    try {
        const query = {
            params: {
                uri: true
            }
        }
      const data = (await this.getters.api.get(`/opportunity/${opportunityId}/attachments`, query)).map(fromAPI);
      commit('opportunityFetched', { opportunityId, value: true });
      commit('setOpportunityAttachments', { opportunityId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionAttachments({ dispatch, commit }, { customerId, interactionId }) {
    try {
        const query = {
            params: {
                uri:true
            }
        }
      const data = (await this.getters.api.get(`/customers/${customerId}/interactions/${interactionId}/attachments`, query)).map(fromAPI);
      commit('interactionFetched', { interactionId, value: true });
      commit('setInteractionAttachments', { interactionId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchAttachmentPublicUri({ dispatch, commit }, { customerId, attachmentId }) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/attachments/${attachmentId}/uri`);
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTaskAttachmentPublicUri({ dispatch, commit }, { taskId, attachmentId }) {
    try {
      const data = await this.getters.api.get(`/tasks/${taskId}/attachments/${attachmentId}/uri`);
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchOpportunityAttachmentPublicUri({ dispatch, commit }, { taskId, attachmentId }) {
    try {
      const data = await this.getters.api.get(`/opportunity/${taskId}/attachments/${attachmentId}/uri`);
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionAttachmentPublicUri({ dispatch, commit }, { customerId, interactionId, attachmentId }) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/interactions/${interactionId}/attachments/${attachmentId}/uri`);
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ dispatch, commit }, { customerId, attachment }) {
        const file = attachment.file;
        delete attachment.file;

        const data = await this.getters.api.post(`/customers/${customerId}/attachments`, { data: toAPI(attachment) });
        // Upload file
        if (file && !data.external && data.uploadUrl) {
            await fetch(data.uploadUrl, { method: "PUT", mode:'cors', body: file }).then(handleErrors);
            data.link = await dispatch('fetchAttachmentPublicUri', { customerId, attachmentId: data.id } );
        }
        commit('setAttachment', { customerId, data: fromAPI(data) });
        return data;
  },
  async addTaskAttachement({ dispatch, commit }, { taskId, attachment }) {
        const file = attachment.file;
        delete attachment.file;

        let data = await this.getters.api.post(`/tasks/${taskId}/attachments`, { data: toAPI(attachment) });
        // Upload file
        if (file && !data.external && data.uploadUrl) {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.success'),
                variant: 'info',
                text: i18n.t(`customers.attachments.modal.file.uploading`)
            });
            await fetch(data.uploadUrl, { method: "PUT", mode: 'cors', body: file }).then(handleErrors);
            data.link = await dispatch('fetchTaskAttachmentPublicUri', { taskId, attachmentId: data.id });
        }
        commit('setTaskAttachment', { taskId, data: fromAPI(data) });

        const task = this.getters['tasks/getFromId'](taskId);
        if (task) {
            this.commit('tasks/setTask', { data: { taskId, nbAttachements: task.nbAttachements + 1 } });
            if (task.projectId || task.project?.id) {
                this.commit('tasks/setProjetTask', { projectId: task.projectId || task.project?.id, data: { taskId, nbAttachements: task.nbAttachements + 1 } });
            }
        }
        return data;
  },
  async addOpportunityAttachement({ dispatch, commit }, { opportunityId, attachment }) {
    const file = attachment.file;
    delete attachment.file;

    let data = await this.getters.api.post(`/opportunity/${opportunityId}/attachments`, { data: toAPI(attachment) });
    // Upload file
    if (file && !data.external && data.uploadUrl) {
        EventsBus.emit('notify', {
            title: i18n.t('commons.ope.success'),
            variant: 'info',
            text: i18n.t(`customers.attachments.modal.file.uploading`)
        });
        await fetch(data.uploadUrl, { method: "PUT", mode: 'cors', body: file }).then(handleErrors);
        data.link = await dispatch('fetchOpportunityAttachmentPublicUri', { opportunityId, attachmentId: data.id });
    }
    commit('setOpportunityAttachment', { opportunityId, data: fromAPI(data) });

    const opportunity = this.getters['opportunity/getFromId'](opportunityId);
    if (opportunity) {
        this.commit('opportunity/setOpportunity', { data: { opportunityId, nbAttachements: opportunity.nbAttachements + 1 } });
    }
    return data;
},
  async addInteractionAttachement({ dispatch, commit }, { customerId, interactionId, attachment }) {
        const file = attachment.file;
        delete attachment.file;

        let data = await this.getters.api.post(`/customers/${customerId}/interactions/${interactionId}/attachments`, { data: toAPI(attachment) });
        // Upload file
        if (file && !data.external && data.uploadUrl) {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.success'),
                variant: 'info',
                text: i18n.t(`customers.attachments.modal.file.uploading`)
            });
            await fetch(data.uploadUrl, { method: "PUT", mode:'cors', body: file }).then(handleErrors);
            data.link = await dispatch('fetchInteractionAttachmentPublicUri', { customerId, interactionId, attachmentId: data.id } );
        }
        commit('setInteractionAttachment', { interactionId, data: fromAPI(data) });

        const interaction = this.getters['interactions/getFromId'](customerId, interactionId);
        if (interaction) {
            this.commit('interactions/setInteraction', { customerId, data: { id: interactionId, nbAttachements: interaction.nbAttachements + 1 } });
        }
        return data;
  },
  async update({ dispatch, commit, getters }, { customerId, attachmentId, attachment }) {
        const file = attachment.file;
        delete attachment.file;

        const data = await this.getters.api.patch(`/customers/${customerId}/attachments/${attachmentId}`, { data:  toAPI(attachment) });
        // Upload file
        if (file && !data.external && data.uploadUrl) {
            await fetch(data.uploadUrl, { method: "PUT", mode:'cors', body: file }).then(handleErrors);
            data.link = await dispatch('fetchTaskAttachmentPublicUri', { customerId, attachmentId: data.id} );
        }
        else if (!data.external){
            // File with no update -> Get from store
            const old = getters.getFromId(customerId, attachmentId);
            data.link = attachment.link || old.link;
        }
        // TODO remove
        attachment.id = attachmentId;
        attachment.link = data.link;
        commit('setAttachment', { customerId, data: attachment });
        return data;
  },
  async updateTaskAttachement({ dispatch, commit, getters }, { taskId, attachmentId, attachment }) {
        const file = attachment.file;
        delete attachment.file;

        const data = await this.getters.api.patch(`/tasks/${taskId}/attachments/${attachmentId}`, { data:  toAPI(attachment) });
        // Upload file
        if (file && !data.external && data.uploadUrl) {
            await fetch(data.uploadUrl, { method: "PUT", mode:'cors', body: file }).then(handleErrors);
            data.link = await dispatch('fetchTaskAttachmentPublicUri', { taskId, attachmentId: data.id} );
        }
        else if (!data.external){
            // File with no update -> Get from store
            const old = getters.getTaskAttachmentFromId(taskId, attachmentId);
            data.link = attachment.link || old.link;
        }
        // TODO remove
        attachment.id = attachmentId;
        attachment.link = data.link;
        commit('setTaskAttachment', { taskId, data: attachment });
        return data;
  },
  async updateOpportunityAttachement({ dispatch, commit, getters }, { opportunityId, attachmentId, attachment }) {
    const file = attachment.file;
    delete attachment.file;

    const data = await this.getters.api.patch(`/opportunity/${opportunityId}/attachments/${attachmentId}`, { data:  toAPI(attachment) });
    // Upload file
    if (file && !data.external && data.uploadUrl) {
        await fetch(data.uploadUrl, { method: "PUT", mode:'cors', body: file }).then(handleErrors);
        data.link = await dispatch('fetchOpportunityAttachmentPublicUri', { opportunityId, attachmentId: data.id} );
    }
    else if (!data.external){
        // File with no update -> Get from store
        const old = getters.getOpportunityAttachmentFromId(opportunityId, attachmentId);
        data.link = attachment.link || old.link;
    }
    // TODO remove
    attachment.id = attachmentId;
    attachment.link = data.link;
    commit('setOpportunityttachment', { opportunityId, data: attachment });
    return data;
},
  async updateInteractionAttachement({ dispatch, commit, getters }, { customerId, interactionId, attachmentId, attachment }) {
        const file = attachment.file;
        delete attachment.file;

        const data = await this.getters.api.patch(`/customers/${customerId}/interactions/${interactionId}/attachments/${attachmentId}`, { data:  toAPI(attachment) });
        // Upload file
        if (file && !data.external && data.uploadUrl) {
            await fetch(data.uploadUrl, { method: "PUT", mode:'cors', body: file }).then(handleErrors);
            data.link = await dispatch('fetchTaskAttachmentPublicUri', { customerId, interactionId, attachmentId: data.id} );
        }
        else if (!data.external){
            // File with no update -> Get from store
            const old = getters.getInteractionAttachmentFromId(interactionId, attachmentId);
            data.link = attachment.link || old.link;
        }
        // TODO remove
        attachment.id = attachmentId;
        attachment.link = data.link;
        commit('setInteractionAttachment', { interactionId, data: attachment });
        return data;
  },
  async remove({ commit }, { customerId, attachmentId }) {
    try {
        await this.getters.api.delete(`/customers/${customerId}/attachments/${attachmentId}`);
        commit('removeAttachment', { customerId, attachmentId });
        await this.dispatch('customers/fetchCustomer', { customerId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeTaskAttachement({ commit }, { taskId, attachmentId }) {
    try {
        await this.getters.api.delete(`/tasks/${taskId}/attachments/${attachmentId}`);
        commit('removeTaskAttachment', { taskId, attachmentId });
        const task = this.getters['tasks/getFromId'](taskId);
        if (task) {
            this.commit('tasks/setTask', { data: { taskId, nbAttachements: task.nbAttachements - 1 } });
            if (task.projectId || task.project?.id) {
                this.commit('tasks/setProjetTask', { projectId: task.projectId || task.project?.id, data: { taskId, nbAttachements: task.nbAttachements - 1 } });
            }
        }
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeOpportunityAttachement({ commit }, { opportunityId, attachmentId }) {
    try {
        await this.getters.api.delete(`/tasks/${opportunityId}/attachments/${attachmentId}`);
        commit('removeOpportunityAttachment', { opportunityId, attachmentId });
        const task = this.getters['opportunity/getFromId'](opportunityId);
        if (task) {
            this.commit('opportunity/setOpportunity', { data: { opportunityId, nbAttachements: task.nbAttachements - 1 } });
        }
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeInteractionAttachement({ commit }, { customerId, interactionId, attachmentId }) {
    try {
        await this.getters.api.delete(`/customers/${customerId}/interactions/${interactionId}/attachments/${attachmentId}`);
        commit('removeInteractionAttachment', { interactionId, attachmentId });

        const interaction = this.getters['interactions/getFromId'](customerId, interactionId);
        if (interaction) {
            this.commit('interactions/setInteraction', { customerId, data: { id: interactionId, nbAttachements: interaction.nbAttachements - 1 } });
        }
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { customerId, value }) {
    state.fetched[customerId] = value;
  },
  taskFetched(state, { taskId, value }) {
    state.taskFetched[taskId] = value;
  },
  opportunityFetched(state, { opportunityId, value }) {
    state.opportunityFetched[opportunityId] = value;
  },
  interactionFetched(state, { interactionId, value }) {
    state.interactionFetched[interactionId] = value;
  },
  setAttachment(state, { customerId, data }) {
      if (!state.attachments[customerId]) state.attachments[customerId] = [];
      const index = state.attachments[customerId].findIndex(c => c.id === data.id);
      if (index > -1) {
        Vue.set(state.attachments[customerId], index, { ...state.attachments[customerId][index], ...data});
      }
      else {
        state.attachments[customerId].push(data);
      }
  },
  setTaskAttachment(state, { taskId, data }) {
      if (!state.taskAttachments[taskId]) state.taskAttachments[taskId] = [];
      const index = state.taskAttachments[taskId].findIndex(c => c.id === data.id);
      if (index > -1) {
        Vue.set(state.taskAttachments[taskId], index, { ...state.taskAttachments[taskId][index], ...data});
      }
      else {
        state.taskAttachments[taskId].push(data);
      }
  },
  setOpportunityAttachment(state, { opportunityId, data }) {
    if (!state.opportunityAttachments[opportunityId]) state.opportunityAttachments[opportunityId] = [];
    const index = state.opportunityAttachments[opportunityId].findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.opportunityAttachments[opportunityId], index, { ...state.opportunityAttachments[opportunityId][index], ...data});
    }
    else {
      state.opportunityAttachments[opportunityId].push(data);
    }
},
  setInteractionAttachment(state, { interactionId, data }) {
      if (!state.interactionAttachments[interactionId]) state.interactionAttachments[interactionId] = [];
      const index = state.interactionAttachments[interactionId].findIndex(c => c.id === data.id);
      if (index > -1) {
        Vue.set(state.interactionAttachments[interactionId], index, { ...state.interactionAttachments[interactionId][index], ...data});
      }
      else {
        state.interactionAttachments[interactionId].push(data);
      }
  },
  setAttachments(state, { customerId, data }) {
    Vue.set(state.attachments, customerId, data);
  },
  setTaskAttachments(state, { taskId, data }) {
    Vue.set(state.taskAttachments, taskId, data);
  },
  setOpportunityAttachments(state, { opportunityId, data }) {
    Vue.set(state.opportunityAttachments, opportunityId, data);
  },
  setInteractionAttachments(state, { interactionId, data }) {
    Vue.set(state.interactionAttachments, interactionId, data);
  },
  removeAttachment(state, { customerId, attachmentId }) {
    const index = state.attachments[customerId]?.findIndex(c => c.id === attachmentId);
    if (index > -1) {
        state.attachments[customerId].splice(index, 1);
    }
  },
  removeTaskAttachment(state, { taskId, attachmentId }) {
    const index = state.taskAttachments[taskId]?.findIndex(c => c.id === attachmentId);
    if (index > -1) {
        state.taskAttachments[taskId].splice(index, 1);
    }
  },
  removeOpportunityAttachment(state, { opportunityId, attachmentId }) {
    const index = state.interactionAttachments[opportunityId]?.findIndex(c => c.id === attachmentId);
    if (index > -1) {
        state.interactionAttachments[opportunityId].splice(index, 1);
    }
  },
  removeInteractionAttachment(state, { interactionId, attachmentId }) {
    const index = state.interactionAttachments[interactionId]?.findIndex(c => c.id === attachmentId);
    if (index > -1) {
        state.interactionAttachments[interactionId].splice(index, 1);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
    for (const key of Object.keys(state.taskFetched)) {
      state.taskFetched[key] = false;
    }
    for (const key of Object.keys(state.opportunityFetched)) {
        state.opportunityFetched[key] = false;
      }
    for (const key of Object.keys(state.interactionFetched)) {
      state.interactionFetched[key] = false;
    }
  }
};

function fromAPI(json) {
    return json;
}

function toAPI(json) {
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
