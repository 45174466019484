const MAX_HISTORIES_ITEMS = 10;

const state = {
  histories: []
};

const getters = {
  getHistories: state => state.histories
};

const actions = {
  addHistory ({commit, state}, { name, link, type }) {
    if (state.histories.length >= MAX_HISTORIES_ITEMS) {
      commit('removeLast');
    }

    commit('addItem', { name, link, type });
  },

  cleanHistory({ commit }) {
    commit('clean');
  }
};

const mutations = {
  removeLast (state) {
    state.histories = state.histories.shift();
  },

  clean (state) {
    state.histories = [];
  },

  addItem(state, { name, link, type }) {
    state.histories.push({ name, link, type });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
