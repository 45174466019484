var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onCancel,
          expression: "onCancel"
        }
      ],
      staticClass: "image-infos-wrapper",
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "b-card",
        [
          _c(
            "b-form-group",
            {
              staticClass: "w-100",
              attrs: {
                label: _vm.$t("commons.editor.image.url.label"),
                "label-for": "input-var-url"
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-var-url",
                  type: "text",
                  size: "sm",
                  placeholder: _vm.$t("commons.editor.link.url.placeholder"),
                  debounce: "500",
                  "aria-describedby": "input-var-url"
                },
                model: {
                  value: _vm.url,
                  callback: function($$v) {
                    _vm.url = $$v
                  },
                  expression: "url"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "w-100",
              attrs: {
                label: _vm.$t("commons.editor.image.title.label"),
                "label-for": "input-var-url"
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-var-title",
                  type: "text",
                  size: "sm",
                  placeholder: _vm.$t("commons.editor.image.title.placeholder"),
                  debounce: "500",
                  "aria-describedby": "input-var-url"
                },
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "w-100",
              attrs: {
                label: _vm.$t("commons.editor.image.alt.label"),
                "label-for": "input-var-url"
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-var-title",
                  type: "text",
                  size: "sm",
                  placeholder: _vm.$t("commons.editor.image.alt.placeholder"),
                  debounce: "500",
                  "aria-describedby": "input-var-url"
                },
                model: {
                  value: _vm.alt,
                  callback: function($$v) {
                    _vm.alt = $$v
                  },
                  expression: "alt"
                }
              })
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mr-2",
              attrs: { variant: "primary", size: "sm" },
              on: { click: _vm.onSave }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("commons.action.ok")) +
                  "\n        "
              )
            ]
          ),
          _vm.canUndo
            ? _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: { variant: "danger", size: "sm" },
                  on: { click: _vm.onRemove }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("commons.action.remove")) +
                      "\n        "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "b-button",
            {
              attrs: { variant: "outline-danger", size: "sm" },
              on: { click: _vm.onCancel }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("commons.action.cancel")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }