import i18n from '@/i18n';

export default [
  {
    path: '/opportunity',
    name: 'opportunityPipelines',
    meta: {
        title: () => {
            return i18n.t('global.opportunity.pipeline.title');
        },
        breadcrumb: [],
        authorisation: { feature: 'opportunity', action: 'read' }
    },
    props: { category: 'automation' },
    component: () => import('@/modules/opportunity/PipelineView.vue'),
  },
  {
    path: "/opportunity/:pipelineId",
    name: "opportunity",
    meta: {
        title: () => {
            return i18n.t('global.opportunity.title');
        },
        breadcrumb: [],
        authorisation: { feature: 'opportunity', action: 'read' }
    },
    props: { category: 'automation' },
    component: () => import('@/modules/opportunity/OpportunityView.vue'),
}
];
