const state = {
    fetched: false,
    plans: []
};

const getters = {
    getAll: (state, getters, store,  rootGetters) => {
        return state.plans || [];
    },
};

const actions = {
    async ensurePlans({ dispatch, state }) {
        if (!state.fetched) {
          return await dispatch('fetchPlans');
        }
        return state.plans;
    },
    async fetchPlans({ dispatch, commit, rootGetters }) {
        try {
            const customers = rootGetters['customers/getAll'];
            let data = customers.map(c => {
                return c.plan;
            }).filter(c => !!c);
            data = [...new Set(data)];
            data.sort();

            commit('fetched', { value: true });
            commit('setPlans', { data });
        } catch(error) {
            throw 'A server error has occured';
        }
    }
};

const mutations = {
    fetched(state, { value }) {
        state.fetched = value;
    },
    setPlans(state, { data }){
       state.plans = data;
    }
};

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
  };