import i18n from '@/i18n';

export default [
  {
    path: '/agreements',
    name: 'agreements',
    meta: {
        title: () => {
            return i18n.t('global.agreements.title');
        },
        breadcrumb: []
    },
    props: { category: 'automation' },
    component: () => import('@/modules/agreements/AgreementsView.vue'),
  }
];
