import i18n from '@/i18n';

export default [
  {
    path: '/tasks',
    name: 'tasks',
    meta: {
        title: () => {
            return i18n.t('global.tasks.title');
        },
        breadcrumb: []
    },
    props: { category: 'automation' },
    component: () => import('@/modules/tasks/TasksView.vue'),
  },
  {
    path: "/tasks/templates",
    name: "tasksTemplates",
    meta: {
        title: () => {
            return i18n.t('global.tasks.templates.title');
        },
        breadcrumb: []
    },
    props: { category: 'automation' },
    component: () => import('@/modules/tasks/TasksTemplatesListView.vue'),
}
];
