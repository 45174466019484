<template>
    <div class="d-flex flex-column skeleton-wrapper">
        <b-skeleton height="115px" class="mb-3"></b-skeleton>
        <b-row class="body">
            <b-col>
                <b-skeleton class="skeleton-body mb-3"></b-skeleton>
            </b-col>
        </b-row>
    </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss">
  .app-main__inner {
      display: flex;
      flex-direction: column;
  }
  .skeleton-wrapper {
      height: 100%;
      flex-grow: 1;

      .body {
        height: 100%;
        flex-grow: 1;

        .skeleton-body {
            height: 100%;
        }
      }
  }
</style>
