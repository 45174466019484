import Vue from 'vue';

Vue.directive('paste', {
  bind: function (el, binding, vnode) {
    el.onpasteHandler = function (event) {
        vnode.context[binding.expression](event);
    };
    el.addEventListener('paste', el.onpasteHandler);
  },
  unbind: function (el) {
    el.removeEventListener('paste', el.onpasteHandler);
  },
});
