import i18n from '@/i18n';

export default [
  {
    path: '/myactions',
    name: 'myactions',
    meta: {
        title: () => {
            return i18n.t('global.myactions.title');
        },
        breadcrumb: []
    },
    props: { category: 'automation' },
    component: () => import('@/modules/dashboards/MyActionsView.vue'),
  }
];
