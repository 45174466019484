import Vue from 'vue';
import store from '../store';
import LRU from 'lru-cache';

const LRU_CACHE = new LRU({
    max: 50000,
    maxAge: 1000 * 15,
    ttl: 1000 * 15
});


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const canEditCustomer = () => {

    return function (customerId) {
        const cached = LRU_CACHE.get(customerId);
        if (typeof cached !== 'undefined') return cached;
        const canEdit = store.getters['canEditCustomer']({ customerId });
        LRU_CACHE.set(customerId, canEdit);
        return canEdit;
    }
    
};
// Create a simple Vue plugin to expose the wrapper object throughout the application
export const CustomerPlugin = {
    install(Vue, options) {
      Vue.prototype.$canEditCustomer = canEditCustomer(options);
    }
};

Vue.use(CustomerPlugin);
