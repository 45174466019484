
import Vue from 'vue';
import dayjs from 'dayjs';

const state = {
  fetched: {},
  alerts: [],
  stats: {}
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
      return state.alerts;
  },
  getAllForCustomerId: (state, getters, store, rootGetters) => customerId => {
    return state.alerts.filter(t => t.customerId === customerId);
},
  getFromId: (state) => (id )=> state.alerts.find(a => a.id === id),
  getStats: (state) => customerId => {
    return state.stats[customerId || 'global'];
  },
  getTypes: (state, getters, store, rootGetters) => {
    const types = ['EMAIL', 'WEBHOOK', 'SLACK'];
    return types;
  },
  getIcon: () => (type) => {
    switch(type) {
        case 'EMAIL': return 'envelope-open';
        case 'WEBHOOK': return 'bolt';
        case 'SLACK': return ['fab', 'slack'];
        case 'ZAPIER': return ['fac', 'zapier'];
        default: return 'bullseye';
    }
  }
};

const actions = {

  async ensureAlerts({ dispatch, state }, { customerId }) {
    if (!state.fetched[customerId]) {
      await dispatch('fetchAlerts', { customerId });
    }
  },
  async ensureAlertsStats({ dispatch, state }, { customerId } = {}) {
    if (!state.stats[customerId || 'global']) {
      await dispatch('fetchAlertsStats', { customerId });
    }
  },
  async fetchAlerts({ dispatch, commit, state }, { customerId, ctx, ref }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      if (ref) {
        query.params = { ref };
      }
      const { metadata, data } = await this.getters.api.get(customerId ? `/customers/${customerId}/alerts` : `/alerts`, query);
      const alerts = data.map(fromAPI);
      commit('fetched', { customerId, value: true });
      commit('setAlerts', { data: alerts });
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: alerts    
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchAlertsStats({ dispatch, commit }, { customerId } = {}) {
    try {
      const data = await this.getters.api.get(customerId ? `/customers/${customerId}/alerts/stats` : `/alerts/stats`);
      commit('setAlertsStats', { customerId, data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { alert }) {
    try {
        const data = await this.getters.api.post(`/alerts`, { data: toAPI(alert) });
        commit('setAlert', { data: fromAPI(data) });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { alertId, alert }) {
    try {
        const data = await this.getters.api.patch(`/alerts/${alertId}`, { data: toAPI(alert) });
        // TODO remove
        alert.id = alertId;
        commit('setAlert', { data: alert });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { alertId }) {
    try {
        await this.getters.api.delete(`/alerts/${alertId}`);
        commit('removeAlert', { alertId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, {  customerId, value }) {
    state.fetched[customerId] = value;
  },
  setAlert(state, { data }) {
    const index = state.alerts.findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.alerts, index, { ...data});
    }
    else {
      state.alerts.push(data);
    }
    // Vue.set(state.alerts[customerId], state.alerts[clientId][customerId].length, data);
  },
  removeAlert(state, { alertId }) {
    const index = state.alerts.findIndex(c => c.id === alertId);
    if (index > -1) {
        state.alerts.splice(index, 1);
    }
  },
  setAlerts(state, { data }) {
    state.alerts = data;
  },
  setAlertsStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId || 'global', data);
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (json.dueDate) json.dueDate = dayjs(json.dueDate).toDate();
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    if (json.trigger.customerId) {
        json.trigger.parameters = json.trigger.parameters || {};
        json.trigger.parameters.customer = json.trigger.parameters.customer || {};
        json.trigger.parameters.customer.customerId = json.trigger.customerId;
    }
    return json;
}

function toAPI(alert) {
    const json = { ...alert };
    // if (json.dueDate) json.dueDate = dayjs(json.dueDate).format('YYYY-MM-DD');
    json.priority = json.priority || 0;
    json.options = json.options || {};
    json.trigger.customerId = json.trigger.parameters?.customer?.customerId || null;
    delete json.trigger.parameters?.customer?.customerId;
    delete json.trigger.id;
    delete json.trigger.createdAt;
    delete json.trigger.updatedAt;
    delete json.trigger.deletedAt;
    delete json.trigger.deleted;
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
