import Image from '@tiptap/extension-image';

const ImageResizable = Image.extend({
  
    addAttributes() {
        return {
          ...this.parent?.(),
          size: {
            default: 'big',
            // Customize the HTML parsing (for example, to load the initial content)
            parseHTML: (element) => {
                return element.getAttribute('data-size');
            },
            // … and customize the HTML rendering.
            renderHTML: (attributes) => {
              if (!attributes.size) return {};
              let width = 224;
              switch (attributes.size) {
                case 'big': width = 562;
                    break;
                case 'small': width = 224;
                    break;
                case 'origine': width = 0;
                    break;
              }
              return {
                'data-size': attributes.size,
                width: width ? `${width}` : '',
                style: width ? `width: ${width}px` : ''
              };
            },
          },
        }
      }

});

export default ImageResizable;