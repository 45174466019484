import mitt from 'mitt';

const SSE_ENABLED = process.env.VUE_APP_SSE_ENABLED !== 'false';
const SSE_URL = process.env.VUE_APP_SSE_URL;

let instance = null;

export class SseService {

    sessionId = null;
    available = false;
    eventSource = null;
    clientId = null;
    userId = null;
    authToken = null;

    constructor() {
        Object.assign(this, mitt());
        // window.addEventListener(`beforeunload`, () => {
        //     if (this.eventSource && this.available) {
        //         this.eventSource.close();
        //         this.eventSource = null;
        //     }
        // });
    }

    init({ clientId, userId, sessionId, token } = {}){
        if (!SSE_ENABLED) return;
        if (this.eventSource && this.available) {
            console.info('Closing connection...');
            this.eventSource.close();
            this.eventSource = null;
        }

        if (token?.length) this.authToken = token;
        if (clientId?.length) this.clientId = clientId;
        if (userId?.length) this.userId = userId;
        if (sessionId?.length) this.sessionId = sessionId;

        this.eventSource = new EventSource(`${SSE_URL}?clientId=${this.clientId}&userId=${this.userId}&sessionId=${this.sessionId}`);

        this.eventSource.addEventListener(`sync`, (event) => {
            const data = JSON.parse(event.data);
            // Check clientId
            if (data.clientId && this.clientId !== data.clientId) return;
            this.emit(data.type, data);
            console.info(event, data);
        });
        this.eventSource.addEventListener(`open`, () => {
            this.available = true;
            console.info('EventSource is opened.' + this.eventSource.readyState);
        });
        this.eventSource.addEventListener(`error`, (err) => {
            this.available = false;
            console.error('EventSource error ', this.eventSource?.readyState, err);
            if(this.eventSource?.readyState === 2){
                setTimeout(() => {
                    this.init();
                }, 5000);
            }
        });
    }

    static init({ clientId, userId, token }){
        SseService.getInstance().init({ clientId, userId, token });
    }

    static getInstance() {
        if (!instance) instance = new SseService();
        return instance;
    }
}

instance = new SseService();

export default instance;