<template>
  <div
    class="vcs__select-menu"
    :class="{ 'vcs__select-menu__not-main': notMain }"
    @mousewheel="onMouseWheel"
    ref="menu"
  >
    <Option
      v-for="(option, index) in options"
      ref="options"
      :active="option.value === nextMenu.value"
      :disabled="option.disabled"
      :focused="index === selectedOption"
      :index="index"
      :key="option.value"
      :label="option.label"
      :description="option.description"
      :onSelect="onSelect"
      :options="option.options"
      :option="option"
      :selectable="option.selectable"
      :value="option.value"
      @openMenu="handleOpenNextMenu"
      @closeMenu="handleCloseMenu"
      @nextOption="moveOption(true)"
      @prevOption="moveOption(false)"
    >
        <slot v-for="(_, name) in $slots" :name="name"/>
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </Option>

    <transition name="vcs__fade">
      <SelectMenu
        ref="childMenu"
        v-if="nextMenu.isOpen"
        :notMain="true"
        :onSelect="onSelect"
        :options="nextMenu.options"
        :withKeyboard="nextMenu.withKeyboard"
      >
        <slot v-for="(_, name) in $slots" :name="name"/>
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
      </SelectMenu>
    </transition>
  </div>
</template>

<script>
import Option from './Option.vue';
import { validateOptions } from './validators';

export default {
  name: 'SelectMenu',
  components: { Option },
  props: {
    notMain: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      validator: value => validateOptions(value),
    },
    withKeyboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { options, withKeyboard } = this.$props;

    return {
      nextMenu: {
        isOpen: false,
        options: false,
        value: '',
        withKeyboard: false,
      },
      selectedOption: withKeyboard ? 0 : null,
      optionsLength: options.length,
    };
  },
  mounted() {
    if (this.$props.withKeyboard) {
      this.$refs.options[0].$el.focus();
    }
  },
  methods: {
    handleOpenNextMenu(value, options, index, withKeyboard = false) {
      this.selectedOption = index;
      this.$refs.options[index].$el.focus();

      if (options && this.nextMenu.value !== value) {
        this.nextMenu = {
          isOpen: true,
          options,
          value,
          withKeyboard,
        };
      }

      if (options && this.$refs.childMenu) {
        this.$refs.childMenu.resetNextMenu();
      }

      if (!options) {
        this.resetNextMenu();
      }
    },
    handleCloseMenu() {
      if (this.$parent && this.$parent.resetNextMenu) {
        this.$parent.resetNextMenu();
      }
    },
    resetNextMenu() {
      this.nextMenu = {
        isOpen: false,
        options: [],
        value: '',
      };

      this.$nextTick(() => {
        if (this.selectedOption >= 0 && this.$refs.options[this.selectedOption]) {
          this.$refs.options[this.selectedOption].$el.focus();
        }
      });
    },
    moveOption(increment = false) {
      const { selectedOption, optionsLength } = this;

      let newSelectedOption = increment ? selectedOption + 1 : selectedOption - 1;
      newSelectedOption = newSelectedOption >= 0 ? newSelectedOption : optionsLength - 1;
      newSelectedOption %= optionsLength;

      this.selectedOption = newSelectedOption;
      this.$refs.options[newSelectedOption].$el.focus();
    },
    onMouseWheel(ev) {
        // Fix: no scroll on team
        ev.preventDefault();
        if (this.$refs.menu) this.$refs.menu.scrollTop += ev.deltaY; // > 0 ? 50 : -50;
    }
  },
  watch: {
    options() {
      this.resetNextMenu();

      if (!this.$props.withKeyboard) {
        this.selectedOption = null;
      }
    },
  },
};
</script>

<style lang="scss">

.vcs__select-menu {
  border-radius: 4px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  padding: 5px 0;
  position: relative;
  text-align: left;
  background: white;

  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  margin-top: 0;
  z-index: 1000;

  .vcs__select-menu__not-main {
    position: absolute;
    left: calc(100% - 1px);
    top: -1px;
    margin-top: 0;
    width: auto;
    min-height: 100%;
  }
}

.-inline .vcs__select-menu {
    min-width: 220px;
    position: relative;

    &.vcs__select-menu__not-main {
        min-width: auto;
        position: absolute;
    }
}


</style>