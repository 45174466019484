
import Vue from "vue";
import EventsBus from '@/libs/EventsBus';
// import dayjs from 'dayjs';

const state = {
    fetched: {},
    configs: {},
    new: {},
    userConfigs: {},
    userNew: {}
};

const getters = {
    getAll: (state, getters, store, rootGetters) => {
        return state.configs[rootGetters.currentClientId];
    },
    get: (state, getters) => id => getters.getAll?.[id],

    getForUserAll: (state, getters, store, rootGetters) => {
        return state.userConfigs[rootGetters.currentClientId];
    },
    getForUser: (state, getters) => id => getters.getForUserAll?.[id]
};

const actions = {
    async ensureConfigs({ dispatch, state }) {
        const clientId = this.getters.currentClientId;
        if (!state.configs[clientId]) {
            return await dispatch('fetchConfigs');
        }
        return state.configs[clientId];
    },
    async fetchConfigs({ commit }) {
        try {
            const clientId = this.getters.currentClientId;
            const data = await this.getters.api.get('/configs');
            const config = fromAPI(data);
            commit("setConfigs", { clientId, data: config });
            if (data.new) {
                commit("setNew", { clientId });
            }
            // Public datelocale for initialize it
            if (config.datelocale) EventsBus.emit('setDatelocale', { datelocale: config.datelocale });
            return data;
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async add({ commit }, { config }) {
        try {
            const clientId = this.getters.currentClientId;
            const data = await this.getters.api.post(`/configs`, { data: toAPI(config) });
            commit("setConfigs", { clientId, data: fromAPI(data) });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async update({ dispatch, commit, state }, { config }) {
        try {
            const clientId = this.getters.currentClientId;
            if (state.new[clientId]) {
                await dispatch('add', { config });
                commit("resetNew", { clientId });
            }
            else {
                await this.getters.api.patch(`/configs`, { data: toAPI(config) });
            }
            commit('setConfigs', { clientId, data: config });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async remove({ commit }, { configId }) {
        try {
            const clientId = this.getters.currentClientId;
            await this.getters.api.delete(`/configs/${configId}`);
            commit("removeConfig", { clientId, configId });
        } catch (error) {
            throw "A server error has occurred";
        }
    },

    async ensureUserConfigs({ dispatch, state }) {
        const clientId = this.getters.currentClientId;
        if (!state.userConfigs[clientId]) {
            return await dispatch('fetchUserConfigs');
        }
        return state.userConfigs[clientId];
    },
    async fetchUserConfigs({ commit }) {
        try {
            const clientId = this.getters.currentClientId;
            const data = await this.getters.api.get('/user-configs');
            const config = fromAPI(data);
            commit("setUserConfigs", { clientId, data: config });
            if (data.new) {
                commit("setUserNew", { clientId });
            }
            return data;
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async addUserConfig({ commit }, { config }) {
        try {
            const clientId = this.getters.currentClientId;
            const data = await this.getters.api.post(`/user-configs`, { data: toAPI(config) });
            commit("setUserConfigs", { clientId, data: fromAPI(data) });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async updateUserConfig({ dispatch, commit, state }, { config }) {
        try {
            const clientId = this.getters.currentClientId;
            if (state.userNew[clientId]) {
                await dispatch('addUserConfig', { config });
                commit("resetUserNew", { clientId });
            }
            else {
                await this.getters.api.patch(`/user-configs`, { data: toAPI(config) });
            }
            commit('setUserConfigs', { clientId, data: config });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async removeUserConfig({ commit }, { configId }) {
        try {
            const clientId = this.getters.currentClientId;
            await this.getters.api.delete(`/user-configs/${configId}`);
            commit("removeUserConfig", { clientId, configId });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async updateUserSetting({ commit }, { key, value} ) {
        try {
            const config = JSON.parse(JSON.stringify({ ...this.getters['configs/getForUserAll'] || {} }));
            config[key] = value;
            await this.dispatch("configs/updateUserConfig", {
                config
            });
          } catch (error) {
            throw 'A server error has occurred';
          }
    },
};

const mutations = {

    fetched(state, { clientId, value }) {
        state.fetched[clientId] = value;
    },
    setConfigs(state, { clientId, data }) {
      Vue.set(state.configs, clientId, data);
    },
    setNew(state, { clientId }) {
        Vue.set(state.new, clientId, true);
      },
    resetNew(state, { clientId }) {
        Vue.set(state.new, clientId, false);
    },
    removeConfig(state, { clientId, configId }) {
      const index = state.configs[clientId].findIndex(c => c.id === configId);
      if (index > -1) {
          state.configs[clientId].splice(index, 1);
      }
    },
    setUserConfigs(state, { clientId, data }) {
        Vue.set(state.userConfigs, clientId, data);
    },
    setUserNew(state, { clientId }) {
        Vue.set(state.userNew, clientId, true);
      },
    resetUserNew(state, { clientId }) {
      Vue.set(state.userNew, clientId, false);
    },
    removeUserConfig(state, { clientId, configId }) {
      const index = state.userConfigs[clientId].findIndex(c => c.id === configId);
      if (index > -1) {
          state.userConfigs[clientId].splice(index, 1);
      }
    },
    reset(state) {
      for (const key of Object.keys(state.fetched)) {
        state.fetched[key] = false;
      }
    }
};

function fromAPI(json) {
    return json.parameters;
}

function toAPI(config) {
    const json = {...config};
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
