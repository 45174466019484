<template>
  <div>
<!-- Add Customer modal-->
        <b-modal id="HomepageModal"
            :title="$t('global.modal.homepage.title')"
            size="lg"
            :ok-title="$t('global.modal.homepage.confirm')"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            hide-footer
        >
            <p class="my-4">{{ $t('global.modal.homepage.desscription') }}</p>
            <div class="d-flex justify-content-center mb-3">
                <div class="btn btn-outline-primary mr-3" :class="{ selected: page.name === homepage }" @click="selectHomepage(page.name)" v-for="page in homepages" :key="page.name">
                    <div class="heading">{{ page.label }}</div>
                    <div class="subheading">{{ page.resume }}</div>
                    <img width="150" src="@/assets/images/icons/from-blank.svg" alt="" />
                </div>
            </div>
        </b-modal>
  </div>
</template>

<script>
  import i18n from "@/i18n";
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import { required, minLength } from 'vuelidate/lib/validators';
  import { generateUID } from '@/libs/utils/String';
  import {library} from '@fortawesome/fontawesome-svg-core';
  import {
    faTrashAlt,
    faCheck,
    faEnvelopeOpen,
    faPhone
  } from '@fortawesome/free-solid-svg-icons'
  import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

  library.add(
    faTrashAlt,
    faCheck,
    faEnvelopeOpen,
    faPhone
  );

  export default {
    components: {
      VuePerfectScrollbar,
      'font-awesome-icon': FontAwesomeIcon
    },
    props: {
    },
    data: () => ({
      activeStep: 1,
      homepage: 'myactions',
      homepages: [
        {
            name: 'myactions',
            label: i18n.t('global.myactions.title'),
            resume: i18n.t('global.myactions.resume')
        },
        {
            name: 'customers',
            label: i18n.t('global.customers.title'),
            resume: i18n.t('global.customers.resume')
        }
      ]
    }),
    validations: {
    },
    computed: {
    },
    methods: {
        checkFormValidity() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        async init() {
            await this.$store.dispatch('configs/ensureUserConfigs');
            const config = this.$store.getters['configs/getForUserAll'];
            this.homepage = config.homepage || 'myactions';
        },
        async resetModal() {
            // this.nameState = null;
            this.$v.$reset();
            await this.init();
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt?.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            await this.saveHomepage();
            this.$emit('ok', { homepage: this.homepage });
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('HomepageModal');
                this.resetModal();
            // this.$bvModal.hide('modal-prevent-closing')
            });
        },
        async selectHomepage(name) {
            this.homepage = name;
            const config = { ...this.$store.getters['configs/getForUserAll'] };
            config.homepage = this.homepage;
            await this.$store.dispatch("configs/updateUserConfig", {
                config
            });
            this.$bvToast.toast(this.$t('settings.global.ope.updated'), {
                title: this.$t('commons.ope.success'),
                autoHideDelay: 5000,
                appendToast: false
            });
            this.$nextTick(() => {
                this.$bvModal.hide('HomepageModal');
            });
        },
    }


  }
</script>
<style lang="scss" scoped>
$deep-blue: #202660af;
$deep-gray: #4f4f4f;
h6 {
  color: $deep-gray;
  font-weight: 500;
}
.description {
    min-height: 150px;
}
.sub-group {
  border-left: 1px solid $deep-blue;
  border-right: 1px solid $deep-blue;
  border-bottom: 1px solid $deep-blue;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px 5px 5px;
}
.list-group.-compact .list-group-item {
  padding-left: 0;
  padding-right: 0;
}
.heading {
    font-size: 20px;
}
.subheading {
    font-size: 14px;
}
</style>
<style lang="scss">
.template-desc {
    ul {
        padding-left: 1rem;
    }
}
</style>