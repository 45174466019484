var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onCancel,
          expression: "onCancel"
        }
      ],
      staticClass: "var-infos-wrapper",
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
        }
      }
    },
    [
      _vm.options.length
        ? [
            !_vm.selected
              ? _c("vue-cascader-select", {
                  attrs: {
                    inline: true,
                    options: _vm.options,
                    value: _vm.variable
                  },
                  on: {
                    clear: function(val) {
                      return (_vm.variable = "")
                    },
                    select: _vm.selectItem
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "node",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("div", { staticClass: "menu-item-var" }, [
                              _c("div", [_vm._v(_vm._s(item.label))]),
                              item.description
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "menu-item-var-description mt-2"
                                    },
                                    [_vm._v(_vm._s(item.description))]
                                  )
                                : _vm._e()
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3739325317
                  )
                })
              : _c(
                  "div",
                  { staticClass: "panel-fallback" },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.$t(
                                "commons.editor.variable.selected.label"
                              ),
                              "label-for": "input-var-fallback"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              {
                                attrs: { size: "sm" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "outline-primary"
                                              },
                                              on: { click: _vm.reset }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "arrow-left" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2946189669
                                )
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "input-var-fallback",
                                    type: "text",
                                    disabled: true,
                                    debounce: "500",
                                    "aria-describedby": "input-var-fallback"
                                  },
                                  model: {
                                    value: _vm.selected.label,
                                    callback: function($$v) {
                                      _vm.$set(_vm.selected, "label", $$v)
                                    },
                                    expression: "selected.label"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.$t(
                                "commons.editor.variable.fallback.label"
                              ),
                              "label-for": "input-var-fallback"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "input-var-fallback",
                                type: "text",
                                size: "sm",
                                placeholder: _vm.$t(
                                  "commons.editor.variable.fallback.placeholder"
                                ),
                                debounce: "500",
                                "aria-describedby": "input-var-fallback"
                              },
                              model: {
                                value: _vm.inputFallback,
                                callback: function($$v) {
                                  _vm.inputFallback = $$v
                                },
                                expression: "inputFallback"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: { variant: "primary", size: "sm" },
                            on: { click: _vm.onSave }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("commons.action.ok")) +
                                "\n                "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-danger", size: "sm" },
                            on: { click: _vm.onCancel }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("commons.action.cancel")) +
                                "\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ]
        : _c("div", { staticClass: "item" }, [_vm._v("\n    No result\n    ")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }