import Vue from 'vue';
import store from '../store';

const QUERY_ENV = 'env';

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const EnvPlugin = {
    install() {
        const searchParams = new URLSearchParams(location.search);

        if (searchParams.has(QUERY_ENV)) {
            store.dispatch('updateEnv', { env: searchParams.get(QUERY_ENV) });
        }
    }
};

Vue.use(EnvPlugin);