var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-sidebar-right help-bar" },
    [
      _c(
        "b-sidebar",
        {
          ref: "helpbar",
          attrs: {
            id: "helpbar",
            title: _vm.title || _vm.$t("global.help.title"),
            right: "",
            shadow: "",
            "bg-variant": "white",
            width: _vm.panelWidth,
            visible: _vm.visible
          },
          on: { hidden: _vm.reset }
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [
              _vm.openKey
                ? _c(
                    "div",
                    { staticClass: "text-muted text-left" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { size: "sm" },
                          on: { change: _vm.onToggleShowNextTime },
                          model: {
                            value: _vm.showNext,
                            callback: function($$v) {
                              _vm.showNext = $$v
                            },
                            expression: "showNext"
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("global.help.auto-show-next")) +
                              "\n                "
                          )
                        ]
                      ),
                      _c("hr")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("default")
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }