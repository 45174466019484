import Vue from 'vue';
import store from '../store';


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useCurrency = () => {

    return function () {
        return store.getters['clients/currency'];
    }
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const CurrencyPlugin = {
    install(Vue, options) {
      Vue.prototype.$currency = useCurrency(options);
    }
};

Vue.use(CurrencyPlugin);
