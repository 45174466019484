import lodash from 'lodash';

/* eslint-disable guard-for-in */
export const objectToSortArray = function (obj) {
  const arr = [];
  let prop;
  for (prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      arr.push({
        key: prop,
        value: obj[prop]
      });
    }
  }
  return arr.sort(function (a, b) {
    return a.key < b.key ? -1 : 1;
  });
};

export const objectToSplitLabel = function (obj, separator) {

  return Object.keys(obj).reduce((acc, label) => {
    const name = label.split(separator);

    if (name.length > 1) {
      if (!acc[name[0]]) {
        acc[name[0]] = {};
      }
      acc[name[0]][name[1]] = obj[label];
    } else {
      acc[label] = obj[label];
    }
    return acc;

  }, {});
};

/**
 * Compare in deep two objects
 * @param a first object to compare
 * @param b second object to compare
 * @returns {boolean} return true if a is deep equal to b otherwise return false
 */
export const shallowObject = function (a, b) {
  let ka = 0;
  let kb = 0;
  if (a) {
    if (!b) return false;
    for (const key in a) {
      if (a.hasOwnProperty(key) && a[key] !== b[key]) {
        return false;
      }
      ka += 1;
    }
  }
  if (b) {
    if (!a) return false;
    for (const key in b) {
      if (b.hasOwnProperty(key)) {
        kb += 1;
      }
    }
  }
  return ka === kb;
};

/**
 * Copy object
 * @param a object to copy
 * @returns {Object} return object copied
 */
export const deepCopy = function (obj) {
  if (!obj) return obj;
  if (typeof obj !== 'object' && !Array.isArray(obj)) return obj;
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Check global variable until it exists
 * @param {*} variable Variable name to watch existance
 * @param {*} expire
 */
export const wait = function (variable, expire) {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      let value, lastError;
      try {
        value = eval(variable);
      }
      catch (ex) {
        lastError = ex;
      }
      if (typeof value === 'undefined') {
        const startTime = new Date().getTime();
        const timer = setInterval(function () {
          try {
            value = eval(variable);
          }
          catch (ex) {
            lastError = ex;
          }
          if (typeof value !== 'undefined') {
            clearInterval(timer);
            resolve(value);
          }
          if (expire && (new Date().getTime() - startTime) > expire) {
            clearInterval(timer);
            reject(lastError);
          }
        }, 250);
        return;
      }
      resolve(value);
    }, 0);
  });
};

export const isEqual = function (obj1, obj2) {
    return lodash.isEqual(obj1, obj2);
  };