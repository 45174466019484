var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
        }
      }
    },
    [
      _c("variable-infos", {
        attrs: { options: _vm.items, value: _vm.value, fallback: _vm.fallback },
        on: { select: _vm.selectItem, cancel: _vm.exit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }