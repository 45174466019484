import { Node, mergeAttributes } from '@tiptap/core';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { PluginKey } from 'prosemirror-state';
import { VariablePlugin } from './VariablePlugin';
import suggestion from './suggestion';

export const VariablePluginKey = new PluginKey('variable');

export const Variable = Node.create({
  name: 'variable',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'variable'
      },
      renderLabel({ options, node }) {
        return `${node.attrs.label || node.attrs.value}`;
      },
      suggestion: {
          ...suggestion,
        char: '=',
        pluginKey: VariablePluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter?.text?.startsWith(' ')

          if (overrideSpace) {
            range.to += 1
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run()
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from)
          const type = state.schema.nodes[this.name]
          const allow = !!$from.parent.type.contentMatch.matchType(type)

          return allow
        },
      },
    }
  },

  group: 'inline',

  inline: true,

  selectable: true,

  atom: true,

  addAttributes() {
    return {
      value: {
        default: null,
        parseHTML: element => element.getAttribute('data-value'),
        renderHTML: attributes => {
          if (!attributes.value) {
            return {}
          }

          return {
            'data-value': attributes.value,
          }
        },
      },

      label: {
        default: null,
        parseHTML: element => element.getAttribute('data-label'),
        renderHTML: attributes => {
          if (!attributes.label) {
            return {}
          }

          return {
            'data-label': attributes.label,
          }
        },
      },

      fallback: {
        default: null,
        parseHTML: element => element.getAttribute('data-fallback'),
        renderHTML: attributes => {
          if (!attributes.fallback) {
            return {}
          }

          return {
            'data-fallback': attributes.fallback,
          }
        },
      },
    }
  },

  addCommands() {
    return {
      variableCommand: attributes => ({ editor, state, chain, tr }) => {
        // Doesn’t work:
        // return editor.chain() …
        // Does work:
        return chain()
        .focus()
        .insertContent([
          {
            type: this.name,
            attrs: attributes,
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run();
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    //   [
    //       'i',
    //       { class: "tag-close" },
    //       'x'
    //   ]
    ]
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isMention = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty) {
          return false
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)

            return false
          }
        })

        return isMention
      }),
    }
  },

  addProseMirrorPlugins() {
    return [
        VariablePlugin({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})