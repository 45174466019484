
import Vue from 'vue';
import dayjs from 'dayjs';
import i18n from '@/i18n';

export const TASK_STATUS = [
    {   
        id: 'TODO',
        name: i18n.t('tasks.status.TODO'),
        color:'#6CC3D5',
        rank: 0
    },
    {   
        id: 'INPROGRESS',
        name: i18n.t('tasks.status.INPROGRESS'),
        color:'#6f42c1',
        rank: 1
    },
    {   
        id: 'VALIDATION',
        name: i18n.t('tasks.status.VALIDATION'),
        color:'#ffc107',
        rank: 2
    },
    {   
        id: 'BLOCKED',
        name: i18n.t('tasks.status.BLOCKED'),
        color:'#b31414',
        rank: 3
    },
    {   
        id: 'COMPLETED',
        name: i18n.t('tasks.status.COMPLETED'),
        color:'#008f05',
        rank: 4
    },
];

export const TASK_PRIORITIES = [
    {
        id: 'null',
        name: "None"
    },
    {
        id: 1,
        name: "Low"
    },
    {
        id: 5,
        name: "High"
    },
    {
        id: 10,
        name: "Critical"
    },
]

export const originalStatus = ['TODO', 'INPROGRESS', 'VALIDATION', 'BLOCKED'];

export const TASK_TYPE = {
    TASK: 'TASK',
    EMAIL: 'EMAIL',
    CALL: 'CALL',
    MEET: 'MEET',
    TICKET: 'TICKET'
};

const state = {
  fetched: {},
  fetchedProjects: {},
  fetchedTemplates: false,
  tasks: [],
  tasksProjects: [],
  tasksTemplates: [],
  taskComments: {},
  stats: {}
};

const getters = {
  getTasksProjectById: (state) => (id) => {
    return state.tasksProjects.find(task => task.id === id)
  },
  getAll: (state, getters, store, rootGetters) => {
    return state.tasksProjects;
  },
  getAllTasks: (state, getters, store, rootGetters) => {
    return state.tasks;
  },
  getAllTasksByStatus: (state, getters, store, rootGetters) => (status) => {
    return state.tasks.filter(t => {
        return t.status === status;
    });
  },
  getAllForCustomerId: (state, getters, store, rootGetters) => customerId => {
    return state.tasks.filter(t => t.customerId === customerId);
},
  getFromId: (state) => (id )=> state.tasks.find(a => a.id === id),
  getProjectFromId: (state) => (id )=> state.tasksProjects.find(a => a.id === id),
  getAllProjects: (state, getters, store, rootGetters) => {
    return state.tasksProjects;
  },
  getTemplatesFromId: (state) => (id )=> state.tasksTemplates.find(a => a.id === id),
  getAllTemplates: (state, getters, store, rootGetters) => {
    return state.tasksTemplates;
  },
  getCommentsFromTaskId: (state) => (taskId)=> state.taskComments[taskId] || [],
  getStats: (state) => customerId => {
    return state.stats[customerId || 'global'];
  },
  getStatus: () => {
    return Object.keys(TASK_STATUS);
  },
  getTypes: () => {
    return Object.keys(TASK_TYPE);
  },
  getIcon: () => (type) => {
    switch (type) {
        case 'EMAIL': return 'envelope-open';
        case 'CALL': return 'phone';
        case 'MEET': return 'users';
        case 'TICKET': return 'ticket-alt';
        default: return 'clipboard-check';
    }
  },
  getStatusVariant: () => (status) => {
    switch (status) {
        case 'TODO': return 'warning';
        case 'COMPLETED': return 'success';
        case 'BLOCKED': return 'danger';
        case 'INPROGRESS': return 'primary';
        default: return '';
    }
  },
  getStatusDefault: () => TASK_STATUS,
  getPrioritiesDefault: () => TASK_PRIORITIES
};

const actions = {

  async ensureTasks({ dispatch, state }, { customerId }) {
    if (!state.fetched[customerId]) {
      await dispatch('fetchTasks', { customerId });
    }
  },
  async ensureTaskId({ dispatch, state }, { taskId }) {
    if (!state.tasks.find(a => a.id === taskId)) {
        await dispatch('fetchTaskById', { taskId });
    }
  },
  async ensureTasksStats({ dispatch, state }, { customerId } = {}) {
    if (!state.stats[customerId || 'global']) {
      await dispatch('fetchTasksStats', { customerId });
    }
  },
  async ensureTasksProjects({ dispatch, state }, { customerId } = {}) {
    if (!state.fetchedProjects[customerId || 'global']) {
      await dispatch('fetchTasksProjects', { customerId });
    }
  },
  async ensureTasksProjectId({ dispatch, state }, { projectId }) {
      const project = state.tasksProjects.find(p => p.id === projectId);
    if (!project) {
      await dispatch('fetchTasksProjectById', { projectId });
    }
  },
  async ensureTasksTemplates({ dispatch, state }) {
    if (!state.fetchedTemplates) {
      await dispatch('fetchTasksTemplates');
    }
  },
  async ensureTasksTemplateId({ dispatch, state }, { projectId }) {
    const project = state.tasksTemplates.find(p => p.id === projectId);
    if (!project) {
      await dispatch('fetchTasksTemplateById', { projectId });
    }
  },
  async fetchTasksProjects({ commit }, { customerId, ctx, ref, skipStore }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      if (ref) {
        query.params = { ref };
      }
      if (ctx?.display) {
        query.params = query.params || {};
        query.params.display = ctx.display;
      }
      const { metadata, data } = await this.getters.api.get(customerId ? `/customers/${customerId}/tasks-projects` : `/tasks-projects`, query);
      const tasksProjects = data.map(fromAPIProject);

      if (!skipStore) {
        commit('fetchedProjects', { customerId, value: true });
        commit('setTasksProjects', { data: tasksProjects });   
      }
      
      return {
        total: metadata.pagination.total || 0,
        page: metadata.pagination.page || 1,
        data: tasksProjects
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTasksProjectById({ commit }, { projectId }) {
    try {
      const data = await this.getters.api.get(`/tasks-projects/${projectId}`);
      const tasksProject = fromAPIProject(data);

      commit('setTasksProject', { data: tasksProject });
      
      return tasksProject;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTasksProjectsNames({ commit }, { customerId, ctx }) {
    try {
      const query = {
        params: {
            display: 'name'
        }
      };
      if (ctx?.page) {
        query.pagination = ctx;
      }
      const { metadata, data } = await this.getters.api.get(customerId ? `/customers/${customerId}/tasks-projects` : `/tasks-projects`, query);

      
      return {
        total: metadata.pagination.total || 0,
        page: metadata.pagination.page || 1,
        data: data
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTasksTemplates({ commit }, { ctx, ref } = {}) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      if (ref) {
        query.params = { ref };
      }
      const data = await this.getters.api.get(`/tasks-templates`, query);
      const tasksTemplates = (data || []).map(fromAPITemplate).sort((a, b) => {
        return ('' + a.name).localeCompare(b.name);
      });

      commit('fetchedTemplates', { value: true });
      commit('setTasksTemplates', { data: tasksTemplates });
      
      return {
        data: tasksTemplates
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTasksTemplateById({ commit }, { templateId }) {
    try {
      const data = await this.getters.api.get(`/tasks-templates/${templateId}`);
      const tasksTemplate = fromAPITemplate(data);

      commit('setTasksTemplate', { data: tasksTemplate });
      
      return tasksTemplate;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async duplicateTasksTemplate({ dispatch }, { template }) {
    try {
        const duplicatedTemplate = JSON.parse(JSON.stringify(template));
        duplicatedTemplate.name = `${duplicatedTemplate.name} (Copy)`;
        delete duplicatedTemplate.id;
        const model = dispatch('addTemplate', {
            tasksTemplate: duplicatedTemplate
        });
        return model;
     } catch (error) {
       throw 'A server error has occurred';
     }
    },
  async fetchTasks({ commit }, { customerId, ctx, ref }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      if (ref) {
        query.params = { ref };
      }
      const { metadata, data } = await this.getters.api.get(customerId ? `/customers/${customerId}/tasks` : `/tasks`, query);
      const tasks = data.map(fromAPI);
      commit('fetched', { customerId, value: true });
      commit('setTasks', { data: tasks });
    //   const projects = [];
    //   for( const group of tasks.filter(t => !!t.group)) {
    //       if (!projects.find(g => g.id === group.id)) projects.push(group);
    //   }
    //   if (projects.length) {
    //     commit('setTasksProjects', { data: projects });
    //   }
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: tasks
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTasksByStatus({ commit }, { customerId, ctx, status }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
        query.pagination.filters.status = {
            type: "equals",
            value: status
        }
      }
      const { metadata, data } = await this.getters.api.get(customerId ? `/customers/${customerId}/tasks` : `/tasks`, query);
      const tasks = data.map(fromAPI);
      commit('setTasksByStatus', { status, data: tasks });
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: tasks
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTaskById({ commit }, { taskId }) {
    try {
      const task = fromAPI(await this.getters.api.get(`/tasks/${taskId}`));
      commit('setTask', { data: task });
      return task;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTaskComments({ commit }, { taskId, ctx } = {}) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      const data = await this.getters.api.get(`/tasks/${taskId}/comments`, query);
      const taskComments = data.map(fromAPIComment);

      commit('setTasksComments', { taskId, data: taskComments });
      
      return {
        data: taskComments
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTasksStats({ dispatch, commit }, { customerId } = {}) {
    try {
      const data = await this.getters.api.get(customerId ? `/customers/${customerId}/tasks/stats` : `/tasks/stats`);
      commit('setTasksStats', { customerId, data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { task }) {
    try {
        const data = fromAPI(await this.getters.api.post(`/tasks`, { data: toAPI(task) }));
        data.nbComments = 0;
        commit('setTask', { data });
        commit('setProjetTask', { projectId: task.projectId || task.project?.id, data });
        // Update interaction linked -> Add taskId to tasks
        if (task.customerId && task.interactionId) {
            const interaction = this.getters['interactions/getFromId'](task.customerId, task.interactionId) || this.getters['interactions/getById'](task.interactionId);
            if (interaction) {
                this.commit('interactions/setInteraction', { customerId: task.customerId, data: { id: interaction.id, tasks: [...new Set([...(interaction.tasks || []), data.id])] } });
            }
        }
        return data;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ dispatch, commit, getters }, { taskId, task}) {
    try {
        const oldTask = getters.getFromId(taskId);
        const needUpdateUser = task.customerId && task.userId === 'OWNER';
        const data = await this.getters.api.patch(`/tasks/${taskId}`, { data: toAPI(task) });
        // TODO remove
        task.id = taskId;
        if (needUpdateUser) {
            const customer = this.getters['customers/getFromId'](task.customerId);
            task.userId = customer?.team?.find(t => t.role === 2)?.userId;
        }
        if(task.project?.id && (task.project.id === oldTask.project.id)) {
            task.project = oldTask.project;
        } else {
            await dispatch('ensureTasksProjectId', { projectId: task.projectId });
            task.project = getters.getProjectFromId(task.projectId);
            delete task.projectId;
        }
        commit('setTask', { data: task });
        commit('setProjetTask', { projectId: task.projectId || task.project?.id, data: task });
        // Remove unused linked interaction
        if (oldTask && oldTask.interactionId && oldTask.interactionId !== task.interactionId) {
            const interaction = this.getters['interactions/getFromId'](task.customerId, oldTask.interactionId) || this.getters['interactions/getById'](oldTask.interactionId);
            if (interaction) {
                this.commit('interactions/setInteraction', { customerId: task.customerId, data: { id: interaction.id, tasks: (interaction.tasks || []).filter(t => t !== taskId)} });
            }
        }
        // Update interaction linked -> Add taskId to tasks
        if (task.customerId && task.interactionId) {
            const interaction = this.getters['interactions/getFromId'](task.customerId, task.interactionId) || this.getters['interactions/getById'](task.interactionId);
            if (interaction) {
                this.commit('interactions/setInteraction', { customerId: task.customerId, data: { id: interaction.id, tasks: [...new Set([...(interaction.tasks || []), taskId])] } });
            }
        }
        return task;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { projectId, taskId }) {
    try {
        const oldTask = getters.getFromId(taskId);
        await this.getters.api.delete(`/tasks/${taskId}`);
        commit('removeTask', { taskId });
        commit('removeProjectTask', { projectId: projectId, taskId });
        // Remove unused linked interaction
        if (oldTask && oldTask.interactionId) {
            const interaction = this.getters['interactions/getFromId'](oldTask.customerId, oldTask.interactionId) || this.getters['interactions/getById'](oldTask.interactionId);
            if (interaction) {
                this.commit('interactions/setInteraction', { customerId: oldTask.customerId, data: { id: interaction.id, tasks: (interaction.tasks || []).filter(t => t !== taskId)} });
            }
        }
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async addProject({ commit }, { tasksProject }) {
    try {
        const data = await this.getters.api.post(`/tasks-projects`, { data: toAPIProject(tasksProject) });
        const project = fromAPIProject(data);
        commit('setTasksProject', { data: project });
        return project;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateProject({ commit }, { projectId, tasksProject }) {
    try {
        const data = await this.getters.api.patch(`/tasks-projects/${projectId}`, { data: toAPIProject(tasksProject) });
        // TODO remove
        tasksProject.id = projectId;
        commit('setTasksProject', { data: tasksProject });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateProjectTags({ commit }, { projectId, project, tags }) {
    try {
        await this.getters.api.put(`/tasks-projects/${projectId}/tags`, { data: tags });
        commit('setTasksProject', { data: { ...project, id: projectId, tags, updatedAt: new Date() } });
      } catch (error) {
        throw 'A server error has occurred';
      }
  }, 
  async removeProject({ commit }, { projectId }) {
    try {
        await this.getters.api.delete(`/tasks-projects/${projectId}`);
        commit('removeTasksProject', { projectId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async addTemplate({ commit }, { tasksTemplate }) {
    try {
        const data = await this.getters.api.post(`/tasks-templates`, { data: toAPITemplate(tasksTemplate) });
        const template = fromAPITemplate(data);
        commit('setTasksTemplate', { data: template });
        return template;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateTemplate({ commit }, { templateId, tasksTemplate }) {
    try {
        const data = await this.getters.api.patch(`/tasks-templates/${templateId}`, { data: toAPITemplate(tasksTemplate) });
        // TODO remove
        tasksTemplate.id = templateId;
        commit('setTasksTemplate', { data: tasksTemplate });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeTemplate({ commit }, { templateId }) {
    try {
        await this.getters.api.delete(`/tasks-templates/${templateId}`);
        commit('removeTasksTemplate', { templateId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async addComment({ commit }, { taskId, taskComment }) {
    try {
        const data = await this.getters.api.post(`/tasks/${taskId}/comments`, { data: toAPIComment(taskComment) });
        const comment = fromAPIComment(data);
        commit('setTaskComment', { taskId, data: comment });
        commit('updateTaskCommentCount', { taskId, interval: 1 });
        return comment;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateComment({ commit }, { taskId, commentId, taskComment }) {
    try {
        const data = await this.getters.api.patch(`/tasks/${taskId}/comments/${commentId}`, { data: toAPIComment(taskComment) });
        // TODO remove
        taskComment.id = commentId;
        commit('setTaskComment', { taskId, data: taskComment });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeComment({ commit }, { taskId, commentId }) {
    try {
        await this.getters.api.delete(`/tasks/${taskId}/comments/${commentId}`);
        commit('removeTaskComment', { taskId, commentId });
        commit('updateTaskCommentCount', { taskId, interval: -1 });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async templateFromProject({ commit }, { tasksProject }) {
    try {
        const template = {
            name: tasksProject.name,
            tasks: tasksProject.tasks?.map(t =>  {
                let isOwner = false;
                if (t.customerId && t.userId) {
                    const targetedCustomer = this.getters['customers/getFromId'](t.customerId);
                    if (targetedCustomer.team.find(u => u.userId === t.userId)) isOwner = true;
                }
                return {
                    type: t.type,
                    title: t.title,
                    description: t.description,
                    priority: t.priority,
                    reminder: t.reminder,
                    recurring: t.recurring,
                    recurringPeriod: t.recurringPeriod,
                    dueTime: dayjs(t.dueDate).format('HH:mm'),
                    dueDateOffset: dayjs(t.dueDate).diff(t.createdAt, 'day'),
                    userId: isOwner ? 'OWNER' : t.userId
                };
                // userId: { type: ['string', 'null'] },
                // contactId: { type: ['string', 'null'] },
            })
        };
        return template;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, {  customerId, value }) {
    state.fetched[customerId] = value;
  },
  fetchedProjects(state, {  customerId, value }) {
    state.fetchedProjects[customerId] = value;
  },
  fetchedTemplates(state, {  value }) {
    state.fetchedTemplates = value;
  },
  setTask(state, { data }) {
    const index = state.tasks.findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.tasks, index, { ...data});
    }
    else {
      state.tasks.push(data);
    }
    // Vue.set(state.tasks[customerId], state.tasks[clientId][customerId].length, data);
  },
  removeTask(state, { taskId }) {
    const index = state.tasks.findIndex(c => c.id === taskId);
    if (index > -1) {
        state.tasks.splice(index, 1);
    }
  },
  removeProjectTask(state, { projectId, taskId }) {
    const project = state.tasksProjects.find(c => c.id === projectId);
    if (project) {
        const index = project.tasks.findIndex(c => c.id === taskId);
        if (index > -1) {
            project.tasks.splice(index, 1);
        }
        project.tasksStatus = initTaskProjectStatus(project);
    }
  },
  setTasks(state, { data }) {
    Vue.set(state, 'tasks', data);
  },
  setTasksByStatus(state, { status, data }) {
    state.tasks = state.tasks.filter(t => t.status !== status);
    state.tasks.push(...data);
  },
  updateTaskCommentCount(state, { taskId, interval }) {
    const task = state.tasks.find(c => c.id === taskId);
    if (task) {
        task.nbComments += interval;
    }
  },
  setProjetTask(state, { projectId, data }) {
    const project = state.tasksProjects.find(c => c.id === projectId);
    if (project) {
        const index = project.tasks.findIndex(c => c.id === data.id);
        if (index > -1) {
          Vue.set(project.tasks, index, { ...data});
        }
        else {
            project.tasks.push(data);
        }
        project.tasksStatus = initTaskProjectStatus(project);
    }
  },
  setTasksStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId || 'global', data);
  },
  setTasksProject(state, { data }) {
    const index = state.tasksProjects.findIndex(c => c.id === data.id);
    if (index > -1) {// Update project task customerId
        if (data.customerId) {
            for (const task of state.tasksProjects[index].tasks){
                task.customerId = data.customerId;
            }
        }
        Vue.set(state.tasksProjects, index, { ...data, tasks: state.tasksProjects[index].tasks});
    }
    else {
      state.tasksProjects.unshift(data);
    }
    // Vue.set(state.tasks[customerId], state.tasks[clientId][customerId].length, data);
  },
  removeTasksProject(state, { projectId }) {
    const index = state.tasksProjects.findIndex(c => c.id === projectId);
    if (index > -1) {
        state.tasksProjects.splice(index, 1);
    }
  },
  setTasksProjects(state, { data }) {
    state.tasksProjects = data;
  },
  setTasksTemplate(state, { data }) {
    const index = state.tasksTemplates.findIndex(c => c.id === data.id);
    if (index > -1) {// Update project task customerId
        Vue.set(state.tasksTemplates, index, { ...data });
    }
    else {
      state.tasksTemplates.unshift(data);
      state.tasksTemplates.sort((a, b) => a.name.localeCompare(b.name));
    }
    // Vue.set(state.tasks[customerId], state.tasks[clientId][customerId].length, data);
  },
  removeTasksTemplate(state, { templateId }) {
    const index = state.tasksTemplates.findIndex(c => c.id === templateId);
    if (index > -1) {
        state.tasksTemplates.splice(index, 1);
    }
  },
  setTasksTemplates(state, { data }) {
    state.tasksTemplates = data;
  },
  setTasksComments(state, { taskId, data }) {
      Vue.set(state.taskComments, taskId, data);
  },
  setTaskComment(state, { taskId, data }) {
    if (!state.taskComments[taskId]) Vue.set(state.taskComments, taskId, []);
    const index = state.taskComments[taskId].findIndex(c => c.id === data.id);
    if (index > -1) {// Update project task customerId
        Vue.set(state.taskComments[taskId], index, { ...data });
    }
    else {
      state.taskComments[taskId].push(data);
    }
  },
  removeTaskComment(state, { taskId, commentId }) {
    const index = state.taskComments[taskId]?.findIndex(c => c.id === commentId);
    if (index > -1) {
        state.taskComments[taskId].splice(index, 1);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (!json.priority) json.priority  = null;
    if (json.dueDate) json.dueDate = dayjs(json.dueDate).toDate();
    if (json.completedAt) json.completedAt = dayjs(json.completedAt).toDate();
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    json.nbComments = json.nbComments || 0;
    json.nbAttachements = json.nbAttachements || 0;
    return json;
}

function toAPI(task) {
    const json = { ...task };
    // if (json.dueDate) json.dueDate = dayjs(json.dueDate).format('YYYY-MM-DD');
    json.customerId = json.customerId || null;
    json.userId = json.userId || null;
    json.contactId = json.contactId || null;
    if (json.project) {
        json.projectId = json.project.id;
        delete json.project;
    }
    json.projectId = json.projectId || 'default';
    if (json.recurring !== null && typeof json.recurring !== 'undefined') json.recurring = parseInt(json.recurring, 10);
    delete json.dueTime;
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

function fromAPIProject(json) {
    if(json.tasks) {
        json.tasks = json.tasks.map(fromAPI);
    }
    else {
      json.tasks = [];
    }
    json.nbTasks = json.tasks.length;
    
    json.tasksStatus = initTaskProjectStatus(json);

    Object.defineProperty(json, 'dueDate', {
        get: () => {
            let dueDate = null;
            for (const task of (json.tasks || [])) {
                if (!dueDate || dayjs(task.dueDate).isAfter(dueDate)) {
                    dueDate = dayjs(task.dueDate);
                }
            }
            return dueDate?.toDate();
        }
    });
    Object.defineProperty(json, 'completedAt', {
        get: () => {
            let completedAt = null;
            for (const task of (json.tasks || [])) {
                if (task.completedAt) {
                    if (!completedAt || dayjs(task.completedAt).isAfter(completedAt)) {
                        completedAt = dayjs(task.completedAt);
                    }
                }
                else {
                    if (!completedAt || dayjs(task.dueDate).isAfter(completedAt)) {
                        completedAt = dayjs(task.dueDate);
                    }
                }
            }
            return completedAt?.toDate();
        }
    });
    return json;
}

function toAPIProject(project) {
    const json = { ...project };
    json.customerId = json.customerId || null;
    if(json.nbTasks) delete json.nbTasks;
    if(json.tasksStatus) delete json.tasksStatus; 
    return json;
}

function fromAPITemplate(json) {
    if(json.tasks) {
        json.tasks = json.tasks.map(fromAPI);
    }
    else {
      json.tasks = [];
    }
    return json;
}

function toAPITemplate(project) {
    const json = { ...project };
    json.customerId = json.customerId || null;
    return json;
}

function fromAPIComment(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPIComment(comment) {
    const json = { ...comment };
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

function initTaskProjectStatus(project) {
    let tasksStatus = project.tasks.reduce((prev, cur) => {
        prev[cur.status] = prev[cur.status] || 0;
        prev[cur.status]++;
        return prev;
    }, {});
    return tasksStatus;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
