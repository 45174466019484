var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "featureRequest",
            title: _vm.$t("global.link.feature-request.title"),
            size: "lg",
            "ok-title": _vm.$t("settings.global.featureRequest.submit.button"),
            "input-class": "dd",
            "ok-disabled": _vm.loading
          },
          on: { show: _vm.resetModal, hidden: _vm.resetModal, ok: _vm.handleOk }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "paste",
                  rawName: "v-paste",
                  value: _vm.onPasteHandler,
                  expression: "onPasteHandler"
                }
              ]
            },
            [
              _c("p", { staticClass: "my-4" }, [
                _vm._v(
                  _vm._s(_vm.$t("settings.global.featureRequest.description"))
                )
              ]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mb-3 nav-justified" },
                        [
                          _c(
                            "b-form",
                            {
                              ref: "form",
                              on: {
                                submit: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleSubmit.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "input-group-1",
                                    "label-cols-sm": "3",
                                    label: _vm.$t(
                                      "settings.profile.modal.form.email.label"
                                    ),
                                    "label-for": "input-1"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      disabled: "",
                                      id: "input-member-name",
                                      type: "email",
                                      size: "lg"
                                    },
                                    model: {
                                      value: _vm.user.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "email", $$v)
                                      },
                                      expression: "user.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "input-group-feature-title",
                                    "label-cols-sm": "3",
                                    label: _vm.$t(
                                      "settings.global.featureRequest.form.title.label"
                                    ),
                                    "label-for": "input-member-feature-title"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "input-member-feature-title",
                                      required: "",
                                      placeholder: _vm.$t(
                                        "settings.global.featureRequest.form.title.placeholder"
                                      ),
                                      state: _vm.$v.form.title.$dirty
                                        ? !_vm.$v.form.title.$invalid
                                        : null
                                    },
                                    model: {
                                      value: _vm.form.title,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "title", $$v)
                                      },
                                      expression: "form.title"
                                    }
                                  }),
                                  _c(
                                    "b-form-invalid-feedback",
                                    {
                                      attrs: {
                                        id:
                                          "input-member-feature-title-feedback"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.global.featureRequest.form.title.invalid"
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "input-description",
                                    label: _vm.$t(
                                      "settings.global.featureRequest.form.description.label"
                                    )
                                  }
                                },
                                [
                                  _c("b-form-textarea", {
                                    staticClass: "description",
                                    attrs: {
                                      id: "input-member-feature-description",
                                      placeholder: _vm.$t(
                                        "settings.global.featureRequest.form.description.placeholder"
                                      )
                                    },
                                    model: {
                                      value: _vm.form.description,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "description", $$v)
                                      },
                                      expression: "form.description"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "mb-3 nav-justified",
                          attrs: {
                            title: _vm.$t(
                              "settings.global.featureRequest.attachments.card.title"
                            )
                          }
                        },
                        [
                          _vm._l(_vm.visualFiles, function(file, $index) {
                            return _c(
                              "b-row",
                              { key: $index, staticClass: "mb-2" },
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-card",
                                      [
                                        file.type.startsWith("image/")
                                          ? _c("b-img", {
                                              staticClass: "img-preview",
                                              attrs: {
                                                src: _vm.generateImageDate(
                                                  file
                                                ),
                                                fluid: ""
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("b-col", { attrs: { lg: "1" } }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "border-0 btn-transition btn btn-outline-danger",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.handleRemoveAttachment(
                                            $index
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "trash-alt" }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          }),
                          _c(
                            "b-row",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _vm.visualFiles.length < 3
                                    ? _c("b-form-file", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          accept: "image/*",
                                          placeholder: _vm.$t(
                                            "component.csvimport.file.label"
                                          ),
                                          "drop-placeholder": _vm.$t(
                                            "component.csvimport.file.drop"
                                          )
                                        },
                                        model: {
                                          value: _vm.files[_vm.files.length],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.files,
                                              _vm.files.length,
                                              $$v
                                            )
                                          },
                                          expression: "files[files.length]"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("b-col", { attrs: { lg: "1" } })
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }