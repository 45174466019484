import Vue from 'vue';
import dayjs from 'dayjs';

const state = {
    fetched: false,
    apiKeys: [],
}

const getters = {
    getAll: (state) => {
        return state.apiKeys;
    },
    getMaxApiKeys : (state, getters, rootState, rootGetters) => {
        return rootGetters["configs/get"](`maxApiKeys`) ?? 3;
    },
    getRemainingApiKeys : (state, getters) => {
        const maxApiKeys = getters.getMaxApiKeys;
        return maxApiKeys - state.apiKeys.length;
    },
    getFromId: (state) => (id )=> state.apiKeys.find(a => a.id === id),

};

const actions = {
  async ensureApiKeys({ dispatch, state }) {
    if (!state.fetched) {
        await dispatch('fetchApiKeys');
    }
  },
  async fetchApiKeys({ commit }) {
    try {
      const data = await this.getters.api.get(`/tools/tokens`);
      const apiKeys = data.map(fromAPI);
      commit('fetched', { value: true });
      commit('setApiKeys', { data: apiKeys });

      return apiKeys;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { apiKey }) {
    try {
        const data = fromAPI(await this.getters.api.post(`/tools/tokens`, { data: toAPI(apiKey) }));
        commit('setApiKey', { data });
        return data;
    } catch (error) {
        throw 'A server error has occurred';
    }
  },
  async update({ commit }, { apiKeyId, apiKey }) {
    try {
        await this.getters.api.patch(`/tools/tokens/${apiKeyId}`, { data: toAPI(apiKey) });
        apiKey.id = apiKeyId;
        commit('setApiKey', { data: apiKey });
        return apiKey;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { apiKeyId }) {
    try {
        await this.getters.api.delete(`/tools/tokens/${apiKeyId}`);
        commit('removeApiKey', { apiKeyId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
};


const mutations = {
  fetched(state, { value }) {
      state.fetched = value;
    },
  setApiKey(state, { data }) {
    const index = state.apiKeys.findIndex(c => c.id === data.id);
    if (index > -1) {
        Vue.set(state.apiKeys, index, { ...data});
    }
    else {
        state.apiKeys.push(data);
    }
  },
  removeApiKey(state, { apiKeyId }) {
    const index = state.apiKeys.findIndex(c => c.id === apiKeyId);
    if (index > -1) {
        state.apiKeys.splice(index, 1);
    }
  },
  setApiKeys(state, { data }) {
    Vue.set(state, 'apiKeys', data);
  },
  reset(state) {
    state.fetched =  false;
  }
};


function fromAPI(json) {
  if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
  if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
  if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
  return json;
}

function toAPI(apiKey) {
  const json = { ...apiKey };
  delete json.tokenClientId
  delete json.tokenClientSecret
  delete json.createdAt;
  delete json.updatedAt;
  delete json.deletedAt;
  delete json.deleted;
  return json;
}


export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations,
};