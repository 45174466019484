import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';

import EventsBus from '@/libs/EventsBus';

import onboarding from './onboarding.routes';
import customer from './customer.routes';
import contact from './contact.routes';
import agreement from './agreement.routes';
import task from './task.routes';
import alert from './alert.routes';
import settings from './settings.routes';
import reports from './reports.routes';
import playbook from './playbook.routes';
import dashboard from './dashboard.routes';

import { authGuard } from "../auth";

Vue.use(Router);

function resetBreadcrumb(to) {
  const links = to.meta.hasOwnProperty('breadcrumb') ? to.meta.breadcrumb.map(l => {
    return {
        ...l,
        text: i18n.te(`breadcrumb.${l.text}`) ? i18n.t(`breadcrumb.${l.text}`) : l.text
    };
  }) : [];

  store.commit('setBreadcrumb', { links });
}

const routes = [
  {
    path: '/loading',
    name: 'loading',
    meta: {
      authenticated: false
    },
    // component: () => import('@/modules/shared/Loading.vue')
  },
  {
      path: '/home',
      name: 'default',
      meta: {
        authenticated: true
      },
      component: () => import('@/modules/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      authenticated: false
    },
    component: () => import('@/modules/commons/LoginPopup.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    // meta: {
    //     authenticated: false
    // },
    // component: () => import('@/modules/shared/Loading.vue')
  },
  {
    path: '/',
    name: 'home',
    redirect: '/customers',
    // component: () => import('@/modules/404/_views/404.vue'),
    meta: {
      authenticated: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/modules/commons/404.vue'),
    meta: {
      authenticated: true
    }
  },
  {
    path: '/unavailable',
    name: 'unavailable',
    component: () => import('@/modules/commons/FeatureUnavailable.vue'),
    meta: {
      authenticated: true
    }
  }
].concat(
    onboarding,
    customer,
    contact,
    agreement,
    task,
    alert,
    settings,
    reports,
    playbook,
    dashboard,
  [
  {
    path: '*',
    redirect: '/404'
  },
//   {
//     path: '/',
//     redirect: '/login'
//   },
]);

const router = new Router({
    // mode: 'history',
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes
});
// State history
router.stateHistory = [];

router.beforeEach(authGuard);

// check admin permission && partitions
router.beforeEach(async (to, from, next) => {

  EventsBus.emit('setSearch', '');

  if (from.name !== to.name) {
    resetBreadcrumb(to);
  }

  store.dispatch('menu/setCurrentItem', to.name);


  // current page is confirm password
  if (to.query.confirm_pwd) {
    store.dispatch('entities/users/passwordConfirm', { code: to.query.confirm_pwd });
    router.push({ name: 'login', params: { updatePassword: 'success' } });
    return;
  }

  // current page is reset password
  else if (to.query.reset_pwd) {
    router.push({ name: 'password.reset', params: { email: to.query.email, token: to.query.reset_pwd } });
    return;
  }

  // if current partition exist, the user is connected but he forces login page
  if (to.name === 'login') {
    // redirect to dashboard
    if (from.name === 'dashboard') {
      return;
    } else {
      return next();
    }
  }

  //  user is authenticated
  if (to.meta.authenticated !== false) {
    try {
      await store.dispatch('clients/ensureClients');
    }
    catch(ex) {
        console.error(ex);
        store.dispatch('users/logout');
        return next({
            name: 'logout'
        });
    }
    if (!store.getters['clients/currentClientId']) {
        return next({
            name: 'logout'
        });
    }
    if (!store.getters['users/currentUserId']) {
        await store.dispatch('users/initCurrentUser');
    }
    await store.dispatch('features/ensureAuthorisations');
    
    // Check autorisations
    if (to.meta.authorisation?.feature && !store.getters['isUserAllowed']({ feature: to.meta.authorisation.feature })){
        if (store.getters['isFeatureCanActivate']({ feature: to.meta.authorisation.feature })) {
            return next({
                name: 'unavailable',
                params: {
                    feature: to.meta.authorisation.feature,
                    from: to
                }
            });
        }
        return next({
            name: '404'
        });
    }

    await store.dispatch('configs/ensureConfigs');
    await store.dispatch('configs/ensureUserConfigs');
    await store.dispatch('customs/ensureCustoms');
    await store.dispatch('onboarding/ensureOnboardingStatus');
    await store.dispatch('users/ensureUsers');
    await store.dispatch('usersTeams/ensureUsersTeams');
    await store.dispatch('customers/ensureCustomers');
    EventsBus.emit('initialized', true);

    // First page
    if (to.redirectedFrom === '/' && from.path === '/') {
        const homepage = store.getters['configs/getForUser']('homepage');
        if (store.getters['onboarding/getShow'] === true) {
            return next({
                name: 'onboarding'
            });
        }
        else if (homepage) {
            return next({
                name: homepage
            });
        }
    }
  }

//   const userHasAuthorisation = to.matched.every(record => {
//     if (!record.meta.authorisation) {
//       return true;
//     }
//     const authorisation = record.meta.authorisation;
//     if (authorisation.onlyInternal && !store.getters['isUserInternal']()) {
//       return false;
//     }
//     if (authorisation.allowInternal && store.getters['isUserInternal']()) {
//       return true;
//     }
//     if (authorisation.level) {
//       return store.getters['getUserPartitionLevel']() >= authorisation.level;
//     }
//     return store.getters['isUserAllowed'](authorisation.feature, authorisation.action);
//   });

  if (false && !userHasAuthorisation) {
    AMessage({
      type: 'error',
      showClose: true,
      message: i18n.t('error.view-not-allowed')
    });
    next({
      name: 'dashboard',
      params: {
        partitionId: store.getters['entities/partitions/currentPartition'].id
      }
    });
  } else {
    // Save only authenticated states
    if (to.meta.authenticated !== false) router.stateHistory.push(to);
    next();
  }
});

export default router;