import Vue from 'vue';
import store from '../store';


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useUserId = () => {

    return function () {
        return store.getters['users/currentUserId'];
    }
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const UserPlugin = {
    install(Vue, options) {
      Vue.prototype.$userId = useUserId(options);
    }
};

Vue.use(UserPlugin);
