<template>
          <div class="var-infos-wrapper" @click.prevent.stop v-click-outside="onCancel">
            <template v-if="options.length">
                <vue-cascader-select
                v-if="!selected"
                :inline="true"
                :options="options"
                @clear="(val) => variable = ''"
                @select="selectItem"
                :value="variable"
                >
                    <template #node="{ item }">
                    <div class="menu-item-var">
                        <div>{{ item.label }}</div>
                        <div v-if="item.description" class="menu-item-var-description mt-2">{{ item.description }}</div>
                        </div>
                    </template>
                </vue-cascader-select>
                <div v-else class="panel-fallback">
                    <b-card>
                        <b-form-group
                            :label="$t('commons.editor.variable.selected.label')"
                            label-for="input-var-fallback"
                        >
                        <b-input-group size="sm">
                            <template #prepend>
                                <b-button
                                    variant="outline-primary"
                                    @click="reset"
                                    >
                                    <b-icon icon="arrow-left" />
                                </b-button>
                            </template>
                            <b-form-input
                            id="input-var-fallback"
                            v-model="selected.label"
                            type="text"
                            :disabled="true"
                            debounce="500"
                            aria-describedby="input-var-fallback"
                            ></b-form-input>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            :label="$t('commons.editor.variable.fallback.label')"
                            label-for="input-var-fallback"
                        >
                            <b-form-input
                            id="input-var-fallback"
                            v-model="inputFallback"
                            type="text"
                            size="sm"
                            :placeholder="$t('commons.editor.variable.fallback.placeholder')"
                            debounce="500"
                            aria-describedby="input-var-fallback"
                            ></b-form-input>
                        </b-form-group>
                        <b-button variant="primary" size="sm" @click="onSave" class="mr-2">
                            {{ $t('commons.action.ok') }}
                        </b-button>
                        <b-button variant="outline-danger" size="sm" @click="onCancel">
                            {{ $t('commons.action.cancel') }}
                        </b-button>
                    </b-card>
                </div>
            </template>
            <div class="item" v-else>
            No result
            </div>
        </div>
</template>

<script>
import i18n from '@/i18n';
import VueCascaderSelect from '@/modules/commons/components/VueCascaderSelect';

export default {
    components: {
        VueCascaderSelect
    },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
        type: String,
        required: false
    },
    fallback: {
        type: String,
        required: false
    }
  },

  data() {
    return {
      selectedIndex: 0,
      variable: '',
      selected: null,
      inputFallback: ''
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },
  created() {
      this.$t = this.$t || ((key) => {
            return i18n.t(key);
      });
      Object.defineProperty(this, '$i18n', {
        get: function get () { return i18n; }
      });
  },
  mounted() {
      if (this.value) {
          const items = this.options?.flatMap(this.getItems);
          this.selected = items.find(i => i.value === this.value);
          this.inputFallback = this.fallback || '';
      }
  },
  methods: {
    getItems(item) {
        return [item, ...(item.options || []).flatMap(this.getItems)];
    },
    selectItem(value) {
        this.selected = value;
    },
    onSave() {
        this.$emit('select', {
            ...this.selected,
            fallback: this.inputFallback
        });
    },
    onCancel() {
        this.$emit('cancel');
    },
    reset() {
        this.selected = null;
        this.inputFallback = '';
    }
  },
}
</script>

<style lang="scss">
.menu-item-var {
    display: flex;
    flex-direction: column;

    .menu-item-var-description {
        font-weight: 200;
        font-size: 12px;
    }
}

.var-infos-wrapper{
  position: relative;
}
</style>