var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icon",
      class: [
        "icon--" + _vm.name,
        "icon--" + _vm.size,
        { "has-align-fix": _vm.fixAlign }
      ]
    },
    [
      _c("svg", { staticClass: "icon__svg" }, [
        _c("use", {
          attrs: {
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            "xlink:href": "#icon--" + _vm.name
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }