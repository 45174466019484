import mitt from 'mitt';

const emitter = mitt();



emitter.once = (event, handler) => {
    emitter.on(event, () => {
        handler.apply(this, ...arguments);
        emitter.off(event);
    });
};

export default emitter;