import { getInstance } from "./authWrapper";

const authentificationEnabled = process.env.VUE_APP_AUTHENTIFICATION_ENABLED !== 'false';

export const authGuard = (to, from, next) => {
  if (!authentificationEnabled || to.meta.authenticated === false) return next();
  const authService = getInstance();

//   if (authService.isAuthenticated) return next();

  const fn = async () => {
    if (authService.isAuthenticated) {
      return next();
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};