var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-sidebar sidebar-shadow" }, [
    _c("div", { staticClass: "app-header__logo" }, [
      _c("div", { staticClass: "logo-src" }),
      _c("div", { staticClass: "header__pane expander" }, [
        _c(
          "button",
          {
            staticClass: "hamburger close-sidebar-btn hamburger--arrow",
            class: { "is-active": !_vm.isCollapsed },
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.toggleBodyClass("closed-sidebar")
              }
            }
          },
          [_vm._m(0)]
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "app-sidebar-content" },
      [
        _c(
          "VuePerfectScrollbar",
          { staticClass: "app-sidebar-scroll" },
          [
            _c("sidebar-menu", {
              attrs: {
                showOneChild: "",
                relative: _vm.relative,
                menu: _vm.menu,
                "hide-toggle": true,
                collapsed: _vm.isCollapsed
              },
              on: { "item-click": _vm.handleMenuClick }
            }),
            _c("div", { staticClass: "app-copyright text-center" }, [
              _vm._v("\n                Copyright 2024 Skalin\n            ")
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }