const pendingPromise = new Map();

export async function debounce(key, fn) {
  let pending = pendingPromise.get(key);
  if (!pending) {
    pending = (async () => {
      try {
        const result = await fn();
        pendingPromise.delete(key);
        return result;
      } catch (err) {
        pendingPromise.delete(key);
        throw err;
      }
    })();
    pendingPromise.set(key, pending);
  }
  return pending;
}

export async function sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
}