import Vue from 'vue';
import store from '../store';


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useClientId = () => {

    return function () {
        return store.getters['clients/currentClientId'];
    }
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const ClientPlugin = {
    install(Vue, options) {
      Vue.prototype.$clientId = useClientId(options);
    }
};

Vue.use(ClientPlugin);
