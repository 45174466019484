<template>
    <div class="button-variable" @click.prevent.stop>
        <button class="menubar__button"
          @click="showVariables = true" >
            {{ $t('commons.editor.command.variable') }}
        </button>
          <div class="items" @click.prevent.stop>
              <variable-infos ref="info" v-click-outside="exit"
                :options="items"
                @select="selectItem"
                @cancel="exit"
              />
        </div>
    </div>
</template>

<script>
import Icon from '@/modules/commons/components/Icon';
import VariableInfos from '@/libs/tiptap/variable/VariableInfos';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default {
    components: {
        Icon,
        VariableInfos
    },
  props: {

    items: {
      type: Array,
      required: true,
    },

    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
      variable: '',
      showVariables: false,
      tip: null
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
    showVariables(val) {
        if (val) this.tip?.show();
        else this.tip?.hide();
    }
  },
//  },
  mounted() {
      this.init();
  },
  methods: {
    init() {
        this.tip = tippy(this.$el, {
          getReferenceClientRect: () => {
            return this.$el?.getBoundingClientRect();
          },
          appendTo: () => document.body,
          content: this.$refs.info?.$el,
          showOnCreate: false,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom',
          theme: 'light',
          maxWidth: 650,
          delay: [200, 0],
        });
    },
    hideVariablesMenu() {
        this.showVariables = false;
    },
    selectItem(value) {
        this.showVariables = false;
      if (value) {
        this.editor.chain().focus().variableCommand({ value: value.value, label: value.label, fallback: value.fallback }).run();
      }
      this.exit();
    },
    exit() {
        this.showVariables = false;
        this.$refs.info?.reset();
    }
  },
}
</script>

<style lang="scss">
.button-variable {
    display: flex;
    flex-direction: column;
    // width: 80px;

    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
}

.menu-item-var {
    display: flex;
    flex-direction: column;

    .menu-item-var-description {
        font-weight: 200;
        font-size: 12px;
    }
}
</style>