import dayjs from 'dayjs';
import Vue from 'vue';
import i18n from '@/i18n';
import EventsBus from '@/libs/EventsBus';
import { fromAPI as fromAPIInteraction } from './interactions';

const state = {
  fetched: {},
  goalsProfiles: [],
  goals: [],
  stats: {}
};

let lastSsersStatsParams = null;

const getters = {
  getAllGoalsProfiles: (state, getters, store, rootGetters) => {
      return state.goalsProfiles ?? [];
  },
  getAllGoals: (state, getters, store, rootGetters) => {
    return state.goals ?? [];
  },
  getGoalProfileFromId: (state, getters) => ( id ) => {
      return state.goalsProfiles.find(g => g.id === id);
  },
  getGoalFromId: (state, getters) => ( id ) => {
    return state.goals.find(g => g.id === id);
},
  getStats: (state) => goalId => {
    return state.stats[goalId];
  },
  getInteractionGoals: (state, getters) => ( interaction ) => {
    if (!interaction) return [];
    return getters.getAllGoals.filter(g => {
        return g.type === 'INTERACTION' && (!g.subType || g.subType === interaction.type) && (!g.tagId || interaction.tags?.includes?.(g.tagId));
    });
  }
};

const actions = {

  async ensureGoalsProfiles({ dispatch, state }) {
    if (!state.fetched.goalsProfiles) {
      await dispatch('fetchGoalsProfiles');
    }
  },
  async ensureGoals({ dispatch, state }) {
    if (!state.fetched.goals) {
      await dispatch('fetchGoals');
    }
  },
  async fetchGoalsProfiles({ dispatch, commit }) {
    try {
      const query = {
          cache: true
      };
      const results = await this.getters.api.get(`/goal-profiles`, query);
      const goalsProfiles = results.map(fromAPIGoalProfile);
      commit('setGoalsProfiles', { data: goalsProfiles });
      commit('fetched', { key: 'goalsProfiles', value: true });
      return goalsProfiles;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchGoals({ dispatch, commit }) {
    try {
      const query = {
          cache: true
      };
      const results = await this.getters.api.get(`/goals`, query);
      const goals = results.map(fromAPIGoal);
      commit('setGoals', { data: goals });
      commit('fetched', { key: 'goals', value: true });
      return goals;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async usersStats({ dispatch, commit, rootGetters }, { user, period = 'MONTH', from, to }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const userId = user?.value && user?.mode === 'USER' ? user.value : null;
      if (user?.mode === 'TEAM') {
        params.users = rootGetters['usersTeams/getMembersFromId'](user.value);
      }
      lastSsersStatsParams = params;
      const results = await this.getters.api.get(userId ? `/goals/users/${userId}/stats` : `/goals/users/stats`, {
        params
      });
      // Not last request don't send result
      if (lastSsersStatsParams !== params) return;

      return results;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async usersStatsExport({ dispatch, commit, rootGetters }, { user, period = 'MONTH', from, to, ctx }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const userId = user?.value && user?.mode === 'USER' ? user.value : null;
      if (user?.mode === 'TEAM') {
        params.users = rootGetters['usersTeams/getMembersFromId'](user.value);
      }
      const results = await this.getters.api.get(userId ? `/goals/users/${userId}/stats/export` : `/goals/users/stats/export`, {
        cache: false,
        pagination: ctx,
        params
      });
      return results.data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async usersInteractionsStats({ dispatch, commit, rootGetters }, { user, period = 'MONTH', from, to, type }) {
    try {
      const params = {
        by: period.toLowerCase(),
        type: type?.length ? type.toLowerCase() : 'customer'
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const userId = user?.value && user?.mode === 'USER' ? user.value : null;
      if (user?.mode === 'TEAM') {
        params.users = rootGetters['usersTeams/getMembersFromId'](user.value);
      }
      const results = await this.getters.api.get(userId ? `/goals/users/${userId}/stats/interactions` : `/goals/users/stats/interactions`, {
        params
      });
      return results;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async usersInteractionsList({ dispatch, commit }, { period = 'MONTH', from, to, ctx }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const { metadata, data } = await this.getters.api.get(`/goals/exports/interactions`, {
        pagination: ctx,
        params
      });
      const interactions = data.map(fromAPIInteraction);
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: interactions
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async usersInteractionsListExport({ dispatch, commit }, { period = 'MONTH', from, to, ctx }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const results = await this.getters.api.get(`/goals/exports/interactions/export`, {
        cache: false,
        pagination: ctx,
        params
      });
      return results.data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async customersStats({ dispatch, commit }, { customerId, period = 'MONTH', from, to, ctx }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const results = await this.getters.api.get(customerId ? `/goals/customers/${customerId}/stats` : `/goals/customers/stats`, {
        pagination: ctx,
        params
      });
      return results;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async customersStatsExport({ dispatch, commit }, { customerId, period = 'MONTH', from, to, ctx }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const results = await this.getters.api.get(customerId ? `/goals/customers/${customerId}/stats/export` : `/goals/customers/stats/export`, {
        cache: false,
        pagination: ctx,
        params
      });
      return results.data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async customersInteractionsStats({ dispatch, commit }, { customerId, period = 'MONTH', from, to, ctx }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const results = await this.getters.api.get(customerId ? `/goals/customers/${customerId}/stats/interactions` : `/goals/customers/stats/interactions`, {
        pagination: ctx,
        params
      });
      return results;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async customersInteractionsList({ dispatch, commit }, { period = 'MONTH', from, to, ctx }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const { metadata, data } = await this.getters.api.get(`/goals/exports/interactions`, {
        pagination: ctx,
        params
      });
      const interactions = data.map(fromAPIInteraction);
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: interactions
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async customersInteractionsListExport({ dispatch, commit }, { period = 'MONTH', from, to, ctx }) {
    try {
      const params = {
        by: period.toLowerCase()
      };
      if (from) {
        params.from = dayjs(from).format('YYYY-MM-DD');
      }
      if (to) {
        params.to = dayjs(to).format('YYYY-MM-DD');
      }
      const results = await this.getters.api.get(`/goals/exports/interactions/export`, {
        cache: false,
        pagination: ctx,
        params
      });
      return results.data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async addProfile({ commit }, { goalProfile }) {
    try {
        const data = await this.getters.api.post(`/goal-profiles`, {
            data: toAPIGoalProfile(goalProfile)
        });
        goalProfile.id = data.id;
        goalProfile.createdAt = new Date();
        goalProfile.updatedAt = new Date();
        commit('setGoalProfile', { data: goalProfile });
        return data;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateProfile({ commit, state, rootGetters }, { goalProfileId, goalProfile }) {
    try {
        const result = await this.getters.api.patch(`/goal-profiles/${goalProfileId}`, {
            data: toAPIGoalProfile(goalProfile)
        });
        // TODO remove
        goalProfile.id = goalProfileId;
        goalProfile.updatedAt = new Date();
        commit('setGoalProfile', { data: goalProfile });
        return goalProfile;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeProfile({ commit }, { goalProfileId }) {
    try {
        await this.getters.api.delete(`/goal-profiles/${goalProfileId}`);
        commit('removeGoalProfile', { goalProfileId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async add({ commit }, { goal }) {
    try {
        const data = await this.getters.api.post(`/goals`, {
            data: toAPIGoal(goal)
        });
        commit('setGoal', { data });
        return data;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit, state, rootGetters }, { goalId, goal }) {
    try {
        const result = await this.getters.api.patch(`/goals/${goalId}`, {
            data: toAPIGoal(goal)
        });
        // TODO remove
        goal.id = goalId;
        goal.updatedAt = new Date();
        commit('setGoal', { data: goal });
        return goal;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { goalId }) {
    try {
        await this.getters.api.delete(`/goals/${goalId}`);
        commit('removeGoal', { goalId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { key, value }) {
    state.fetched[key] = value;
  },
  setGoalProfile(state, { data }) {
    const index = state.goalsProfiles.findIndex(c => c.id === data.id);
    if (index > -1) {
        Vue.set(state.goalsProfiles, index, { ...state.goalsProfiles[index], ...data});
    }
    else {
        state.goalsProfiles.unshift(data);
    }
  },
  setGoalsProfiles(state, { data }) {
    Vue.set(state, 'goalsProfiles', data);
  },
  removeGoalProfile(state, {  goalProfileId }) {
    const index = state.goalsProfiles.findIndex(c => c.id === goalProfileId);
    if (index > -1) {
        state.goalsProfiles.splice(index, 1);
    }
  },
  setGoal(state, { data }) {
    const index = state.goals.findIndex(c => c.id === data.id);
    if (index > -1) {
        Vue.set(state.goals, index, { ...state.goals[index], ...data});
    }
    else {
        state.goals.unshift(data);
    }
  },
  setGoals(state, { data }) {
    Vue.set(state, 'goals', data);
  },
  removeGoal(state, {  goalId }) {
    const index = state.goals.findIndex(c => c.id === goalId);
    if (index > -1) {
        state.goals.splice(index, 1);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPIGoalProfile(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json; 
}

function toAPIGoalProfile(goal) {
    const json = {...goal};
    
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

function fromAPIGoal(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json; 
}

function toAPIGoal(goalType) {
    const json = {...goalType};
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
