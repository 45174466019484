import EventsBus from '@/libs/EventsBus';
import Vue from 'vue';
import dayjs from 'dayjs';
import i18n from '@/i18n';
import { generateUID } from '@/libs/utils/String';
import { validateJSONSchema } from '@/libs/utils/Validator';
import { sleep } from '@/libs/utils/Promise';
import { PLAYBOOK_STEP_PARAMETERS_SCHEMA } from './utils/stepValidator';
import { CUSTOM_DATA_TYPE } from '@/store/modules/customs.js';

let globalStore = null;

export const PLAYBOOK_TYPE = {
    LIFECYCLE: 'LIFECYCLE',
    UPSELL: 'UPSELL',
    RISK: 'RISK'
};

const TRIGGER_TYPES = [
    {
        group: 'CUSTOMER',
        category: "all",
        triggers: [
            {
                type: 'CUSTOMER@MANUAL',
                category: 'playbook'
            },
            {
                type: 'CUSTOMER@CREATED',
                category: 'all'
            },
            {
                type: 'CUSTOMER.STAGE@CHANGED',
                category: 'all'
            },
            {
                type: 'CUSTOMER.PROFILE@CHANGED',
                category: 'all'
            },
            {
                type: 'CUSTOMER.RENEWAL@REACHED',
                category: 'all'
            },
            {
                type: 'CUSTOMER.END_DATE@REACHED',
                category: 'all'
            },
            {
                type: 'CUSTOMER.PULSE@CHANGED',
                category: 'all'
            },
            {
                type: 'CUSTOMER.OWNER@CHANGED',
                category: 'all'
            },
            {
                type: 'CUSTOMER.GHOST@ENTER',
                category: 'all'
            },
            {
                type: 'CUSTOMER.GHOST@LEAVE',
                category: 'all'
            },
            {
                type: 'CUSTOMER.TAG@ADDED',
                category: 'all'
            },
            
        ],
    },
    {
        group: 'CONTACT',
        category: 'all',
        triggers: [
            {
                type: 'CONTACT@MANUAL',
                category: 'playbook'
            },
            {
                type: 'CONTACT@CREATED',
                category: 'all'
            },
            {
                type: 'CONTACT.NPS@CHANGED',
                category: 'all'
            },
            {
                type: 'CONTACT.TAG@ADDED',
                category: 'all'
            },
        ],
    },
    {
        group: 'HEALTHSCORE',
        category: 'all',
        triggers: [
            {
                type: 'HEALTHSCORE@ENTER_DANGER',
                category: 'all'
            },
            {
                type: 'HEALTHSCORE@LEAVE_DANGER',
                category: 'all'
            },
            {
                type: 'HEALTHSCORE@ENTER_HEALTHY',
                category: 'all'
            },
            {
                type: 'HEALTHSCORE@LEAVE_HEALTHY',
                category: 'all'
            },
            {
                type: 'HEALTHSCORE@CHANGED',
                category: 'all'
            },
        ],
    },
    {
        group: 'INTERACTION',
        category: 'all',
        triggers: [
            {
                type: 'CUSTOMER.INTERACTION@NO_TOUCH',
                category: 'all'
            },
            {
                type: 'CONTACT.INTERACTION@NO_TOUCH',
                category: 'all'
            },
            {
                type: 'CUSTOMER.INTERACTION@SCORE_EVOLUTION',
                category: 'all'
            },
        ],
    },
    {
        group: 'USAGE',
        category: 'all',
        triggers: [
            {
                type: 'CUSTOMER.USAGE@NO_ACTIVITY',
                category: 'all'
            },
            {
                type: 'CONTACT.USAGE@NO_ACTIVITY',
                category: 'all'
            },
            {
                type: 'CUSTOMER.USAGE@NO_ACTIVITY_SINCE',
                category: 'all'
            },
            {
                type: 'CONTACT.USAGE@NO_ACTIVITY_SINCE',
                category: 'all'
            },
            {
                type: 'CUSTOMER.USAGE@NEW_ACTIVITY_SINCE',
                category: 'all'
            },
            {
                type: 'CONTACT.USAGE@NEW_ACTIVITY_SINCE',
                category: 'all'
            },
            {
                type: 'CUSTOMER.USAGE@FIRST_ACTIVITY',
                category: 'all'
            },
            {
                type: 'CONTACT.USAGE@FIRST_ACTIVITY',
                category: 'all'
            },
        ],
    },
    {
        group: 'TASK',
        category: 'alert',
        triggers: [
            {
                type: 'TASK@CREATED',
                category: 'alert'
            },
            {
                type: 'TASK@ASSIGNED',
                category: 'alert'
            },
            {
                type: 'TASK@COMPLETED',
                category: 'alert'
            },
            {
                type: 'TASK@DUEDATE',
                category: 'alert'
            },
        ],
    },
    {
        group: 'TASK_PROJECT',
        category: 'alert',
        triggers: [
            {
                type: 'TASK_PROJECT@CREATED',
                category: 'alert'
            },
            {
                type: 'TASK_PROJECT@COMPLETED',
                category: 'alert'
            },
            {
                type: 'TASK_PROJECT@DUEDATE',
                category: 'alert'
            },
        ],
    },
    {
        group: 'PLAYBOOK',
        category: 'alert',
        triggers: [
            {
                type: 'PLAYBOOK.ACTIVATION@MANUAL_STEP',
                category: 'alert'
            },
        ],
    },
];

const PROPERTIES = [
    {
        name: 'CUSTOMER',
        label: i18n.t(`schemas.object.customer.label`),
        value: 'COMMON',
        selectable: false,
        properties: {
            'customer.stage': { key: 'stage', label: i18n.t('schemas.customer.properties.stage'), type: 'enum', enumFunction: async () => {
                await globalStore.dispatch('customerStages/ensureStages');
                return globalStore.getters['customerStages/getAll'].map(s => ({value: s.name, text: s.label}));
            }},
            'customer.profileId': { key: 'profileId', label: i18n.t('schemas.customer.properties.profileId'), selectLabel: i18n.t('schemas.customer.properties.profileId.select'), type: 'enum', enumFunction: async () => {
                await globalStore.dispatch('customerProfiles/ensureProfiles');
                return globalStore.getters['customerProfiles/getAll'].map(s => ({value: s.id, text: s.name}));
            }},
            'customer.csmPulse': { key: 'csmPulse', label: i18n.t('schemas.customer.properties.csmPulse'), type: 'number', minimum: 1, maximum: 5 },
            'customer.tags': { key: 'tags', label: i18n.t('schemas.customer.properties.tags'), selectLabel: i18n.t('schemas.customer.properties.tags.select'), type: 'enum[]', enumFunction: async () => {
                await globalStore.dispatch('tags/ensureTags');
                return globalStore.getters['tags/getAllByEntity']('CUSTOMER').map(s => ({value: s.id, text: s.name}));
            }},
            'customer.ownerId': { key: 'name', label: i18n.t('schemas.customer.properties.owner'), type: 'user' },
            'customer.name': { key: 'name', label: i18n.t('schemas.customer.properties.name'), type: 'string' },
            'customer.refId': { key: 'refId', label: i18n.t('schemas.customer.properties.refId'), type: 'string' },
            'customer.domain': { key: 'domain', label: i18n.t('schemas.customer.properties.domain'), type: 'string' },
            'customer.createdAt': { key: 'createdAt', label: i18n.t('schemas.customer.properties.createdAt'), type: 'date', options: { pastOnly: true } },
            'customer.churnAt': { key: 'churnAt', label: i18n.t('schemas.customer.properties.churnAt'), type: 'date', options: { pastOnly: true } }
        }
    },
    {
        name: 'CONTACT',
        label: i18n.t(`schemas.object.triggeredContact.label`),
        value: 'CONTACT',
        selectable: false,
        filter: 'contact',
        properties: {}
    },
    {
        name: 'AGREEMENT',
        label: i18n.t(`schemas.object.agreement.label`),
        value: 'AGREEMENT',
        selectable: false,
        properties: {
            'agreement.mrr': { key: 'mrr', label: i18n.t('schemas.agreement.properties.mrr'), type: 'number', minimum: 0 },
            'agreement.plan': { key: 'tags', label: i18n.t('schemas.agreement.properties.plan'), type: 'enum[]', enumFunction: async () => {
                await globalStore.dispatch('customerPlans/ensurePlans');
                return globalStore.getters['customerPlans/getAll'].map(p => ({value: p, text: p}));
            }},
            'agreement.renewalDate': { key: 'renewalDate', label: i18n.t('schemas.agreement.properties.renewalDate'), type: 'date', options: { futurOnly: false } },
            'agreement.endDate': { key: 'endDate', label: i18n.t('schemas.agreement.properties.endDate'), type: 'date', options: { futurOnly: false } },
            'agreement.autoRenew': { key: 'endDate', label: i18n.t('schemas.agreement.properties.autoRenew'), type: 'boolean' },
        }
    },
    {
        name: 'HEALTHSCORE',
        label: i18n.t(`schemas.object.healthScore.label`),
        value: 'HEALTHSCORE',
        selectable: false,
        properties: {
            'healthscore.level': { key: 'healthScore', label: i18n.t('schemas.healthScore.properties.level'), selectLabel: i18n.t('schemas.healthScore.properties.level.select'), type: 'enum', enum: ['GOOD', 'MEDIUM', 'POOR'] },
            'healthscore.score': { key: 'healthScore', label: i18n.t('schemas.healthScore.properties.score'), selectLabel: i18n.t('schemas.healthScore.properties.score.select'), type: 'number', minimum: 0, maximum: 10 }
        }
    },
    {
        name: 'INTERACTION',
        label: i18n.t(`schemas.object.interaction.label`),
        value: 'INTERACTION',
        selectable: false,
        properties: {
            'interaction.trigger.lastTouch': { key: 'lastTouch', label: i18n.t('schemas.interraction.properties.trigger.lastTouch'), type: 'date', options: { pastOnly: true }, filter: 'contact' },
            'interaction.lastTouch': { key: 'lastTouch', label: i18n.t('schemas.interraction.properties.lastTouch'), type: 'date', options: { pastOnly: true } },
            'interaction.champion.lastTouch': { key: 'lastTouch', label: i18n.t('schemas.interraction.properties.champion.lastTouch'), type: 'date', options: { pastOnly: true } },
            'interaction.sponsor.lastTouch': { key: 'lastTouch', label: i18n.t('schemas.interraction.properties.sponsor.lastTouch'), type: 'date', options: { pastOnly: true } },
            'interaction.keyUser.lastTouch': { key: 'lastTouch', label: i18n.t('schemas.interraction.properties.keyUser.lastTouch'), type: 'date', options: { pastOnly: true } },
            'interaction.endUser.lastTouch': { key: 'lastTouch', label: i18n.t('schemas.interraction.properties.endUser.lastTouch'), type: 'date', options: { pastOnly: true } }
        }
    },
    {
        name: 'USAGE',
        label: i18n.t(`schemas.object.usage.label`),
        value: 'USAGE',
        selectable: false,
        properties: {
            'usage.trigger.lastActivity': { key: 'lastActivity', label: i18n.t('schemas.usage.properties.trigger.lastActivity'), type: 'date', options: { pastOnly: true, context: 'feature' }, filter: 'contact' },
            'usage.lastActivity': { key: 'lastActivity', label: i18n.t('schemas.usage.properties.lastActivity'), type: 'date', options: { pastOnly: true, context: 'feature' } },
            'usage.champion.lastActivity': { key: 'lastActivity', label: i18n.t('schemas.usage.properties.champion.lastActivity'), type: 'date', options: { pastOnly: true, context: 'feature' } },
            'usage.sponsor.lastActivity': { key: 'lastActivity', label: i18n.t('schemas.usage.properties.sponsor.lastActivity'), type: 'date', options: { pastOnly: true, context: 'feature' } },
            'usage.keyUser.lastActivity': { key: 'lastActivity', label: i18n.t('schemas.usage.properties.keyUser.lastActivity'), type: 'date', options: { pastOnly: true, context: 'feature' } },
            'usage.endUser.lastActivity': { key: 'lastActivity', label: i18n.t('schemas.usage.properties.endUser.lastActivity'), type: 'date', options: { pastOnly: true, context: 'feature' } },
        }
    }
];

const CONTACT_PROPERTIES = [
    {
        name: 'CONTACT',
        label: i18n.t(`schemas.object.contact.label`),
        value: 'COMMON',
        selectable: false,
        filter: 'contact',
        properties: {
            'contact.npsScore': { key: 'npsScore', label: i18n.t('schemas.contact.properties.npsScore'), type: 'number', minimum: 1, maximum: 10, filter: 'contact' },
            'contact.tags': { key: 'tags', label: i18n.t('schemas.contact.properties.tags'), selectLabel: i18n.t('schemas.contact.properties.tags.select'), type: 'enum[]', enumFunction: async () => {
                await globalStore.dispatch('tags/ensureTags');
                return globalStore.getters['tags/getAllByEntity']('CONTACT').map(s => ({value: s.id, text: s.name}));
            }, filter: 'contact'},
            'contact.lastName': { key: 'lastName', label: i18n.t('schemas.contact.properties.lastName'), type: 'string', filter: 'contact' },
            'contact.refId': { key: 'refId', label: i18n.t('schemas.contact.properties.refId'), type: 'string', filter: 'contact' },
            'contact.email': { key: 'email', label: i18n.t('schemas.contact.properties.email'), type: 'string', filter: 'contact' },
            'contact.createdAt': { key: 'createdAt', label: i18n.t('schemas.contact.properties.createdAt'), type: 'date', options: { pastOnly: true }, filter: 'contact' }
        }
    },
    {
        name: 'INTERACTION',
        label: i18n.t(`schemas.object.interaction.label`),
        value: 'INTERACTION',
        selectable: false,
        properties: {
            'interaction.contact.lastTouch': { key: 'lastTouch', label: i18n.t('schemas.interraction.properties.contact.lastTouch'), type: 'date', options: { pastOnly: true }, filter: 'contact' },
        }
    },
    {
        name: 'USAGE',
        label: i18n.t(`schemas.object.usage.label`),
        value: 'USAGE',
        selectable: false,
        properties: {
            'usage.contact.lastActivity': { key: 'lastActivity', label: i18n.t('schemas.usage.properties.contact.lastActivity'), type: 'date', options: { pastOnly: true, context: 'feature' }, filter: 'contact' },
        }
    }
];

const ACTION_TYPES = [
    {
        group: 'CUSTOMER',
        items: [
            { name: 'CUSTOMER_STAGE_CHANGE', icon: 'folder'},
            { name: 'CUSTOMER_PROFILE_CHANGE', icon: 'sliders-h', iconSrc: 'fontawesome'},
            { name: 'CUSTOMER_GOAL_PROFILE_CHANGE', icon: 'bullseye', feature: 'goal' },
            { name: 'CUSTOMER_OWNER_CHANGE', icon: 'star'},
            { name: 'CUSTOMER_TAG_CHANGE', icon: 'tag'},
            { name: 'CUSTOMER_CUSTOM_USER_CHANGE', icon: 'user-tie', iconSrc: 'fontawesome', feature: 'custom' },
            { name: 'CUSTOMER_CUSTOM_SCORE_CHANGE', icon: 'star', iconSrc: 'fontawesome', feature: 'custom' }
        ]
    },
    {
        group: 'CONTACT',
        items: [
            { name: 'CONTACT_TAG_CHANGE', icon: 'tag'}
        ]
    },
    {
        group: 'TASK',
        items: [
          { name: 'TASK_CREATE', icon: 'list-task'},
          { name: 'TASK_PROJECT_CREATE', icon: 'card-list'}
        ]
    },
    {
        group: 'OUTPUT',
        items: [
            { name: 'OUTPUT_MAIL_INBOX', icon: 'inbox', connector: ['GMAIL', 'MICROSOFT'] },
            { name: 'OUTPUT_MAIL_INBOX_SEND', icon: 'inbox', connector: ['GMAIL', 'MICROSOFT'] },
            { name: 'OUTPUT_MAIL_INBOX_VALIDATION', icon: 'inbox', connector: ['GMAIL', 'MICROSOFT'] },
            { name: 'OUTPUT_MAIL_INTERNAL', icon: 'envelope-open'},
            { name: 'OUTPUT_WEBHOOK', icon: 'hourglass'},
            { name: 'OUTPUT_SLACK', icon: ['fab', 'slack'], iconSrc: 'fontawesome', connector: ['SLACK']}
        ]
    },
    {
        group: 'PLAYBOOK',
        items: [
          { name: 'PLAYBOOK_RUN', icon: 'play'}
        ]
    }
];

const CONDITION_TYPES = [
    {
        group: 'TIME',
        items: [
            { name: 'COND_WAIT_TIME_UNTIL', icon: 'hourglass'},
            { name: 'COND_WAIT_MANUAL', icon: 'hand-index'}
        ]
    },
    {
        group: 'TASK',
        items: [
            { name: 'COND_WAIT_TASK@COMPLETED', icon: 'check'},
            { name: 'COND_WAIT_TASK_PROJECT@COMPLETED', icon: 'check-all'}
        ]
    },
    {
        group: 'ACTION',
        items: [
            { name: 'COND_STOP', icon: 'stop-circle'},
            { name: 'COND_FILTER_STOP', icon: 'stoplights'}
        ]
    },
    {
        group: 'FILTER',
        items: [
            { name: 'COND_FILTER_CUSTOMER', icon: 'diagram-3-fill'},
            { name: 'COND_FILTER_MANUAL', icon: 'diagram-3-fill', shift: '4', iconstack: true, icon2: 'hand-index', shift2: '-8'},
            { name: 'COND_FILTER_MERGE_CUSTOMER', icon: 'diagram-3-fill', rotate: '180', shift: '-4', iconstack: true, icon2: 'diagram-3-fill', shift2: '4' },
            { name: 'COND_FILTER_MERGE_MANUAL', icon: 'diagram-3-fill', rotate: '180', shift: '-2', iconstack: true, icon2: 'diagram-3-fill', shift2: '6', icon3: 'hand-index', shift3: '-8' }
        ]
    }
];

const FILTER_STEP_TYPES = ['COND_FILTER_STOP', 'COND_FILTER_CUSTOMER', 'COND_FILTER_MERGE_CUSTOMER'];

const VARIABLES_SCHEMA = [{
    label: i18n.t('schemas.object.triggeredContact.label'),
    description: i18n.t('schemas.object.triggeredContact.description'),
    value: 'contact',
    selectable: false,
    options: [
        { label: i18n.t('schemas.contact.properties.email'), value: 'contact.email' },
        { label: i18n.t('schemas.contact.properties.firstName'), value: 'contact.firstName' },
        { label: i18n.t('schemas.contact.properties.lastName'), value: 'contact.lastName' },
        { label: i18n.t('schemas.contact.properties.jobTitle'), value: 'contact.jobTitle' },
        { label: i18n.t('schemas.contact.properties.phone'), value: 'contact.phone' },
        { label: i18n.t('schemas.contact.properties.createdAt'), value: 'contact.createdAt' },
      ]
  },
  {
    label: i18n.t('schemas.object.recipient.label'),
    description: i18n.t('schemas.object.recipient.description'),
    value: 'recipient',
    selectable: false,
    options: [
        { label: i18n.t('schemas.recipient.properties.email'), value: 'recipient.email' },
        { label: i18n.t('schemas.recipient.properties.firstName'), value: 'recipient.firstName' },
        { label: i18n.t('schemas.recipient.properties.lastName'), value: 'recipient.lastName' },
        { label: i18n.t('schemas.recipient.properties.phone'), value: 'recipient.phone' }
    ],
  },
  {
    label: i18n.t('schemas.object.customer.label'),
    description: i18n.t('schemas.object.customer.description'),
    value: 'customer',
    selectable: false,
    options: [
      { label: i18n.t('schemas.customer.properties.name'), value: 'customer.name' },
      { label: i18n.t('schemas.customer.properties.refId'), value: 'customer.refId' },
      { label: i18n.t('schemas.customer.properties.domain'), value: 'customer.domain' },
      { label: i18n.t('schemas.customer.properties.stage'), value: 'customer.stage' },
      { label: i18n.t('schemas.customer.properties.csmPulse'), value: 'customer.csmPulse' },
      { label: i18n.t('schemas.customer.properties.churnAt'), value: 'customer.churnAt' },
      {
        label: i18n.t('schemas.customer.properties.owner'),
        value: 'customer.owner',
        selectable: false,
        options: [
          { label: i18n.t('schemas.customer.properties.owner.email'), value: 'customer.owner.email' },
          { label: i18n.t('schemas.customer.properties.owner.firstName'), value: 'customer.owner.firstName' },
          { label: i18n.t('schemas.customer.properties.owner.lastName'), value: 'customer.owner.lastName' },
          { label: i18n.t('schemas.customer.properties.owner.phone'), value: 'customer.owner.phone' }
        ]
    },
      {
          label: i18n.t('schemas.customer.properties.sponsor'),
          value: 'customer.sponsor',
          selectable: false,
          options: [
            { label: i18n.t('schemas.customer.properties.sponsor.email'), value: 'customer.sponsor.email' },
            { label: i18n.t('schemas.customer.properties.sponsor.firstName'), value: 'customer.sponsor.firstName' },
            { label: i18n.t('schemas.customer.properties.sponsor.lastName'), value: 'customer.sponsor.lastName' },
            { label: i18n.t('schemas.customer.properties.sponsor.phone'), value: 'customer.sponsor.phone' }
          ]
      },
      {
        label: i18n.t('schemas.customer.properties.champion'),
        value: 'customer.champion',
        selectable: false,
        options: [
          { label: i18n.t('schemas.customer.properties.champion.email'), value: 'customer.champion.email' },
          { label: i18n.t('schemas.customer.properties.champion.firstName'), value: 'customer.champion.firstName' },
          { label: i18n.t('schemas.customer.properties.champion.lastName'), value: 'customer.champion.lastName' },
          { label: i18n.t('schemas.customer.properties.champion.phone'), value: 'customer.champion.phone' }
        ]
    },
    ],
  },
  {
    label: i18n.t('schemas.object.agreement.label'),
    description: i18n.t('schemas.object.agreement.description'),
    value: 'agreement',
    selectable: false,
    options: [
        { label: i18n.t('schemas.agreement.properties.mrr'), value: 'agreement.mrr' },
        { label: i18n.t('schemas.agreement.properties.plan'), value: 'agreement.plan' },
        { label: i18n.t('schemas.agreement.properties.renewalDate'), value: 'agreement.renewalDate' },
        { label: i18n.t('schemas.agreement.properties.endDate'), value: 'agreement.endDate' },
        { label: i18n.t('schemas.agreement.properties.autoRenew'), value: 'agreement.autoRenew' }
      ]
  }
];

const state = {
  fetched: {},
  fetchedPlaybooks: {},
  activations: [],
  fetchedActivations: {},
  playbooks: [],
  evolution: {},
  activationComments: {},
  stats: {},
  types: []
};

const getters = {
  getFromId: (state) => (id) => {
    return state.playbooks.find(playbook => playbook.id === id);
  },
  getAllPlaybooks: (state, getters, store, rootGetters) => {
    return state.playbooks;
  },
  getAllActivations: (state, getters, store, rootGetters) => {
    return state.activations;
  },
  getActivationsForPlaybookId: (state, getters, store, rootGetters) => playbookId => {
    return state.activations.filter(i => i.playbookId === playbookId);
  },
  getActivationsForCustomerId: (state, getters, store, rootGetters) => customerId => {
    return state.activations.filter(i => i.customerId === customerId).flat();
  },
  getActivationFromId: (state) => (playbookId, id) => {
      return state.activations?.find(a => a.id === id && a.playbookId === playbookId);
  },
  getEvolution: (state) => (customerId = 'global', period = 'WEEK') => {
    return state.evolution[period]?.[customerId];
  },
  getPlaybookFromId: (state) => (id)=> state.playbooks.find(a => a.id === id),
  getCommentsFromActivationId: (state) => (activationId)=> state.activationComments[activationId] || [],
  getStats: (state) => customerId => {
    return state.stats[customerId || 'global'];
  },
  getTriggers: (state, getters, store, rootGetters) => (category)=> {
    // Get customer custom fields
    const customerCustoms = rootGetters['customs/getAllByEntity']('CUSTOMER');
    // Get contact custom fields
    const contactCustoms = rootGetters['customs/getAllByEntity']('CONTACT');

    const triggers = TRIGGER_TYPES.filter(c => c.category === "all" || c.category === category).map(c => {
        return {
            group: c.group,
            triggers: [...c.triggers.filter(d => d.category === "all" || d.category === category)]
        };
    });
    if (customerCustoms?.length) {
        const customerTriggers = triggers.find(t => t.group === 'CUSTOMER');
        for (const custom of customerCustoms) {
            // If date or datetime -> Add date reached event
            if ([CUSTOM_DATA_TYPE.DATE, CUSTOM_DATA_TYPE.DATETIME].includes(custom.dataType)) {
                customerTriggers.triggers.push({
                    type: `CUSTOMER.CUSTOM.${custom.id}@CHANGED`,
                    category: 'all',
                    label: i18n.t(`alerts.trigger.type.CUSTOMER.CUSTOM.date.CHANGED.label`, { name: custom.name }),
                    dataType: custom.dataType,
                    mode: 'CUSTOM'
                });
                customerTriggers.triggers.push({
                    type: `CUSTOMER.CUSTOM.${custom.id}@REACHED`,
                    category: 'all',
                    label: i18n.t(`alerts.trigger.type.CUSTOMER.CUSTOM.REACHED.label`, { name: custom.name }),
                    dataType: custom.dataType,
                    mode: 'CUSTOM'
                });
            }
            else {
                customerTriggers.triggers.push({
                    type: `CUSTOMER.CUSTOM.${custom.id}@CHANGED`,
                    category: 'all',
                    label: i18n.t(`alerts.trigger.type.CUSTOMER.CUSTOM.CHANGED.label`, { name: custom.name }),
                    dataType: custom.dataType,
                    mode: 'CUSTOM'
                });
            }
        }
    }
    if (contactCustoms?.length) {
        const contactTriggers = triggers.find(t => t.group === 'CONTACT');
        for (const custom of contactCustoms) {
            // If date or datetime -> Add date reached event
            if ([CUSTOM_DATA_TYPE.DATE, CUSTOM_DATA_TYPE.DATETIME].includes(custom.dataType)) {
                contactTriggers.triggers.push({
                    type: `CONTACT.CUSTOM.${custom.id}@CHANGED`,
                    category: 'all',
                    label: i18n.t(`alerts.trigger.type.CONTACT.CUSTOM.date.CHANGED.label`, { name: custom.name }),
                    dataType: custom.dataType,
                    mode: 'CUSTOM'
                });
                contactTriggers.triggers.push({
                    type: `CONTACT.CUSTOM.${custom.id}@REACHED`,
                    category: 'all',
                    label: i18n.t(`alerts.trigger.type.CONTACT.CUSTOM.REACHED.label`, { name: custom.name }),
                    dataType: custom.dataType,
                    mode: 'CUSTOM'
                });
            }
            else {
                contactTriggers.triggers.push({
                    type: `CONTACT.CUSTOM.${custom.id}@CHANGED`,
                    category: 'all',
                    label: i18n.t(`alerts.trigger.type.CONTACT.CUSTOM.CHANGED.label`, { name: custom.name }),
                    dataType: custom.dataType,
                    mode: 'CUSTOM'
                });
            }
        }
    }
    return triggers;
  },
  getProperties: (state, getters, store, rootGetters) => {
    const properties = PROPERTIES.map(p => {
        return {
            ...p,
            properties: {...p.properties}
        };
    });
    // Add customer custom fields
    const customs = rootGetters['customs/getAllByEntity']('CUSTOMER');
    if (customs.length) {
        const customerProperties = properties.find((p => p.name === 'CUSTOMER'));
        if (customerProperties) {
            for (const custom of customs) {
                let propType = 'string';
                const options = {};
                switch (custom.dataType) {
                    case CUSTOM_DATA_TYPE.BOOLEAN:
                        propType = 'boolean';
                        break;
                    case CUSTOM_DATA_TYPE.NUMBER:
                    case CUSTOM_DATA_TYPE.CURRENCY:
                        propType = 'number';
                        break;
                    case CUSTOM_DATA_TYPE.SCORE:
                        propType = 'number';
                        options.minimum = 0;
                        options.maximum = 5;
                        break;
                    case CUSTOM_DATA_TYPE.PERCENT:
                        propType = 'number';
                        options.minimum = 0;
                        options.maximum = 100;
                        break;
                    case CUSTOM_DATA_TYPE.DATE:
                    case CUSTOM_DATA_TYPE.DATETIME:
                        propType = 'date';
                        break;
                    case CUSTOM_DATA_TYPE.ENUM:
                        propType = 'enum';
                        options.enum = custom.options?.map(o => ({ value: o.value, label: o.label }));
                        break;
                    case CUSTOM_DATA_TYPE.USER:
                        propType = 'user';
                        break;
                    default:
                        propType = 'string';
                        break;
                }
                customerProperties.properties[`customer.${custom.id}`] = { key: custom.id, label: custom.name, type: propType, ...options };
            }
        }
    }
    const contactProperties = properties.find((p => p.name === 'CONTACT'));
    if (contactProperties) {
        contactProperties.properties = getters.getContactProperties[0].properties;
    }
    return properties;
  },
  getContactProperties: (state, getters, store, rootGetters) => {
    const properties = CONTACT_PROPERTIES.map(p => {
        return {
            ...p,
            properties: {...p.properties}
        };
    });
    // Add contact custom fields
    const customs = rootGetters['customs/getAllByEntity']('CONTACT');
    if (customs.length) {
        const contactProperties = properties.find((p => p.name === 'CONTACT'));
        if (contactProperties) {
            for (const custom of customs) {
                let propType = 'string';
                const options = {};
                switch (custom.dataType) {
                    case CUSTOM_DATA_TYPE.BOOLEAN:
                        propType = 'boolean';
                        break;
                    case CUSTOM_DATA_TYPE.NUMBER:
                    case CUSTOM_DATA_TYPE.CURRENCY:
                        propType = 'number';
                        break;
                    case CUSTOM_DATA_TYPE.SCORE:
                        propType = 'number';
                        options.minimum = 0;
                        options.maximum = 5;
                        break;
                    case CUSTOM_DATA_TYPE.PERCENT:
                        propType = 'number';
                        options.minimum = 0;
                        options.maximum = 100;
                        break;
                    case CUSTOM_DATA_TYPE.DATE:
                    case CUSTOM_DATA_TYPE.DATETIME:
                        propType = 'date';
                        break;
                    case CUSTOM_DATA_TYPE.ENUM:
                        propType = 'enum';
                        options.enum = custom.options?.map(o => ({ value: o.value, label: o.label }));
                        break;
                    case CUSTOM_DATA_TYPE.USER:
                        propType = 'user';
                        break;
                    default:
                        propType = 'string';
                        break;
                }
                contactProperties.properties[`contact.${custom.id}`] = { key: custom.id, label: custom.name, type: propType, ...options };
            }
        }
    }
    return properties;
  },
  getActions: (state, getters, store, rootGetters) => {
    const connections = rootGetters['connections/getAll'];
    return ACTION_TYPES.map(group => {
        return {
            ...group,
            items: group.items.filter(i => {
                if (!i.connector && !i.feature) return true;
                let allow = true;
                if (i.connector) allow = connections?.filter(c => i.connector.includes(c.type))?.length > 0;
                if (i.feature) allow = rootGetters.isUserAllowed({ feature: i.feature });
                return allow;
            })
        };
    });
  },
  getConditions: () => {
      return CONDITION_TYPES;
  },
  getFilterStepTypes: () => {
    return FILTER_STEP_TYPES;
  },
  getTypes: () => {
    return Object.values(PLAYBOOK_TYPE);
  },
  getVariables: (state, getters, store, rootGetters) => {
      const variables = VARIABLES_SCHEMA.map(p => {
          return {
              ...p,
              options: [...p.options.map(o => ({ ...o }))]
          };
      });
      // Add customer custom fields
      const customs = rootGetters['customs/getAllByEntity']('CUSTOMER');
      if (customs.length) {
          const customerVariables = variables.find((p => p.value === 'customer'));
            if (customerVariables) {
              for (const custom of customs) {
                  switch (custom.dataType) {
                      case CUSTOM_DATA_TYPE.USER:
                           customerVariables.options.push({
                              label: custom.name,
                              value: `customer.${custom.id}`,
                              selectable: false,
                              options: [
                                  { label: i18n.t('schemas.customer.properties.custom.user.email', { name: custom.name }), value: `customer['${custom.id}'].email` },
                                  { label: i18n.t('schemas.customer.properties.custom.user.firstName', { name: custom.name }), value: `customer['${custom.id}'].firstName` },
                                  { label: i18n.t('schemas.customer.properties.custom.user.lastName', { name: custom.name }), value: `customer['${custom.id}'].lastName` },
                                  { label: i18n.t('schemas.customer.properties.custom.user.phone', { name: custom.name }), value: `customer['${custom.id}'].phone` }
                              ]
                          });
                          break;
                      default:
                          customerVariables.options.push({ label: custom.name, value: `customer['${custom.id}']` });
                          break;
                  }
              }
          }
      }
      // Add customer custom fields
      const customsContact = rootGetters['customs/getAllByEntity']('CONTACT');
      if (customsContact.length) {
          const contactVariables = variables.find((p => p.value === 'contact'));
          const recipientVariables = variables.find((p => p.value === 'recipient'));
          if (contactVariables) {
              for (const custom of customsContact) {
                  switch (custom.dataType) {
                      case CUSTOM_DATA_TYPE.USER:
                          contactVariables.options.push({
                              label: i18n.t('schemas.contact.properties.custom.field', { name: custom.name }),
                              value: `contact['${custom.id}']`,
                              selectable: false,
                              options: [
                                  { label: i18n.t('schemas.contact.properties.custom.user.email', { name: custom.name }), value: `contact['${custom.id}'].email` },
                                  { label: i18n.t('schemas.contact.properties.custom.user.firstName', { name: custom.name }), value: `contact['${custom.id}'].firstName` },
                                  { label: i18n.t('schemas.contact.properties.custom.user.lastName', { name: custom.name }), value: `contact['${custom.id}'].lastName` },
                                  { label: i18n.t('schemas.contact.properties.custom.user.phone', { name: custom.name }), value: `contact['${custom.id}'].phone` }
                              ]
                          });
                          recipientVariables.options.push({
                            label: i18n.t('schemas.recipient.properties.custom.field', { name: custom.name }),
                            value: `recipient['${custom.id}']`,
                            selectable: false,
                            options: [
                                { label: i18n.t('schemas.recipient.properties.custom.user.email', { name: custom.name }), value: `recipient['${custom.id}'].email` },
                                { label: i18n.t('schemas.recipient.properties.custom.user.firstName', { name: custom.name }), value: `recipient['${custom.id}'].firstName` },
                                { label: i18n.t('schemas.recipient.properties.custom.user.lastName', { name: custom.name }), value: `recipient['${custom.id}'].lastName` },
                                { label: i18n.t('schemas.recipient.properties.custom.user.phone', { name: custom.name }), value: `recipient['${custom.id}'].phone` }
                            ]
                        });
                          break;
                      default:
                          contactVariables.options.push({ label: i18n.t('schemas.contact.properties.custom.field', { name: custom.name }), value: `contact['${custom.id}']` });
                          recipientVariables.options.push({ label: i18n.t('schemas.recipient.properties.custom.field', { name: custom.name }), value: `recipient['${custom.id}']` });
                          break;
                  }
              }
          }
      }
      return variables;
  },
  getRecursiveBranchs: (state, getters) => (steps, branchIds) => {
    if (!branchIds) return [];
    const childBranchIds = steps.filter(s => branchIds.includes(s.branch) && s.mode === 'FILTER').flatMap(s => s.parameters?.branchs?.map(b => b.id));
    if (childBranchIds.length) {
        return [...branchIds, ...childBranchIds, ...getters.getRecursiveBranchs(steps, childBranchIds)];
    }
    return [...branchIds, ...childBranchIds];
  },
};

const actions = {

  async ensurePlaybooks({ dispatch, state }, { customerId } = {}) {
    if (!state.fetchedPlaybooks[customerId || 'global']) {
      await dispatch('fetchPlaybooks', { customerId });
    }
  },
  async ensurePlaybooksStats({ dispatch, state }, { playbookId } = {}) {
    if (!state.stats[playbookId || 'global']) {
      await dispatch('fetchPlaybooksStats', { playbookId });
    }
  },
  async ensurePlaybookId({ dispatch, state }, { playbookId }) {
    const playbook = state.playbooks.find(p => p.id === playbookId);
    if (!playbook) {
      await dispatch('fetchPlaybookById', { playbookId });
    }
  },
  async ensureActivations({ dispatch, state }, { playbookId }) {
    if (!state.fetchedActivations[playbookId]) {
      await dispatch('fetchActivations', { playbookId });
    }
  },
  async ensureActivationId({ dispatch, state }, { playbookId, activationId }) {
    if (!state.activations?.find(i => i.id === activationId && i.playbookId === playbookId)) {
      await dispatch('fetchActivationById', { playbookId, activationId });
    }
  },
  async ensureActivationsStats({ dispatch, state }, { customerId } = {}) {
    if (!state.stats[customerId || 'global']) {
      await dispatch('fetchActivationsStats', { customerId });
    }
  },
  async ensureActivationsEvolution({ dispatch, state }, { customerId, period }) {
    if (!state.evolution[period]?.[customerId || 'global']) {
      return await dispatch('fetchActivationsEvolution', { customerId, period });
    }
    return state.evolution[period]?.[customerId || 'global'];
  },
  async fetchPlaybooksStats({ dispatch, commit }, { customerId } = {}) {
    try {
      const data = await this.getters.api.get(`/playbooks/stats`);
      commit('setPlaybooksStats', { customerId, data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchActivationsStats({ dispatch, commit }, { customerId } = {}) {
    try {
      const data = {
        statIns1: 10
    };
      commit('setActivationsStats', { customerId, data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchPlaybooks({ commit }, { customerId, ctx, ref, skipStore }) {
    try {
      const query = {};
      if (ctx) {
        query.pagination = ctx;
      }

      if (ref) {
        query.params = { ref };
      }
      if (ctx?.display) {
        query.params = query.params || {};
        query.params.display = ctx.display;
      }

      const results = await this.getters.api.get(customerId ? `/customers/${customerId}/playbooks` : `/playbooks`, query);
      const playbooks = ctx ? results.data.map(fromAPIPlaybook) : results.map(fromAPIPlaybook);
    
      if (!skipStore) {
        commit('fetchedPlaybooks', { customerId, value: true });
        commit('setPlaybooks', { data: playbooks });   
      }
      return ctx ? {
        total: results.metadata?.pagination?.total || 0,
        page: results.metadata?.pagination?.page || 1,
        data: playbooks
      } : playbooks;

    } catch (error) {
      console.error(error);
      throw 'A server error has occurred';
    }
  },
  async fetchPlaybookById({ commit }, { playbookId }) {
    try {
      const data = fromAPIPlaybook(await this.getters.api.get(`/playbooks/${playbookId}`));

      commit('setPlaybook', { data: data });
      
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchActivations({ commit }, { customerId, contactId, playbookId, ctx, ref }) {
    try {
      const query = {
        pagination: { page: 1 }
      };
      if (ctx?.page) {
        query.pagination = ctx;
      }
      let url = '/playbooks-activations';
      if (playbookId) {
        url = `/playbooks/${playbookId}/activations`;
        if (contactId) url = `/contacts/${contactId}/playbooks/${playbookId}/activations`;
        else if (customerId) url = `/customers/${customerId}/playbooks/${playbookId}/activations`;
      }
      else {
        if (contactId) url = `/contacts/${contactId}/playbooks-activations`;
        else if (customerId) url = `/customers/${customerId}/playbooks-activations`;
      }

      const { metadata, data } = await this.getters.api.get(url, query);

      const activations = data.map(fromAPI);
      commit('fetchedActivations', { playbookId, value: true });
      commit('setActivations', { playbookId, data: activations, reset: true });
      if (metadata?.pagination) {
        commit('setActivationsTotal', { playbookId, total: metadata.pagination.total });
      }

      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: activations
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchActivationById({ commit }, { playbookId, activationId }) {
    try {
      const activation = fromAPI(await this.getters.api.get(`/playbooks/${playbookId}/activations/${activationId}`));

      commit('setActivation', { playbookId, data: activation });
      
      return activation;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchActivationByIds({ commit }, { playbookId, activationIds }) {
    try {
        const query = {
            params: {
                ids: activationIds.join(',')
            }
        };

      const data = await this.getters.api.get(`/playbooks/${playbookId}/activations`, query);

      const activations = data.map(fromAPI);

      commit('setActivations', { playbookId, data: activations });

      return activations;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchActivationManuals({ commit }, { ctx }) {
    try {
      const query = {};
      if (ctx) {
        query.pagination = ctx;
      }

      const data = await this.getters.api.get(`/playbooks/activations/manuals`, query);
      const activations = data?.metadata ? data.data.map(fromAPI) : data.map(fromAPI);

      commit('setActivations', { data: activations });

      return activations;
    } catch (error) {
      throw 'A server error has occurred';
    }
  }, 
  async fetchActivationComments({ commit }, { activationId, ctx } = {}) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      const data = await this.getters.api.get(`/activations/${activationId}/comments`, query);
      const activationComments = data.map(fromAPIComment);

      commit('setActivationsComments', { activationId, data: activationComments });
      
      return {
        data: activationComments
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchActivationsEvolution({ dispatch, commit }, { customerId, period = 'WEEK'}) {
    try {
      const data = await this.getters.api.get(`/playbooks-activations/evolution`, {
        params: {
            by: period.toLowerCase(),
            customerId
        }
      });
      commit('setActivationsEvolution', { customerId, period, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit, dispatch }, { playbook }) {
    try {
        playbook.status = 'INACTIVE';
        
        const data = await this.getters.api.post(`/playbooks`, { data: toAPIPlaybook(playbook) });
        
        const model = fromAPIPlaybook(data);
        if (model.steps) model.steps = await Promise.all(model.steps.map(async s => {
            return {
                ...s,
                valid: await dispatch('isValidStep', { step: s })
            };
        }));
        model.valid = (model.steps || []).every(s => s.valid);
        commit('setPlaybook', { data: model });
        return model;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { playbookId, playbook }) {
     try {
        const data = await this.getters.api.patch(`/playbooks/${playbookId}`, { data: toAPIPlaybook(playbook) });
        // TODO remove
        playbook.id = playbookId;
        commit('setPlaybook', { data: playbook });
      } catch (error) {
        if (error.response?.status === 400 && error.response.data?.data?.code === 'ERR_VALIDATION') {
            if (playbook.status === 'ACTIVE') {
                EventsBus.emit('notify', {
                    title: i18n.t('commons.ope.error'),
                    variant: 'danger',
                    text: i18n.t(`playbooks.ope.updated.failed.active`)
                });
            }
        }
        throw 'A server error has occurred';
      }
  },
  async duplicate({ dispatch }, { playbook }) {
    try {
        const duplicatedPlaybook = JSON.parse(JSON.stringify(playbook));
        duplicatedPlaybook.title = `${duplicatedPlaybook.title} (Copy)`;
        delete duplicatedPlaybook.id;
        delete duplicatedPlaybook.createdAt;
        delete duplicatedPlaybook.updatedAt;
        delete duplicatedPlaybook.ownerId;
        delete duplicatedPlaybook.lastUserId;
        delete duplicatedPlaybook.nbActivations;
        delete duplicatedPlaybook.activations;
        if (duplicatedPlaybook.trigger) {
            delete duplicatedPlaybook.trigger.id;
            delete duplicatedPlaybook.trigger.createdAt;
            delete duplicatedPlaybook.trigger.updatedAt;
        }
        if (duplicatedPlaybook.steps?.length) {
            duplicatedPlaybook.steps = duplicatedPlaybook.steps.map(s => ({
                ...s,
                id: generateUID(5)
            }));
        }

        const model = dispatch('add', {
            playbook: duplicatedPlaybook
        });
        return model;
     } catch (error) {
       throw 'A server error has occurred';
     }
 },
  async remove({ commit }, { playbookId }) {
    try {
        await this.getters.api.delete(`/playbooks/${playbookId}`);
        commit('removePlaybook', { playbookId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async disable({ commit, getters }, { playbookId, status, disableActivations }) {
    try {
        const playbook = getters.getFromId(playbookId);
        if (!playbook) return;
        playbook.status = status;
       const data = await this.getters.api.patch(`/playbooks/${playbookId}`, { data: toAPIPlaybook(playbook), params: disableActivations ? { 'stop-activations': true } : null });
       // TODO remove
       playbook.id = playbookId;
       commit('setPlaybook', { data: playbook });
     } catch (error) {
       if (error.response?.status === 400 && error.response.data?.data?.code === 'ERR_VALIDATION' && status === 'ACTIVE') {
           EventsBus.emit('notify', {
               title: i18n.t('commons.ope.error'),
               variant: 'danger',
               text: i18n.t(`playbooks.ope.updated.failed.active`)
           });
       }
       throw 'A server error has occurred';
     }
 },
  async activate({ commit }, { playbookId, customerId, options, params, conditions, contactConditions }) {
    try {
        if (customerId) {
            const data = fromAPI(await this.getters.api.post(`/playbooks/${playbookId}/activate`, { data: { customerId, options, params, source: 'MANUAL' } }));
            commit('setActivation', { playbookId, data });
            return data;
        }
        else {
            const activateSearchId = await this.getters.api.post(`/playbooks/${playbookId}/activate-search`, {
                data: {
                    conditions,
                    contactConditions
                }
            });
            console.info(activateSearchId);
            if (activateSearchId) {
                let status = null;
                let filterResult = null;
                while (!['success', 'error'].includes(status?.toLowerCase?.())) {
                    await sleep(2000);
                    filterResult = await this.getters.api.post(`/playbooks/activate-search/${activateSearchId}/status`);
                    status = filterResult.status;
                    console.info(status);
                }
                console.info(filterResult?.result?.results?.last?.data?.matched);
                return activateSearchId;
            }
        }
      } catch (error) {
        if (error.response?.status === 400 && error.response.data?.data?.code === 'ERR_VALIDATION') {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.error'),
                variant: 'danger',
                text: i18n.t(`playbooks.activations.ope.failed.${error.response.data.data.data[0]}`)
            });
        }
        throw 'A server error has occurred';
      }
  },
  async updateActivation({ commit }, { playbookId, activationId, activation }) {
    try {
        await this.getters.api.patch(`/playbooks/${playbookId}/activations/${activationId}`, { data: toAPI(activation) });
        // TODO remove
        activation.id = activationId;
        commit('setActivation', { playbookId, data: activation });
        return activation;
      } catch (error) {
        throw 'A server error has occurred';
      }
    
  },
  async removeActivation({ commit }, { playbookId, activationId }) {
    try {
        await this.getters.api.delete(`/playbooks/${playbookId}/activations/${activationId}`);
        commit('removeActivation', { playbookId, activationId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateActivationStatus({ commit }, { playbookId, activationId, status, oldStatus }) {
    try {
        await this.getters.api.post(`/playbooks/${playbookId}/activations/${activationId}/status`, { data: { status } });

        commit('setActivationStatus', { playbookId, activationId, status, oldStatus });
        return true;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateActivationStep({ commit }, { playbookId, activationId, stepId, step }) {
    try {
        await this.getters.api.post(`/playbooks/${playbookId}/activations/${activationId}/steps/${stepId}`, { data: step });
        // Split manual case stepId:branchId
        const realStepId = stepId?.split(':')[0];
        commit('setActivationStep', { playbookId, activationId, stepId: realStepId, step });
        return true;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async retryActivationStep({ commit }, { playbookId, activationId, stepId }) {
    try {
        await this.getters.api.post(`/playbooks/${playbookId}/activations/${activationId}/steps/${stepId}/retry`);
        commit('setActivationStatus', { playbookId, activationId, status: 'RUNNING' });
        commit('setActivationStep', { playbookId, activationId, stepId, step: {
            status: 'RUNNING',
            endAt: null
        }});
        return true;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async skipActivationStep({ commit }, { playbookId, activationId, stepId }) {
    try {
        await this.getters.api.post(`/playbooks/${playbookId}/activations/${activationId}/steps/${stepId}/skip`);
        commit('setActivationStep', { playbookId, activationId, stepId, step: {
            status: 'SKIPPED',
            endAt: new Date()
        }});
        return true;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async addComment({ commit }, { activationId, activationComment }) {
    try {
        const data = await this.getters.api.post(`/activations/${activationId}/comments`, { data: toAPIComment(activationComment) });
        const comment = fromAPIComment(data);
        commit('setActivationComment', { activationId, data: comment });
        return comment;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateComment({ commit }, { activationId, commentId, activationComment }) {
    try {
        const data = await this.getters.api.patch(`/activations/${activationId}/comments/${commentId}`, { data: toAPIComment(activationComment) });
        // TODO remove
        activationComment.id = commentId;
        commit('setActivationComment', { activationId, data: activationComment });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeComment({ commit }, { activationId, commentId }) {
    try {
        await this.getters.api.delete(`/activations/${activationId}/comments/${commentId}`);
        commit('removeActivationComment', { activationId, commentId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async isValid({ dispatch, state }, { playbook } = {}) {
    if (!playbook?.trigger?.type && playbook?.trigger?.type !== 'NONE') return false;
    return playbook.steps?.length && playbook.steps.every((s) => s.valid);
  },
  async isValidStep({ dispatch, state }, { step } = {}) {
    if (!step.type) return false;
    if (PLAYBOOK_STEP_PARAMETERS_SCHEMA[step.type]) {
        const { errors } = validateJSONSchema(step.parameters, PLAYBOOK_STEP_PARAMETERS_SCHEMA[step.type], `step.${step.id}.`);
        if (errors?.length) console.info(errors, step.parameters);
        return errors?.length === 0;
    }
    return false;
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  },
  cloneActivation({ dispatch, state }, { activation } = {}) {
    return fromAPI(JSON.parse(JSON.stringify(activation || {})));
  },
};

const mutations = {
  fetched(state, { customerId, value }) {
    state.fetched[customerId] = value;
  },
  fetchedPlaybooks(state, { customerId, value }) {
    state.fetchedPlaybooks[customerId || 'global'] = value;
  },
  setPlaybook(state, { data }) {
    const index = state.playbooks.findIndex(c => c.id === data.id);
    if (index > -1) {
      if (state.stats.global && typeof data.status !== 'undefined' && state.playbooks[index].status !== data.status) {
        if (data.status === 'ACTIVE') state.stats.global.global.active++;
        else state.stats.global.global.active--;
      }
      Vue.set(state.playbooks, index, { ...data});
    }
    else {
      state.playbooks.push(data);
      if (state.stats.global) {
          state.stats.global.global.total++;
          state.stats.global.global.active++;
      }
    }
  },
  removePlaybook(state, { playbookId }) {
    const index = state.playbooks.findIndex(c => c.id === playbookId);
    if (index > -1) {
        if (state.stats.global) {
            state.stats.global.global.total--;
            if (state.playbooks[index].status === 'ACTIVE') state.stats.global.global.active--;
        }
        state.playbooks.splice(index, 1);
    }
  },
  setPlaybooks(state, { data }) {
    state.playbooks = data;
  },
  setPlaybooksStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId || 'global', data);
  },
  fetchedActivations(state, { playbookId, value }) {
    state.fetchedActivations[playbookId] = value;
  },
  setActivation(state, { playbookId, data }) {
    const index = state.activations.findIndex(c => c.id === data.id && c.playbookId === playbookId);
    if (index > -1) {
      Vue.set(state.activations, index, { ...data});
    }
    else {
      state.activations.push(data);
      const playbook = state.playbooks.find(c => c.id === playbookId);
      if (playbook) {
         playbook.nbActivations++;
      }
      if (state.stats.global) state.stats.global.activations.running++;
    }
    // Vue.set(state.activations[customerId], state.activations[clientId][customerId].length, data);
  },
  removeActivation(state, { playbookId, activationId }) {
    const index = state.activations.findIndex(c => c.id === activationId && c.playbookId === playbookId);
    if (index > -1) {
        if (state.stats.global) {
            if (state.activations[index].status === 'RUNNING' && state.stats.global.activations.running > 0) state.stats.global.activations.running--;
            else if (state.activations[index].status === 'WAITING' && state.stats.global.activations.waiting > 0) state.stats.global.activations.waiting--;
            else if (state.activations[index].status === 'PAUSED' && state.stats.global.activations.paused > 0) state.stats.global.activations.paused--;
        }
        const playbook = state.playbooks.find(c => c.id === playbookId);
        if (playbook?.nbActivations > 0 && ['RUNNING', 'PAUSED', 'WAITING'].includes(state.activations[index].status)) {
            playbook.nbActivations--;
        }
        state.activations.splice(index, 1);
    }
  },
  setActivationStatus(state, { playbookId, activationId, status, oldStatus }) {
    const index = state.activations.findIndex(c => c.id === activationId && c.playbookId === playbookId);
    if (index > -1) {
        state.activations[index].status = status;
        if (state.stats.global) {
            if (oldStatus === 'PAUSED') {
                state.stats.global.activations.running++;
                if (state.stats.global.activations.paused > 0) state.stats.global.activations.paused--;
            }
            else {
                if (state.stats.global.activations.running > 0 && oldStatus === 'RUNNING') state.stats.global.activations.running--;
                else if (state.stats.global.activations.waiting > 0 && oldStatus === 'WAITING') state.stats.global.activations.waiting--;
                state.stats.global.activations.paused++;
            }
        }
    }
  },
  setActivationStep(state, { playbookId, activationId, stepId, step }) {
    const index = state.activations.findIndex(c => c.id === activationId && c.playbookId === playbookId);
    if (index > -1) {
        const curStep = state.activations[index].steps.find(s => s.id === stepId);
        if (curStep) {
            curStep.status = step.status;
            curStep.results = step.results;
            curStep.startAt = step.startAt;
            curStep.endAt = step.endAt;
        }
    }
  },
  setActivations(state, { playbookId, data, reset }) {
      const ids = data.map(a => a.id);
      const activations = reset ? state.activations.filter(a => a.playbookId !== playbookId) : state.activations.filter(a => a.playbookId !== playbookId && !ids.includes(a.id));
    state.activations = activations.concat(data);
  },
  setActivationsTotal(state, { playbookId, total }) {
    const playbook = state.playbooks.find(c => c.id === playbookId);
      if (playbook) {
         playbook.nbActivations = total;
      }
  },
  setActivationsStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId || 'global', data);
  },
  setActivationsEvolution(state, { customerId, period, data }) {
    if (!state.evolution[period]) Vue.set(state.evolution, period, {});
    Vue.set(state.evolution[period], customerId || 'global', data);
  },
  setActivationsComments(state, { activationId, data }) {
      Vue.set(state.activationComments, activationId, data);
  },
  setActivationComment(state, { activationId, data }) {
    if (!state.activationComments[activationId]) Vue.set(state.activationComments, activationId, []);
    const index = state.activationComments[activationId].findIndex(c => c.id === data.id);
    if (index > -1) {// Update playbook activation customerId
        Vue.set(state.activationComments[activationId], index, { ...data });
    }
    else {
      state.activationComments[activationId].push(data);
    }
  },
  removeActivationComment(state, { activationId, commentId }) {
    const index = state.activationComments[activationId]?.findIndex(c => c.id === commentId);
    if (index > -1) {
        state.activationComments[activationId].splice(index, 1);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (!json.priority) json.priority = null;
    if (json.completedAt) json.completedAt = dayjs(json.completedAt).toDate();
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    if (json.startAt) json.startAt = dayjs(json.startAt).toDate();
    if (json.endAt) json.endAt = dayjs(json.endAt).toDate();
    return json;
}

function toAPI(activation) {
    const json = { ...activation };
    // if (json.dueDate) json.dueDate = dayjs(json.dueDate).format('YYYY-MM-DD');
    json.customerId = json.customerId || null;
    json.userId = json.userId || null;
    json.contactId = json.contactId || null;
    if (json.recurring !== null && typeof json.recurring !== 'undefined') json.recurring = parseInt(json.recurring, 10);
    delete json.dueTime;
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    delete json.nbActivations;
    return json;
}

function fromAPIPlaybook(json) {
    if (json.activations) {
        json.activations = json.activations.map(fromAPI);
    }
    else {
      json.activations = [];
    }
    json.trigger = json.trigger || {};
    json.stop = json.stop || {};
    json.steps = json.steps || [];

    // Reorder steps
    if (json.steps?.length) {
        const byBranch = json.steps.reduce((prev, cur) => {
            if (!prev[cur.branch]) prev[cur.branch] = [];
            prev[cur.branch].push(cur);
            return prev;
        }, {});
        for (const branch of Object.keys(byBranch)) {
            const rankedSteps = byBranch[branch].sort((a, b) => a.index - b.index);
            rankedSteps.forEach((s, i) => s.index = i);
        }
        json.steps.sort((a, b) => {
            if (a.branch === b.branch) return a.index < b.index;
            return a.branch === 'main' ? -1 : b.branch === 'main' ? 1 : a.branch?.localeCompare(b.branch);
        });
    }

    json.options = json.options || {};
    json.periodicity = json.periodicity || {};
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    if (json.startAt) json.startAt = dayjs(json.startAt).toDate();
    if (json.endAt) json.endAt = dayjs(json.endAt).toDate();
    return json;
}

// function fromAPIStepsRank(current, steps) {
//     if (!current.to?.length) return current;
//     const next = steps.filter(s => current.to.includes(s.id));
//     if (next.length) {
//         current.steps = next.map(n => {
//             return fromAPIStepsRank(n, steps);
//         });
//     }
//     return current;
// }

function toAPIPlaybook(playbook) {
    const json = { ...playbook };
    json.customerId = json.customerId || null;
    json.options = json.options || {};
    json.periodicity = json.periodicity || {};
    if (json.steps?.length) {
        json.steps = json.steps.map(s => {
            s.parameters = s.parameters || {};
            return s;
        });
    }
    delete json.stepsVersions;
    delete json.nbActivationsVersion;
    return json;
}

function fromAPIComment(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPIComment(comment) {
    const json = { ...comment };
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations,
  init: (ctx, store) => {
    globalStore = store;
  }
};
