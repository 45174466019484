var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container app-theme-white" },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [
          _c("Header", {
            attrs: {
              breadcrumb: _vm.breadcrumbLinks,
              collapsed: _vm.collapsed
            },
            on: {
              "update:collapsed": function($event) {
                _vm.collapsed = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [
          _c("Sidebar", {
            attrs: { collapsed: _vm.collapsed },
            on: {
              "update:collapsed": function($event) {
                _vm.collapsed = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-main__outer" },
        [
          _c("div", { staticClass: "app-main__inner" }, [_vm._t("default")], 2),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in", appear: "" } },
            [_c("Footer")],
            1
          )
        ],
        1
      ),
      _vm.helpComponent && _vm.$isInternal()
        ? _c(
            "div",
            { staticClass: "help-wrapper" },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in", appear: "" } },
                [
                  _c(
                    "Helpbar",
                    {
                      attrs: {
                        title: _vm.helpTitle,
                        "auto-open": _vm.helpAutoOpen,
                        "open-key": _vm.helpOpenKey
                      }
                    },
                    [_c(_vm.helpComponent, { tag: "component" })],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.helpbar",
                      modifiers: { helpbar: true }
                    }
                  ],
                  staticClass: "help-btn",
                  attrs: {
                    pill: "",
                    size: "lg",
                    "bg-variant": "white",
                    variant: "outline-primary",
                    title: _vm.helpTitle
                  }
                },
                [_c("b-icon", { attrs: { scale: "2", icon: "info" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.toasts, function(toast) {
        return _c(toast, { key: toast, tag: "component" })
      }),
      _vm.modal
        ? _c(_vm.modal, { tag: "component", attrs: { notify: _vm.notify } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }