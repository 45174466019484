/* eslint-disable */
import i18n from '@/i18n';

export const isEmailValid = function (value) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const generateUID = function (nb) {
  nb = nb || 10;
  var mask = '';
  for (var i = 0; i < nb; i++) {
    mask += 'x';
  }
  return mask.replace(/x/g, function () {
    return Math.floor(Math.random() * 16)
      .toString(16)
      .toLowerCase();
  });
};

export function multilineEllipsis(value, maxLength = 7) {
  return value.split(' ')
    .map(v => v.length > maxLength ? `${v.substr(0, maxLength)}…` : v)
    .join('\n');
}

export function formatNumberRange(value, config) {
  if (!value || value.length !== 2 || (!Number.isFinite(value[0]) && !Number.isFinite(value[1]))) {
    return '-';
  } else if (!Number.isFinite(value[0])) {
    return `≤ ${value[1]}${config.unit ? ' ' + config.unit : ''}`;
  } else if (!Number.isFinite(value[1])) {
    return `≥ ${value[0]}${config.unit ? ' ' + config.unit : ''}`;
  } else {
    return `${value[0]} - ${value[1]}${config.unit ? ' ' + config.unit : ''}`;
  }
}

export function formatDateRange(value) {
  if (!value || value.length !== 2 || (value[0] === null && value[1] === null)) {
    return '-';
  }
  if (value[1] === null) {
    return `${i18n.t('commons.after')} ${i18n.d(new Date(value[0]), 'short')}`;
  }
  if (value[0] === null) {
    return `${i18n.t('commons.before')}  ${i18n.d(new Date(value[1]), 'short')}`;
  }
  return `${i18n.d(new Date(value[0]), 'short')} - ${i18n.d(new Date(value[1]), 'short')}`;
}

export function formatBool(value, config) {
  if (value) {
    return config.hasOwnProperty('trueLabel') ? config.trueLabel : i18n.t('commons.yes');
  } else if (value === false) {
    return config.hasOwnProperty('falseLabel') ? config.falseLabel : i18n.t('commons.no');
  } else {
    return '-';
  }
}

export function formatEnum(value, config) {
  // Get the label of the enum in the config
  var themeArray = value !== null && value.length > 0 ? value.map(x => config.options.find(y => y.value === x)?.label || i18n.t('commons.filter-invalid-value')) : ['-'];
  return themeArray.join(', ');
}

const typeFormater = {
  daterange: formatDateRange,
  numberrange: formatNumberRange,
  bool: formatBool,
  enum: formatEnum
};


export function formatToString(value, config) {
  return typeFormater.hasOwnProperty(config.type) ? typeFormater[config.type](value, config) : `${value};`
}

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toEllipsis(string, size, dot = true) {
    if(string.length > size && dot) return string.substring(0, size) + '...';
    return string;
}
export function removeAccents(string) {
    if (!string?.length) return '';
    return string.toLowerCase().normalize("NFD").replace(/ /gi, '-').replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s\][^,^-]/gi, '').replace(/\^/gi, '');
}