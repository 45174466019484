<template>
    <div class="notification-badge">
        <b-avatar 
        :badge="hasNewNotifications"
        badge-variant="danger"
        :id="`notification-avatar-${_uid}`"
        class="mr-1 -pointer p-1 app-notification-logo" 
        variant="primary"
        size="m" 
        icon="bell-fill">
        </b-avatar>
        <pulser v-if="animatePulse" class="pulser" />
        <b-popover :target="`notification-avatar-${_uid}`" placement="bottomleft" class="popover">
            <div v-click-outside="onClickOutside" class="p-2">
                <header class="d-flex justify-content-between align-items-center mb-2">
                    <h5 class="m-0">{{ $t("notifications.popover.title") }}</h5>
                    <table-settings-notification 
                    :show.sync="tableSettingShow"/>
                </header>
                <main>
                    <b-tabs v-model='activeTab'>
                        <b-tab>
                            <template #title>
                                <div class="px-2 d-flex flex-row justify-content-center align-items-center">
                                    {{ $t('notifications.tabs.new') }} 
                                    <span class="notification-number ml-1" v-if="userStats.new">{{ userStats.new }}</span>
                                </div>
                            </template>
                            <notification-list-view 
                                v-if="activeTab === 0"
                                :notificationType="notificationsType.NEW"
                                :notifications="newNotifications"
                                :noNotificationMessage="$t('notifications.popover.new.empty')"
                                :show.sync="notificationListShow"
                                @disabled-popover="disabledPopover"
                            />
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <div class="px-2">
                                    {{ $t('notifications.tabs.all') }}
                                </div>
                            </template>
                            <notification-list-view 
                                v-if="activeTab === 1"
                                :notificationType="notificationsType.ALL"
                                :notifications="allNotifications"
                                :noNotificationMessage="$t('notifications.popover.all.empty')"
                                :show.sync="notificationListShow"
                                @disabled-popover="disabledPopover"
                            />
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <div class="px-2">
                                    {{ $t('notifications.tabs.archived') }}
                                </div>
                            </template>
                            <notification-list-view 
                                v-if="activeTab === 2"
                                :notificationType="notificationsType.ARCHIVED"
                                :notifications="archivedNotifications"
                                :noNotificationMessage="$t('notifications.popover.archived.empty')"
                                :show.sync="notificationListShow"
                                @disabled-popover="disabledPopover"
                            />
                        </b-tab>
                    </b-tabs>
                </main>
                
            </div>
        </b-popover>
    </div>
</template>
<script>
import { has } from 'lodash';
import NotificationListView from './NotificationListView.vue';
import TableSettingsNotification from './TableSettingsNotification.vue';
import { notificationsType } from '../../../store/modules/notifications';
import EventsBus from '@/libs/EventsBus';
import Pulser from "@/modules/commons/others/Pulser";

export default {
    data() {
        return {
            notificationListShow: false,
            tableSettingShow: false,
            animatePulse: false,
            activeTab: 0,
            notificationsType: notificationsType,
            badgeRef: null
        };
    },
    components: {
        NotificationListView,
        TableSettingsNotification,
        Pulser
    },
    mounted() {
        EventsBus.on('notification/user/create',async () => {
            await this.animateBadge();
        });
    },
    destroyed() {
        EventsBus.off('notification/user/create');
    },
    computed: {
        userStats() {
            return this.$store.getters['notifications/getUserStats'];
        },
        hasNewNotifications() {
            return has(this.userStats, 'new') && this.userStats.new > 0;
        },
        currentUserId() {
            return this.$store.getters["users/currentUserId"];
        },
        newNotifications() {
            return this.$store.getters['notifications/getNewNotifications'];
        },
        allNotifications() {
            return this.$store.getters['notifications/getAllNotifications'];
        },
        archivedNotifications() {
            return this.$store.getters['notifications/getArchivedNotifications'];
        }
    },
    methods: {
        onClickOutside() {
            if (!(this.notificationListShow || this.tableSettingShow)) {
                this.disabledPopover();
            }     
        },
        disabledPopover() {
            this.tableSettingShow = false;
            this.$root.$emit('bv::hide::popover', `notification-avatar-${this._uid}`);
        },
        async animateBadge() {
            this.animatePulse = true;
            setTimeout(() => {
                this.animatePulse = false;
            }, 10000);
        }
    },
    watch: {
        async currentUserId(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                await this.$store.dispatch('notifications/fetchUserStats');
                if (this.userStats?.news > 0) {
                    this.$root.$emit('bv::show::popover', `notification-avatar-${this._uid}`);
                }
            }
        },
        hasNewNotifications(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                if (newVal === true) {
                    this.$nextTick(() => {
                        this.animateBadge();
                    });
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.notification-number{
    font-size: 70%;
    background-color: rgba(var(--app-link-color-rgb), 0.2);
    color: var(--app-link-color-hex); 
    border-radius: 50%;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.3rem; 
    height: 1.3rem;
}

.notification-badge {
    .pulser {
        position: absolute;
        right: -5px;
        bottom: 9px;
    }
}

.popover{
    min-width: 600px;   
}
</style>

<style lang="scss">

.notification-badge {
    .b-avatar .b-avatar-badge {
        min-height: 1.3em;
        min-width: 1.3em;
    }
}

</style>