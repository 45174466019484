/* eslint-disable */
import axios from 'axios';
import { BaseInterceptor } from './BaseInterceptor';
import EventsBus from '@/libs/EventsBus';
import i18n from '@/i18n';
export class SecurityInterceptor extends BaseInterceptor {


  getType() {
    return 'SecurityInterceptor';
  }

  requestInterceptor() {
    return {
      request: config => {
        config = this.setRequestHeaders(config);
        config = this.setClientId(config);
        return config;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  responseInterceptor() {
    return {
      response: response => {
        return response;
      },
      error: async error => {
        const originalRequest = error.config;
        const errorResponse = error.response;
        if (!originalRequest?.skipAuthErrorInterceptor) {
          if (errorResponse.status === 401) {
            this.detachResponseInterceptor();
            try {
              const result = await this.$store.dispatch('users/logout', { url: location.href });
              if (!result) return;
              this.attachResponseInterceptor();
              this.setRequestHeaders(originalRequest);
              this.setClientId(originalRequest);
              return await axios(originalRequest);
            }
            catch (err) {
              console.error(err)
              this.attachResponseInterceptor();
              await this.destroyToken();
              this.$router.push({ name: 'login' });
              throw error;
            }
          }
          else if (errorResponse.status === 403) {
            if (errorResponse.data.data.data === 'ROLE_DENIED') {
                EventsBus.emit('notify', {
                    title: i18n.t('commons.ope.error'),
                    variant: 'danger',
                    text: i18n.t(`commons.403.ROLE_DENIED.error`)
                });
            }
          }
        }
        throw error;
      }
    };
  }
  setClientId(config) {
    if (config && !config.skipClientId) {
      if (!config.params) {
        config.params = {};
      }
      if (!config.params.clientId) {
        config.params.clientId = this.$store.getters['clients/currentClientId'];
      }
    }
    return config;
  }
  setRequestHeaders(config) {
    const token = this.$store.getters['users/token'];
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['Content-type'] = 'application/json';
    config.headers['Source'] = 'skalin/ui';
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    // TODO: check if have any lang saved in localStorage, if not browser lang or default
    config.headers['Accept-Language'] = this.$store.getters['users/language'] || 'en';
    // Add SessionId
    if (this.$store.getters['sessionId']?.length) config.headers['Session-Id'] = this.$store.getters['sessionId'];
    return config;
  }
  async destroyToken() {
    try {
      await this.$store.dispatch('users/logout');
    }
    catch (error) {
      console.error(error);
    }
  }
}
