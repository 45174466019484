/* eslint-disable */
import { BaseInterceptor } from './BaseInterceptor';

export class EnvInterceptor extends BaseInterceptor {

  getType() {
    return 'EnvInterceptor';
  }

  requestInterceptor() {
    return {
      request: config => {
        config = this.setEnv(config);
        return config;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  responseInterceptor() {
    return {
      response: response => {
        return response;
      },
      error: async error => {
        throw error;
      }
    };
  }
  setEnv(config) {
    const env = this.$store.getters['env'];
    if (env) {
        config.params = config.params || {};
        config.params.env = env;
    }
    return config;
  }
}
