<template>
    <div>
        <b-avatar 
            icon="sliders" 
            :id="`notification-filters-${_uid}`"
            size="2rem"
            class="filter-badge p-1 -pointer"
            @click="show = !show"
            :badge="hasFilters"
            badge-top
            badge-variant="danger"
        />
        
        <b-popover 
        :target="`notification-filters-${_uid}`" 
        placement="bottomleft" 
        class="popover" 
        :title="$t('notifications.filters.title')"
        :show.sync="showed"
        >
            <div v-click-outside="onCancel" class="pb-4">
                <b-tabs>
                    <b-tab class="pt-2">
                        <template #title>
                            {{ $t('notifications.filters.tab.label.entities') }}
                        </template>
                        <b-row align-v="center" no-gutters
                            v-for="(option, index) in entitiesFilterList"
                            :key="index"
                            :id="'checkbox-line-entities-' + index"
                            class="mb-2 p-2 rounded row-item"
                            >
                            <b-col>
                                <div class="column-name d-flex">
                                    {{ option.text }}
                                </div>
                            </b-col>
                            <b-col class="text-right">
                                <b-form-checkbox
                                :key="index"
                                :id="'checkbox-entities-' + index"
                                v-model="option.checked"
                                >
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab class="pt-2">
                        <template #title>
                            {{ $t('notifications.filters.tab.label.events') }}
                        </template>
                        <b-row align-v="center" no-gutters
                            v-for="(option, index) in eventsFilterList"
                            :key="index"
                            :id="'checkbox-line-events-' + index"
                            class="mb-2 p-2 rounded row-item"
                            >
                            <b-col>
                                <div class="column-name d-flex">
                                    {{ option.text }}
                                </div>
                            </b-col>
                            <b-col class="text-right">
                                <b-form-checkbox
                                :key="index"
                                :id="'checkbox-events-' + index"
                                v-model="option.checked"
                                >
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
                <b-row class="text-center mt-3">
                    <b-col>
                        <b-button @click="onSave" size="sm" variant="primary" class="mr-2">
                            {{ $t('commons.action.save') }}
                        </b-button>
                        <b-button @click="onCancel" size="sm" variant="outline-danger">
                            {{ $t('commons.action.cancel') }}
                        </b-button>
                    </b-col>
                </b-row>
                <a class="-pointer reset-link" @click="handleResetSettings">
                    <b-icon icon="arrow-clockwise" /> {{ $t('commons.table-settings-reset') }}
                </a>
            </div>
        </b-popover>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    data() {
        return {
            filters: [],
            showed: false,
            checked: false
        };
    },
    mounted() {
        this.filters = _.cloneDeep(this.defaultFilters);
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        entitiesFilterList() {
            return this.filters.filter(filter => filter.value.column === 'notificationType');
        },
        eventsFilterList() {
            return this.filters.filter(filter => filter.value.column === 'eventType');
        },
        hasFilters() {
            return this.$store.getters['notifications/getHasFilters'];
        },
        defaultFilters() {
            return this.$store.getters['notifications/getdefaultFilters'];
        },
    },
    watch: {
        showed(value) {
            this.$emit('update:show', value);
        }
    },
    methods: {
        handleResetSettings() {
            this.filters = this.filters.map(filter => ({ ...filter, checked: true }));
        },
        onSave() {
            this.$store.dispatch('notifications/setFilters', this.filters);
            this.$root.$emit('bv::hide::popover', `notification-filters-${this._uid}`);
        },
        onCancel() {
            this.filters = _.cloneDeep(this.defaultFilters);
            this.$root.$emit('bv::hide::popover', `notification-filters-${this._uid}`);
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-badge{
    background-color: transparent;
    &:hover {
        background-color: rgba(1, 1, 1, 0.06);
    }
}

.settings-columns {
    max-height: 350px;
    overflow-x: auto;
}

.popover{
    width: 300px;
}

.checkbox-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    
}

.column-name {
    color: #5A5A5A;
}

.reset-link {
    font-size: 11px;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.row-item{
    background-color: #F5F5F5;
}


</style>