import EventsBus from '@/libs/EventsBus';
import Vue from 'vue';
import dayjs from 'dayjs';
import i18n from '@/i18n';

const state = {
  fetched: {},
  fetchedContact: {},
  interactions: {},
  contactInteractions:{},
  interactionsIds: {},
  stats: {},
  evolution: {},
  lastTouchStats: {},
  goalStats: {},
  comments: {},
  goals: null
};

const getters = {
  getAll: (state, getters, store, rootGetters) => customerId => {
      return state.interactions[customerId] || [];
  },
  getContactInteractions: (state, getters, store, rootGetters) => contactId => {
    return state.contactInteractions[contactId] || [];
},
  getFromId: (state, getters) => (customerId, id )=> getters.getAll(customerId).find(a => a.id === id),
  getById: (state, getters) => ( id ) => {
    return state.interactionsIds[id];
  },
  getStats: (state) => customerId => {
    return state.stats[customerId];
  },
  getEvolution: (state) => (customerId, period = 'MONTH') => {
    return state.evolution[period]?.[customerId];
  },
  getLastTouchStats: (state, getters, store, rootGetters) => {
    return state.lastTouchStats[rootGetters.currentClientId];
},
  getGoalFromId: (state, getters) => id => getters.getGoals.find(a => a.id === id),
  getGoals: (state) => {
    return state.goals || [];
  },
  getGoalStats: (state) => customerId => {
    return state.goalStats[customerId];
  },
  getCommentsFromId: (state) => (id)=> state.comments[id] || [],
  getIcon: () => (type) => {
        switch (type) {
            case 'EMAIL': return 'envelope-open';
            case 'NOTE': return 'comment-alt';
            case 'CALL': return 'phone';
            case 'MEET': return 'users';
            case 'APP': return 'mobile-alt';
            case 'TICKET': return 'ticket-alt';
            default: return 'mobile-alt';
        }
    }
};

const actions = {

  async ensureInteractions({ dispatch, state }, { customerId }) {
    if (!state.fetched[customerId]) {
      await dispatch('fetchInteractions', { customerId });
    }
  },
  async ensureInteractionId({ dispatch, state }, { customerId, interactionId }) {
    if (!state.interactionsIds[interactionId]) {
      await dispatch('fetchInteractionById', { customerId, interactionId });
    }
    return state.interactionsIds[interactionId];
  },
  async ensureInteractionsStats({ dispatch, state }, { customerId }) {
    if (!state.stats[customerId]) {
      await dispatch('fetchInteractionsStats', { customerId });
    }
  },
  async ensureInteractionsEvolution({ dispatch, state }, { customerId, period }) {
    if (!state.evolution[period]?.[customerId]) {
      return await dispatch('fetchInteractionsEvolution', { customerId, period });
    }
    return state.evolution[period]?.[customerId];
  },
  async ensureInteractionLastTouchStats({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.lastTouchStats[clientId]) {
      await dispatch('fetchInteractionLastTouchStats');
    }
  },
  async ensureInteractionsGoals({ dispatch, state }) {
    if (!state.goals) {
      await dispatch('fetchInteractionsGoals');
    }
  },
  async ensureInteractionsGoalStats({ dispatch, state }, { customerId }) {
    if (!state.goalStats[customerId]) {
      await dispatch('fetchInteractionsGoalStats', { customerId });
    }
  },
  async fetchInteractions({ dispatch, commit }, { customerId, ctx, ref }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      if (ref) {
        query.params = { ref };
      }
      const { metadata, data } = await this.getters.api.get(`/customers/${customerId}/interactions`, query);
      const interactions = data.map(fromAPI);
      commit('fetched', { customerId, value: true });
      commit('setInteractions', { customerId, data: interactions });
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: interactions
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },

  async listInteractionByContactId({ dispatch, commit }, { contactId, ctx }) {
    try {
        const query = {};
        if (ctx?.page) {
            query.pagination = ctx;
        }
        const { metadata, data } = await this.getters.api.get(`/contacts/${contactId}/interactions`, query);
        const interactions = data.map(fromAPI);
        commit('fetchedContact', { contactId, value: true });
        commit('setContactInteraction', { contactId, data: interactions });
        return {
            total: metadata.pagination.total || 0,
            page: metadata.pagination.page,
            data: interactions
        };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionById({ dispatch, commit }, { customerId, interactionId }) {
    try {
      const data = fromAPI(await this.getters.api.get(`/customers/${customerId}/interactions/${interactionId}`));
      commit('setInteractionId', { data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionsStats({ dispatch, commit }, { customerId }) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/interactions/stats`);
      commit('setInteractionsStats', { customerId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionsEvolution({ dispatch, commit }, { customerId, period = 'MONTH'}) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/interactions/evolution`, {
        params: {
            by: period.toLowerCase()
        }
      });
      commit('setInteractionsEvolution', { customerId, period, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionLastTouchStats({ dispatch, commit }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.get(`/customers/interactions/last-touch-evolution/stats`);
        commit('setLastTouchStats', { clientId, data });
        return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionLastTouchEvolution({ dispatch, commit }, { period = 'MONTH', ctx } ) {
    try {
        const query = {
            pagination: ctx,
            params: {
                by: period.toLowerCase()
            }
        };
        const data = await this.getters.api.get(`/customers/interactions/last-touch-evolution`, query);
        return data.map(r => {
            return {
                ...r,
                refTs: dayjs(r.refTs).toDate()
            };
        }).sort((a, b) => {
           a.refTs - b.refTs
        });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionLastTouchList({ dispatch, commit }, { period = 'MONTH', from, ctx } ) {
    try {
        const query = {
            pagination: ctx,
            params: {
                by: period.toLowerCase()
            }
        };
        if (from) {
            query.params.from = from;
        }
      const data = await this.getters.api.get(`/customers/interactions/last-touch-list`, query);
      return data.map(r => {
        return {
            ...r,
            ts: dayjs(r.ts).toDate()
        };
      });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionsGoals({ dispatch, commit }) {
    try {
      const data = (await this.getters.api.get(`/interactions/goals`)).map(goalFromAPI);
      commit('setInteractionsGoals', { data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchInteractionsGoalStats({ dispatch, commit }, { customerId }) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/interactions/goals/stats`);
      const stats = data ? goalStatsFromAPI(data) : null;
      commit('setInteractionsGoalStats', { customerId, data: stats });
      return stats;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchComments({ commit }, { customerId, interactionId, ctx } = {}) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      const data = await this.getters.api.get(`/customers/${customerId}/interactions/${interactionId}/comments`, query);
      const comments = data.map(fromAPIComment);

      commit('setComments', { interactionId, data: comments });
      
      return {
        data: comments
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchPendingInteractions({ dispatch, commit }, { customerId, ctx }) {
    try {
      const query = {};
      if (ctx?.page) {
        query.pagination = ctx;
      }
      const { metadata, data } = await this.getters.api.get(customerId ? `/customers/${customerId}/interactions/pending` : `/interactions/pending`, query);
      const interactions = data.map(fromAPI);
      return {
          total: metadata.pagination.total || 0,
          page: metadata.pagination.page || 1,
          data: interactions
      };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async summarizeInteractions({ dispatch, commit }, { customerId, question, lang, custom, force, retry = 0 }) {
    try {
      const query = {
        cache: true,
        skipAuthErrorInterceptor: true,
        params: {
            q: question,
            lang,
            force
        }
      };
      const result = question !== 'CUSTOM' ? (await this.getters.api.get(`/customers/${customerId}/ai/interactions`, query)) : (await this.getters.api.post(`/customers/${customerId}/ai/interactions`, {
        ...query,
        data: {
            prompt,
            question: custom
        }
      }));
      return result;
    } catch (error) {
        if (error.response?.status === 403) {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.error'),
                variant: 'danger',
                text: i18n.t(`commons.403.chatGPT`)
            });
            return;
        }
        if (error.response?.status === 504 && retry < 3) {
            return dispatch('summarizeInteractions', { customerId, question, custom, retry: retry + 1 });
        }
        EventsBus.emit('notify', {
            title: i18n.t('commons.ope.error'),
            variant: 'danger',
            text: i18n.t(`customers.interactions.ai.ope.failed`)
        });
        throw 'A server error has occurred';
      }
  },
  async add({ commit }, { customerId, interaction }) {
    try {
        const data = await this.getters.api.post(`/customers/${customerId}/interactions`, { data: toAPI(interaction) });
        const result = fromAPI(data);
        commit('setInteraction', { customerId, data: result });
        return result;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { customerId, interactionId, interaction }) {
    try {
        const data = await this.getters.api.patch(`/customers/${customerId}/interactions/${interactionId}`, { data: toAPI(interaction) });
        // TODO remove
        interaction.id = interactionId;
        commit('setInteraction', { customerId, data: interaction });
        return interaction;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateScore({ commit }, { customerId, interactionId, score }) {
    try {
        await this.getters.api.patch(`/customers/${customerId}/interactions/${interactionId}/score`, { data: { score } });
        commit('setInteraction', { customerId, data: { id: interactionId, score } });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateTags({ commit }, { customerId, interactionId, tags }) {
    try {
        await this.getters.api.put(`/customers/${customerId}/interactions/${interactionId}/tags`, { data: tags });
        commit('setInteraction', { customerId, data: { id: interactionId, tags, updatedAt: new Date() } });
      } catch (error) {
        throw 'A server error has occurred';
      }
  }, 
  async remove({ commit }, { customerId, interactionId }) {
    try {
        await this.getters.api.delete(`/customers/${customerId}/interactions/${interactionId}`);
        commit('removeInteraction', { customerId, interactionId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async addGoal({ commit }, { goal }) {
    try {
        const data = await this.getters.api.post(`/interactions/goals`, { data: goalToAPI(goal) });
        commit('setInteractionsGoal', { data });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateGoal({ commit }, { goalId, goal }) {
    try {
        const data = await this.getters.api.patch(`/interactions/goals/${goalId}`, { data: goalToAPI(goal) });
        // TODO remove
        goal.id = goalId;
        commit('setInteractionsGoal', { data: goal });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeGoal({ commit }, { goalId }) {
    try {
        await this.getters.api.delete(`/interactions/goals/${goalId}`);
        commit('removeInteractionsGoal', { goalId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async addComment({ commit }, { customerId, interactionId, comment }) {
    try {
        const data = await this.getters.api.post(`/customers/${customerId}/interactions/${interactionId}/comments`, { data: toAPIComment(comment) });
        const result = fromAPIComment(data);
        commit('setComment', { interactionId, data: result });
        commit('updateInteractionCommentCount', { customerId, interactionId, interval: 1 });
        return result;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateComment({ commit }, { customerId, interactionId, commentId, comment }) {
    try {
        const data = await this.getters.api.patch(`/customers/${customerId}/interactions/${interactionId}/comments/${commentId}`, { data: toAPIComment(comment) });
        // TODO remove
        comment.id = commentId;
        commit('setComment', { interactionId, data: comment });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async removeComment({ commit }, { customerId, interactionId, commentId }) {
    try {
        await this.getters.api.delete(`/customers/${customerId}/interactions/${interactionId}/comments/${commentId}`);
        commit('removeComment', { interactionId, commentId });
        commit('updateInteractionCommentCount', { customerId, interactionId, interval: -1 });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, {  customerId, value }) {
    state.fetched[customerId] = value;
  },
  fetchedContact(state, { contactId, value }) {
    state.fetchedContact[contactId] = value;
  },
  setInteraction(state, { customerId, data }) {
    if (state.interactionsIds[data.id]) state.interactionsIds[data.id] = { ...state.interactionsIds[data.id], ...data};
    if (!state.interactions[customerId]) return;
    const index = state.interactions[customerId].findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.interactions[customerId], index, { ...state.interactions[customerId][index], ...data});
    }
    else {
      state.interactions[customerId].push(data);
    }
    // Vue.set(state.interactions[customerId], state.interactions[clientId][customerId].length, data);
  },
  removeInteraction(state, { customerId, interactionId }) {
    if (!state.interactions[customerId]) return;
    const index = state.interactions[customerId].findIndex(c => c.id === interactionId);
    if (index > -1) {
        state.interactions[customerId].splice(index, 1);
    }
  },
  setInteractions(state, { customerId, data }) {
    Vue.set(state.interactions, customerId, data);
  },
  setContactInteraction(state, { contactId, data }) {
    Vue.set(state.contactInteractions, contactId, data);
  },
  setInteractionId(state, { data }) {
    Vue.set(state.interactionsIds, data.id, data);
  },
  updateInteractionCommentCount(state, { customerId, interactionId, interval }) {
    if (state.interactionsIds[interactionId]) state.interactionsIds[interactionId].nbComments += interval;
    if (!state.interactions[customerId]) return;
    const int = state.interactions[customerId].find(c => c.id === interactionId);
    if (int) {
        int.nbComments += interval;
    }
  },
  setInteractionsStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId, data);
  },
  setInteractionsEvolution(state, { customerId, period, data }) {
    if (!state.evolution[period]) Vue.set(state.evolution, period, {});
    Vue.set(state.evolution[period], customerId, data);
  },
  setLastTouchStats(state, { clientId, data }) {
    Vue.set(state.lastTouchStats, clientId, data);
  },
  setInteractionsGoal(state, { data }) {
    if (data.default) {
        state.goals.forEach(s => {
            s.default = false;
        });
    }
    const index = state.goals.findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.goals, index, { ...state.goals[index], ...data});
    }
    else {
      state.goals.push(data);
    }
  },
  setInteractionsGoals(state, { data }) {
    state.goals = data;
  },
  removeInteractionsGoal(state, { goalId }) {
    const index = state.goals.findIndex(c => c.id === goalId);
    let isDefault = false;
    if (index > -1) {
        isDefault = state.goals[index].default;
        state.goals.splice(index, 1);
    }
    if (isDefault && state.goals.length) state.goals[0].default = true;
  },
  setInteractionsGoalStats(state, { customerId, data }) {
    Vue.set(state.goalStats, customerId, data);
  },
  setComments(state, { interactionId, data }) {
    Vue.set(state.comments, interactionId, data);
  },
  setComment(state, { interactionId, data }) {
    if (!state.comments[interactionId]) Vue.set(state.comments, interactionId, []);
    const index = state.comments[interactionId].findIndex(c => c.id === data.id);
    if (index > -1) {// Update project task customerId
        Vue.set(state.comments[interactionId], index, { ...data });
    }
    else {
      state.comments[interactionId].push(data);
    }
  },
  removeComment(state, { interactionId, commentId }) {
    const index = state.comments[interactionId]?.findIndex(c => c.id === commentId);
    if (index > -1) {
        state.comments[interactionId].splice(index, 1);
    }
  },
  reset(state) {
    state.goals = null;
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

export function fromAPI(json) {
    if (json.ts) json.ts = dayjs(json.ts).toDate();
    if (json.dueDate) json.dueDate = dayjs(json.dueDate).toDate();
    if (json.score) json.score = parseInt(json.score, 10);
    if (json.scoreAi) json.scoreAi = parseInt(json.scoreAi, 10);
    json.nbAttachements = json.nbAttachements || 0;
    return json;
}


export function goalFromAPI(json) {
    if (json.targets) {
        json.targets = json.targets.map(t => {
            return {
                name: t.name,
                period: t.period,
                type: t.type,
                target: parseInt(t.target, 10),
                periodCount: parseInt(t.periodCount, 10),
            }
        })
    }
    return json;
}
export function goalStatsFromAPI(json) {
    if (json.targets) {
        json.targets = json.targets.map(t => {
            return {
                name: t.name,
                period: t.period,
                type: t.type,
                target: parseInt(t.target, 10),
                periodCount: parseInt(t.periodCount, 10),
                count: parseInt(t.count, 10),
                percent: parseFloat(t.percent)
            }
        })
    }
    return json;
}
export function toAPI(interaction) {
    const json = { ...interaction };
    return json;
}

export function goalToAPI(goal) {
    const json = {...goal};
    if (json.targets) {
        json.targets = json.targets.map(t => {
            return {
                name: t.name,
                period: t.period,
                type: t.type,
                target: parseInt(t.target, 10),
                periodCount: parseInt(t.periodCount, 10),
            }
        })
    }
    return json;
}

function fromAPIComment(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPIComment(comment) {
    const json = { ...comment };
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
