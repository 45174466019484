import axios from 'axios';

const BASE_API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async get (url, params = { skipClientId: false }) {
        return await axios({ method:'GET', url: `${BASE_API_URL}${url}`, ...params });
    },
    
    async post (url, params = { skipClientId: false }) {
        return await axios({ method:'POST', url: `${BASE_API_URL}${url}`, ...params });
    },
    
    async delete (url, params = { skipClientId: false }) {
        return await axios({ method:'DELETE', url: `${BASE_API_URL}${url}`, ...params });
    },
    
    async put(url, params = { skipClientId: false }) {
        return await axios({ method:'PUT', url: `${BASE_API_URL}${url}`, ...params });
    },

    async patch(url, params = { skipClientId: false }) {
        return await axios({ method:'PATCH', url: `${BASE_API_URL}${url}`, ...params });
    }
}