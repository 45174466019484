import i18n from '@/i18n';

export default [
    {
        path: "/profile",
        name: "profile",
        meta: {
            breadcrumb: []
        },
        props: { category: "automation" },
        component: () => import("@/modules/settings/ProfileView.vue")
    },
    {
        path: "/users",
        name: "users",
        meta: {
            title: () => {
                return i18n.t('settings.users.title');
            },
            breadcrumb: []
        },
        props: { category: "automation" },
        component: () => import("@/modules/users/UsersView.vue")
    },
    {
        path: "/users/:userId",
        name: "userView",
        meta: {
            sidebar: ["Users"]
        },
        props: { category: "automation" },
        component: () => import("@/modules/users/UserView.vue"),
        children: [
            {
                path: "overview",
                name: "userOverview",
                component: () =>
                    import("@/modules/users/views/UserOverview.vue")
            },
            {
                path: "customers",
                name: "userCustomers",
                component: () =>
                    import("@/modules/users/views/UserOverview.vue")
            },
            {
                path: "activity",
                name: "userActivity",
                component: () =>
                    import("@/modules/users/views/UserOverview.vue")
            }
        ]
    },
    {
        path: "/settings",
        name: "settings",
        meta: {
            title: () => {
                return i18n.t('settings.general.title');
            },
            breadcrumb: []
        },
        props: { category: "automation" },
        component: () => import("@/modules/settings/SettingsView.vue"),
    },
    {
        path: "/settings/customer-stages",
        name: "settingsCustomerStages",
        meta: {
            title: () => {
                return i18n.t('settings.settingsCustomerStages.title');
            },
            breadcrumb: []
        },
        props: { category: "automation" },
        component: () => import("@/modules/settings/SettingsCustomerStagesView.vue"),
    },
    {
        path: "/settings/customer-profiles",
        name: "settingsCustomerProfiles",
        meta: {
            title: () => {
                return i18n.t('settings.settingsCustomerProfiles.title');
            },
            breadcrumb: []
        },
        props: { category: "automation" },
        component: () => import("@/modules/settings/SettingsCustomerProfilesView.vue"),
    },
    {
        path: "/settings/goals",
        name: "settingsGoals",
        meta: {
            title: () => {
                return i18n.t('settings.settingsGoals.title');
            },
            authorisation: { feature: 'goal', action: 'read' },
            breadcrumb: []
        },
        props: { category: "automation" },
        component: () => import("@/modules/settings/goals/SettingsGoalsView.vue"),
    },
    // {
    //     path: "/settings/interaction-goals",
    //     name: "settingsInteractionGoals",
    //     meta: {
    //         title: () => {
    //             return i18n.t('settings.settingsInteractionGoals.title');
    //         },
    //         breadcrumb: []
    //     },
    //     props: { category: "automation" },
    //     component: () => import("@/modules/settings/SettingsInteractionGoalsView.vue"),
    // },
    {
        path: "/imports",
        name: "imports",
        meta: {
            title: () => {
                return i18n.t('settings.imports.title');
            },
            breadcrumb: []
        },
        props: { category: "automation" },
        component: () => import("@/modules/settings/ImportsView.vue")
    },
    {
        path: "/integration",
        name: "integration",
        meta: {
            title: () => {
                return i18n.t('settings.integration.title');
            },
            breadcrumb: []
        },
        props: { category: "automation" },
        component: () => import("@/modules/settings/IntegrationView.vue"),
        children: [
            {
                path: "tracking",
                name: "integrationTracking",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'TRACKING'
                },
                component: () => import("@/modules/settings/integrations/tracking/TrackingView.vue")
            },
            {
                path: "apiKeys",
                name: "integrationApi",
                meta : {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'API'
                },
                component: () => import("@/modules/settings/integrations/apiKeys/ApiKeysView.vue")

            },
            {
                path: "gmail",
                name: "integrationGmail",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'GMAIL'
                },
                component: () => import("@/modules/settings/integrations/gmail/GmailView.vue")
            },
            {
                path: "hubspot",
                name: "integrationHubspot",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'HUBSPOT'
                },
                component: () => import("@/modules/settings/integrations/hubspot/HubspotView.vue")
            },
            {
                path: "microsoft",
                name: "integrationMicrosoft",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'MICROSOFT'
                },
                component: () => import("@/modules/settings/integrations/microsoft/MicrosoftlView.vue")
            },
            {
                path: "salesforce",
                name: "integrationSalesforce",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'SALESFORCE'
                },
                component: () => import("@/modules/settings/integrations/salesforce/SalesforceView.vue")
            },
            {
                path: "pipedrive",
                name: "integrationPipedrive",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'PIPEDRIVE'
                },
                component: () => import("@/modules/settings/integrations/pipedrive/PipedriveView.vue")
            },
            {
                path: "intercom",
                name: "integrationIntercom",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'INTERCOM'
                },
                component: () => import("@/modules/settings/integrations/intercom/IntercomView.vue")
            },
            {
                path: "sellsy",
                name: "integrationSellsy",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'SELLSY'
                },
                component: () => import("@/modules/settings/integrations/sellsy/SellsyView.vue")
            },
            {
                path: "stripe",
                name: "integrationStripe",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'STRIPE'
                },
                component: () => import("@/modules/settings/integrations/stripe/StripeView.vue")
            },
            {
                path: "chargebee",
                name: "integrationChargebee",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'CHARGEBEE'
                },
                component: () => import("@/modules/settings/integrations/chargebee/ChargebeeView.vue")
            },
            {
                path: "zendesk",
                name: "integrationZendesk",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'ZENDESK'
                },
                component: () => import("@/modules/settings/integrations/zendesk/ZendeskView.vue")
            },
            {
                path: "jira",
                name: "integrationJira",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'JIRA'
                },
                component: () => import("@/modules/settings/integrations/jira/JiraView.vue")
            },
            {
                path: "slack",
                name: "integrationSlack",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'SLACK'
                },
                component: () => import("@/modules/settings/integrations/slack/SlackView.vue")
            },
            {
                path: "segment",
                name: "integrationSegment",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'SEGMENT'
                },
                component: () => import("@/modules/settings/integrations/segment/SegmentView.vue")
            },
            {
                path: "pendo",
                name: "integrationPendo",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'PENDO'
                },
                component: () => import("@/modules/settings/integrations/pendo/PendoView.vue")
            },
            {
                path: "mixpanel",
                name: "integrationMixpanel",
                meta: {
                    title: () => {
                        return i18n.t('settings.integration.title');
                    },
                    connectId: 'MIXPANEL'
                },
                component: () => import("@/modules/settings/integrations/mixpanel/MixpanelView.vue")
            }
        ]
    }
];
