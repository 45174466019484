import Vue from 'vue';

function isDescendant(parent, child) {
  let node = child.parentNode;
  if (!node) return false;
  // Test parent <> html document
  // Fix bug parent null when DOM detached (tags-options)
  while (node !== document) {
    if (node === parent) {
      return true;
    }
    if (node.parentNode === null) return true;
    node = node.parentNode;
  }
  return false;
}

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!isDescendant(el, event.target)) {
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
});
