import Vue from 'vue';
import store from '../store';


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useUserAllowed = () => {

    return function ({ feature, action }) {
        return store.getters['isUserAllowed']({ feature, action });
    }
    
};

export const isFeatureVisible = () => {

    return function ({ feature }) {
        return store.getters['isFeatureVisible']({ feature });
    }

};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const FeaturePlugin = {
    install(Vue, options) {
      Vue.prototype.$isUserAllowed = useUserAllowed(options);
      Vue.prototype.$isFeatureVisible = isFeatureVisible(options);
    }
};

Vue.use(FeaturePlugin);