<template>
          <div @click.prevent.stop>
            <variable-infos
                :options="items"
                :value="value"
                :fallback="fallback"
                @select="selectItem"
                @cancel="exit"
            />
        </div>
</template>

<script>

import VariableInfos from '@/libs/tiptap/variable/VariableInfos';

export default {
    components: {
        VariableInfos
    },
  props: {

    items: {
      type: Array,
      required: true,
    },

    value: {
        type: String,
        required: false
    },
    fallback: {
        type: String,
        required: false
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
      variable: '',
      enabled: false
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },
  mounted() {
      setTimeout(() => {
          this.enabled = true;
      }, 150);
  },
  methods: {
    // show() {
    //     this.$nextTick(() => {
    //         this.$refs.popover.$emit('enable');
    //     });
    // },
    // hide() {
    //     this.$nextTick(() => {
    //         this.$refs.popover.$emit('disable');
    //     });
    // },
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(value) {
      if (value) {
        this.command({ value: value.value, label: value.label, fallback: value.fallback });
      }
      this.exit();
    },
    exit() {
        if (this.enabled) {
            const instance = this.$el._tippy;
            instance?.destroy();
        }
    }
  },
}
</script>

<style lang="scss">
.menu-item-var {
    display: flex;
    flex-direction: column;

    .menu-item-var-description {
        font-weight: 200;
        font-size: 12px;
    }
}
// .items {
//   padding: 0.2rem;
//   position: relative;
//   border-radius: 0.5rem;
//   background: #FFF;
//   color: rgba(0, 0, 0, 0.8);
//   overflow: hidden;
//   font-size: 0.9rem;
//   box-shadow:
//     0 0 0 1px rgba(0, 0, 0, 0.05),
//     0px 10px 20px rgba(0, 0, 0, 0.1),
//   ;
// }

// .item {
//   display: block;
//   margin: 0;
//   width: 100%;
//   text-align: left;
//   background: transparent;
//   border-radius: 0.4rem;
//   border: 1px solid transparent;
//   padding: 0.2rem 0.4rem;

//   &.is-selected {
//     border-color: #000;
//   }
// }
</style>