<template>
    <div class="app-container app-theme-white">
        <transition name="fade" mode="out-in" appear>
            <Header :breadcrumb="breadcrumbLinks" :collapsed.sync="collapsed" />
        </transition>
        <transition name="fade" mode="out-in" appear>
            <Sidebar :collapsed.sync="collapsed" />
        </transition>
        <div class="app-main__outer">
            <div class="app-main__inner">
                <slot></slot>
            </div>
            <transition name="fade" mode="out-in" appear>
                <Footer/>
            </transition>
        </div>
        <div class="help-wrapper" v-if="helpComponent && $isInternal()">
            <transition name="fade" mode="out-in" appear>
                <Helpbar :title="helpTitle" :auto-open="helpAutoOpen" :open-key="helpOpenKey">
                    <component :is="helpComponent" />
                </Helpbar>
            </transition>
            <b-button pill class="help-btn" size="lg" bg-variant="white" variant="outline-primary" v-b-toggle.helpbar :title="helpTitle"><b-icon scale="2" icon="info" /></b-button>
        </div>
        <component :is="toast" v-for="toast of toasts" :key="toast" />
        <component v-if="modal" :is="modal" :notify="notify" />
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import EventsBus from '@/libs/EventsBus';
    import Header from "../Components/Header";
    import Sidebar from "../Components/Sidebar";
    import Footer from "../Components/Footer";
    import Helpbar from "../Components/Helpbar";
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faCog,
    } from '@fortawesome/free-solid-svg-icons'
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    import { TOASTS } from '@/news';

    library.add(
        faCog,
    );
    export default {
        name: 'app',
        components: {
            ...TOASTS,
            Header,
            Sidebar,
            Footer,
            Helpbar,
            VuePerfectScrollbar,
            'font-awesome-icon': FontAwesomeIcon,
            'endTrialModal': () => import('@/Layout/Components/Modals/endTrialModal.vue'),
            'endTrialSuccessModal': () => import('@/Layout/Components/Modals/endTrialSuccessModal.vue'),
            'endAgreement': () => import('@/Layout/Components/Modals/endContractModal.vue'),
        },
        data: () => ({
            modal: null,
            notify: null,
            collapsed: false
        }),
        computed: {
            breadcrumbLinks() {
                return this.$store.state.breadcrumbLinks;
            },
            toasts() {
                return Object.keys(TOASTS);
            },
            helpTitle() {
                return this.$route.meta?.help?.title ? this.$route.meta?.help?.title() : null;
            },
            helpComponent() {
                return this.$route.meta?.help?.component;
            },
            helpAutoOpen() {
                return !!this.$route.meta?.help?.autoOpen;
            },
            helpOpenKey() {
                return this.$route.meta?.help?.openKey;
            }
        },
        mounted() {
            EventsBus.on('initialized', async () => {
                this.verifyNotification();
            });
        },
        methods: {
            verifyNotification() {
                const client = this.$store.getters['clients/currentClient'];
                if (!client) return;
                const notify = client.parameters?.notify;
                if (!notify) return;

                const user = this.$store.getters['users/currentUser'];
                // console.info(user)
                // If notification doesn't start or user created after notify start -> Go out
                if (notify.start && (dayjs(notify.start).toDate() > new Date() || dayjs(notify.start).toDate() < user?.createdAt)) return;
                // If notification expired -> Go out
                if (notify.expire && dayjs(notify.expire).toDate() < new Date()) return;
                this.notify = notify;
                // Show notification
                if (notify?.key === 'endTrial') {
                    this.modal = 'endTrialModal';
                }
                else if (notify?.key === 'endTrialSuccess') {
                    this.modal = 'endTrialSuccessModal';
                }
                else if (notify?.key === 'endAgreement') {
                    this.modal = 'endAgreement';
                }
            }
        }
    }
</script>
<style lang="scss">
.help-btn {
    position: fixed !important;
    bottom: 10px;
    right: 10px;
    z-index: 1900;
    border-width: 3px !important;
    // background-color: var(--white) !important;
}
</style>