
const state = {
};

const getters = {
    
};

const actions = {
    async resetServer({ dispatch }) {
        return await this.getters.api.post(`/cache/raz`);
    }
};

const mutations = {
    
};

export default {
    namespaced: true,
    getters,
    actions,
    state,
    mutations
};
