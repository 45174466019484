<template>
    <div>
        <!-- profile modal-->
        <b-modal
            id="profile"
            :title="$t('settings.profile.title')"
            size="lg"
            :ok-title="$t('commons.action.save')"
            :input-class="'dd'"
            :hide-footer="true"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            @close="$emit('close', $event)"
        >
            <b-row>
                <b-col md="12">
                    <b-card class="mb-3 nav-justified">
                        <b-form ref="form" @submit.stop.prevent="handleSubmit">
                            <b-form-group
                                id="input-group-1"
                                label-cols-sm="3"
                                :label="$t('settings.profile.modal.form.email.label')"
                                label-for="input-1"
                            >
                                <b-form-input
                                    disabled
                                    id="input-member-name"
                                    v-model="user.email"
                                    type="email"
                                    size="lg"
                                    :state="$v.form.email.$dirty ? !$v.form.email.$invalid : null"
                                ></b-form-input>
                                <b-form-invalid-feedback
                                    id="input-member-name-feedback"
                                >
                                    {{ $t('settings.profile.modal.form.email.invalid') }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                id="input-group-firstname"
                                label-cols-sm="3"
                                :label="$t('settings.profile.modal.form.firstname.label')"
                                label-for="input-member-firstname"
                            >
                                <b-form-input
                                    id="input-member-firstname"
                                    v-model="form.firstName"
                                    required
                                    :placeholder="$t('settings.profile.modal.form.firstname.label')"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="input-group-lastname"
                                label-cols-sm="3"
                                :label="$t('settings.profile.modal.form.lastname.label')"
                                label-for="input-member-lastname"
                            >
                                <b-form-input
                                    id="input-member-lastname"
                                    v-model="form.lastName"
                                    required
                                    :placeholder="$t('settings.profile.modal.form.lastname.label')"
                                ></b-form-input>
                            </b-form-group>
                            <b-button
                                variant="primary"
                                @click="handleSaveProfile"
                                >{{
                                    $t("settings.profile.save.button")
                                }}</b-button
                            >
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                     <b-card class="mb-3 nav-justified" :title="$t('settings.profile.modal.mail.title')">
                        <b-form-group
                                :label="$t('settings.profile.modal.mail.weekly.global')"
                                label-cols-sm="10"
                            >
                            <b-form-checkbox v-model="email.global" size="lg" switch>
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group
                                :label="$t('settings.profile.modal.mail.weekly.owner')"
                                label-cols-sm="10"
                            >
                            <b-form-checkbox v-model="email.owned" size="lg" switch>
                            </b-form-checkbox>
                        </b-form-group>
                        <b-button
                            variant="primary"
                            @click="handleSaveEmailConfig"
                            >{{
                                $t("settings.profile.save.emails.button")
                            }}</b-button>
                        
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                     <b-card class="mb-3 nav-justified" :title="$t('settings.profile.modal.ui.title')">
                        <b-form-group
                                id="input-group-locale"
                                label-cols-sm="3"
                                :label="$t('settings.profile.modal.form.locale.label')"
                                label-for="input-locale"
                            >
                            <b-form-select
                                id="input-locale"
                                v-model="language"
                                :options="languages"
                                required
                                placeholder="Locale"
                            ></b-form-select>
                        </b-form-group>
                        <b-button class="mb-4" variant="primary" @click="selectLanguage">{{ $t("settings.profile.save.locale.button") }}</b-button>
                        <b-form-group
                                v-if="$isInternal()"
                                id="input-group-theme"
                                label-cols-sm="3"
                                :label="$t('settings.profile.modal.form.theme.label')"
                                label-for="input-theme"
                            >
                            <b-form-select
                                id="input-theme"
                                v-model="theme"
                                :options="themes"
                                required
                                placeholder="Theme"
                            ></b-form-select>
                        </b-form-group>
                        <b-button v-if="$isInternal()" class="mb-4" variant="primary" @click="selectTheme">{{ $t("settings.profile.save.theme.button") }}</b-button>
                        <b-form-group
                                id="input-group-homepage"
                                label-cols-sm="3"
                                :label="$t('settings.profile.modal.form.homepage.label')"
                                label-for="input-theme"
                            >
                            <div class="d-flex justify-left-center mb-3">
                            <div class="btn btn-outline-primary mr-3" :class="{ 'bg-primary': page.name === homepage, 'text-white': page.name === homepage }" @click="selectHomepage(page.name)" v-for="page in homepages" :key="page.name">
                                <div class="heading">{{ page.label }}</div>
                                <div class="subheading">{{ page.resume }}</div>
                                <img width="150" src="@/assets/images/icons/from-blank.svg" alt="" />
                            </div>
                        </div>
                        </b-form-group>
                        <b-button variant="primary" @click="saveHomepage">{{ $t("settings.profile.save.homepage.button") }}</b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import EventsBus from '@/libs/EventsBus';
import { required, minLength } from 'vuelidate/lib/validators';
import { email } from '@/libs/utils/Validator';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import i18n from "@/i18n";
import AvatarUpload from "@/modules/commons/components/AvatarUpload";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faEdit,
    faTrashAlt,
    faUserTie,
    faPhone,
    faMobileAlt,
    faCommentAlt,
    faComment,
    faHandshake,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
    faEdit,
    faTrashAlt,
    faUserTie,
    faPhone,
    faMobileAlt,
    faCommentAlt,
    faComment,
    faHandshake,
    faUsers
);

const languages = ['en', 'fr'];
const knownThemes = ['light', 'dark', 'blue'];

export default {
    components: {
        AvatarUpload,
        VuePerfectScrollbar,
        "font-awesome-icon": FontAwesomeIcon
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        subheading: i18n.t("customers.dashboard.subheading"),
        icon: "pe-7s-paint icon-gradient bg-sunny-morning",
        breadcrumb: [
            {
                text: i18n.t("users.view.heading"),
                to: { name: "users" }
            }
        ],
        form: {
            firstName: "",
            lastName: "",
            avatar: "/faces/anonymous.png"
        },
        languages: languages.map(t => ({ value: t, text: t })),
        themes: knownThemes.map(t => ({ value: t, text: t })),
        homepages: [
            {
                name: 'myactions',
                label: i18n.t('global.myactions.title'),
                resume: i18n.t('global.myactions.resume')
            },
            {
                name: 'customers',
                label: i18n.t('global.customers.title'),
                resume: i18n.t('global.customers.resume')
            }
        ],
        email: {
            global: false,
            owned: false
        },
        language: 'fr',
        theme: 'light',
        homepage: 'myactions',
        loaded: false
    }),
    validations: {
        form: {
            email: {
                email
            }
            // firstName: {
            //     required,
            //     minLength: minLength(2)
            // },
            // lastName: {
            //     required,
            //     minLength: minLength(2)
            // }
        },
    },
    watch: {
        async clientId() {
            if (this.clientId) {
                await this.init();
            }
        }
    },
    computed: {
        clientId() {
            return this.$store.getters['clients/currentClientId'];
        },
        connections() {
            return this.$store.getters['connections/getAll'] ? [...this.$store.getters['connections/getAll']] : [];
        }
    },
    async mounted() {
        if (this.clientId) {
            await this.init();
        }
    },
    methods: {
        async init() {
            await this.$store.dispatch("connections/ensureConnections");
            await this.$store.dispatch('configs/ensureUserConfigs');
            const config = this.$store.getters['configs/getForUserAll'];
            this.email.global = typeof config?.emailGlobal !== 'undefined' ? config.emailGlobal : true;
            this.email.owned = typeof config?.emailOwned !== 'undefined' ? config.emailOwned : true;
            this.language = this.$store.getters['configs/getForUser']('language') || i18n.locale;
            this.theme = window.localStorage.getItem('theme') || 'light';
            this.homepage = config.homepage || 'myactions';
        },
        checkFormValidity() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        resetModal() {
            this.$v.$reset();
            this.form.firstName = this.user?.firstName || "";
            this.form.lastName = this.user?.lastName || "";
            this.form.avatar = this.user?.avatar || "";
            this.theme = localStorage.getItem("theme") || 'light';
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSaveProfile() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            await this.$store.dispatch("users/update", {
                 userId: this.user.id,
                 user: this.form
            });
            this.$bvToast.toast(`Profile updated`, {
              title: this.$t('commons.ope.success'),
              autoHideDelay: 5000,
              appendToast: false
            });
            this.$emit("ok", this.form);
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide("profile");
                this.resetModal();
            });
        },
        async handleSaveEmailConfig() {
            const config = { ...this.$store.getters['configs/getForUserAll'] };
            config.emailGlobal = this.email.global;
            config.emailOwned = this.email.owned;
            await this.$store.dispatch("configs/updateUserConfig", {
                config
            });
            this.$bvToast.toast(this.$t('settings.global.ope.updated'), {
              title: this.$t('commons.ope.success'),
              autoHideDelay: 5000,
              appendToast: false
            });
        },
        async selectLanguage() {
            if (this.language && !languages.includes(this.language)) {
                throw new Error(`"${this.language}" has not been defined as a language.`);
            }
            // Update user config with language
            const config = { ...this.$store.getters['configs/getForUserAll'] };
            config.language = this.language
            await this.$store.dispatch("configs/updateUserConfig", {
                config
            });
            // Emit App new language event
            EventsBus.emit('language', this.language);
        },
        selectTheme() {
            if (this.theme && !knownThemes.includes(this.theme)) {
                throw new Error(`"${this.theme}" has not been defined as a theme.`);
            }
            let body = document.body;        
            body.setAttribute("data-theme", this.theme);
            localStorage.setItem("theme", this.theme);        
        },
        async selectHomepage(name) {
            this.homepage = name;
        },
        async saveHomepage() {
            const config = { ...this.$store.getters['configs/getForUserAll'] };
            config.homepage = this.homepage;
            await this.$store.dispatch("configs/updateUserConfig", {
                config
            });
            this.$bvToast.toast(this.$t('settings.global.ope.updated'), {
              title: this.$t('commons.ope.success'),
              autoHideDelay: 5000,
              appendToast: false
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.icon-wrapper {
    cursor: pointer;
}
.description {
    min-height: 150px;
}
</style>
