var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "createClient",
            title: "Create client",
            size: "lg",
            "ok-title": "Create",
            "input-class": "dd"
          },
          on: { show: _vm.resetModal, hidden: _vm.resetModal, ok: _vm.handleOk }
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v("To create a new client, enter its name and domain")
          ]),
          _c(
            "b-form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-1",
                    label: _vm.$t("settings.global.account.form.name.label"),
                    "label-for": "input-1"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-client-name",
                      type: "text",
                      size: "lg",
                      required: "",
                      placeholder: "Client name",
                      debounce: "500",
                      state: _vm.$v.form.name.$dirty
                        ? !_vm.$v.form.name.$invalid
                        : null,
                      "aria-describedby": "input-client-name-feedback"
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-client-name-feedback" } },
                    [
                      _vm._v(
                        "\n                    Enter at least 3 letters\n                    "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-1",
                    label: _vm.$t("settings.global.account.form.domain.label"),
                    "label-for": "input-client-domain"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-client-domain",
                      type: "text",
                      required: "",
                      placeholder: "Client domain",
                      debounce: "500",
                      state: _vm.$v.form.domain.$dirty
                        ? !_vm.$v.form.domain.$invalid
                        : null,
                      "aria-describedby": "input-client-domain-feedback"
                    },
                    model: {
                      value: _vm.form.domain,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "domain", $$v)
                      },
                      expression: "form.domain"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-client-domain-feedback" } },
                    [
                      _vm._v(
                        "\n                    Enter at least 3 letters and valid domain\n                    "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-plan-group",
                    label: _vm.$t("settings.global.account.form.plan.label")
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "input-plan",
                      options: _vm.plans,
                      required: "",
                      placeholder: "Plan",
                      state: _vm.$v.form.plan.$dirty
                        ? !_vm.$v.form.plan.$invalid
                        : null
                    },
                    model: {
                      value: _vm.form.plan,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "plan", $$v)
                      },
                      expression: "form.plan"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-client-plan-feedback" } },
                    [
                      _vm._v(
                        "\n                    Select a client plan\n                    "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-currency",
                    label: _vm.$t(
                      "settings.global.account.form.currency.label"
                    ),
                    "label-for": "input-member-currency"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "input-member-currency",
                      options: _vm.currencies,
                      placeholder: _vm.$t(
                        "settings.global.account.form.currency.label"
                      )
                    },
                    model: {
                      value: _vm.form.currency,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "currency", $$v)
                      },
                      expression: "form.currency"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }