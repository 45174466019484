import Vue from 'vue';
import store from '../store';


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useDebug = () => {

    return function () {
        return store.getters['isDebug'];
    }
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const DebugPlugin = {
    install(Vue, options) {
      Vue.prototype.$isDebug = useDebug(options);
    }
};

Vue.use(DebugPlugin);
