
import Vue from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import EventsBus from '@/libs/EventsBus';

dayjs.extend(utc)

const state = {
  filters: {},
  fetched: {},
  activity: {},
  stats: {},
  usageDatasetMonth: {},
  usageDatasetWeek: {},
  usageContactDatasetMonth: {},
  usageContactDatasetWeek: {},
  live: {
    identity: [],
    features: [],
    webhooks: []
  }
};

const getters = {
  getSelectedFilters: (state) => {
      return state.filters
  },
  getAll: (state, getters, store, rootGetters) => customerId => {
      return state.activity[customerId] || [];
  },
  getFromContactId: (state, getters) => (customerId, contactId )=> getters.getAll(customerId).find(a => a.contactId === contactId),
  getStats: (state) => customerId => {
    return state.stats[customerId];
  },
  getUsageDataset:(state) => (customerId, period = 'WEEK', group, ctx) => {
    if (period === 'MONTH') {
        return state.usageDatasetMonth[group || 'all']?.[`${customerId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`];
    }
    else {
        return state.usageDatasetWeek[group || 'all']?.[`${customerId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`];
    }
  },
  getContactUsageDataset:(state) => (contactId, period = 'WEEK', group, ctx) => {
    if (period === 'MONTH') {
        return state.usageContactDatasetMonth[group || 'all']?.[`${contactId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`];
    }
    else {
        return state.usageContactDatasetWeek[group || 'all']?.[`${contactId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`];
    }
  },
  getLive: (state) => ({
        identity: state.live.identity.filter(e => e.deleted !== true),
        features: state.live.features.filter(e => e.deleted !== true),
        webhooks: state.live.webhooks.filter(e => e.deleted !== true)
  })
};

const actions = {

  async ensureActivity({ dispatch, state }, { customerId }) {
    if (!state.fetched[customerId]) {
      await dispatch('fetchActivity', { customerId });
    }
  },
  async ensureActivityStats({ dispatch, state }, { customerId }) {
    if (!state.stats[customerId]) {
      await dispatch('fetchActivityStats', { customerId });
    }
  },
  async ensureUsageEvolution({ dispatch, state }, { customerId, period = 'WEEK', ctx, group }) {
    if (period === 'MONTH' && !state.usageDatasetMonth[group || 'all']?.[`${customerId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`]) {
        await dispatch('fetchUsageEvolution', { customerId, period, ctx, group });
    }
    else if (period === 'WEEK' && !state.usageDatasetWeek[group || 'all']?.[`${customerId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`]) {
        await dispatch('fetchUsageEvolution', { customerId, period, ctx, group });
    }
  },
  async ensureContactUsageEvolution({ dispatch, state }, { contactId, period = 'WEEK', ctx, group }) {
    if (period === 'MONTH' && !state.usageContactDatasetMonth[group || 'all']?.[`${contactId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`]) {
        await dispatch('fetchContactUsageEvolution', { contactId, period, ctx, group });
    }
    else if (period === 'WEEK' && !state.usageContactDatasetWeek[group || 'all']?.[`${contactId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`]) {
        await dispatch('fetchContactUsageEvolution', { contactId, period, ctx, group });
    }
  },
  async fetchActivity({ dispatch, commit }, { customerId, ctx }) {
    try {
        const query = {
            cache: true
        };
        if (ctx?.page) {
          query.pagination = ctx;
        }
        const { metadata, data } = await this.getters.api.get(`/customers/${customerId}/activity`, query);
        const activity = data.map(stats => {
            return {
                contactId: stats.contactId,
                activity: stats.activity.map(a => {
                    return {
                        ts: dayjs(a.ts).toDate(),
                        visits: a.visits,
                        events: a.events
                    }
                })
            }
        });
        commit('fetched', { customerId, value: true });
        commit('setActivity', { customerId, data: activity });
        return {
            total: metadata.pagination.total || 0,
            data: activity
        };
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchLive({ commit }) {
    try {
        const data = await this.getters.api.get(`/activity/live`);
        commit('setActivityLive', { 
            identity: data?.identity?.map(e => fromAPILiveEvent(e)),
            features: data?.features?.map(e => fromAPILiveEvent(e)),
            webhooks: data?.webhooks?.map(e => fromAPILiveEvent(e)),
        });
    } catch (error) {
        throw 'A server error has occurred';
    }
  },
  async clearLive({ commit }) {
    try {
        commit('clearActivityLive');
    } catch (error) {
        throw 'A server error has occurred';
    }
  },
  async resetLive({ commit }) {
    try {
        commit('resetActivityLive');
    } catch (error) {
        throw 'A server error has occurred';
    }
  },
  async fetchActivityStats({ dispatch, commit }, { customerId }) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/activity/stats`);
      commit('setActivityStats', { customerId, data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchUsageEvolution({ dispatch, commit }, { customerId, period = 'WEEK', ctx, group }) {
    try {
        const data = await this.getters.api.get(customerId ? `/customers/${customerId}/activity/usage` : `/activity/usage`, {
        params: {
          by: period.toLowerCase(),
          group
        },
        pagination: ctx
    });
      commit('setUsageEvolution', { customerId, data, period, group });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchContactUsageEvolution({ dispatch, commit }, { contactId, period = 'WEEK', ctx, group }) {
    try {
        const data = await this.getters.api.get(contactId ? `/contacts/${contactId}/activity/usage` : `/activity/usage`, {
        params: {
          by: period.toLowerCase(),
          group
        },
        pagination: ctx
    });
      commit('setContactUsageEvolution', { contactId, data, period, group });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchReportActivityFeatures({ dispatch, commit }, { customerId, ctx }) {
    try {
        const query = {
            cache: false,
            pagination: ctx
        };
      const data = await this.getters.api.get(customerId ? `/customers/${customerId}/activity/features` : `/activity/features`, query);
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchReportActivityFeatureEvolution({ dispatch, commit }, { feature, customerId, period = 'WEEK', ctx }) {
    try {
        const query = {
            cache: false,
            pagination: ctx,
            params: {
                feature,
                by: period.toLowerCase()
            }
        };
      const data = await this.getters.api.get(customerId ? `/customers/${customerId}/activity/features/details` : `/activity/features/details`, query);
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async export({ dispatch, commit }, { type, from, to, ctx }) {
    try {
      const query = {
          cache: false,
          pagination: ctx,
          data: {
            type,
            from: dayjs(from).format('YYYY-MM-DD'),
            to: dayjs(to).format('YYYY-MM-DD'),
          }
      };
      const results = await this.getters.api.post(`/activity/export`, query);
      const exportId = results.data?.id;
      if (exportId) {
        EventsBus.emit('beginExport', {
            ...results.data
        });
        const result = await this.dispatch('exports/waitExport', { exportId });
        console.info(result);
        EventsBus.emit('endExport', {
            id: exportId,
            result
        });
        return result;
      }
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async updateFilters({ commit }, { customerId, ctx }) {
    try {
        commit("setFilters", { ...ctx.filters, customer: customerId });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  setFilters(state, selectedFilters){
    state.filters = selectedFilters;
  },
  fetched(state, {  customerId, value }) {
    state.fetched[customerId] = value;
  },
  setActivity(state, { customerId, data }) {
    Vue.set(state.activity, customerId, data);
  },
  setActivityStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId, data);
  },
  setActivityLive(state, { identity, features, webhooks }) {
    const ids = state.live.identity.map(e => e.eventId);
    const newEvents = identity.filter(e => !ids.includes(e.eventId));
    for(const evt of newEvents) {
        state.live.identity.push(evt);
    }
    const idsF = state.live.features.map(e => e.eventId);
    const newEventsF = features.filter(e => !idsF.includes(e.eventId));
    for(const evt of newEventsF) {
        state.live.features.push(evt);
    }
    const idsW = state.live.webhooks.map(e => e.eventId);
    const newEventsW = webhooks.filter(e => !idsW.includes(e.eventId));
    for(const evt of newEventsW) {
        state.live.webhooks.push(evt);
    }
    // Array.prototype.push.apply(state.live, );
  },
  clearActivityLive(state) {
      for(const evt of state.live.identity.filter(e => !e.deleted)) {
        Vue.set(evt, 'deleted', true);
      }
      for(const evt of state.live.features.filter(e => !e.deleted)) {
        Vue.set(evt, 'deleted', true);
      }
      for(const evt of state.live.webhooks.filter(e => !e.deleted)) {
        Vue.set(evt, 'deleted', true);
      }
  },
  resetActivityLive(state) {
    state.live = {
        identity: [],
        features: [],
        webhooks: []
    };
  },
  setUsageEvolution(state, { customerId, data, period = 'WEEK', group, ctx }) {
    if (period === 'MONTH') {
        if (!state.usageDatasetMonth[group || 'all']) Vue.set(state.usageDatasetMonth, group || 'all', {});
        Vue.set(state.usageDatasetMonth[group || 'all'], `${customerId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`, data);
    }
    else {
        if (!state.usageDatasetWeek[group || 'all']) Vue.set(state.usageDatasetWeek, group || 'all', {});
        Vue.set(state.usageDatasetWeek[group || 'all'], `${customerId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`, data);
    }
  },
  setContactUsageEvolution(state, { contactId, data, period = 'WEEK', group, ctx }) {
    if (period === 'MONTH') {
        if (!state.usageContactDatasetMonth[group || 'all']) Vue.set(state.usageContactDatasetMonth, group || 'all', {});
        Vue.set(state.usageContactDatasetMonth[group || 'all'], `${contactId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`, data);
    }
    else {
        if (!state.usageContactDatasetWeek[group || 'all']) Vue.set(state.usageContactDatasetWeek, group || 'all', {});
        Vue.set(state.usageContactDatasetWeek[group || 'all'], `${contactId || 'all'}${ctx ? JSON.stringify(ctx) : ''}`, data);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPILiveEvent(event) {
    event.ts = dayjs(event.data?.ts).utc(true).local().toDate();
    return event;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
