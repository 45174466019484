
import Vue from 'vue';
import dayjs from 'dayjs';
import { sleep } from '@/libs/utils/Promise';

export const EXPORT_TYPE = {
    CSV: 'application/csv',
    JSON: 'application/json',
    PLAIN: 'application/plain'
};
export const EXPORT_STATUS = {
    PENDING: 'PENDING',
    RUNNING: 'RUNNING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
}
export const EXPORT_QUERY = {
    CUSTOMER_EXPORT: 'CUSTOMER_EXPORT',
    CONTACT_EXPORT: 'CONTACT_EXPORT'
}

const state = {
  fetched: {},
  exports: {}
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
      return state.exports[rootGetters.currentClientId] || [];
  },
  getFromId: (state, getters) => id => getters.getAll.find(a => a.id === id),
  getAllByQuery: (state, getters) => query => getters.getAll.filter(a => a.query === query).sort((a, b) => {
      return a.createdAt.getTime() - b.createdAt.getTime();
  })
};

const actions = {

  async ensureExports({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetched[clientId]) {
      return await dispatch('fetchExports');
    }
    return state.exports[clientId];
  },
  async fetchExports({ dispatch, commit }) {
    try {
      const clientId = this.getters.currentClientId;
      const data = (await this.getters.api.get('/exports')).map(fromAPI);
      commit('fetched', { clientId, value: true });
      commit('setExports', { clientId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchExport({ dispatch, commit }, { exportId }) {
    try {
      const clientId = this.getters.currentClientId;
      const data = fromAPI(await this.getters.api.get(`/exports/${exportId}`));
      commit('setExport', { clientId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async waitExport({ dispatch, commit, getters, state }, { exportId }) {
    try {
      const clientId = this.getters.currentClientId;
      // Ensure getters work getFromId
      if (!state.exports[clientId]) state.exports[clientId] = [];

      let current = getters.getFromId(exportId);
      while(![EXPORT_STATUS.SUCCESS, EXPORT_STATUS.ERROR].includes(current?.status)) {
        // Wait 3s before each status call
        await sleep(3000);
        await dispatch('fetchExport', { exportId });
        current = getters.getFromId(exportId);
      }
      return current;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { custom }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.post(`/customs`, { data: toAPI(custom) });
        const model = fromAPI(data);
        commit('setCustom', { clientId, data: model });
        return model;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { clientId, value }) {
    state.fetched[clientId] = value;
  },
  setExport(state, { clientId, data }) {
    if (!state.exports[clientId]) state.exports[clientId] = [];
    const index = state.exports[clientId].findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.exports[clientId], index, { ...state.exports[clientId][index], ...data});
    }
    else {
        state.exports[clientId].push(data);
    }
  },
  setExports(state, { clientId, data }) {
    Vue.set(state.exports, clientId, data);
  },
  removeExport(state, { clientId, exportId }) {
    if (!state.exports[clientId]) state.exports[clientId] = [];
    const index = state.exports[clientId].findIndex(c => c.id === exportId);
    if (index > -1) {
        state.exports[clientId].splice(index, 1);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.endAt) json.endAt = dayjs(json.endAt).toDate();
    return json;
}

function toAPI(custom) {
    const json = {...custom};
    delete json.createdAt;
    delete json.endAt;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
