import i18n from '@/i18n';

export default [
  {
    path: '/customers',
    name: 'customers',
    meta: {
        title: () => {
            return i18n.t('global.customers.title');
        },
        breadcrumb: []
    },
    props: { category: 'automation' },
    component: () => import('@/modules/customers/CustomersDashboard.vue'),
  },
  {
    path: '/customers/:customerId',
    name: 'customerView',
    meta: {
        title: () => {
            return i18n.t('global.customers.title');
        },
        sidebar: ['Customers', 'Dashboards']
    },
    props: { category: 'automation' },
    component: () => import('@/modules/customers/CustomerView.vue'),
    children: [
        {
            path: 'overview',
            name: 'customerOverview',
            component: () => import('@/modules/customers/views/CustomerOverview.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.customers.title');
                }
            }
        },
        {
            path: 'childrens',
            name: 'customerChildrens',
            component: () => import('@/modules/customers/views/CustomerChildrens.vue'),
            meta: {
                  title: () => {
                      return i18n.t('global.customers.title');
                  }
            }
        },
        {
            path: 'interactions',
            name: 'customerInteractions',
            component: () => import('@/modules/customers/views/CustomerInteractions.vue'),
            meta: {
                  title: () => {
                      return i18n.t('global.customers.title');
                  }
            }
        },
        {
            path: 'usage',
            name: 'customerUsage',
            component: () => import('@/modules/customers/views/CustomerUsage.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.customers.title');
                }
            }
        },
        {
            path: 'contacts',
            name: 'customerContacts',
            component: () => import('@/modules/customers/views/CustomerContacts.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.customers.title');
                }
            }
        },
        {
            path: 'tasks',
            name: 'customerTasks',
            component: () => import('@/modules/customers/views/CustomerTasks.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.customers.title');
                }
            }
        },
        {
            path: 'agreement',
            name: 'customerAgreement',
            component: () => import('@/modules/customers/views/CustomerAgreement.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.customers.title');
                }
            }
        },
        {
            path: 'playbooks',
            name: 'customerPlaybooks',
            component: () => import('@/modules/customers/views/CustomerPlaybooks.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.customers.title');
                }
            }
        },
        {
            path: 'settings',
            name: 'customerSettings',
            component: () => import('@/modules/customers/views/CustomerSettings.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.customers.title');
                }
            }
        },
      ]
  }
];
