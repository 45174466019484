<template>
    <div class="d-flex flex-column skeleton-wrapper">
        <b-skeleton height="115px" class="mb-3"></b-skeleton>
        <b-row class="body">
            <b-col>
                <b-skeleton class="skeleton-body mb-3">
                </b-skeleton>
                <div id="btn-refresh-wrapper">
                    <button id="btn-refresh" onclick="refreshApp()">
                    Reload Skalin
                    </button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
  export default {
    mounted() {
        setTimeout(() => {
              const btnWrapper = document.getElementById("btn-refresh-wrapper");
              if (btnWrapper) btnWrapper.style.display = 'flex';
        }, 45 * 1000);
    }
  }
</script>

<style lang="scss">
  .app-main__inner {
      display: flex;
      flex-direction: column;
  }
  .skeleton-wrapper {
      height: 100%;
      flex-grow: 1;

      .body {
        height: 100%;
        flex-grow: 1;

        .skeleton-body {
            height: 100%;
        }
      }
  }
  #btn-refresh-wrapper {
    display: none;
    position: absolute;
    top: 50%;
    left: calc(50% - 90px);
  }
  #btn-refresh{
    margin: auto;
    width: 200px;
    white-space: nowrap;
    box-shadow: 1px 2px 7px 1px #2026604d;
    background-color: #202660;
    color: #f1f1f2;
    position: relative;
    transition: all 0.1s ease-in-out;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    padding: 20px 50px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
  }
  #btn-refresh:hover{
    background: #202660;
    border-color: #202660;
  }
  #btn-refresh:active{
    transform: translateX(1px);
    transition-duration: .1s;
    opacity: .5;
  }
</style>
