
import Vue from 'vue';
import dayjs from 'dayjs';

const state = {
  fetched: false,
  mails: [],
};

const getters = {
  getAllMails: (state, getters, store, rootGetters) => {
    return state.mails;
  },
  getMailsFromId: (state) => (id )=> state.mails.find(a => a.id === id),
};

const actions = {

  async ensureMails({ dispatch, state }) {
    if (!state.fetched) {
      await dispatch('fetchMails');
    }
  },
  async fetchMails({ commit }, { playbookId, activationId, stepId } = {}) {
    try {
      const data = await this.getters.api.get(playbookId && activationId ? `/playbooks/${playbookId}/activations/${activationId}/steps/${stepId}/outputs/mails` : `/outputs/mails`);
      const mails = data.map(fromMailAPI);
      commit('fetched', { value: true });
      commit('setMails', { data: mails });

      return mails;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async addMail({ commit }, { mail }) {
    try {
        const data = fromMailAPI(await this.getters.api.post(`/outputs/mails`, { data: toMailAPI(mail) }));
        commit('setMail', { data });
        return data;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateMail({ commit }, { mailId, mail }) {
    try {
        const data = await this.getters.api.patch(`/outputs/mails/${mailId}`, { data: toMailAPI(mail) });
        // TODO remove
        mail.id = mailId;
        commit('setMail', { data: mail });
        return mail;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async generateMail({ commit }, { mailId, mail }) {
    try {
        const data = await this.getters.api.patch(`/outputs/mails/${mailId}/generate`, { data: toMailAPI(mail) });
        // TODO remove
        mail.id = mailId;
        commit('setMail', { data: mail });
        return mail;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async fetchMailUserSignature({ commit }, { userId } = {}) {
    try {
      const data = await this.getters.api.get(`/outputs/mails/${userId}/signature`);
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { value }) {
    state.fetched = value;
  },
  setMail(state, { data }) {
    const index = state.mails.findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.mails, index, { ...state.mails[index], ...data});
    }
    else {
      state.mails.push(data);
    }
  },
  removeMail(state, { mailId }) {
    const index = state.mails.findIndex(c => c.id === mailId);
    if (index > -1) {
        state.mails.splice(index, 1);
    }
  },
  setMails(state, { data }) {
    Vue.set(state, 'mails', data);
  },
  reset(state) {
    state.fetched =  false;
  }
};

function fromMailAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.processedAt) json.processedAt = dayjs(json.processedAt).toDate();
    return json;
}

function toMailAPI(mail) {
    const json = { ...mail };
    delete json.createdAt;
    delete json.processedAt;
    delete json.taskId;
    delete json.processId;
    delete json.env;
    delete json.customerId;
    delete json.templateSubject;
    delete json.templateBody;
    delete json.group;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
