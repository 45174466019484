var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.results
      ? _c("div", { staticClass: "search-results" }, [
          _vm.noResult
            ? _c(
                "div",
                { staticClass: "search-inner" },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "search-group -empty",
                      attrs: { "no-body": "" }
                    },
                    [
                      _vm._v(
                        "\n                No results found...\n            "
                      )
                    ]
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "search-inner" },
                [
                  _vm.results.data.CUSTOMER
                    ? _c(
                        "b-card",
                        {
                          staticClass: "search-group mr-3",
                          attrs: { "no-body": "", header: "Customers" }
                        },
                        [
                          _c(
                            "b-list-group",
                            _vm._l(_vm.results.data.CUSTOMER, function(res) {
                              return _c("b-list-group-item", { key: res.id }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: res.link },
                                    on: { click: _vm.hideSearch }
                                  },
                                  [_vm._v(_vm._s(res.label))]
                                )
                              ])
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.results.data.CONTACT
                    ? _c(
                        "b-card",
                        {
                          staticClass: "search-group mr-3",
                          attrs: { "no-body": "", header: "Contacts" }
                        },
                        [
                          _c(
                            "b-list-group",
                            _vm._l(_vm.results.data.CONTACT, function(res) {
                              return _c("b-list-group-item", { key: res.id }, [
                                res.label && res.label.trim() != ""
                                  ? _c(
                                      "a",
                                      {
                                        attrs: { href: res.link },
                                        on: { click: _vm.hideSearch }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(res.label) +
                                            " (" +
                                            _vm._s(res.description) +
                                            ")"
                                        )
                                      ]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        attrs: { href: res.link },
                                        on: { click: _vm.hideSearch }
                                      },
                                      [_vm._v(_vm._s(res.description))]
                                    )
                              ])
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.results.data.INTERACTION
                    ? _c(
                        "b-card",
                        {
                          staticClass: "search-group",
                          attrs: { "no-body": "", header: "Interactions" }
                        },
                        [
                          _c(
                            "b-list-group",
                            _vm._l(_vm.results.data.INTERACTION, function(res) {
                              return _c(
                                "b-list-group-item",
                                { key: res.id },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      icon: _vm.getInteractionIcon(res),
                                      size: "lg"
                                    }
                                  }),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: res.link },
                                      on: { click: _vm.hideSearch }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(res.label) +
                                          " (" +
                                          _vm._s(
                                            _vm.showInterractionRelativeDate(
                                              res.createdAt
                                            )
                                          ) +
                                          ")"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.results.data.TASK
                    ? _c(
                        "b-card",
                        {
                          staticClass: "search-group",
                          attrs: { "no-body": "", header: "Tasks" }
                        },
                        [
                          _c(
                            "b-list-group",
                            _vm._l(_vm.results.data.TASK, function(res) {
                              return _c(
                                "b-list-group-item",
                                { key: res.id },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      icon: _vm.getTaskIcon(res),
                                      size: "lg"
                                    }
                                  }),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: res.link },
                                      on: { click: _vm.hideSearch }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(res.label) +
                                          " (" +
                                          _vm._s(
                                            _vm.showInterractionRelativeDate(
                                              res.options.dueDate
                                            )
                                          ) +
                                          ")"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }