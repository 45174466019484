import i18n from '@/i18n';

export default [
  {
    path: '/playbooks',
    name: 'playbooks',
    meta: {
        title: () => {
            return i18n.t('global.playbooks.title');
        },
        help: {
            title: () => {
                return i18n.t('global.help.title');
            },
            autoOpen: true,
            openKey: 'playbooks',
            component: () => import('@/modules/playbooks/PlaybooksHelp'),
        },
        breadcrumb: [],
        authorisation: { feature: 'playbook', action: 'read' }
    },
    props: { category: 'automation' },
    component: () => import('@/modules/playbooks/PlaybooksDashboard.vue'),
  },
  {
    path: '/playbooks/:playbookId',
    name: 'playbookView',
    meta: {
        title: () => {
            return i18n.t('global.playbooks.title');
        },
        authorisation: { feature: 'playbook', action: 'read' },
        sidebar: ['Customers', 'Dashboards']
    },
    props: { category: 'automation' },
    component: () => import('@/modules/playbooks/PlaybookView.vue'),
    children: [
        {
            path: 'overview',
            name: 'playbookOverview',
            component: () => import('@/modules/playbooks/views/PlaybookOverview.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.playbooks.title');
                },
                authorisation: { feature: 'playbook', action: 'read' },
            }
        },
        {
            path: 'settings',
            name: 'playbookSettings',
            component: () => import('@/modules/playbooks/views/PlaybookSettings.vue'),
            meta: {
                title: () => {
                    return i18n.t('global.playbooks.title');
                },
                authorisation: { feature: 'playbook', action: 'read' },
            }
        }
      ]
  }
];
