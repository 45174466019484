import Vue from 'vue';
import store from '../store';


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useRole = () => {

    return function () {
        return store.getters['clients/currentRole'];
    }
};

export const useRoleAllowed = () => {

    return function (minRole) {
        return store.getters['hasRole']({ minRole });
    }
    
};
// Create a simple Vue plugin to expose the wrapper object throughout the application
export const RolePlugin = {
    install(Vue, options) {
      Vue.prototype.ROLES = store.ROLES;
      Vue.prototype.$role = useRole(options);
      Vue.prototype.$hasRole = useRoleAllowed(options);
    }
};

Vue.use(RolePlugin);
