
import Vue from 'vue';
import dayjs from 'dayjs';

const state = {
  fetched: false,
  webhooks: [],
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
    return state.webhooks;
  },
  getFromId: (state) => (id )=> state.webhooks.find(a => a.id === id),
};

const actions = {

  async ensureWebhooks({ dispatch, state }) {
    if (!state.fetched) {
      await dispatch('fetchWebhooks');
    }
  },
  async fetchWebhooks({ commit }) {
    try {
      const data = await this.getters.api.get(`/webhooks`);
      const webhooks = data.map(fromAPI);
      commit('fetched', { value: true });
      commit('setWebhooks', { data: webhooks });

      return webhooks;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { webhook }) {
    try {
        const data = fromAPI(await this.getters.api.post(`/webhooks`, { data: toAPI(webhook) }));
        commit('setWebhook', { data });
        return data;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { webhookId, webhook }) {
    try {
        const data = await this.getters.api.patch(`/webhooks/${webhookId}`, { data: toAPI(webhook) });
        // TODO remove
        webhook.id = webhookId;
        commit('setWebhook', { data: webhook });
        return webhook;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { webhookId }) {
    try {
        await this.getters.api.delete(`/webhooks/${webhookId}`);
        commit('removeWebhook', { webhookId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { value }) {
    state.fetched = value;
  },
  setWebhook(state, { data }) {
    const index = state.webhooks.findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.webhooks, index, { ...data});
    }
    else {
      state.webhooks.push(data);
    }
  },
  removeWebhook(state, { webhookId }) {
    const index = state.webhooks.findIndex(c => c.id === webhookId);
    if (index > -1) {
        state.webhooks.splice(index, 1);
    }
  },
  setWebhooks(state, { data }) {
    Vue.set(state, 'webhooks', data);
  },
  reset(state) {
    state.fetched =  false;
  }
};

function fromAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPI(webhook) {
    const json = { ...webhook };
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
