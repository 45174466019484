
import Vue from 'vue';
import i18n from '@/i18n';

const state = {
  fetched: {},
  stages: {},
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
      return state.stages[rootGetters.currentClientId] || [];
  },
  getFromId: (state, getters) => id => getters.getAll.find(a => a.id === id),
}

const actions = {

  async ensureStages({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetched[clientId]) {
      return await dispatch('fetchStages');
    }
    return state.stages[clientId];
  },
  async fetchStages({ dispatch, commit }) {
    try {
      const clientId = this.getters.currentClientId;
      const data = ([{
        name: null,
        label: i18n.t('customers.payment.stage.NOTSET.label')
  },{
          name: 'UPTODATE',
          label: i18n.t('customers.payment.stage.UPTODATE.label')
    }, {
        name: 'OVERDUE',
        label: i18n.t('customers.payment.stage.OVERDUE.label')
    }]).map(fromAPI);
      commit('fetched', { clientId, value: true });
      commit('setStages', { clientId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { stage }) {
    // try {
    //     const clientId = this.getters.currentClientId;
    //     const data = await this.getters.api.post(`/customers/stages`, { data: toAPI(stage) });
    //     commit('setStage', { clientId, data: fromAPI(data) });
    //   } catch (error) {
    //     throw 'A server error has occurred';
    //   }
  },
  async update({ commit }, { name, stage }) {
    // try {
    //     const clientId = this.getters.currentClientId;
    //     const data = await this.getters.api.patch(`/customers/stages/${name}`, { data: toAPI(stage) });
    //     commit('setStage', { clientId, data: stage });
    //   } catch (error) {
    //     throw 'A server error has occurred';
    //   }
  },
  async remove({ commit }, { name }) {
    // try {
    //     const clientId = this.getters.currentClientId;
    //     await this.getters.api.delete(`/customers/stages/${name}`);
    //     commit('removeStage', { clientId, name });
    //   } catch (error) {
    //     throw 'A server error has occurred';
    //   }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { clientId, value }) {
    state.fetched[clientId] = value;
  },
  setStage(state, { clientId, data }) {
    const index = state.stages[clientId].findIndex(c => c.name === data.name);
    if (data.default) {
        state.stages[clientId].forEach(s => {
            s.default = false;
        });
    }
    if (index > -1) {
      Vue.set(state.stages[clientId], index, { ...state.stages[clientId][index], ...data});
    }
    else {
        state.stages[clientId].push(data);
    }
  },
  setStages(state, { clientId, data }) {
    Vue.set(state.stages, clientId, data);
  },
  removeStage(state, { clientId, name }) {
    const index = state.stages[clientId].findIndex(c => c.name === name);
    let isDefault = false;
    if (index > -1) {
        isDefault = state.stages[clientId][index].default;
        state.stages[clientId].splice(index, 1);
    }
    if (isDefault && state.stages[clientId].length) state.stages[clientId][0].default = true;
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    return json;
}

function toAPI(stage) {
    const json = {...stage};
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
