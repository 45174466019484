var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "updater", attrs: { id: "updater" } },
    [
      _vm.showAlert
        ? _c(
            "b-alert",
            {
              staticClass:
                "position-fixed fixed-bottom rounded-0 alert-updater",
              staticStyle: { "z-index": "2000" },
              attrs: { show: "", variant: "success", dismissible: "" }
            },
            [
              !_vm.updating
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-center"
                    },
                    [
                      _c("b-img", {
                        attrs: {
                          src: "https://picsum.photos/50/50",
                          thumbnail: "",
                          center: "",
                          fluid: "",
                          rounded: "circle"
                        }
                      }),
                      _c("span", { staticClass: "ml-3" }, [
                        _vm._v(_vm._s(_vm.$t("sw.notif.refresh.message")))
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-3 updater-link-wrapper" },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { variant: "outline-primary", size: "sm" },
                              on: { click: _vm.refreshApp }
                            },
                            [_vm._v(_vm._s(_vm.$t("sw.notif.refresh.reload")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-center"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "wrapper-img" },
                        [
                          _c("div", {
                            staticClass: "spinner-border text-primary"
                          }),
                          _c("b-img", {
                            attrs: {
                              src: "https://picsum.photos/50/50",
                              thumbnail: "",
                              center: "",
                              fluid: "",
                              rounded: "circle"
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "ml-3 install" }, [
                        _vm._v(_vm._s(_vm.$t("sw.notif.refresh.installing")))
                      ])
                    ]
                  )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }