var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-image",
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
        }
      }
    },
    [
      !_vm.editor.isActive("image")
        ? _c(
            "button",
            {
              staticClass: "menubar__button",
              class: { "is-active": _vm.editor.isActive("image") },
              attrs: { title: _vm.$t("commons.editor.command.image") },
              on: { click: _vm.showImageModal }
            },
            [_c("icon", { attrs: { name: "image" } })],
            1
          )
        : _vm._e(),
      _vm.editor.isActive("image") && _vm.updating
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "menubar__button",
                attrs: { title: _vm.$t("commons.editor.command.image-update") },
                on: { click: _vm.updateImage }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("commons.editor.command.image-update")) +
                    "\n        "
                )
              ]
            ),
            _c(
              "a",
              {
                staticClass: "button-link -pointer ml-2",
                on: {
                  click: function($event) {
                    return _vm.changeSize("small")
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("commons.editor.command.image.small")) +
                    "\n        "
                )
              ]
            ),
            _c(
              "a",
              {
                staticClass: "button-link -pointer ml-2",
                on: {
                  click: function($event) {
                    return _vm.changeSize("big")
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("commons.editor.command.image.big")) +
                    "\n        "
                )
              ]
            ),
            _c(
              "a",
              {
                staticClass: "button-link -pointer ml-2",
                on: {
                  click: function($event) {
                    return _vm.changeSize("origine")
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("commons.editor.command.image.original")) +
                    "\n        "
                )
              ]
            ),
            _c(
              "a",
              {
                staticClass: "button-link -pointer ml-2",
                on: {
                  click: function($event) {
                    return _vm.setImage({ url: null })
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("commons.action.remove")) +
                    "\n        "
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.editor.isActive("image") && !_vm.updating
        ? _c(
            "button",
            {
              staticClass: "menubar__button",
              attrs: { title: _vm.$t("commons.editor.command.image-update") },
              on: { click: _vm.updateImage }
            },
            [_c("icon", { attrs: { name: "image" } })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
            }
          }
        },
        [
          _c("image-infos", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.exit,
                expression: "exit"
              }
            ],
            ref: "info",
            attrs: { "can-undo": _vm.canRemove },
            on: { select: _vm.setImage, cancel: _vm.exit },
            model: {
              value: _vm.params,
              callback: function($$v) {
                _vm.params = $$v
              },
              expression: "params"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }