var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-avatar", {
        staticClass: "filter-badge p-1 -pointer",
        attrs: {
          icon: "sliders",
          id: "notification-filters-" + _vm._uid,
          size: "2rem",
          badge: _vm.hasFilters,
          "badge-top": "",
          "badge-variant": "danger"
        },
        on: {
          click: function($event) {
            _vm.show = !_vm.show
          }
        }
      }),
      _c(
        "b-popover",
        {
          staticClass: "popover",
          attrs: {
            target: "notification-filters-" + _vm._uid,
            placement: "bottomleft",
            title: _vm.$t("notifications.filters.title"),
            show: _vm.showed
          },
          on: {
            "update:show": function($event) {
              _vm.showed = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.onCancel,
                  expression: "onCancel"
                }
              ],
              staticClass: "pb-4"
            },
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    {
                      staticClass: "pt-2",
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t(
                                      "notifications.filters.tab.label.entities"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    _vm._l(_vm.entitiesFilterList, function(option, index) {
                      return _c(
                        "b-row",
                        {
                          key: index,
                          staticClass: "mb-2 p-2 rounded row-item",
                          attrs: {
                            "align-v": "center",
                            "no-gutters": "",
                            id: "checkbox-line-entities-" + index
                          }
                        },
                        [
                          _c("b-col", [
                            _c("div", { staticClass: "column-name d-flex" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(option.text) +
                                  "\n                            "
                              )
                            ])
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "text-right" },
                            [
                              _c("b-form-checkbox", {
                                key: index,
                                attrs: { id: "checkbox-entities-" + index },
                                model: {
                                  value: option.checked,
                                  callback: function($$v) {
                                    _vm.$set(option, "checked", $$v)
                                  },
                                  expression: "option.checked"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      staticClass: "pt-2",
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t(
                                      "notifications.filters.tab.label.events"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    _vm._l(_vm.eventsFilterList, function(option, index) {
                      return _c(
                        "b-row",
                        {
                          key: index,
                          staticClass: "mb-2 p-2 rounded row-item",
                          attrs: {
                            "align-v": "center",
                            "no-gutters": "",
                            id: "checkbox-line-events-" + index
                          }
                        },
                        [
                          _c("b-col", [
                            _c("div", { staticClass: "column-name d-flex" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(option.text) +
                                  "\n                            "
                              )
                            ])
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "text-right" },
                            [
                              _c("b-form-checkbox", {
                                key: index,
                                attrs: { id: "checkbox-events-" + index },
                                model: {
                                  value: option.checked,
                                  callback: function($$v) {
                                    _vm.$set(option, "checked", $$v)
                                  },
                                  expression: "option.checked"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "text-center mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: { size: "sm", variant: "primary" },
                          on: { click: _vm.onSave }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("commons.action.save")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "outline-danger" },
                          on: { click: _vm.onCancel }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("commons.action.cancel")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a",
                {
                  staticClass: "-pointer reset-link",
                  on: { click: _vm.handleResetSettings }
                },
                [
                  _c("b-icon", { attrs: { icon: "arrow-clockwise" } }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("commons.table-settings-reset")) +
                      "\n            "
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }