<template>
    <div>
        <div v-if="results" class="search-results">
            <div class="search-inner" v-if="noResult">
                <b-card no-body class="search-group -empty">
                    No results found...
                </b-card>
            </div>
            <div class="search-inner" v-else>
                <b-card no-body v-if="results.data.CUSTOMER" header="Customers" class="search-group mr-3">
                    <b-list-group>
                        <b-list-group-item v-for="res in results.data.CUSTOMER" :key="res.id">
                            <a :href="res.link" @click="hideSearch">{{ res.label }}</a>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
                <b-card no-body v-if="results.data.CONTACT" header="Contacts" class="search-group mr-3">     
                    <b-list-group>
                        <b-list-group-item v-for="res in results.data.CONTACT" :key="res.id">
                            <a v-if="res.label && res.label.trim() != ''" :href="res.link" @click="hideSearch">{{ res.label }} ({{ res.description }})</a>
                            <a v-else :href="res.link" @click="hideSearch">{{ res.description }}</a>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
                <b-card no-body v-if="results.data.INTERACTION" header="Interactions" class="search-group">
                    <b-list-group>
                        <b-list-group-item v-for="res in results.data.INTERACTION" :key="res.id">
                            <font-awesome-icon class="mr-2" :icon="getInteractionIcon(res)" size="lg" />
                            <a :href="res.link" @click="hideSearch">{{ res.label }} ({{ showInterractionRelativeDate(res.createdAt) }})</a>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
                <b-card no-body v-if="results.data.TASK" header="Tasks" class="search-group">
                    <b-list-group>
                        <b-list-group-item v-for="res in results.data.TASK" :key="res.id">
                            <font-awesome-icon class="mr-2" :icon="getTaskIcon(res)" size="lg" />
                            <a :href="res.link" @click="hideSearch">{{ res.label }} ({{ showInterractionRelativeDate(res.options.dueDate) }})</a>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
    import { dateFormatters } from '@/libs/formatters';
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faEnvelopeOpen,
        faPhone,
        faMobileAlt,
        faCommentAlt,
        faTicketAlt,
        faUsers,
        faClipboardCheck
    } from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faEnvelopeOpen,
        faPhone,
        faMobileAlt,
        faCommentAlt,
        faTicketAlt,
        faUsers,
        faClipboardCheck
    );

    

    export default {
        components: {
            'font-awesome-icon': FontAwesomeIcon
        },
        data() {
            return {
            }
        },
        props: {
            results: {
                type: Object
            }
        },
        computed: {
            noResult() {
                return Object.keys(this.results?.data || {}).length === 0;
            }
        },
        methods: {
            hideSearch() {
                this.showResults = false;
                this.$emit('follow');
            },
            showInterractionRelativeDate(date) {
                if (!date) return '-';
                return dateFormatters.fromNow(date);
            },
            getInteractionIcon(search) {
                return this.$store.getters['interactions/getIcon'](search.options?.type);
            },
            getTaskIcon(search) {
                return this.$store.getters['tasks/getIcon'](search.options?.type);
            },
        }
    }
</script>
<style lang="scss">
.search-results {
    // width: 100%;
    // height: 100%;
    // position: fixed;
    // margin-top: 50px;
    // margin-left: 280px;
    // right: 0;
    // bottom: 0;
    // inset: 0px;
    // opacity: 0.85;
    // backdrop-filter: blur(2px);
    // background-color: #FFFFFF;
    // display: flex;
    // flex-direction: row;
    margin-top: 9px;
    position: absolute;
    left: 280px;
    backdrop-filter: blur(2px);
    background-color: #0c0c0c30;
    display: flex;
    flex-direction: row;
    // height: 500px;
    overflow: hidden;
    padding: 1rem;
    min-width: calc(100% - 279px);

    .search-inner {
        height: 467px;
        overflow: hidden;
        display: flex;

        .search-group {
            width: 380px;

            .list-group-item {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                min-height: 42px;
            }

            &.-empty {
                font-size: 22px;
                text-align: center;
                justify-content: center;
            }
        }
    }
}
.closed-sidebar {
    .search-results {
        min-width: calc(100% - 79px);
    }
}
</style>