import { SecurityInterceptor } from './SecurityInterceptor';
import { CacheInterceptor } from './CacheInterceptor';
import { PendingInterceptor } from './PendingInterceptor';
import { TransformInterceptor } from './TransformInterceptor';
import { PaginationInterceptor } from './PaginationInterceptor';
import { EnvInterceptor } from './EnvInterceptor';
import { VersionInterceptor } from './VersionInterceptor';

function register({ store, router, i18n }) {
  // Allow only one get call by route (http request concurency = 1)
  const interceptorPending = new PendingInterceptor({store, router, i18n});
  // Attach to request
  interceptorPending.register();

  // Cache all get request locally
  const interceptorCache = new CacheInterceptor({store, router, i18n});
  // Attach to request
  interceptorCache.register();

  // Create env interceptor for sending env to API
  const interceptorEnv = new EnvInterceptor({store, router, i18n});
  // Attach to request
  interceptorEnv.register();

  // Create env interceptor for sending version UI to API
  const interceptorVersion = new VersionInterceptor({store, router, i18n});
  // Attach to request
  interceptorVersion.register();

  // Create interceptor for pagination
  const paginationInterceptor = new PaginationInterceptor({store, router, i18n});
  // Attach to request
  paginationInterceptor.register();

  // Create network interceptor for token purpose (last !important)
  const interceptor = new SecurityInterceptor({store, router, i18n});
  // Attach to request
  interceptor.register();

  // Create network interceptor for token purpose (last !important)
  const transformInterceptor = new TransformInterceptor({store, router, i18n});
  // Attach to request
  transformInterceptor.register();
}

export {
  register
};
