var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar-input" },
    [
      _c("img", { attrs: { src: _vm.image, alt: "" } }),
      _c("b-form-file", {
        staticClass: "ml-5 file-selector custom-select",
        attrs: {
          placeholder: _vm.$t("component.csvimport.file.label"),
          "drop-placeholder": _vm.$t("component.csvimport.file.drop"),
          accept: "image/*"
        },
        on: { change: _vm.handleImage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }