
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import { AgreementStatusEnum } from '@/store/modules/agreements';

dayjs.extend(advancedFormat)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

const state = {};

const getters = {};
const actions = {
  async fetchReportData(store, { reportName, axes = [], filters = [], from = null, to = null } = {}) {
    const queryParams = {
      axes,
      filters,
      from,
      to,
    };

    const formattedQueryParams = Object.entries(queryParams)
      .map(([key, value]) => {
        const formattedValue = ['axes', 'filters'].includes(key) ? JSON.stringify(value) : value;
        return `${key}=${formattedValue}`;
      })
      .join('&');

    const response = await this.getters.api.get(`/api/v1/reports/${reportName}/data/?${formattedQueryParams}`);

    return response.data.data;
  },
  async fetchCustomersRenewalByPeriod({ dispatch, commit, rootGetters }, { period = 'MONTH', ctx } ) {
    try {
      const customers = await await this.dispatch('customers/ensureCustomers');
      let filteredList = [...customers];
      if (ctx?.filters?.owner?.value) {
        const userValue = ctx.filters.owner.value;
        switch (ctx.filters.owner.mode) {
            case 'USER': {
                if (userValue === 'null') {
                    filteredList = filteredList.filter((a) => !a.team.find((u) => u.role === 2))
                } else {
                    filteredList = filteredList.filter((a) => a.team.find((u) => u.userId === userValue && u.role === 2));
                }
                break;
            }
            case 'TEAM': {
                const teamMembers = rootGetters['usersTeams/getMembersFromId'](userValue);
                filteredList = filteredList.filter((a) => a.team.find((u) => teamMembers.includes(u.userId) && u.role === 2));
                break;
            }
          }
      }
      if (ctx?.filters?.id?.value) {
        filteredList = filteredList.filter(a => ctx?.filters?.id?.value.includes(a.id));
      }
      const now = dayjs().date(1).toDate();
      const data = filteredList.filter(c => c.renewalDate >= now).sort((a, b) => a.renewalDate > b.renewalDate ? 1 : -1).reduce((prev, cur) => {
          let level = getLevel(cur.healthScore);
          if(cur.nextAgreement && cur.nextAgreement.status == AgreementStatusEnum.VALIDATED){
            level = 'VALIDATED';
          }
          const month = dayjs(cur.renewalDate).format(period === 'MONTH' ? 'YYYY/MMMM' : 'YYYY/WW');
          if (!prev[level]) prev[level] = {};
          if (!prev[level][month]) prev[level][month] = { level, period: month, count: 0, mrr: 0, ids: []};
          prev[level][month].count++;
          prev[level][month].mrr += cur.mrr;
          prev[level][month].ids.push(cur.id);
          return prev;
      }, {});

      return Object.keys(data).map(level => {
        return {
            level,
            dataset: data[level]
        };
      });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },

  async fetchGlobalHealthScoreByPeriod({ dispatch, commit }, { period = 'MONTH', ctx } ) {
    try {
        const query = {
            pagination: ctx
        };
      return await this.getters.api.get(`/customers/healthscore?by=${period.toLowerCase()}`, query);
    //   return data.map(r => {
    //     return {
    //         level: r.level,
    //         count: parseInt(r.count, 10),
    //         ts: r.ts
    //     }
    //   });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },

  async fetchGlobalTimeRentabilityByPeriod({ dispatch, commit }, { period = 'MONTH', ctx } ) {
    try {
        const query = {
            pagination: ctx
        };
      return await this.getters.api.get(`/customers/time-profitability?by=${period.toLowerCase()}`, query);
    //   return data.map(r => {
    //     return {
    //         level: r.level,
    //         count: parseInt(r.count, 10),
    //         ts: r.ts
    //     }
    //   });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },

  async exportTimeRentabilityByPeriod({ dispatch, commit }, { period = 'MONTH', ctx } ) {
    try {
        const query = {
            cache: false,
            pagination: ctx
        };
        const results = await this.getters.api.get(`/customers/time-profitability/export?by=${period.toLowerCase()}`, query);
        return results.data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },

  async fetchMrrByPeriod({ dispatch, commit }, { period = 'MONTH', ctx } ) {
    try {
        const query = {
            pagination: ctx
        };
      return await this.getters.api.get(`/agreements/mrr?by=${period.toLowerCase()}`, query);
    //   return data.map(r => {
    //     return {
    //         level: r.level,
    //         count: parseInt(r.count, 10),
    //         ts: r.ts
    //     }
    //   });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
};
const mutations = {};

function getLevel(score) {
    if (score < 4) return 'POOR';
    if (score < 7) return 'MEDIUM';
    return 'GOOD';

}


export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
