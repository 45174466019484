
import Vue from 'vue';
import i18n from "@/i18n";
import dayjs from 'dayjs';
import { spawn, Thread, Worker } from "threads";
import EventsBus from '@/libs/EventsBus';
import SseService from '@/libs/SseService';
import { deepCopy, isEqual } from "@/libs/utils/Object";
import { sleep } from '@/libs/utils/Promise';
import { parseDataType } from '@/store/modules/customs.js';
import { debounce } from '@/libs/utils/Promise';
import { debounce as debounceTimer } from '@/libs/utils/Debounce';
import { getDb } from '@/libs/db';
import { fromAPI, fromAPIKpi, toAPI } from './utils/customers';

let utilsWorker = null;

const REFRESH_CUSTOMER = 1000 * 60 * 25; // 25 min

const state = {
  synced: {},
  fetched: {},
  fetchedKpis: {},
  churns: {},
  customers: {},
  customersKpis: {},
  stats: {},
  customerStats: {},
  healthScoreStatsMonth: {},
  healthScoreStatsWeek: {},
  totalMrr: 0,
  topMinMrr: 0,
  lastSort: {
    sortName: null,
    sortDirection: null,
    page: 1
  },
  lastShowCustomer: null,
  currentFilter: {
    user: null,
    tags: {},
    stages: [],
    paymentStages: [],
    profiles: [],
    goalProfiles: [],
    goals: [],
    plans: []
  }
};

const getters = {
  isSynced: (state, getters, store, rootGetters) => {
    return state.synced[rootGetters.currentClientId] === true;
  },
  isFetched: (state, getters, store, rootGetters) => {
    return state.fetched[rootGetters.currentClientId] === true;
  },
  getAll: (state, getters, store, rootGetters) => {
      return state.customers[rootGetters.currentClientId] || [];
  },
  getFromId: (state, getters) => (id) => {
    return getters.getAll.find(a => a.id === id);
    // if (lastCustomer?.id === id) return lastCustomer;
    // lastCustomer = getters.getAll.find(a => a.id === id);
    // return lastCustomer;
  },
  getParentsId: (state, getters) => (id) => {
    const customer = getters.getFromId(id);
    if (!customer?.parentId) return [];
    return [...getters.getParentsId(customer.parentId), customer.parentId];
  },
  getChildrenHierarchy: (state, getters) => (id) => {
    const childrens = getters.getAll.filter(c => c.parentId === id).map(c => ({'id': c.id, 'name': c.name}));
    if (!childrens.length) return [];
    return childrens.map(c => ({ ...c, childrens: getters.getChildrenHierarchy(c.id) }));
  },
  getChildrensId: (state, getters) => (id) => {
    const childrens = getters.getAll.filter(c => c.parentId === id).map(c => c.id);
    if (!childrens.length) return [];
    return [...childrens , ...childrens.flatMap(c => getters.getChildrensId(c))];
},
  getKpis: (state, getters, store, rootGetters) => {
    return state.customersKpis[rootGetters.currentClientId] || [];
  },
  getStats: (state) => {
    return state.stats;
  },
  getStatsFromId: (state, getters) => id => state.customerStats[id],
  getHealthScoreStats: (state, getters) => (id, period = 'WEEK') => {
    if (period === 'MONTH') {
        return state.healthScoreStatsMonth[id];
    }
    else {
        return state.healthScoreStatsWeek[id];
    }
  },
  getAllStages: (state, getters, store, rootGetters) => {
    return state.stages;
  },
  getCurrentFilter: (state) => {
    return state.currentFilter;
  },
  getLastSort: (state) => {
    return state.lastSort;
  },
  getLastShowCustomer: (state) => {
    return state.lastShowCustomer;
  },
  getTotalMrr: (state) => {
    return state.totalMrr;
  },
  systemTags: (state, getters) => customer => {
    const tags = [];
    if (!customer) return tags;
    if (getters.isTop(customer)) tags.push('top');
    if (getters.isRenewals(customer)) tags.push('renewals');
    if (getters.isAutorenew(customer)) tags.push('autorenew');
    if (getters.isChurn(customer)) tags.push('churn');
    if (getters.isDropping(customer)) tags.push('dropping');
    if (getters.isGhost(customer)) tags.push('ghost');
    if (getters.isFollowed(customer)) tags.push('followed');
    return tags;
  },
  isTop: (state, getters) => customer => {
    if (!customer?.mrr) return false;
    return customer.mrr >= state.topMinMrr;
  },
  isRenewals: (state) => customer => {
    if (!customer) return false;
    const deadline = dayjs().add(31, "day").toDate();
    return customer.renewalDate < deadline;
  },
  isAutorenew: (state) => customer => {
    if (!customer) return false;
    return customer.renewalDate && !customer.autoRenew;
  },
  isChurn: (state) => customer => {
    if (!customer) return false;
    return customer.healthScore !== null && customer.healthScore < 4;
  },
  isDropping: (state) => customer => {
    if (!customer) return false;
    return customer.prevHealthScore && customer.healthScore < customer.prevHealthScore;
  },
  isNotOwned: (state, getters, rootState, rootGetters) => customer => {
    if (!customer) return false;
    if (!customer.team?.length) return true;
    return !customer.team?.find((u) => u.role === 2);
  },
  isFollowed: (state, getters, rootState, rootGetters) => customer => {
    if (!customer) return false;
    const currentUserId = rootGetters["users/currentUserId"];
    return !!customer.team?.find((u) => u.userId === currentUserId);
  },
  isGhost: (state, getters, rootState, rootGetters) => customer => {
    if (!customer) return false;
    const ghostPeriodUsage = rootGetters["configs/get"]("ghostPeriodUsage") || 30;
    const ghostPeriodInterraction = rootGetters["configs/get"]("ghostPeriodInterraction") || 30;
    return (!customer.lastActivityTs ||
        dayjs().diff(dayjs(customer.lastActivityTs), "days") >
          ghostPeriodUsage) &&
      (!customer.lastTouchTs ||
        dayjs().diff(dayjs(customer.lastTouchTs), "days") >
          ghostPeriodInterraction);
  },
  getDefaultTags: () => [
    // { id: "myself", name: i18n.t('customers.dashboard.cards.list.filter.myself'), color: '#FFCE67', icon: false},
    {
      id: "followed",
      name: i18n.t("customers.dashboard.cards.list.filter.followed"),
      color: "#FFCE67",
    },
    {
      id: "top",
      name: i18n.t("customers.dashboard.cards.list.filter.top"),
      color: "#FFCE67",
    },
    {
      id: "renewals",
      name: i18n.t("customers.dashboard.cards.list.filter.renewals"),
      color: "#FFCE67",
    },
    {
      id: "autorenew",
      name: i18n.t("customers.dashboard.cards.list.filter.autorenew"),
      color: "#FFCE67",
    },
    {
      id: "churn",
      name: i18n.t("customers.dashboard.cards.list.filter.churn"),
      color: "#FFCE67",
    },
    {
      id: "dropping",
      name: i18n.t("customers.dashboard.cards.list.filter.dropping"),
      color: "#FFCE67",
    },
    {
      id: "ghost",
      name: i18n.t("customers.dashboard.cards.list.filter.ghost"),
      color: "#FFCE67",
    },
  ],
  setListFilter: (state, getters, rootState, rootGetters) => ({ search, user, tags, stages, paymentStages, profiles, goalProfiles, goals, plans, customers }) => {
    const currentUserId = rootGetters['users/currentUserId'];
    const ghostPeriodUsage = rootGetters["configs/get"]("ghostPeriodUsage") || 30;
    const ghostPeriodInterraction = rootGetters["configs/get"]("ghostPeriodInterraction") || 30;
    const list = getters.getAll.filter((a) => search?.length ? a.name.toLowerCase().includes(search.toLowerCase()) : true);
    let filteredList = list;
    let listByMrr, deadline;
    if (user?.value) {
      switch (user.mode) {
        case 'USER': {
            if (user.value === 'nobody') {
                filteredList = filteredList.filter((a) => !a.team.find((u) => u.role === 2));
            } else {
                filteredList = filteredList.filter((a) => a.team.find((u) => u.userId === user.value && u.role === 2));
            }
            break;
        }
        case 'TEAM': {
            const teamMembers = rootGetters['usersTeams/getMembersFromId'](user.value);
            filteredList = filteredList.filter((a) => a.team.find((u) => teamMembers.includes(u.userId) && u.role === 2));
            break;
        }
      }
    } 
    if (tags?.value?.length) {
      if (tags.operator === 'AND') {
            for (const tagId of tags.value) {
                switch (tagId) {
                case "top":
                    filteredList = filteredList.filter((a) => a.mrr && a.mrr >= state.topMinMrr);
                    break;
                case "renewals":
                    deadline = dayjs().add(31, "day").toDate();
                    filteredList = filteredList.filter((a) => a.renewalDate && a.renewalDate < deadline);
                    break;
                case "autorenew":
                    filteredList = filteredList.filter((a) => a.renewalDate && !a.autoRenew);
                    break;
                case "churn":
                    filteredList = filteredList.filter((a) => a.healthScore !== null && a.healthScore < 4);
                    break;
                case "myself":
                    filteredList = filteredList.filter((a) => a.team.find((u) => u.userId === currentUserId && u.role === 2));
                    break;
                case "followed":
                    filteredList = filteredList.filter((a) => a.team.find((u) => u.userId === currentUserId));
                    break;
                case "ghost":
                    filteredList = filteredList.filter((a) => (!a.lastActivityTs || dayjs().diff(dayjs(a.lastActivityTs), "days") > ghostPeriodUsage) &&
                                                            (!a.lastTouchTs || dayjs().diff(dayjs(a.lastTouchTs), "days") > ghostPeriodInterraction)
                    );
                    break;
                case "dropping":
                    filteredList = filteredList.filter((a) => a.prevHealthScore && a.healthScore < a.prevHealthScore);
                    break;
                default:
                    filteredList = filteredList.filter((a) => a.tags.includes(tagId));
                    break;
                }
            }
        }
        else if (tags.operator === 'OR') {
            filteredList = filteredList.filter((a) => {
              for (const tagId of tags.value) {
                switch (tagId) {
                case "top":
                    if (a.mrr && a.mrr >= state.topMinMrr) return true;
                    break;
                case "renewals":
                    deadline = dayjs().add(31, "day").toDate();
                    if (a.renewalDate && a.renewalDate < deadline) return true;
                    break;
                case "autorenew":
                    if (a.renewalDate && !a.autoRenew) return true;
                    break;
                case "churn":
                    if (a.healthScore !== null && a.healthScore < 4) return true;
                    break;
                case "myself":
                    if (a.team.find((u) => u.userId === currentUserId && u.role === 2)) return true;
                    break;
                case "followed":
                    if (a.team.find((u) => u.userId === currentUserId)) return true;
                    break;
                case "ghost":
                    if ((!a.lastActivityTs || dayjs().diff(dayjs(a.lastActivityTs), "days") > ghostPeriodUsage) && (!a.lastTouchTs || dayjs().diff(dayjs(a.lastTouchTs), "days") > ghostPeriodInterraction)) return true;
                    break;
                case "dropping":
                    if (a.prevHealthScore && a.healthScore < a.prevHealthScore) return true;
                    break;
                default:
                    if (a.tags.includes(tagId)) return true;
                    break;
                }
              }
              return false;
            });
        }
    }
    if (tags?.exclude?.length) {
        for (const tagId of tags.exclude) {
            switch (tagId) {
            case "top":
                filteredList = filteredList.filter((a) => a.mrr && a.mrr >= state.topMinMrr);
                break;
            case "renewals":
                deadline = dayjs().add(31, "day").toDate();
                filteredList = filteredList.filter((a) => !a.renewalDate || a.renewalDate >= deadline);
                break;
            case "autorenew":
                filteredList = filteredList.filter((a) => !a.renewalDate || a.autoRenew);
                break;
            case "churn":
                filteredList = filteredList.filter((a) => a.healthScore === null || a.healthScore >= 4);
                break;
            case "myself":
                filteredList = filteredList.filter((a) => !a.team.find((u) => u.userId === currentUserId && u.role === 2));
                break;
            case "followed":
                filteredList = filteredList.filter((a) => !a.team.find((u) => u.userId === currentUserId));
                break;
            case "ghost":
                filteredList = filteredList.filter((a) => (a.lastActivityTs && dayjs().diff(dayjs(a.lastActivityTs), "days") <= ghostPeriodUsage) &&
                                                        (a.lastTouchTs && dayjs().diff(dayjs(a.lastTouchTs), "days") <= ghostPeriodInterraction)
                );
                break;
            case "dropping":
                filteredList = filteredList.filter((a) => !a.prevHealthScore || a.healthScore >= a.prevHealthScore);
                break;
            default:
                filteredList = filteredList.filter((a) => !a.tags.includes(tagId));
                break;
            }
        }
}
    if (stages?.length) {
      const stagesValues = stages.map((s) => s.value ?? s);
      filteredList = filteredList.filter((a) => stagesValues?.includes(a.stage));
    } else {
      filteredList = filteredList.filter((a) => a.stage !== "churn");
    }
    if (paymentStages?.length) {
      const hasNotSetPaymentStage = paymentStages.includes(null);
      filteredList = filteredList.filter((a) => paymentStages.includes(a.payment?.status) || (hasNotSetPaymentStage && !a.payment));
    }
    if (profiles?.length) {
      filteredList = filteredList.filter((a) => profiles.includes(a.profileId));
    }
    if (goalProfiles?.length) {
      filteredList = filteredList.filter((a) => goalProfiles.includes(a.goalProfileId));
    }
    if (goals?.length) {
      filteredList = filteredList.filter((a) => goals.includes(a.goalId));
    }
    if (plans?.length) {
      filteredList = filteredList.filter((a) => plans.includes(a.plan));
    }
    if (customers?.length) {
        filteredList = filteredList.filter((a) => customers.includes(a.id));
    }
    return filteredList;
  },
  setCtxFilter: (state, getters, rootState, rootGetters) => (ctx, { filterPrefix = '', user, tags, stages, paymentStages, profiles, goalProfiles, goals, plans }) => {
    const currentUserId = rootGetters['users/currentUserId'];
    const ghostPeriodUsage = rootGetters["configs/get"]("ghostPeriodUsage") || 30;
    const ghostPeriodInterraction = rootGetters["configs/get"]("ghostPeriodInterraction") || 30;
      let deadline;
    if (user?.value) {
        switch (user.mode) {
          case 'USER': {
              if (user.value === 'nobody') {
                  ctx.filters[`${filterPrefix}owner`] = {
                      value: 'null',
                  };
              } else {
                  ctx.filters[`${filterPrefix}owner`] = {
                      value: user.value
                  };
              }
              break;
          }
          case 'TEAM': {
              const teamMembers = rootGetters['usersTeams/getMembersFromId'](user.value);
              ctx.filters[`${filterPrefix}owner`] = {
                type: "enum",
                value: [...teamMembers]
              };
              break;
          }
        }
    }
    if (tags?.value?.length || tags?.exclude?.length) {
          ctx.filters[`${filterPrefix}tags`] = {
            type: "enum",
            value: tags.value || [],
            exclude: tags.exclude || [],
            operator: tags.operator
          };
      }
      if (stages?.length) {
        ctx.filters[`${filterPrefix}stage`] = {
          type: "enum",
          value: stages.map((s) => s.value ?? s),
        };
      } else if (!ctx.filters[`${filterPrefix}stage`]) {
        ctx.filters[`${filterPrefix}stage`] = {
          value: "churn",
          operator: "not",
        };
      }
      if (paymentStages?.length) {
        const hasNotSetPaymentStage = paymentStages.includes(null);
        ctx.filters[`${filterPrefix}paymentStage`] = {
          type: "enum",
          value: hasNotSetPaymentStage ? [...paymentStages.filter((s) => s?.length), "NOTSET"] : [...paymentStages.filter((s) => s?.length)]
        };
      }
      if (profiles?.length) {
        ctx.filters[`${filterPrefix}profileId`] = {
          type: "enum",
          value: [...profiles]
        };
      }
      if (goalProfiles?.length) {
        ctx.filters[`${filterPrefix}goalProfileId`] = {
          type: "enum",
          value: [...goalProfiles]
        };
      }
      if (goals?.length) {
        ctx.filters[`${filterPrefix}goalId`] = {
          type: "enum",
          value: [...goals]
        };
      }
      if (plans?.length) {
        ctx.filters[`${filterPrefix}plan`] = {
          type: "enum",
          value: [...plans]
        };
      }
      return ctx;
  }
};

const actions = {

  async ensureCustomers({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetched[clientId]) {
      return await dispatch('fetchCustomers');
    }
    return state.customers[clientId];
  },
  async ensureCustomerId({ dispatch, state }, { customerId }) {
    const clientId = this.getters.currentClientId;
    if (!state.synced[clientId] || !state.customers[clientId]?.find(i => i.id === customerId)) {
      await dispatch('fetchCustomer', { customerId });
    }
  },
  async ensureChurns({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.churns[clientId]) {
      return await dispatch('fetchChurns');
    }
    return state.customers[clientId];
  },
  async ensureCustomersStats({ dispatch, state }) {
    if (!Object.keys(state.stats).length) {
      await dispatch('fetchCustomersStats');
    }
  },
  async ensureCustomerStats({ dispatch, state }, { customerId }) {
    if (!state.customerStats[customerId]) {
        await dispatch('fetchCustomerStats', { customerId });
    }
  },
  async ensureHealthScoreStats({ dispatch, state }, { customerId, period = 'WEEK', env }) {
    if (period === 'MONTH' && !state.healthScoreStatsMonth[customerId]) {
        await dispatch('fetchHealthScoreStats', { customerId, period, env });
    }
    else if (period === 'WEEK' && !state.healthScoreStatsWeek[customerId]) {
        await dispatch('fetchHealthScoreStats', { customerId, period, env });
    }
  },
  async ensureKpis({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetchedKpis[clientId]) {
      return await dispatch('fetchCustomersKpis');
    }
    return state.customersKpis[clientId];
  },
  async fetchCustomers({ dispatch, commit }, options) {
    try {
        return await debounce('fetchCustomers', async () => {
            const clientId = this.getters.currentClientId;
            const loadData = async () => {
                let rawData = (await this.getters.api.get('/customers')).map(fromAPI);
                if (rawData?.length) {
                    // Timeout kpis retrieved
                    const allKpis = await Promise.race([dispatch('ensureKpis'), sleep(5000)]);
                    rawData = await utilsWorker?.processAll(rawData, allKpis);
                }
                EventsBus.emit('init_stop', 'customers');
                return await initData(rawData, false);
            };
            const initData = async (data, fromDb) => {
                if (!fromDb) {
                    dbSaveCustomers(clientId, data);
                }
                // data = await utilsWorker?.processChildren(data);

                commit('setCustomers', { clientId, data });
                commit('fetched', { clientId, value: true });
                commit('churns', { clientId, value: true });

                if (!fromDb) {
                    commit('synced', { clientId, value: true });
                }

                return data;
            };
            try {
                const cachedCustomers = options?.nocache === true ? [] : await utilsWorker?.getCached({ clientId });
                if (cachedCustomers?.length) {
                    setTimeout(loadData, 0);
                    EventsBus.emit('init_start', {
                        key: 'customers',
                        message: i18n.t('cached.customer.refresh.pending'),
                        priority: 1
                    });
                    return await initData(cachedCustomers, true);
                }
            }
            catch (ex) {
                console.error(ex);
            }
            return await loadData();
        }); 
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchCustomer({ state, dispatch, commit }, { customerId }) {
    try {
      const clientId = this.getters.currentClientId;
      const data = fromAPI(await this.getters.api.get(`/customers/${customerId}`));
      data._dataPath = [data.id];
      if (data.parentId && state.customers[clientId]?.length) {
        const parent = state.customers[clientId].find(c => c.id === data.parentId);
        if (parent) {
            // Update grid child path
            data._dataPath = [...(parent._dataPath || []), data.id];
        }
      }

      commit('setCustomer', { clientId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchChurns({ dispatch, commit }) {
    try {
      const clientId = this.getters.currentClientId;
      const data = (await this.getters.api.get('/customers', {
        params: {
            'churn': 1
        }
      })).map(fromAPI);
      for (const cust of data) {
        commit('setCustomer', { clientId, data: cust });
      }
      commit('churns', { clientId, value: true });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchCustomersStats({ dispatch, commit }) {
    try {
      const data = await this.getters.api.get('/customers/stats');
      commit('setCustomersStats', { data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchCustomerStats({ dispatch, commit }, { customerId, env }) {
    try {
      const params = {
      };
      if (env) {
          params.env = env;
      }
      const data = await this.getters.api.get(`/customers/${customerId}/stats`, {
            params
      });
      if (!env) commit('setCustomerStats', { customerId, data, env });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchHealthScoreStats({ dispatch, commit }, { customerId, period = 'WEEK', env }) {
    try {
        const clientId = this.getters.currentClientId;
        const params = {
            by: period.toLowerCase()
        };
        if (env) {
            params.env = env;
        }
      const data = await this.getters.api.get(`/customers/${customerId}/healthscorev2`, {
          params
      });
      if (!env) commit('setHealthScoreStats', { clientId, customerId, data, period, env });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchCustomersKpis({ dispatch, commit, getters }) {
    try {
        return await debounce('fetchCustomersKpis', async () => {
            const clientId = this.getters.currentClientId;
            const loadData = async () => {
                const rawData = await this.getters.api.get('/customers/kpis');
                const { kpis, customers } = await utilsWorker?.processAllKpi(state.synced[clientId] ? getters.getAll : null, rawData);
                // Check if customers allready initialized -> Set kpis
                if (customers?.length) {
                    dbSaveCustomers(clientId, customers);
                    // Update store and indexedDb customers with fresh kpis
                    commit('setCustomers', { clientId, data: customers });
                }
                return await initData(kpis, false);
            };
            const initData = async (data, fromDb) => {
                if (!fromDb) {
                    utilsWorker?.dbAddKpis(clientId, data);
                }
                commit('setCustomersKpis', { clientId, data });
                commit('fetchedKpis', { clientId, value: true });
                return data;
            };
            try {
                const cachedCustomersKpi = await utilsWorker?.getCachedKpi({ clientId });
                if (cachedCustomersKpi?.length) {
                    setTimeout(loadData, 0);
                    return await initData(cachedCustomersKpi, true);
                }
            }
            catch (ex) {
                console.error(ex);
            }
            return await loadData();
        });

    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async match({ commit }, { conditions, contactConditions }) {
    try {
        const searchId = await this.getters.api.post(`/customers-search`, {
            data: {
                conditions,
                contactConditions
            }
        });
        if (searchId) {
            let status = null;
            let filterResult = null;
            while (!['success', 'error'].includes(status?.toLowerCase?.())) {
                await sleep(2000);
                filterResult = await this.getters.api.post(`/customers-search/${searchId}/status`);
                status = filterResult.status;
                console.info(status);
            }
            console.info(filterResult?.result?.results?.last?.data?.matched);
            return searchId; //filterResult?.result?.results?.last?.data?.matched;
        }
      } catch (error) {
        if (error.response?.status === 400 && error.response.data?.data?.code === 'ERR_VALIDATION') {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.error'),
                variant: 'danger',
                text: i18n.t(`playbooks.activations.ope.failed.${error.response.data.data.data[0]}`)
            });
        }
        throw 'A server error has occurred';
      }
  },
  async matchResult({ commit }, { searchId, ctx }) {
    try {
        if (!searchId) return [];
        const query = {
            cache: true
        };
        if (ctx?.page) {
          query.pagination = ctx;
        }
        const results = await this.getters.api.get(`/customers-search/${searchId}/result`, query);
        return ctx ? {
            total: results.metadata.pagination.total || 0,
            page: results.metadata.pagination.page || 1,
            data: results.data
        } : results.data;
      } catch (error) {
        if (error.response?.status === 400 && error.response.data?.data?.code === 'ERR_VALIDATION') {
            EventsBus.emit('notify', {
                title: i18n.t('commons.ope.error'),
                variant: 'danger',
                text: i18n.t(`playbooks.activations.ope.failed.${error.response.data.data.data[0]}`)
            });
        }
        throw 'A server error has occurred';
      }
  },
  async add({ commit }, { customer }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = fromAPI(await this.getters.api.post(`/customers`, { data: toAPI(customer) }));
        // Init child path
        data._dataPath = [data.id];

        commit('setCustomer', { clientId, data });
        if (customer.parentId) {
            commit('addCustomerChild', { clientId, customerId: customer.parentId, childId: data.id });
        }
        return data;
      } catch (error) {
        EventsBus.emit('notify', {
            title: i18n.t('commons.ope.error'),
            variant: 'danger',
            text: i18n.t(`customers.ope.created.failed`)
        });
        throw 'A server error has occurred';
      }
  },
  async update({ commit, getters, rootGetters }, { customerId, customer }) {
    try {
        const clientId = this.getters.currentClientId;
        const old = getters.getFromId(customerId);
        if (old?.stage === customer.stage) {
            delete customer.stage;
        }
        if (isEqual(old?.team, customer?.team)) {
            delete customer.team;
        }
        const customs = rootGetters['customs/getAllByEntity']('CUSTOMER');
        if (customs.length) {
            for (const custom of customs) {
                if (customer[custom.id] === old[custom.id]) {
                    delete customer[custom.id];
                }
            }
        }
        const data = await this.getters.api.patch(`/customers/${customerId}`, { data: toAPI(customer) });
        
        if (old.parentId && old.parentId !== customer.parentId) {
            commit('removeCustomerChild', { clientId, customerId: old.parentId, childId: customerId });
        }
        if (customer.parentId) {
            commit('addCustomerChild', { clientId, customerId: customer.parentId, childId: customerId });
        }
        // TODO remove
        customer.id = customerId;
        customer.updatedAt = new Date();
        commit('setCustomer', { clientId, data: fromAPI({ ...old, ...customer }) });
        return getters.getFromId(customerId);
      } catch (error) {
        EventsBus.emit('notify', {
            title: i18n.t('commons.ope.error'),
            variant: 'danger',
            text: i18n.t(`customers.ope.updated.failed`)
        });
        throw 'A server error has occurred';
      }
  },
  async updateScore({ commit }, { customerId, csmScore }) {
    try {    
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.patch(`/customers/${customerId}/score`, { data: { csmScore: csmScore } });
        commit('setCustomer', { clientId, data: { id: customerId, csmScore, updatedAt: new Date(), lastCsmTs: new Date() } });
        commit('setCustomerStatsCsmScore', { customerId, score: csmScore });
      } catch (error) {
        EventsBus.emit('notify', {
            title: i18n.t('commons.ope.error'),
            variant: 'danger',
            text: i18n.t(`customers.ope.updated.failed`)
        });
        throw 'A server error has occurred';
      }
  },
  async updateTags({ commit }, { customerId, tags }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.put(`/customers/${customerId}/tags`, { data: tags });
        commit('setCustomer', { clientId, data: { id: customerId, tags, updatedAt: new Date() } });
      } catch (error) {
        EventsBus.emit('notify', {
            title: i18n.t('commons.ope.error'),
            variant: 'danger',
            text: i18n.t(`customers.ope.updated.failed`)
        });
        throw 'A server error has occurred';
      }
  }, 
  async remove({ commit }, { customerId }) {
    try {
        const clientId = this.getters.currentClientId;
        await this.getters.api.delete(`/customers/${customerId}`);
        const old = getters.getFromId(customerId);
        if (old.parentId) {
            commit('removeCustomerChild', { clientId, customerId: old.parentId, childId: customerId });
        }
        commit('removeCustomer', { clientId, customerId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async setListFilter({ getters, rootGetters }, { search, user, tags, stages, paymentStages, profiles, goalProfiles, goals, plans, withParents, customers }) {
    const currentUserId = rootGetters['users/currentUserId'];
    const ghostPeriodUsage = rootGetters["configs/get"]("ghostPeriodUsage") || 30;
    const ghostPeriodInterraction = rootGetters["configs/get"]("ghostPeriodInterraction") || 30;
    const teamMembers = user?.mode === 'TEAM' && user?.value ? rootGetters['usersTeams/getMembersFromId'](user.value) : [];
    const topMinMrr = state.topMinMrr;

    return await utilsWorker?.setListFilter({ search, user, tags, stages, paymentStages, profiles, goalProfiles, goals, plans, withParents, customersId: customers }, { customers: getters.getAll, currentUserId, ghostPeriodUsage, ghostPeriodInterraction, topMinMrr, teamMembers });
  },
  async export({ dispatch, commit }, { ctx }) {
    try {
      const query = {
          cache: false,
          pagination: ctx
      };
      const results = await this.getters.api.post(`/customers/export`, query);
      const exportId = results.data?.id;
      if (exportId) {
        EventsBus.emit('beginExport', {
            ...results.data
        });
        const result = await this.dispatch('exports/waitExport', { exportId });
        console.info(result);
        EventsBus.emit('endExport', {
            id: exportId,
            result
        });
        return result;
      }
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async import({ dispatch, rootGetters }, { customers }) {
    const result = {
        count: 0,
        created: 0,
        updated: 0,
        deleted: 0,
        error: 0
    };
    try {
        EventsBus.emit('customers/import/start', {
            count: customers.length
        });
        const customs = rootGetters['customs/getAllByEntity']('CUSTOMER');

        const actualCustomers = await dispatch('fetchCustomers');
        // All customer without parent first to create them before
        customers = customers.sort((a, b) => {
            if (!a.parentId) return -1;
            if (!b.parentId) return 1;
            return a.parentId - b.parentId;
        });
        for (const customer of customers.filter(c => !c.deleted)) {
            customer.source = 'IMPORT';
            const found = actualCustomers.find(c => (c.refId?.length && c.refId?.toLowerCase() === customer.refId?.toLowerCase()) || (c.domain?.length && c.domain?.toLowerCase() === customer.domain?.toLowerCase()) || (c.name?.length && c.name?.toLowerCase() === customer.name?.toLowerCase()));
            if (found) {
                const updated = { ...found };
                updated.name = customer.name;
                if (customer.refId) updated.refId = customer.refId;
                if (customer.domain) updated.domain = customer.domain?.toLowerCase();
                if (customer.description) updated.description = customer.description;
                if (customer.csmScore) updated.csmScore = customer.csmScore;
                if (customer.stage) updated.stage = customer.stage.toLowerCase();
                if (customer.profileId) updated.profileId = customer.profileId;
                if (customer.goalId) updated.goalId = customer.goalId;
                if (customer.owner) {
                    updated.team = deepCopy(updated.team);
                    updated.team.forEach(u => u.role = 1);
                    const foundTeamMember = updated.team.find(u => u.userId === customer.owner);
                    if (foundTeamMember) foundTeamMember.role = 2;
                    else updated.team.push({ userId: customer.owner, role: 2 });
                    delete customer.owner;
                }
                if (customer.team) {
                    updated.team = deepCopy(updated.team);
                    for (const id of customer.team) {
                        const foundTeamMember = updated.team.find(u => u.userId === id);
                        if (!foundTeamMember) updated.team.push({ userId: id, role: 1 });
                    }
                    delete customer.team;
                }
                if (customer.tags && typeof customer.tags !== 'undefined') updated.tags = customer.tags;
                else delete updated.tags;
                // Update all custom defined
                if (customs.length) {
                    for (const custom of customs) {
                        if (typeof customer[custom.id] !== 'undefined') {
                            updated[custom.id] = parseDataType(customer, custom);
                        }
                    }
                }

                if (customer.parentId?.length) {
                    const parentFound = actualCustomers.find(c => (c.refId?.length && c.refId?.toLowerCase() === customer.parentId?.toLowerCase()) || (c.domain?.length && c.domain?.toLowerCase() === customer.parentId?.toLowerCase()) || (c.name?.length && c.name?.toLowerCase() === customer.parentId?.toLowerCase()));
                    updated.parentId = parentFound?.id;
                }
                else if (typeof customer.parentId !== 'undefined') {
                    updated.parentId = null;
                }
                try {
                    await dispatch('update', { customerId: updated.id, customer: updated });
                    if (customer.csmScore !== null) await dispatch('updateScore', { customerId: updated.id, csmScore: customer.csmScore });
                    result.updated++;
                }
                catch (ex) {
                    console.error(ex);
                    result.error++;
                }
            }
            else {
                if (customer.team) {
                    customer.team = customer.team.map(u => ({ userId: u, role: 1 }));
                }
                if (customer.owner) {
                    if (customer.team) {
                        customer.team.push({ userId: customer.owner, role: 2 });
                    }
                    else {
                        customer.team = [{ userId: customer.owner, role: 2 }];
                    }
                    delete customer.owner;
                }
                // Delete all custom undefined
                if (customs.length) {
                    for (const custom of customs) {
                        if (typeof customer[custom.id] === 'undefined') {
                            delete customer[custom.id];
                        } else {
                            customer[custom.id] = parseDataType(customer, custom);
                        }
                    }
                }
                if (customer.parentId?.length) {
                    const parentFound = actualCustomers.find(c => (c.refId?.length && c.refId?.toLowerCase() === customer.parentId?.toLowerCase()) || (c.domain?.length && c.domain?.toLowerCase() === customer.parentId?.toLowerCase()) || (c.name?.length && c.name?.toLowerCase() === customer.parentId?.toLowerCase()));
                    customer.parentId = parentFound?.id;
                }
                else {
                    customer.parentId = null;
                }
                try {
                    const data = await dispatch('add', { customer });
                    if (customer.csmScore !== null) await dispatch('updateScore', { customerId: data.id, csmScore: customer.csmScore });
                    result.created++;
                    actualCustomers.push(data);
                }
                catch (ex) {
                    console.error(ex);
                    result.error++;
                }
            }
            result.count++;
            EventsBus.emit('customers/import/process', customer);
            console.info(customer);
        }
        for (const customer of customers.filter(c => !!c.deleted)) {
            const found = actualCustomers.find(c => (c.refId?.length && c.refId?.toLowerCase() === customer.refId?.toLowerCase()) || (c.domain?.length && c.domain?.toLowerCase() === customer.domain?.toLowerCase()) || (c.name?.length && c.name?.toLowerCase() === customer.name?.toLowerCase()));
            if (found) {
                try {
                    await dispatch('remove', { customerId: found.id });
                    result.deleted++;
                }
                catch (ex) {
                    console.error(ex);
                    result.error++;
                }
            }
            EventsBus.emit('customers/import/process', customer);
            result.count++;
        }
        // Raz server redis cache
        if (customers?.length) {
            this.dispatch('cache/resetServer');
        }
        // const data = await this.getters.api.post(`/customers`, { data: toAPI(customer) });
        // commit('setCustomer', { clientId, data: fromAPI(data) });
        
      } catch (error) {
        throw 'A server error has occurred';
      }
      return result;
  },
  updateCurrentFilter({ commit }, filters) {
    commit('setCurrentFilter', filters);
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  synced(state, { clientId, value }) {
    Vue.set(state.synced, clientId, value);
  },
  fetched(state, { clientId, value }) {
    state.fetched[clientId] = value;
  },
  churns(state, { clientId, value }) {
    state.churns[clientId] = value;
  },
  fetchedKpis(state, { clientId, value }) {
    state.fetchedKpis[clientId] = value;
  },
  setCustomer(state, { clientId, data }) {
    const index = state.customers[clientId].findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.customers[clientId], index, { ...state.customers[clientId][index], ...data});
      getDb()?.customers?.update(data.id, {
        data: { ...state.customers[clientId][index], ...data},
        updatedAt: new Date()
      });
    }
    else {
        state.customers[clientId].push(data);
        getDb()?.customers?.add({
          id: data.id,
          clientId,
          data,
          createdAt: new Date(),
          updatedAt: new Date()
        });
    }
  },
  addCustomerChild(state, { clientId, customerId, childId }) {
    const parent = state.customers[clientId].find(c => c.id === customerId);
    const child = state.customers[clientId].find(c => c.id === childId);
    if (parent && child) {
        parent.children = parent.children || [];
        if (!parent.children.find(c => c.id === childId)) parent.children.push(child);
        // Update grid child path
        child._dataPath = [...(parent._dataPath || []), child.id];
    }
  },
  removeCustomerChild(state, { clientId, customerId, childId }) {
    const parent = state.customers[clientId].find(c => c.id === customerId);
    if (parent?.children?.length) {
        parent.children = parent.children.filter(c => c.id !== childId);
        if (!parent.children.length) delete parent.children;
    }
    // Update grid child path
    const child = state.customers[clientId].find(c => c.id === childId);
    if (child) {
        child._dataPath = [child.id];
    }
  },
  setCustomers(state, { clientId, data }) {
    const listByMrr = data.filter((a) => a.mrr).sort((a, b) => b.mrr - a.mrr);
    if (listByMrr.length) {
        let totalMrr = 0;
        listByMrr.forEach((c) => (totalMrr = totalMrr + c.mrr));
        state.totalMrr = totalMrr;
        const index = Math.floor(listByMrr.length * 0.3);
        state.topMinMrr = listByMrr[index].mrr;
    }
    Vue.set(state.customers, clientId, data);
  },
  setCustomersKpis(state, { clientId, data }) {
    Vue.set(state.customersKpis, clientId, data);
  },
  setCustomersStats(state, { data }) {
    state.stats = data;
  },
  setCustomerStats(state, { customerId, data }) {
    Vue.set(state.customerStats, customerId, data);
  },
  setCustomerStatsCsmScore(state, { customerId, score }) {
    const stats = state.customerStats[customerId];
    if (stats?.healthScores) {
        stats.healthScores.csm = score;
    }
  },
  setHealthScoreStats(state, { clientId, customerId, data, period = 'WEEK' }) {
    data.forEach(d => {
        if (!d.children) delete d.children;
    });
    if (period === 'MONTH') {
        Vue.set(state.healthScoreStatsMonth, customerId, data);
    }
    else {
        Vue.set(state.healthScoreStatsWeek, customerId, data);
    }
  },
  removeCustomer(state, { clientId, customerId }) {
    const index = state.customers[clientId].findIndex(c => c.id === customerId);
    if (index > -1) {
        state.customers[clientId].splice(index, 1);
        getDb()?.customers?.delete(customerId);
    }
  },
  setCurrentFilter(state, filter) {
    state.currentFilter = {...state.currentFilter, ...filter };
  },
  setLastSort(state, { sortName, sortDirection, page }) {
    state.lastSort = {
        sortName,
        sortDirection,
        page
    };
  },
  setLastShowCustomer(state, { customerId }) {
    state.lastShowCustomer = customerId;
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
    for (const key of Object.keys(state.churns)) {
        state.churns[key] = false;
      }
    for (const key of Object.keys(state.customerStats)) {
      state.customerStats[key] = false;
    }
    state.stats = {};
  }
};

const dbSaveCustomers = debounceTimer((clientId, data) => {
    utilsWorker?.dbAdd(clientId, data);
}, 500);

export default {
  namespaced: true,
  init: async (context) => {
    utilsWorker = await spawn(new Worker("./workers/customers"));
    // Sync each 10 min all customers
    setInterval(async () => {
        await context?.dispatch('fetchCustomers', { nocache: true });
        EventsBus.emit('customers/refresh', {});
    }, REFRESH_CUSTOMER); // 25 min

    SseService.on('customers/update', async ({ customerId }) => {
        if (customerId) {
            const isNew = !context?.getters?.getFromId(customerId);
            await context?.dispatch('fetchCustomer', { customerId });
            // Signal to customer list
            EventsBus.emit('customers/refresh', isNew ? {} : { customerId });
        }
        else {
            await context?.dispatch('fetchCustomers', { nocache: true });
            // Signal to customer list
            EventsBus.emit('customers/refresh', {});
        }
    });
    SseService.on('customers/delete', async ({ clientId, customerId }) => {
        if (customerId) {
            const old = context?.getters?.getFromId(customerId);
            if (old?.parentId) {
                context?.commit('removeCustomerChild', { clientId, customerId: old.parentId, childId: customerId });
            }
            context?.commit('removeCustomer', { clientId, customerId });
            // Signal to customer list
            EventsBus.emit('customers/refresh', {});
        }
    });
  },
  getters,
  actions,
  state,
  mutations
};
