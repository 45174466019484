export const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£'
};

export const defaultCurrencySymbols = '€';

export function getCurrenciesOptions() {
  return [
    { value: 'EUR', text: 'EUR (€)' },
    { value: 'USD', text: 'USD ($)' },
    { value: 'GBP', text: 'GBP (£)' }
  ];
}