import dayjs from 'dayjs';

const state = {
  context: {},
  currentItem: '',
  univers: [],
  authorisations: [],
  features: [
      {
        name: 'onboarding',
        internal: false
      },
      {
        name: 'user.team',
        authorisation: 'user.team',
        internal: false,
    },
      {
        name: 'playbook',
        authorisation: 'playbook',
        internal: false,
        // allowedClientIds: ['d0fd3591c03aff5c', '6cc71abd6dcded68', '8ed920c8c1f3b2e5', 'a0c224f3974433f9', 'f437aaf73a7ce52e', 'cc1aa253551cb998']
    },
    {
        name: 'report.team',
        authorisation: 'report.team',
        internal: false,
        // allowedClientIds: ['d0fd3591c03aff5c', '6cc71abd6dcded68', '8ed920c8c1f3b2e5', 'a0c224f3974433f9', 'f437aaf73a7ce52e', 'cc1aa253551cb998']
    },
    {
        name: 'report.profitability',
        authorisation: 'report.profitability',
        internal: false
    },
    {
        name: 'report.mrr',
        authorisation: 'report.mrr',
        internal: true,
        hidden: true
    },
    {
        name: 'custom',
        authorisation: 'custom',
        internal: false
    },
    {
        name: 'chatGPT',
        authorisation: 'chatGPT',
        internal: false
    },
    {
        name: 'goal',
        authorisation: 'goal',
        internal: false,
        hidden: true
    }
  ]
};

export function isMenuItemAuthorised(item, rootGetters) {
  if (!item.hasOwnProperty('authorisation')) {
    return true;
  }
  if (item.authorisation.onlyInternal && !rootGetters.isUserInternal()) {
    return false;
  }
  if (item.authorisation.allowInternal && rootGetters.isUserInternal()) {
    return true;
  }
  if (item.authorisation.level && rootGetters.getUserPartitionLevel() < item.authorisation.level) {
    return false;
  }
  return rootGetters.isUserAllowed(item.authorisation.feature, item.authorisation.action);
}

const getters = {
  context: state => state.context,
  currentItem: state => state.currentItem,
  hasUserAuthorisation: (state, getters, store, rootGetters)  => ({ feature }) => {
    const user = rootGetters['users/currentUser'];
    const featureInfos = state.features.find(f => f.name === feature);
    if (!featureInfos) return true;
    if (featureInfos.internal && user?.internal) return true;
    if (featureInfos.allowedClientIds?.includes(rootGetters['currentClientId'])) return true;
    if (featureInfos.authorisation) return getters.getAuthorisations.includes(featureInfos.authorisation);
    if (featureInfos.internal === false) return true;
    return false;
  },
  isFeatureVisible: (state, getters, store, rootGetters)  => ({ feature }) => {
    const user = rootGetters['users/currentUser'];
    const featureInfos = state.features.find(f => f.name === feature);
    if (!featureInfos) return true;
    if (featureInfos.allowedClientIds && !featureInfos.allowedClientIds?.includes(rootGetters['currentClientId'])) return false;
    if (featureInfos.hidden) return getters.getAuthorisations.includes(featureInfos.authorisation);
    if (featureInfos.internal) return user?.internal;
    return true;
  },
  isFeatureCanActivate: (state, getters, store, rootGetters)  => ({ feature }) => {
    const featureInfos = state.features.find(f => f.name === feature);
    return !featureInfos?.hidden;
  },
  getTrialEnabled: (state, getters, store, rootGetters)  => ({ feature }) => {
    const client = rootGetters['clients/currentClient'];
    const additionalFeatures = client?.additionalFeatures;
    if (!additionalFeatures) return;
    const trial = additionalFeatures.find(f => f.name === feature && f.expire && dayjs(f.expire).toDate().getTime() > new Date().getTime());
    if (trial?.expire) return { ...trial, expire: dayjs(trial.expire).toDate() };
    return trial;
  },
  getAuthorisations: state => state.authorisations
};

const actions = {
    async ensureAuthorisations({ dispatch, state }) {
        if (!state.authorisations.length) {
          await dispatch('fetchAuthorisations');
        }
    },
    async fetchAuthorisations({ dispatch, commit, state }) {
        try {
          
            const data = fromAPIAuthorisation((await this.getters.api.get('/features')));
          commit('setAuthorisations', { data: data.features });
          return data;
        } catch (error) {
          throw 'A server error has occurred';
        }
      },
  setCurrentItem(store, value) {
    store.commit('setCurrentItem', value);
  },
  setContext(store, { state, value }) {
    store.commit('setContext', { stateKey: state, value });
  },
  clearContext(store) {
    store.commit('clearContext');
  }
};

export const mutations = {
    setAuthorisations(state, { data }) {
        state.authorisations = data;
      },
  setCurrentItem(state, value) {
    state.currentItem = value;
  },
  setContext(state, { stateKey, value }) {
    state.context[stateKey] = value;
  },
  clearContext(state) {
    state.context = {};
  }
};


function fromAPIAuthorisation(json) {
    return json;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
