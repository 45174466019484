import joi from 'joi';
import enjoi from 'enjoi';

const EXP_DOMAIN = new RegExp(/^[a-zA-Z0-9-_]{1,61}[a-zA-Z0-9-_](?:\.[a-zA-Z-_]{2,})+$/);

export function checkIsValidDomain(domain) {
    if (!domain?.length) return false;
    return !!(domain.match(EXP_DOMAIN) && domain.split('.').length < 5);
}
export function checkHttps(value) {
    if (!value?.length) return false;
    return (value && value.startsWith('https://'));
}
export function email(value) {
    if (!value?.length) return false;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
}

/**
 * Validates body schema
 * @param {*} target
 * @param {*} jsonSchema
 */
export function validateJSONSchema(target, jsonSchema, messagePrefix = '') {
    const schema = enjoi.schema(jsonSchema, {
        extensions: [{
            type: 'any',
            base: joi.any()
        }]
    });
    const result = schema.validate(target, {
        allowUnknown: false,
        abortEarly: false,
        stripUnknown: true
    });
    return {
        errors: result.error ? result.error.details.map((item) => messagePrefix + item.message.replace(/"/gi, '')) : [],
        value: result.value
    };
}