var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-variable",
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "button",
        {
          staticClass: "menubar__button",
          on: {
            click: function($event) {
              _vm.showVariables = true
            }
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("commons.editor.command.variable")) +
              "\n    "
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "items",
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
            }
          }
        },
        [
          _c("variable-infos", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.exit,
                expression: "exit"
              }
            ],
            ref: "info",
            attrs: { options: _vm.items },
            on: { select: _vm.selectItem, cancel: _vm.exit }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }