<template>
    <div v-click-outside="onClickOutside">
        <div class="search-wrapper" v-bind:class="{ active: searchOpen }">
            <div class="input-holder" @keydown.enter="handleSearch">
                <input type="text" ref="searchInput" v-model="term" class="search-input" :placeholder="$t('commons.search.placeholder')" />
                <button class="search-icon" @click="handleSearch"><span/></button>
            </div>
            <button class="close" @click="handleResetSearch"/>
        </div>
        <search-results-panel v-if="showResults" :results="results" @follow="onFollow" />
    </div>
</template>

<script>
    import EventsBus from '@/libs/EventsBus';
    import { debounce } from '@/libs/utils/Debounce';
    import SearchResultsPanel from './SearchResultsPanel';

    export default {
        components: {
            SearchResultsPanel
        },
        data() {
            return {
                searchOpen: false,
                term: '',
                results: null,
                showResults: false
            }
        },
        watch: {
            term: function () {
                this.handleSearch();
            }
        },
        mounted() {
            EventsBus.on('setSearch', this.handleSetSearch);
        },
        destroyed() {
            EventsBus.off('setSearch', this.handleSetSearch);
        },
        methods: {
            handleSearch() {
                if (this.searchOpen) {
                    debounce(async () => {
                        if (this.term === '') return this.results = null;
                        this.results = await this.$store.dispatch('search/search', { term: this.term });
                        this.showResults = true;
                    }, 500)(this);
                    // EventsBus.emit('search', this.term);
                }
                else {
                    this.searchOpen = !this.searchOpen;
                    this.$refs.searchInput?.focus();
                }
            },
            handleResetSearch() {
                this.term = '';
                if (this.searchOpen) {
                    // EventsBus.emit('search', this.term);
                    this.results = null;
                    this.showResults = false;
                }
                this.searchOpen = !this.searchOpen;
            },
            handleSetSearch(term = '') {
                this.term = term;
                this.searchOpen = false;
                this.showResults = false;
            },
            onFollow() {
                this.handleSetSearch();
            },
            onClickOutside() {
                this.handleSetSearch();
            }
        }
    }
</script>
<style lang="scss">
</style>