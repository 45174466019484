import i18n from '@/i18n';
import { Editor, Range } from '@tiptap/core'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'
import { findSuggestionMatch } from './findSuggestionMatch';
import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import VariableList from './VariableList.vue';

export const VariablePluginKey = new PluginKey('variable')

export function VariablePlugin({
  pluginKey = VariablePluginKey,
  editor,
  char = '@',
  allowSpaces = false,
  prefixSpace = true,
  startOfLine = false,
  decorationTag = 'span',
  decorationClass = 'variable',
  command = () => null,
  items = () => [],
  render = () => ({}),
  allow = () => true,
}) {

  let props;
  const renderer = render?.();

  let component;
  let popup;

  async function showVariablePopin(view, index, event) {
    const state = view.state.selection.node.attrs;
    const decorationNode = event.target;
    const range = {
        from: view.state?.selection.from,
        to: view.state?.selection.to
    };

    props = {
      editor,
      range: range,
      query: state.value,
      text: state.label,
      items: await items({
          editor,
          query: state.value,
        }),
      value: state.value,
      fallback: state.fallback,
      command: commandProps => {
        command({
          editor,
          range: range,
          props: commandProps,
        })
      },
      decorationNode,
      // virtual node for popper.js or tippy.js
      // this can be used for building popups without a DOM node
      clientRect: () => {
          return decorationNode.getBoundingClientRect();
      }
    }
  // console.info('handleClick')
  component = new VueRenderer(VariableList, {
      // using vue 2:
      editor: editor,
      // parent: this,
      propsData: props,
      // using vue 3:
      //   props,
      //   editor: props.editor,
    });
    popup = tippy(component.element, {
      getReferenceClientRect: props.clientRect,
      appendTo: () => document.body,
      content: component.element,
      showOnCreate: true,
      interactive: true,
      trigger: 'manual',
      placement: 'left',
      theme: 'light',
      delay: [200, 0],
    });
  };

  return new Plugin({
    key: pluginKey,

    view() {
      return {
        update: async (view, prevState) => {
          const prev = this.key?.getState(prevState)
          const next = this.key?.getState(view.state)

          // See how the state changed
          const moved = prev.active && next.active && prev.range.from !== next.range.from
          const started = !prev.active && next.active
          const stopped = prev.active && !next.active
          const changed = !started && !stopped && prev.query !== next.query
          const handleStart = started || moved
          const handleChange = changed && !moved
          const handleExit = stopped || moved

          // Cancel when suggestion isn't active
          if (!handleStart && !handleChange && !handleExit) {
            return
          }

          const state = handleExit && !handleStart
            ? prev
            : next
          const decorationNode = document.querySelector(`[data-decoration-id="${state.decorationId}"]`)

          props = {
            editor,
            range: state.range,
            query: state.query,
            text: state.text,
            items: (handleChange || handleStart)
              ? await items({
                editor,
                query: state.query,
              })
              : [],
            command: commandProps => {
              command({
                editor,
                range: state.range,
                props: commandProps,
              })
            },
            decorationNode,
            // virtual node for popper.js or tippy.js
            // this can be used for building popups without a DOM node
            clientRect: decorationNode
              ? () => {
                // because of `items` can be asynchrounous we’ll search for the current docoration node
                const { decorationId } = this.key?.getState(editor.state)
                const currentDecorationNode = document.querySelector(`[data-decoration-id="${decorationId}"]`)

                // @ts-ignore-error
                return currentDecorationNode.getBoundingClientRect()
              }
              : null,
          }

          if (handleExit) {
            renderer?.onExit?.(props)
          }

          if (handleChange) {
            renderer?.onUpdate?.(props)
          }

          if (handleStart) {
            renderer?.onStart?.(props)
          }
        },

        destroy: () => {
          if (!props) {
            return
          }

          renderer?.onExit?.(props)
        },
      }
    },

    state: {
      // Initialize the plugin's internal state.
      init() {
        return {
          active: false,
          range: {},
          query: null,
          text: null,
          composing: false,
        }
      },

      // Apply changes to the plugin state from a view transaction.
      apply(transaction, prev, oldState, state) {
        const { composing } = editor.view
        const { selection } = transaction
        const { empty, from } = selection
        const next = { ...prev }

        next.composing = composing

        // We can only be suggesting if there is no selection
        // or a composition is active (see: https://github.com/ueberdosis/tiptap/issues/1449)
        if (empty || editor.view.composing) {
          // Reset active state if we just left the previous suggestion range
          if (
            (from < prev.range.from || from > prev.range.to)
            && !composing
            && !prev.composing
          ) {
            next.active = false
          }

          // Try to match against where our cursor currently is
          const match = findSuggestionMatch({
            char,
            allowSpaces,
            prefixSpace,
            startOfLine,
            $position: selection.$from,
          })
          const decorationId = `id_${Math.floor(Math.random() * 0xFFFFFFFF)}`

          // If we found a match, update the current state to show it
          if (match && allow({ editor, state, range: match.range })) {
            next.active = true
            next.decorationId = prev.decorationId ? prev.decorationId : decorationId
            next.range = match.range
            next.query = match.query
            next.text = match.text
          } else {
            next.active = false
          }
        } else {
          next.active = false
        }

        // Make sure to empty the range if suggestion is inactive
        if (!next.active) {
          next.decorationId = null
          next.range = {}
          next.query = null
          next.text = null
        }

        return next
      },
    },

    props: {
      handleClick(view, index, event) {
          if (view.state?.selection?.node?.type?.name !== 'variable') return false;

          showVariablePopin(view, index, event);

          return true;
      },

      // Call the keydown hook if suggestion is active.
      handleKeyDown(view, event) {
        const { active, range } = this.getState(view.state);

        if (!active) {
          return false;
        }

        return renderer?.onKeyDown?.({ view, event, range }) || false;
      },

      // Setup decorator on the currently active suggestion.
      decorations(state) {
        const { active, range, decorationId } = this.getState(state);

        if (!active) {
          return null;
        }
        return DecorationSet.create(state.doc, [
          Decoration.inline(range.from, range.to, {
            nodeName: decorationTag,
            class: decorationClass,
            'id': decorationId,
            'data-decoration-id': decorationId
          }),
        ]);
      }
    }
  })
}