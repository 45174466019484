import i18n from '@/i18n';

export default [
  {
    path: '/reports',
    name: 'reports',
    props: { category: 'automation' },
    component: () => import('@/modules/reports/ReportView.vue'),
    children: [
        {
            path: 'renewal',
            name: 'reportRenewal',
            component: () => import('@/modules/reports/RenewalReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.renewal.title');
                },
                authorisation: { feature: 'report.renewal', action: 'read' }
            }
        },
        {
            path: 'healthscore',
            name: 'healthScore',
            component: () => import('@/modules/reports/HealthScoreReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.healthscore.title');
                },
                authorisation: { feature: 'report.healthscore', action: 'read' }
           }
        },
        {
            path: 'time-profitability',
            name: 'timeProfitability',
            component: () => import('@/modules/reports/TimeProfitabilityReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.time-profitability.title');
                },
                authorisation: { feature: 'report.profitability', action: 'read' }
           }
        },
        {
            path: 'features',
            name: 'activityfeatures',
            component: () => import('@/modules/reports/ActivityFeatureReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.activityFeatures.title');
                },
                filterKey: 'features'
           }
        },
        {
            path: 'features/details',
            name: 'activityFeatureDetails',
            component: () => import('@/modules/reports/ActivityFeatureDetailsReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.activityFeatures.title');
                },
                sidebar: ['Reports', 'Product Features'],
                filterKey: 'features'
           },
        },
        {
            path: 'team',
            name: 'reportTeam',
            component: () => import('@/modules/reports/TeamPerformanceReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.team.title');
                },
                authorisation: { feature: 'report.team', action: 'read' }
           }
        },
        {
            path: 'interaction-last-touch',
            name: 'reportInteractionLastTouch',
            component: () => import('@/modules/reports/InteractionLastTouchReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.interaction-last-touch.title');
                },
                authorisation: { feature: 'report.interaction-last-touch', action: 'read' }
           }
        },
        {
            path: 'mrr',
            name: 'reportMrr',
            component: () => import('@/modules/reports/MrrReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.mrr.title');
                },
                authorisation: { feature: 'report.mrr', action: 'read' }
           }
        },
        {
            path: 'goals/users',
            name: 'reportGoalsUsers',
            component: () => import('@/modules/reports/goals/GoalsUsersReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.goal.user.title');
                },
                authorisation: { feature: 'goal', action: 'read' }
           }
        },
        {
            path: 'goals/customers',
            name: 'reportGoalsCustomers',
            component: () => import('@/modules/reports/goals/GoalsCustomersReport.vue'),
            meta: {
                title: () => {
                    return i18n.t('report.goal.customer.title');
                },
                authorisation: { feature: 'goal', action: 'read' }
           }
        },
      ]
  },
];
