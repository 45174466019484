import axios from 'axios';

export class BaseInterceptor {

  _requestInterceptor = null;
  _responseInterceptor = null;

  $store = null;
  $router = null;

  static registeredInterceptors = [];

  constructor({ store, router, i18n }) {
    this.$store = store;
    this.$router = router;
    this.$i18n = i18n;
  }

  getType() {
    return 'BaseInterceptor';
  }

  requestInterceptor(){
    return {
      request: config => {
        return config;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  responseInterceptor(){
    return {
      response: response => {
        return response;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  attachRequestInterceptor() {
    // Attach to request
    const { request, error } = this.requestInterceptor();
    this._requestInterceptor = axios.interceptors.request.use(request, error);
  }
  attachResponseInterceptor() {
    // Attach to response
    const { response, error } = this.responseInterceptor();
    this._responseInterceptor = axios.interceptors.response.use(response, error);
  }
  detachRequestInterceptor() {
    if (this._requestInterceptor) axios.interceptors.response.eject(this._requestInterceptor);
  }
  detachResponseInterceptor() {
    if (this._responseInterceptor) axios.interceptors.response.eject(this._responseInterceptor);
  }
  register(){
    // Attach to request
    this.attachRequestInterceptor();
    // Attach to reqponse
    this.attachResponseInterceptor();
    this.constructor.registeredInterceptors.push(this);
  }

  getRegisteredInterceptors() {
    return this.constructor.registeredInterceptors;
  }
}
