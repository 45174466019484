var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cached", attrs: { id: "cached" } },
    [
      _vm.showAlert
        ? _c(
            "b-alert",
            {
              staticClass: "position-fixed fixed-bottom rounded-0 alert-cached",
              staticStyle: { "z-index": "2000" },
              attrs: { show: "", variant: "light", dismissible: "" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row align-items-center justify-content-center"
                },
                [
                  _c("div", { staticClass: "wrapper-img d-flex" }, [
                    _c("div", { staticClass: "spinner-border text-primary" })
                  ]),
                  _c("span", { staticClass: "ml-3 install" }, [
                    _vm._v(_vm._s(_vm.message))
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }