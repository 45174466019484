var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside"
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "search-wrapper", class: { active: _vm.searchOpen } },
        [
          _c(
            "div",
            {
              staticClass: "input-holder",
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleSearch.apply(null, arguments)
                }
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.term,
                    expression: "term"
                  }
                ],
                ref: "searchInput",
                staticClass: "search-input",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("commons.search.placeholder")
                },
                domProps: { value: _vm.term },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.term = $event.target.value
                  }
                }
              }),
              _c(
                "button",
                { staticClass: "search-icon", on: { click: _vm.handleSearch } },
                [_c("span")]
              )
            ]
          ),
          _c("button", {
            staticClass: "close",
            on: { click: _vm.handleResetSearch }
          })
        ]
      ),
      _vm.showResults
        ? _c("search-results-panel", {
            attrs: { results: _vm.results },
            on: { follow: _vm.onFollow }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }