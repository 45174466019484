var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "vcs__option",
      class: {
        "vcs__option--active": _vm.active,
        "vcs__option--disabled": _vm.disabled,
        "vsc__option--focused": _vm.focused
      },
      on: {
        mouseenter: function($event) {
          return _vm.$emit("openMenu", _vm.value, _vm.options, _vm.index, false)
        },
        click: _vm.handleSelection,
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "right", 39, $event.key, [
                "Right",
                "ArrowRight"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 2) {
              return null
            }
            return _vm.$emit(
              "openMenu",
              _vm.value,
              _vm.options,
              _vm.index,
              true
            )
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            return _vm.$emit("closeMenu")
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            return _vm.$emit("nextOption")
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            return _vm.$emit("prevOption")
          }
        ]
      }
    },
    [
      (!_vm.isLeaf && !_vm.hasNodeSlot) || (_vm.isLeaf && !_vm.hasLeafSlot)
        ? _c("span", [_vm._v(_vm._s(_vm.label))])
        : _vm.isLeaf
        ? [_vm._t("leaf", null, { item: _vm.$props })]
        : [_vm._t("node", null, { item: _vm.$props })],
      !_vm.isLeaf ? _c("arrow", { attrs: { direction: "right" } }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }