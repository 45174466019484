import i18n from "@/i18n";
import EventsBus from '@/libs/EventsBus';
import SseService from '@/libs/SseService';
import Vue from 'vue';
import dayjs from 'dayjs';
import { debounce } from '@/libs/utils/Promise';
import { getDb } from '@/libs/db';

export const TAGS_ICON = {
    CUSTOMER: {

    },
    CONTACT: {
        keyuser: 'key',
        enduser: 'mouse',
        champion: 'trophy',
        sponsor: 'cash'
    },
    INTERACTION: {
        support: 'question-circle',
        training: 'info-circle',
        follow: 'bell',
        br: 'people',
        bug: 'exclamation-circle',
        request: 'cloud-plus'
    }
};

const state = {
  fetched: {},
  tags: {}
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
      return state.tags[rootGetters.currentClientId] || [];
  },
  getFromId: (state, getters) => id => getters.getAll.find(a => a.id === id),
  getAllByEntity: (state, getters) => entity => getters.getAll.filter(a => a.entity === entity).sort((a, b) => {
      if (a.type !== b.type) return ('' + b.type).localeCompare(a.type);
      return ('' + a.name).localeCompare(b.name);
  }),
}

const actions = {

  async ensureTags({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetched[clientId]) {
      return await dispatch('fetchTags');
    }
    return state.tags[clientId];
  },
  async fetchTags({ dispatch, commit }, options) {
    try {
      return await debounce('fetchTags', async () => {
        const clientId = this.getters.currentClientId;
        const loadData = async () => {
            const data = (await this.getters.api.get('/tags')).map(fromAPI);
            EventsBus.emit('init_stop', 'tags');
            return await initData(data, false);
          };
          const initData = async (data, fromDb) => {
            commit('fetched', { clientId, value: true });
            commit('setTags', { clientId, data, fromDb });
            return data;
          };
          try {
            const cachedTags = options?.nocache === true ? [] : await getDb()?.tags?.where({ clientId })?.toArray();
            if (cachedTags?.length) {
                const parsedTags = cachedTags.map(c => c.data);
                setTimeout(loadData, 0);
                EventsBus.emit('init_start', {
                    key: 'tags',
                    message: i18n.t('cached.tags.refresh.pending'),
                    priority: 5
                });
                return await initData(parsedTags, true);
            }
          }
          catch(ex) {
            console.error(ex);
          }
          return await loadData();
      });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchTag({ dispatch, commit }, { tagId }) {
    try {
      const clientId = this.getters.currentClientId;
      const data = fromAPI(await this.getters.api.get(`/tags/${tagId}`));
      commit('setTag', { clientId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { tag }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.post(`/tags`, { data: toAPI(tag) });
        const model = fromAPI(data);
        commit('setTag', { clientId, data: model });
        return model;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { tagId, tag }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.patch(`/tags/${tagId}`, { data: toAPI(tag) });
        // TODO remove
        tag.id = tagId;
        commit('setTag', { clientId, data: fromAPI(tag) });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { tagId }) {
    try {
        const clientId = this.getters.currentClientId;
        await this.getters.api.delete(`/tags/${tagId}`);
        commit('removetag', { clientId, tagId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { clientId, value }) {
    state.fetched[clientId] = value;
  },
  setTag(state, { clientId, data }) {
    const index = state.tags[clientId].findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.tags[clientId], index, { ...state.tags[clientId][index], ...data});
      getDb()?.tags?.update(data.id, {
        data: { ...state.tags[clientId][index], ...data},
        updatedAt: new Date()
      });
    }
    else {
        state.tags[clientId].push(data);
        getDb()?.tags?.add({
            id: data.id,
            entity: data.entity,
            clientId,
            data,
            createdAt: new Date(),
            updatedAt: new Date()
        });
    }
  },
  setTags(state, { clientId, data, fromDb }) {
    if (!fromDb) {
        getDb()?.tags?.clear()?.then(() => {
            getDb()?.tags?.bulkAdd(data.map(d => {
                return {
                    id: d.id,
                    entity: d.entity,
                    clientId,
                    data: d,
                    createdAt: new Date(),
                    updatedAt: new Date()
                };
            }));
        });
    }
    Vue.set(state.tags, clientId, data);
  },
  removetag(state, { clientId, tagId }) {
    const index = state.tags[clientId].findIndex(c => c.id === tagId);
    if (index > -1) {
        const entity = state.tags[clientId][index].entity;
        state.tags[clientId].splice(index, 1);
        getDb()?.tags?.delete([tagId, entity]);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPI(tag) {
    const json = {...tag};
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  init: async (context) => {
    SseService.on('tags/update', async ({ tagId, entity }) => {
        if (tagId) {
            // const isNew = !context?.getters?.getFromId(tagId);
            await context?.dispatch('fetchTag', { tagId });
            // Signal to tags list
            // EventsBus.emit('tags/refresh', isNew ? {} : { tagId });
        }
        else {
            await context?.dispatch('fetchTags', { nocache: true });
            // Signal to tags list
            // EventsBus.emit('tags/refresh', {});
        }
    });
    SseService.on('tags/delete', async ({ clientId, tagId }) => {
        if (tagId) {
            context?.commit('removetag', { clientId, tagId });
            // Signal to tags list
            // EventsBus.emit('tags/refresh', {});
        }
    });
  },
  getters,
  actions,
  state,
  mutations
};
