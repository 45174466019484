export const DEFAULT_CONDITION_SCHEMA = {
    type: 'object',
    required: ['operator', 'property', 'type', 'values'],
    additionalProperties: true,
    properties: {
        operator: { type: 'string' },
        property: { type: 'string' },
        type: { type: 'string' },
        values: {
            type: 'array',
            items: {
                anyOf: [{ type: 'integer' }, { type: 'number' }, { type: 'boolean' }, { type: 'string' }]
            },
            minItems: 1
        }
    }
}

export const PLAYBOOK_STEP_PARAMETERS_SCHEMA = {
    COND_WAIT_MANUAL: {
        type: 'object',
        required: [],
        additionalProperties: false,
        properties: {
        }
    },
    COND_WAIT_TIME_UNTIL: {
        type: 'object',
        required: ['duration', 'durationPeriod', 'startAt'],
        additionalProperties: false,
        properties: {
            duration: { type: 'integer' },
            durationPeriod: { type: 'string' },
            startAt: { type: 'string' }
        }
    },
    COND_STOP: {
        type: 'object',
        required: [],
        additionalProperties: false,
        properties: {
        }
    },
    COND_FILTER_STOP: {
        type: 'object',
        required: ['mode', 'conditions'],
        additionalProperties: false,
        properties: {
            mode: { type: 'string', enum: ['MATCH', 'NOT_MATCH'] },
            conditions: {
                anyOf: [
                    {
                        type: 'null',
                    },
                    {
                        type: 'object',
                        additionalProperties: false,
                        required: ['all'],
                        properties: {
                            all: {
                                type: 'array',
                                items: {
                                    ...DEFAULT_CONDITION_SCHEMA
                                },
                                minItems: 1
                            }
                        }
                    },
                    {
                        type: 'object',
                        additionalProperties: false,
                        required: ['any'],
                        properties: {
                            any: {
                                type: 'array',
                                items: {
                                    ...DEFAULT_CONDITION_SCHEMA
                                },
                                minItems: 1
                            }
                        }
                    }
                ]
            }
        }
    },
    COND_FILTER_CUSTOMER: {
        type: 'object',
        required: ['branchs'],
        additionalProperties: false,
        properties: {
            branchs: {
                type: 'array',
                items: {
                    type: 'object',
                    required: ['id'],
                    additionalProperties: false,
                    properties: {
                        id: { type: 'string' },
                        name: { type: 'string' },
                        conditions: {
                            anyOf: [
                                {
                                    type: 'null',
                                },
                                {
                                    type: 'object',
                                    additionalProperties: false,
                                    required: ['all'],
                                    properties: {
                                        all: {
                                            type: 'array',
                                            items: {
                                                ...DEFAULT_CONDITION_SCHEMA
                                            },
                                            minItems: 1
                                        }
                                    }
                                },
                                {
                                    type: 'object',
                                    additionalProperties: false,
                                    required: ['any'],
                                    properties: {
                                        any: {
                                            type: 'array',
                                            items: {
                                                ...DEFAULT_CONDITION_SCHEMA
                                            },
                                            minItems: 1
                                        }
                                    }
                                }
                            ]
                        }
                    }
                }
            }
        }
    },
    COND_FILTER_MANUAL: {
        type: 'object',
        required: ['branchs'],
        additionalProperties: false,
        properties: {
            branchs: {
                type: 'array',
                items: {
                    type: 'object',
                    required: ['id'],
                    additionalProperties: false,
                    properties: {
                        id: { type: 'string' },
                        name: { type: 'string' }
                    }
                }
            }
        }
    },
    COND_FILTER_MERGE_CUSTOMER: {
        type: 'object',
        required: ['branchs'],
        additionalProperties: false,
        properties: {
            branchs: {
                type: 'array',
                items: {
                    type: 'object',
                    required: ['id'],
                    additionalProperties: false,
                    properties: {
                        id: { type: 'string' },
                        name: { type: 'string' },
                        conditions: {
                            anyOf: [
                                {
                                    type: 'null',
                                },
                                {
                                    type: 'object',
                                    additionalProperties: false,
                                    required: ['all'],
                                    properties: {
                                        all: {
                                            type: 'array',
                                            items: {
                                                ...DEFAULT_CONDITION_SCHEMA
                                            },
                                            minItems: 1
                                        }
                                    }
                                },
                                {
                                    type: 'object',
                                    additionalProperties: false,
                                    required: ['any'],
                                    properties: {
                                        any: {
                                            type: 'array',
                                            items: {
                                                ...DEFAULT_CONDITION_SCHEMA
                                            },
                                            minItems: 1
                                        }
                                    }
                                }
                            ]
                        }
                    }
                }
            }
        }
    },
    COND_FILTER_MERGE_MANUAL: {
        type: 'object',
        required: ['branchs'],
        additionalProperties: false,
        properties: {
            branchs: {
                type: 'array',
                items: {
                    type: 'object',
                    required: ['id'],
                    additionalProperties: false,
                    properties: {
                        id: { type: 'string' },
                        name: { type: 'string' }
                    }
                }
            }
        }
    },
    CUSTOMER_STAGE_CHANGE: {
        type: 'object',
        required: ['stage'],
        additionalProperties: false,
        properties: {
            stage: { type: 'string' }
        }
    },
    CUSTOMER_PROFILE_CHANGE: {
        type: 'object',
        required: ['profileId'],
        additionalProperties: false,
        properties: {
            profileId: { type: 'string' }
        }
    },
    CUSTOMER_GOAL_PROFILE_CHANGE: {
        type: 'object',
        required: ['goalProfileId'],
        additionalProperties: false,
        properties: {
            goalProfileId: { type: 'string' }
        }
    },
    CUSTOMER_OWNER_CHANGE: {
        type: 'object',
        required: ['userId'],
        additionalProperties: false,
        properties: {
            action: { type: 'string', enum: ['AUTO', 'MANUAL'] },
            userId: { type: 'string' }
        }
    },
    CUSTOMER_CUSTOM_USER_CHANGE: {
        type: 'object',
        required: ['userId', 'customId'],
        additionalProperties: false,
        properties: {
            action: { type: 'string', enum: ['AUTO', 'MANUAL'] },
            userId: { type: 'string' },
            customId: { type: 'string' }
        }
    },
    CUSTOMER_CUSTOM_SCORE_CHANGE: {
        type: 'object',
        required: ['value', 'customId'],
        additionalProperties: false,
        properties: {
            value: { type: 'number' },
            customId: { type: 'string' }
        }
    },
    CUSTOMER_TAG_CHANGE: {
        type: 'object',
        required: ['action', 'tag'],
        additionalProperties: false,
        properties: {
            action: { type: 'string', enum: ['ADD', 'REMOVE'] },
            tag: { type: 'string' }
        }
    },
    CONTACT_TAG_CHANGE: {
        type: 'object',
        required: ['action', 'tag'],
        additionalProperties: false,
        properties: {
            action: { type: 'string', enum: ['ADD', 'REMOVE'] },
            tag: { type: 'string' }
        }
    },
    TASK_PROJECT_CREATE: {
        type: 'object',
        required: ['templateId'],
        additionalProperties: false,
        properties: {
            templateId: { type: 'string' },
            waitForCompletion: {
                anyOf: [{ type: 'integer', enum: [0, 1] }, { type: 'boolean' }, { type: 'null' }]
            }
        }
    },
    TASK_CREATE: {
        type: 'object',
        required: ['task'],
        additionalProperties: false,
        properties: {
            task: {
                type: 'object',
                additionalProperties: true,
                properties: {
                    type: { type: 'string' },
                    title: { type: 'string' },
                    description: { type: 'string' }
                }
            },
            waitForCompletion: {
                anyOf: [{ type: 'integer', enum: [0, 1] }, { type: 'boolean' }, { type: 'null' }]
            }
        }
    },
    OUTPUT_MAIL_INTERNAL: {
        type: 'object',
        required: ['mode', 'subject'],
        additionalProperties: false,
        properties: {
            body: { type: 'string' },
            mode: { type: 'string' },
            subject: { type: 'string' },
            failOnError: {
                anyOf: [{ type: 'integer', enum: [0, 1] }, { type: 'boolean' }, { type: 'null' }]
            }
        }
    },
    OUTPUT_MAIL_INBOX: {
        type: 'object',
        required: ['mode', 'subject'],
        additionalProperties: false,
        properties: {
            body: { type: 'string' },
            mode: { type: 'string' },
            subject: { type: 'string' },
            failOnError: {
                anyOf: [{ type: 'integer', enum: [0, 1] }, { type: 'boolean' }, { type: 'null' }]
            }
        }
    },
    OUTPUT_MAIL_INBOX_SEND: {
        type: 'object',
        required: ['mode', 'subject'],
        additionalProperties: false,
        properties: {
            body: { type: 'string' },
            mode: { type: 'string' },
            subject: { type: 'string' },
            failOnError: {
                anyOf: [{ type: 'integer', enum: [0, 1] }, { type: 'boolean' }, { type: 'null' }]
            }
        }
    },
    OUTPUT_MAIL_INBOX_VALIDATION: {
        type: 'object',
        required: ['mode', 'subject'],
        additionalProperties: false,
        properties: {
            body: { type: 'string' },
            mode: { type: 'string' },
            subject: { type: 'string' },
            failOnError: {
                anyOf: [{ type: 'integer', enum: [0, 1] }, { type: 'boolean' }, { type: 'null' }]
            }
        }
    },
    OUTPUT_WEBHOOK: {
        type: 'object',
        required: ['url'],
        additionalProperties: false,
        properties: {
            url: { type: 'string' },
            additionalHeaders: {
                type: 'object',
                additionalProperties: { type: "string", minLength: 1 }
            }
        }
    },
    OUTPUT_SLACK: {
        type: 'object',
        additionalProperties: false,
        properties: {
            channel: {
                type: 'object',
                additionalProperties: { type: "string", minLength: 1 }
            }
        }
    },
    PLAYBOOK_RUN: {
        type: 'object',
        required: ['playbookId'],
        additionalProperties: false,
        properties: {
            playbookId: { type: 'string' },
            waitForCompletion: {
                anyOf: [{ type: 'integer', enum: [0, 1] }, { type: 'boolean' }, { type: 'null' }]
            }
        }
    },
    OPPORTUNITY_CREATE: {
        type: 'object',
        required: ['opportunity'],
        additionalProperties: false,
        properties: {
            opportunity: {
                type: 'object',
                additionalProperties: true,
                properties: {
                    pipelineId: { type: 'string' },
                    title: { type: 'string' },
                    description: { type: 'string' },
                    amount: { type: 'number' }
                }
            }
        }
    },
    'COND_WAIT_TASK_PROJECT@COMPLETED': {
        type: 'object',
        required: ['stepId'],
        additionalProperties: false,
        properties: {
            stepId: { type: 'string' }
        }
    },
    'COND_WAIT_TASK@COMPLETED': {
        type: 'object',
        required: ['stepId'],
        additionalProperties: false,
        properties: {
            stepId: { type: 'string' }
        }
    }
};
