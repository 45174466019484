<template>
    <div class="button-image" @click.prevent.stop>
        <button
            v-if="!editor.isActive('image')"
            @click="showImageModal "
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('image') }"
            :title="$t('commons.editor.command.image')"
        >
          <icon name="image" />
        </button>
        <div v-if="editor.isActive('image') && updating">
            <button class="menubar__button" @click="updateImage" :title="$t('commons.editor.command.image-update')">
                {{ $t('commons.editor.command.image-update') }}
            </button>
            <a class="button-link -pointer ml-2" @click="changeSize('small')">
                {{ $t('commons.editor.command.image.small' )}}
            </a>
            <a class="button-link -pointer ml-2" @click="changeSize('big')">
                {{ $t('commons.editor.command.image.big' )}}
            </a>
            <a class="button-link -pointer ml-2" @click="changeSize('origine')">
                {{ $t('commons.editor.command.image.original' )}}
            </a>
            <a class="button-link -pointer ml-2" @click="setImage({ url: null })">
                {{ $t('commons.action.remove') }}
            </a>
        </div>
        <button
        v-if="editor.isActive('image') && !updating"
            @click="updateImage"
            class="menubar__button"
            :title="$t('commons.editor.command.image-update')"
        >
          <icon name="image" />
        </button>
          <div @click.prevent.stop>
              <image-infos ref="info" v-click-outside="exit"
                v-model="params"
                :can-undo="canRemove"
                @select="setImage"
                @cancel="exit"
              />
        </div>
    </div>
</template>

<script>
import Icon from '@/modules/commons/components/Icon';
import ImageInfos from '@/libs/tiptap/image/ImageInfos';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default {
    components: {
        Icon,
        ImageInfos
    },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    updating: {
        type: Boolean,
        default: false
    }
  },

  data() {
    return {
      params: {
        url: '',
        title: '',
        alt: ''
      },
      tip: null,
      canRemove: false
    }
  },
  mounted() {
      this.init();
  },
  methods: {
    init() {
        this.tip = tippy(this.$el,  {
          getReferenceClientRect: () => {
            return this.$el?.getBoundingClientRect();
          },
          appendTo: () => document.body,
          content: this.$refs.info?.$el,
          showOnCreate: false,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom',
          theme: 'light',
          maxWidth: 550,
          delay: [200, 0],
        });
    },
    showImageModal() {
        this.params = {
            url: '',
            title: '',
            alt: ''
        };
        this.$emit('show');
        this.canRemove = false;
        this.tip?.show();
    },
    updateImage() {
        this.$emit('show');
        this.canRemove = true;
        this.params = {
            url: this.editor.getAttributes('image')?.src,
            title: this.editor.getAttributes('image')?.title,
            alt: this.editor.getAttributes('image')?.alt
        };
        this.tip?.show();
        // console.info(this.editor.getAttributes('link').href);
        // editor.chain().focus().unsetLink().run()
    },
    changeSize(size) {
        this.editor.commands.updateAttributes('image', { size });
    //     width: 224px;
    // aspect-ratio: auto 224 / 174;
    // height: 174px;


    // width: 562px;
    // aspect-ratio: auto 562 / 320;
    // height: 320px;

        // width: 562px;
    },
    setImage({ url, title, alt }) {
            // const previousUrl = this.editor.getAttributes('link').href
            // const url = window.prompt('URL', previousUrl)
            this.exit();
            // cancelled
            if (url === '') {
                return;
            }

            // empty
            if (url === null) {
                this.editor.commands.deleteSelection();
                return;
            }

            // update link
            this.editor.commands.setImage({
                src: url,
                alt: alt,
                title: title
            });
        },
    exit() {
        this.$emit('hide');
        this.tip?.hide();
        // this.$refs.info?.reset();
    }
  },
}
</script>

<style lang="scss">
.button-image {
    display: flex;
    flex-direction: column;
    // width: 80px;

    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
}

.menu-item-var {
    display: flex;
    flex-direction: column;

    .menu-item-var-description {
        font-weight: 200;
        font-size: 12px;
    }
}
</style>