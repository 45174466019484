import i18n from '@/i18n';

export default [
    {
      path: '/onboarding',
      name: 'onboarding',
      redirect: { name: 'onboardingEssentialList' },
      meta: {
          title: () => {
              return i18n.t('onboarding.title');
          },
          breadcrumb: [],
      },
      component: () => import('@/modules/onboarding/OnboardingView.vue'),
      children: [
        {
            path: 'essential',
            name: 'onboardingEssentialList',
            component: () => import('@/modules/onboarding/views/OnboardingEssentialList.vue'),
            meta: {
                title: () => {
                    return i18n.t('onboarding.title');
                }
            }
        },
        {
            path: 'advanced',
            name: 'onboardingAdvancedList',
            component: () => import('@/modules/onboarding/views/OnboardingAdvancedList.vue'),
            meta: {
                  title: () => {
                      return i18n.t('onboarding.title');
                  }
            }
        },
        {
            path: 'expert',
            name: 'onboardingExpertList',
            component: () => import('@/modules/onboarding/views/OnboardingExpertList.vue'),
            meta: {
                title: () => {
                    return i18n.t('onboarding.title');
                }
            }
        },
      ]
    }
  ];