import dayjs from 'dayjs';
import EventsBus from '@/libs/EventsBus';

import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

const languages = ['en', 'fr'];

// Listen to lagnuage changed
EventsBus.on('language', language => {
    if (language && !languages.includes(language)) {
        throw new Error(`"${language}" has not been defined as a language.`);
    }
    dayjs.locale(language);
});