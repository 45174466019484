
import i18n from "@/i18n";
import EventsBus from '@/libs/EventsBus';
import Vue from 'vue';
import { debounce } from '@/libs/utils/Promise';
import { getDb } from '@/libs/db';

export const DEFAULT_STAGES = [{name: 'trial', label: 'Trial', rank: 1 }, {name: 'onboarding', label: 'Onboarding', rank: 2 }, {name: 'run', label: 'Run', rank: 3 }, {name: 'churn', label: 'Churn', rank: 4 }];

const state = {
  fetched: {},
  stages: {},
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
      return state.stages[rootGetters.currentClientId] || [];
  },
  getFromName: (state, getters) => name => getters.getAll.find(a => a.name === name),
  getDefaultStage: () => DEFAULT_STAGES
}

const actions = {

  async ensureStages({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetched[clientId]) {
      return await dispatch('fetchStages');
    }
    return state.stages[clientId];
  },
  async fetchStages({ dispatch, commit }) {
    try {
        return await debounce('fetchStages', async () => {
            const clientId = this.getters.currentClientId;
            const loadData = async () => {
                const data = (await this.getters.api.get('/customers/stages')).map(fromAPI);
                EventsBus.emit('init_stop', 'stages');
                return await initData(data, false);
            };
            const initData = async (data, fromDb) => {
                commit('fetched', { clientId, value: true });
                commit('setStages', { clientId, data, fromDb });
                return data;
            };
            try {
                const cachedStages = await getDb()?.stages?.where({ clientId })?.toArray();
                if (cachedStages?.length) {
                    const parsed = cachedStages.map(c => c.data);
                    setTimeout(loadData, 0);
                    EventsBus.emit('init_start', {
                        key: 'stages',
                        message: i18n.t('cached.stages.refresh.pending'),
                        priority: 2
                    });
                    return await initData(parsed, true);
                }
            }
            catch(ex) {
                console.error(ex);
            }
            return await loadData();
        });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit, state }, { stage }) {
    try {
        const clientId = this.getters.currentClientId;
        stage.rank = state.stages[clientId].length + 1;
        const data = await this.getters.api.post(`/customers/stages`, { data: toAPI(stage) });
        commit('setStage', { clientId, data: fromAPI(data) });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { name, stage }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.patch(`/customers/stages/${encodeURIComponent(name)}`, { data: toAPI(stage) });
        commit('setStage', { clientId, data: stage });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { name }) {
    try {
        const clientId = this.getters.currentClientId;
        await this.getters.api.delete(`/customers/stages/${encodeURIComponent(name)}`);
        commit('removeStage', { clientId, name });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async updateRank({ commit }, { name, rank }) {
    try {
        const clientId = this.getters.currentClientId;
        commit('setRank', { clientId, name, rank });
        const data = await this.getters.api.post(`/customers/stages/${encodeURIComponent(name)}/rank`, {
            data: {
                rank
            }
        });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { clientId, value }) {
    state.fetched[clientId] = value;
  },
  setStage(state, { clientId, data }) {
    const index = state.stages[clientId].findIndex(c => c.name === data.name);
    if (data.default) {
        state.stages[clientId].forEach(s => {
            s.default = false;
        });
    }
    if (index > -1) {
      Vue.set(state.stages[clientId], index, { ...state.stages[clientId][index], ...data});
    }
    else {
        state.stages[clientId].push(data);
    }
    getDb()?.stages?.clear()?.then(() => {
        getDb()?.stages?.bulkAdd(state.stages[clientId].map(d => {
            return {
                name: d.name,
                clientId: d.clientId,
                data: d,
                createdAt: new Date(),
                updatedAt: new Date()
            };
        }));
    });
  },
  setStages(state, { clientId, data, fromDb }) {
    if (!fromDb) {
        getDb()?.stages?.clear()?.then(() => {
            getDb()?.stages?.bulkAdd(data.map(d => {
                return {
                    name: d.name,
                    clientId: d.clientId,
                    data: d,
                    createdAt: new Date(),
                    updatedAt: new Date()
                };
            }));
        });
    }
    Vue.set(state.stages, clientId, data.sort((a, b) => a.rank > b.rank ? 1 : -1));
  },
  removeStage(state, { clientId, name }) {
    const index = state.stages[clientId].findIndex(c => c.name === name);
    const current = state.stages[clientId][index];
    let isDefault = false;
    if (index > -1) {
        isDefault = state.stages[clientId][index].default;
        state.stages[clientId].splice(index, 1);
    }
    if (isDefault && state.stages[clientId].length) {
        const runStage = state.stages[clientId].find(s => s.name === 'run');
        runStage.default = true;
    }

    for (const stage of state.stages[clientId] || []) {
        if (stage.rank < current.rank) continue;
        stage.rank--;
    }

    getDb()?.stages?.clear()?.then(() => {
        getDb()?.stages?.bulkAdd(state.stages[clientId].map(d => {
            return {
                name: d.name,
                clientId: d.clientId,
                data: d,
                createdAt: new Date(),
                updatedAt: new Date()
            };
        }));
    });
  },
  setRank(state, { clientId, name, rank }) {
    const current = state.stages[clientId].find(c => c.name === name);
    if (current.rank === rank) return;
    const isAcending = current.rank < rank;

    for (const stage of state.stages[clientId] || []) {
        if (stage.name === name) continue;
        if (isAcending && stage.rank > current.rank && stage.rank <= rank) {
            stage.rank--;
        }
        else if (!isAcending && stage.rank < current.rank && stage.rank >= rank) {
            stage.rank++;
        }
    }
    current.rank = rank;
    Vue.set(state.stages, clientId, state.stages[clientId].sort((a, b) => a.rank > b.rank ? 1 : -1));

    getDb()?.stages?.clear()?.then(() => {
        getDb()?.stages?.bulkAdd(state.stages[clientId].map(d => {
            return {
                name: d.name,
                clientId: d.clientId,
                data: d,
                createdAt: new Date(),
                updatedAt: new Date()
            };
        }));
    });
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    return json;
}

function toAPI(stage) {
    const json = {...stage};
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
