<template>
    <div>
        <!-- profile modal-->
        <b-modal
            id="featureRequest"
            :title="$t('global.link.feature-request.title')"
            size="lg"
            :ok-title="$t('settings.global.featureRequest.submit.button')"
            :input-class="'dd'"
            
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            :ok-disabled="loading"
        >
        <div v-paste="onPasteHandler">
        <p class="my-4">{{ $t('settings.global.featureRequest.description') }}</p>
            <b-row>
                <b-col md="12">
                    <b-card class="mb-3 nav-justified">
                        <b-form ref="form" @submit.stop.prevent="handleSubmit">
                            <b-form-group
                                id="input-group-1"
                                label-cols-sm="3"
                                :label="$t('settings.profile.modal.form.email.label')"
                                label-for="input-1"
                            >
                                <b-form-input
                                    disabled
                                    id="input-member-name"
                                    v-model="user.email"
                                    type="email"
                                    size="lg"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="input-group-feature-title"
                                label-cols-sm="3"
                                :label="$t('settings.global.featureRequest.form.title.label')"
                                label-for="input-member-feature-title"
                            >
                                <b-form-input
                                    id="input-member-feature-title"
                                    v-model="form.title"
                                    required
                                    :placeholder="$t('settings.global.featureRequest.form.title.placeholder')"
                                    :state="$v.form.title.$dirty ? !$v.form.title.$invalid : null"
                                ></b-form-input>
                                <b-form-invalid-feedback
                                    id="input-member-feature-title-feedback"
                                >
                                    {{ $t('settings.global.featureRequest.form.title.invalid') }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                id="input-description"
                                :label="$t('settings.global.featureRequest.form.description.label')"
                            >
                                <b-form-textarea
                                    id="input-member-feature-description"
                                    class="description"
                                    v-model="form.description"
                                    :placeholder="$t('settings.global.featureRequest.form.description.placeholder')"
                                ></b-form-textarea>
                                <!-- <text-editor v-model="form.description" /> -->
                            </b-form-group>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                     <b-card class="mb-3 nav-justified" :title="$t('settings.global.featureRequest.attachments.card.title')">
                         <b-row v-for="(file, $index) in visualFiles" :key="$index" class="mb-2">
                             <b-col>
                                 <b-card>
                                 <b-img class="img-preview" v-if="file.type.startsWith('image/')" :src="generateImageDate(file)" fluid />
                                 </b-card>
                                 <!-- {{ file.size }} -->
                        </b-col>
                        <b-col lg="1">
                            <button class="border-0 btn-transition btn btn-outline-danger" @click.prevent.stop="handleRemoveAttachment($index)">
                                <font-awesome-icon icon="trash-alt"/>
                            </button>
                        </b-col>
                        </b-row>
                        <b-row class="mb-2">
                             <b-col>
                                 <b-form-file 
                                    v-if="visualFiles.length < 3"
                                    accept="image/*"
                                    class="mb-2"
                                    v-model="files[files.length]"
                                    :placeholder="$t('component.csvimport.file.label')"
                                    :drop-placeholder="$t('component.csvimport.file.drop')"
                                ></b-form-file>
                            </b-col>
                            <b-col lg="1">
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        </b-modal>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import i18n from "@/i18n";
import TextEditor from '@/modules/commons/components/TextEditor';
import { getFileFromPasteEvent } from '@/libs/utils/uri';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faEdit,
    faTrashAlt,
    faUserTie,
    faPhone,
    faMobileAlt,
    faCommentAlt,
    faComment,
    faHandshake,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
    faEdit,
    faTrashAlt,
    faUserTie,
    faPhone,
    faMobileAlt,
    faCommentAlt,
    faComment,
    faHandshake,
    faUsers
);

export default {
    components: {
        TextEditor,
        VuePerfectScrollbar,
        "font-awesome-icon": FontAwesomeIcon
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        subheading: i18n.t("customers.dashboard.subheading"),
        icon: "pe-7s-paint icon-gradient bg-sunny-morning",
        breadcrumb: [
            {
                text: i18n.t("users.view.heading"),
                to: { name: "users" }
            }
        ],
        form: {
            title: "",
            description: ""
        },
        files: [null, null, null],
        loading: false
    }),
    validations: {
        form: {
            title: {
                required,
                minLength: minLength(3)
            },
            description: {
            }
        },
    },
    computed: {
        visualFiles() {
            return this.files.filter(f => !!f);
        }
    },
    methods: {
        checkFormValidity() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        resetModal() {
            this.$v.$reset();
            this.form.title = "";
            this.form.description = "";
            this.files = [null, null, null];
            this.loading = false;
        },
        onPasteHandler(event) {
            const file = getFileFromPasteEvent(event);
            if (!file) { return; }
            const lg = this.files.filter(f => !!f).length;
            this.$set(this.files, lg, file);
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmitRequest();
        },
        async handleSubmitRequest() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.loading = true;
            await this.$store.dispatch("tools/submitRequest", {
                 ...this.form,
                 files: this.visualFiles.length ? this.visualFiles : null
            });
            this.$bvToast.toast(this.$t('settings.global.featureRequest.ope.created'), {
              title: this.$t('commons.ope.success'),
              autoHideDelay: 5000,
              appendToast: false
            });
            this.loading = false;
            this.$emit("ok", this.form);
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide("featureRequest");
                this.resetModal();
            });
        },
        handleRemoveAttachment(index) {
            const indexFile = this.files.findIndex(f => f === this.visualFiles[index]);
            this.$set(this.files, indexFile, null);
        },
        generateImageDate(file) {
            return URL.createObjectURL(file);
        }
    }
};
</script>
<style lang="scss" scoped>
.icon-wrapper {
    cursor: pointer;
}
.description {
    min-height: 150px;
}
.img-preview {
    max-height: 150px;
}
</style>
