import LRU from 'lru-cache';
import { BaseInterceptor } from './BaseInterceptor';

const LRU_CACHE = new LRU({
    max: 500,
    maxAge: 1000 * 60 * 1,
    ttl: 1000 * 60 * 1
});

const cacheable = true;
const Cache = {
    _items: {},
    get(key){
      const cached = LRU_CACHE.get(key);
      return cached ? JSON.parse(cached) : null;
    },
    set(key, value){
        LRU_CACHE.set(key, JSON.stringify(value));
    },
    del(key) {
      LRU_CACHE.keys().filter(k => k.indexOf(key) > -1).forEach(k => {
        LRU_CACHE.del(k);
      });
    }
  };

export class CacheInterceptor extends BaseInterceptor {

  getType() {
    return 'CacheInterceptor';
  }

  requestInterceptor() {
    return {
      request: request => {
        // Reset cache
        if (request?.resetCache) {
            if (Array.isArray(request?.resetCache)) {
                request?.resetCache.map(u => Cache.del(u));
            }
            else {
                Cache.del(request.resetCache);
            }
        }
        const isCacheable = request?.cache === true;
        // Only cache GET requests
        if (request.method === 'get' && cacheable && isCacheable) {
          const key = this.getCacheKey(request);

          const _cached = Cache.get(key);

          if (_cached) {
            _cached.__fromCache = true;

            // console.log(`"${key}" served from cache:`, _cached);

            request.data = _cached;

            // Set the request adapter to send the cached response and prevent the request from actually running
            request.adapter = () => {
              return Promise.resolve({
                data: _cached,
                status: request.status,
                statusText: request.statusText,
                headers: request.headers,
                config: request,
                request: request
              });
            };
          }
        }
        return request;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  responseInterceptor() {
    return {
      response: response => {
        // if you dont want to cache a specific url, send a param `__cache = false`
        const isCacheable = response?.config?.cache === true;

        if (cacheable && isCacheable) {
          const key = this.getCacheKey(response.config);
          if (response.config.method === 'get') {
            // On get request, store the response in the cache
            Cache.set(key, response.data);
          }
          else {
            // For post, put or delete, just delete the cached version of the url
            // e.g. posting to `/audiences` would delete the `/audiences` cache, so when you ask for audiences again you get the real version
            Cache.del(this.getCacheKey(response.config, true));
          }
        }
        return response;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  getCacheKey(config, onlyRoot = false) {
    let key = config.url;
    // Append the params, I use jquery param but you can change to whatever you use
    if (config.params && !onlyRoot)
      key += '?' + JSON.stringify(config.params);
    return key;
  }
}
