<template>
    <div class="app-sidebar-right help-bar">
        <b-sidebar id="helpbar" ref="helpbar" :title="title || $t('global.help.title')" right shadow bg-variant="white" @hidden="reset" :width="panelWidth" :visible="visible">
            <div class="px-3 py-2">
                <div class="text-muted text-left" v-if="openKey">
                    <b-form-checkbox size="sm" v-model="showNext" @change="onToggleShowNextTime">
                    {{ $t('global.help.auto-show-next') }}
                    </b-form-checkbox>
                    <hr />
                </div>
                <slot></slot>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    // import i18n from '@/i18n';
    // import { SidebarMenu } from 'vue-sidebar-menu';
    // import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    // import EventsBus from '@/libs/EventsBus';


    export default {
        components: {
        },
        data() {
            return {
                visible: false,
                showNext: true,
                panelWidth: '',
                closeFn: null
            }
        },
        provide() {
            return {
                expand: () => {
                    this.panelWidth = this.getWindowWidth === 'lg' ? '50%' : '80%';
                },
                onClose: (fn) => {
                    this.closeFn = fn;
                }
            }
        },
        props: {
            title: String,
            openKey: String,
            autoOpen: Boolean
        },
        computed: {
            show() {
                return !localStorage.getItem(`help-${this.openKey}`);
            },
            getWindowWidth() {
                const windowWidth = document.documentElement.clientWidth;

                if (windowWidth < '1350') {
                    return 'md';
                } else {
                    return 'lg';
                }
            }
        },
        watch: {
            show(value) {
                this.showNext = value;
            }
        },
        async mounted() {
            this.showNext = this.show;
            if (this.autoOpen && (!this.openKey || this.showNext)) {
                this.visible = true;
            }
        },
        methods: {
            reset() {
                this.panelWidth = '';
                if (this.closeFn) this.closeFn();
            },
            onToggleShowNextTime(value) {
                if (!value) localStorage.setItem(`help-${this.openKey}`, true);
                else localStorage.removeItem(`help-${this.openKey}`);
            }
        }
    }
</script>
<style lang="scss">

</style>