import i18n from '@/i18n';

export default [
  {
    path: '/contacts',
    name: 'contacts',
    meta: {
        title: () => {
            return i18n.t('global.contacts.title');
        },
        breadcrumb: []
    },
    props: { category: 'automation' },
    component: () => import('@/modules/contacts/ContactsView.vue'),
  }
];
