import Vue from 'vue';
import i18n from "@/i18n";
import store from '../store';


/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useLanguage = () => {

    return function () {
        const language = store.getters['configs/getForUser']('language') || i18n.locale;
        return language;
    }
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const LanguagePlugin = {
    install(Vue, options) {
      Vue.prototype.$lang = useLanguage(options);
    }
};

Vue.use(LanguagePlugin);
