
import Vue from "vue";
import dayjs from 'dayjs';

const state = {
    fetched: {},
    connections: {}
};

const CONNECTS = [
    {
        id: 'GMAIL',
        type: 'MAIL',
        logo: 'assets/images/connect/gmail-logo.svg',
        route: 'integrationGmail'
    },
    {
        id: 'MICROSOFT',
        type: 'MAIL',
        logo: 'assets/images/connect/microsoft-logo.svg',
        route: 'integrationMicrosoft'
    },
    {
        id: 'SLACK',
        logo: 'assets/images/connect/slack-logo.svg',
        route: 'integrationSlack',
        // allowedClientIds: ['6e3c963bedd96099'],
        // internal: true
    },
    {
        id: 'HUBSPOT',
        type: 'CRM',
        logo: 'assets/images/connect/hubspot-logo.svg',
        route: 'integrationHubspot'
    },
    {
        id: 'SALESFORCE',
        type: 'CRM',
        logo: 'assets/images/connect/salesforce-logo.svg',
        route: 'integrationSalesforce'
    },
    {
        id: 'PIPEDRIVE',
        type: 'CRM',
        logo: 'assets/images/connect/pipedrive-logo.svg',
        route: 'integrationPipedrive'
    },
    {
        id: 'SELLSY',
        logo: 'assets/images/connect/sellsy-logo.svg',
        route: 'integrationSellsy'
    },
    {
        id: 'INTERCOM',
        logo: 'assets/images/connect/intercom-logo.svg',
        route: 'integrationIntercom'
    },
    {
        id: 'STRIPE',
        type: 'AGREEMENT',
        logo: 'assets/images/connect/stripe-logo.svg',
        route: 'integrationStripe'
    },
    {
        id: 'CHARGEBEE',
        type: 'AGREEMENT',
        logo: 'assets/images/connect/chargebee-logo.svg',
        route: 'integrationChargebee'
    },
    {
        id: 'ZENDESK',
        logo: 'assets/images/connect/zendesk-logo.svg',
        route: 'integrationZendesk'
    },
    {
        id: 'JIRA',
        logo: 'assets/images/connect/jira-logo.svg',
        route: 'integrationJira'
    },
    {
        id: 'SEGMENT',
        logo: 'assets/images/connect/segment-logo.svg',
        route: 'integrationSegment'
    },
    {
        id: 'PENDO',
        logo: 'assets/images/connect/pendo-logo.svg',
        route: 'integrationPendo'
    },
    {
        id: 'MIXPANEL',
        type: 'TRACKER',
        logo: 'assets/images/connect/mixpanel-logo.svg',
        route: 'integrationMixpanel',
        internal: true
    },
];

export const CUSTOMER_MATCH = ['refId', 'name', 'domain'];
export const DEFAULT_CUSTOMER_MATCH = ['refId', 'name'];

const getters = {
    getAll: (state, getters, store, rootGetters) => {
        return state.connections[rootGetters.currentClientId] || [];
    },
    getFromId: (state, getters) => id => getters.getAll.find(a => a.id === id),
    getConnects: (state, getters, store, rootGetters) => {
        const internal = rootGetters['users/currentUser']?.internal || false;
        return CONNECTS.filter(c => {
            if (c.allowedClientIds?.includes(rootGetters.currentClientId)) return true;
            return (!c.internal || c.internal === internal);
        });
    },
    getCustomerMatch: () => {
        return [...CUSTOMER_MATCH];
    },
    getDefaultCustomerMatch: () => {
        return [...DEFAULT_CUSTOMER_MATCH];
    }
};

const actions = {
    async setCurrentClientId({ commit, getters }, { clientId }) {
        const client = getters.getAll?.find(c => c.id === clientId);
        if (client) {
            commit("setCurrentClient", { client });
            this.commit('users/reset');
            this.commit('interactions/reset');
        }
        
    },
    async ensureConnections({ dispatch, state }) {
        const clientId = this.getters.currentClientId;
        if (!state.connections[clientId]) {
            return await dispatch('fetchConnections');
        }
        return state.connections[clientId];
    },
    async fetchConnections({ dispatch, commit }) {
        try {
            const clientId = this.getters.currentClientId;
            const data = await this.getters.api.get('/connections');
            commit("setConnections", { clientId, data: data.map(fromAPI) });
            return data;
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async add({ commit }, { connection }) {
        try {
            const clientId = this.getters.currentClientId;
            const data = await this.getters.api.post(`/connections`, { data: toAPI(connection) });
            commit("setConnection", { clientId, data });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async update({ commit }, { connectionId, connection }) {
        try {
            const clientId = this.getters.currentClientId;
            if (connection?.credentials) connection.reconnect = false;
            const data = await this.getters.api.patch(`/connections/${connectionId}`, { data: toAPI(connection) });
            // TODO remove
            connection.id = connectionId;
            connection.updatedAt = new Date();
            commit('setConnection', { clientId, data: fromAPI(connection) });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async remove({ commit }, { connectionId }) {
        try {
            const clientId = this.getters.currentClientId;
            await this.getters.api.delete(`/connections/${connectionId}`);
            commit("removeConnection", { clientId, connectionId });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async getAuthUrl({ dispatch, commit }, { connectId, params = {} }) {
        return await this.getters.api.get(`/connections/${connectId.toLowerCase()}/oauth-uri`, { params });
    },
    async validateAuthCode({ dispatch, commit }, { connectId, code, params = {} }) {
        const data = await this.getters.api.post(`/connections/${connectId.toLowerCase()}/validate-code`, { data: { code, ...params } });
        return data;
    },
    async callApiGet({ dispatch, commit }, { connectId, connectionId, api }) {
        if (connectionId) {
            return await this.getters.api.get(`/connections/${connectionId}/${connectId.toLowerCase()}/${api}`);
        }
        return await this.getters.api.get(`/connections/${connectId.toLowerCase()}/${api}`);
    },
    async callApiPost({ dispatch, commit }, { connectId, connectionId, api, data }) {
        if (connectionId) {
            return await this.getters.api.post(`/connections/${connectionId}/${connectId.toLowerCase()}/${api}`, data);
        }
        return await this.getters.api.post(`/connections/${connectId.toLowerCase()}/${api}`, data);
    },
};

const mutations = {

    fetched(state, { clientId, value }) {
        state.fetched[clientId] = value;
    },
    setConnection(state, { clientId, data }) {
      const index = state.connections[clientId].findIndex(c => c.id === data.id);
      if (index > -1) {
        Vue.set(state.connections[clientId], index, { ...state.connections[clientId][index], ...data});
      }
      else {
          state.connections[clientId].push(data);
      }
    },
    setConnections(state, { clientId, data }) {
      Vue.set(state.connections, clientId, data);
    },
    removeConnection(state, { clientId, connectionId }) {
      const index = state.connections[clientId].findIndex(c => c.id === connectionId);
      if (index > -1) {
          state.connections[clientId].splice(index, 1);
      }
    },
    reset(state) {
      for (const key of Object.keys(state.fetched)) {
        state.fetched[key] = false;
      }
    }
};

function fromAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    if (json.lastExecutionTs) json.lastExecutionTs = dayjs(json.lastExecutionTs).toDate();
    return json;
}

function toAPI(connection) {
    const json = {...connection};
    // Force Active status
    json.status = "ACTIVE";
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
