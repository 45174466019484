var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "editor",
      class: { "with-menu": !_vm.hideMenu, "is-invalid": _vm.invalid }
    },
    [
      _vm.editor
        ? _c(
            "div",
            {
              staticClass: "menubar is-hidden mb-2",
              class: { "is-focused": !_vm.hideMenu || _vm.focused },
              on: {
                "!click": function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("bold") },
                      attrs: { title: _vm.$t("commons.editor.command.bold") },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "bold" } })],
                    1
                  )
                : _vm._e(),
              !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("italic") },
                      attrs: { title: _vm.$t("commons.editor.command.italic") },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "italic" } })],
                    1
                  )
                : _vm._e(),
              !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("strike") },
                      attrs: { title: _vm.$t("commons.editor.command.strike") },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleStrike()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "strike" } })],
                    1
                  )
                : _vm._e(),
              !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("underline") },
                      attrs: {
                        title: _vm.$t("commons.editor.command.underline")
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleUnderline()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "underline" } })],
                    1
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly && _vm.mode !== "slack"
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: {
                        "is-active": _vm.editor.isActive("heading", {
                          level: 1
                        })
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 1 })
                            .run()
                        }
                      }
                    },
                    [_vm._v("\n        H1\n      ")]
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly && _vm.mode !== "slack"
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: {
                        "is-active": _vm.editor.isActive("heading", {
                          level: 2
                        })
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 2 })
                            .run()
                        }
                      }
                    },
                    [_vm._v("\n        H2\n      ")]
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly && _vm.mode !== "slack"
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: {
                        "is-active": _vm.editor.isActive("heading", {
                          level: 3
                        })
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 3 })
                            .run()
                        }
                      }
                    },
                    [_vm._v("\n        H3\n      ")]
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("bulletList") },
                      attrs: {
                        title: _vm.$t("commons.editor.command.bulletList")
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleBulletList()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "ul" } })],
                    1
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: {
                        "is-active": _vm.editor.isActive("orderedList")
                      },
                      attrs: {
                        title: _vm.$t("commons.editor.command.orderedList")
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleOrderedList()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "ol" } })],
                    1
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly && _vm.mode !== "slack"
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("taskList") },
                      attrs: {
                        title: _vm.$t("commons.editor.command.taskList")
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleTaskList()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "task-list" } })],
                    1
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly
                ? _c("text-editor-link-command", {
                    attrs: { editor: _vm.editor },
                    on: {
                      show: function($event) {
                        _vm.linkShow = true
                      },
                      hide: function($event) {
                        _vm.linkShow = false
                      }
                    }
                  })
                : _vm._e(),
              !_vm.singleline && !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("blockquote") },
                      attrs: {
                        title: _vm.$t("commons.editor.command.blockquote")
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleBlockquote()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "quote" } })],
                    1
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("code") },
                      attrs: { title: _vm.$t("commons.editor.command.code") },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleCode()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "code" } })],
                    1
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly && _vm.mode !== "slack"
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      class: { "is-active": _vm.editor.isActive("codeBlock") },
                      attrs: {
                        title: _vm.$t("commons.editor.command.codeBlock")
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleCodeBlock()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "block_code" } })],
                    1
                  )
                : _vm._e(),
              !_vm.singleline && !_vm.textonly
                ? _c(
                    "button",
                    {
                      staticClass: "menubar__button",
                      attrs: {
                        title: _vm.$t("commons.editor.command.hardbreak")
                      },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .setHardBreak()
                            .run()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "hardbreak" } })],
                    1
                  )
                : _vm._e(),
              _vm.allowVariables
                ? _c("text-editor-variable-command", {
                    attrs: { editor: _vm.editor, items: _vm.variables }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("editor-content", {
        staticClass: "editor__content",
        attrs: { editor: _vm.editor }
      }),
      _vm.showPlaceholder
        ? _c("p", { staticClass: "-placeholder", on: { click: _vm.focus } }, [
            _vm._v("\n      " + _vm._s(_vm.placeholder) + "\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }