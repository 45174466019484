import { BaseInterceptor } from './BaseInterceptor';

const cacheable = true;
const Cache = {
  _items: {},
  get(key) {
    return this._items[key];
  },
  set(key, value) {
    this._items[key] = value;
  },
  del(key) {
    this._items[key] = null;
  }
};

export class PendingInterceptor extends BaseInterceptor {

  getType() {
    return 'PendingInterceptor';
  }

  requestInterceptor() {
    return {
      request: request => {
        // Only cache GET requests
        if (request.method === 'get' && cacheable) {
          const key = this.getCacheKey(request);

          const _pending = Cache.get(key);

          if (_pending) {
            return new Promise((resolve, reject) => {
              _pending.add((err, data) => {
                if (err) return reject(err);
                // console.log(`"${key}" served from pendings:`);

                request.data = data;

                // Set the request adapter to send the cached response and prevent the request from actually running
                request.adapter = () => {
                  const res = {
                    data: data,
                    status: request.status,
                    statusText: request.statusText,
                    headers: request.headers,
                    config: request,
                    request: request
                  };
                  if (data.status === 'success') {
                    return Promise.resolve(res);
                  } else {
                    return Promise.reject(res);
                  }

                };
                return resolve(request);
              });
            });
          }
          else {
            Cache.set(key, {
              _fn: [],
              add(fn) {
                this._fn.push(fn);
              },
              resolve(data) {
                this._fn.forEach(fn => {
                  fn(null, JSON.parse(JSON.stringify(data)));
                });
              },
              reject(err) {
                this._fn.forEach(fn => {
                  fn(err);
                });
              }
            });
          }
        }
        return request;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  responseInterceptor() {
    return {
      response: response => {
        if (cacheable) {
          const key = this.getCacheKey(response.config);
          if (response.config.method === 'get') {
            // On get request, store the response in the cache
            Cache.get(key)?.resolve(response.data);
            Cache.del(key);
          }
        }
        return response;
      },
      error: error => {
        if (cacheable) {
          const key = this.getCacheKey(error.response.config);
          if (error.response.config.method === 'get') {
            // On get request, store the response in the cache
            Cache.get(key)?.resolve(error.response.data);
            Cache.del(key);
          }
        }
        return Promise.reject(error);
      }
    };
  }
  getCacheKey(config) {
    let key = config.url;
    // Append the params, I use jquery param but you can change to whatever you use
    if (config.params)
      key += '?' + JSON.stringify(config.params);
    return key;
  }
}
