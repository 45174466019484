
export const factorizableOperators = {
    number: ['EQUALS', 'NOT'],
    'number[]': ['CONTAINS', 'NOT_CONTAINS'],
    enum: ['EQUALS', 'NOT'],
    'enum[]': ['CONTAINS', 'NOT_CONTAINS'],
    entity: ['IN', 'NOT_IN'],
    'entity[]': ['IN', 'NOT_IN'],
    action: ['EQUALS', 'NOT'],
    playbook: ['EQUALS', 'NOT'],
    boolean: ['EQUALS', 'NOT'],
    string: ['START_WITH', 'END_WITH', 'EQUALS', 'NOT', 'CONTAINS', 'NOT_CONTAINS', 'MATCH', 'NOT_MATCH'],
    'string[]': ['CONTAINS', 'NOT_CONTAINS'],
    user: ['EQUALS', 'NOT']
  };
  
export const negativeOperators = [
  'NOT_CONTAINS',
  'NOT_EXISTS',
  'NOT_MATCH',
  'NOT_IN',
  'NOT'
];

const OPERATORS = {
    boolean: ['EQUALS', 'NOT'],
    date: ['EQUALS', 'EXISTS', 'NOT_EXISTS', 'FUTUR', 'INFERIOR', 'PAST', 'SUPERIOR'],
    datetime: ['EQUALS', 'EXISTS', 'NOT_EXISTS', 'FUTUR', 'INFERIOR', 'PAST', 'SUPERIOR'],
    enum: ['EQUALS', 'NOT', 'EXISTS', 'NOT_EXISTS'],
    'enum[]': ['CONTAINS', 'NOT_CONTAINS', 'EXISTS', 'NOT_EXISTS'],
    exp: ['AND', 'OR'],
    geopoint: ['EXISTS', 'NOT_EXISTS'],
    number: ['EQUALS', 'NOT', 'EXISTS', 'NOT_EXISTS', 'INFERIOR', 'SUPERIOR'],
    'number[]': ['CONTAINS', 'NOT_CONTAINS', 'EMPTY', 'NOT_EMPTY'],
    object: ['EXISTS', 'NOT_EXISTS'],
    string: ['EQUALS', 'NOT', 'EXISTS', 'NOT_EXISTS', 'CONTAINS', 'NOT_CONTAINS', 'START_WITH', 'END_WITH', 'MATCH', 'NOT_MATCH'],
    'string[]': ['CONTAINS', 'NOT_CONTAINS', 'EMPTY', 'NOT_EMPTY'],
    user: ['EQUALS', 'NOT', 'EXISTS', 'NOT_EXISTS']
};

const propertyInfo = {
};
const enumDescriptions = {};

export function getPropertyInfo(field) {
  return propertyInfo.hasOwnProperty(removeFieldModifier(field)) ? propertyInfo[removeFieldModifier(field)] : null;
}

export function getPropertyType(field) {
  const info = getPropertyInfo(field);
  if (!info || !info.type) {
    return null;
  }

  let type = info.type;

  if (type === 'array') {
    const arrayType = info.arrayType;
    type = `${arrayType}[]`;
  }

  return type;
}

export function getPropertyLabel(property) {
  const info = getPropertyInfo(property);
  return info ? info.label : null;
}


/**
 * Returns field modifier (list, event, feed) from path items
 */
export function getFieldModifier(path) {
  const res = null;

  for (const item of path) {
    if (item.includes('[list]')) {
      return '[list]';
    }
    if (item.includes('[feed]')) {
      return '[feed]';
    }
  }

  return res;
}

export function removeFieldModifier(property) {
    if (property === null || typeof property === 'undefined') {
      return property;
    }
    if (property.includes('[list]')) {
      return property.substr(0, property.indexOf('[list]'));
    }
    if (property.includes('[playbook]')) {
      return property.substr(0, property.indexOf('[playbook]'));
    }
    return property;
}

const state = {
  fetched: false
};


const getters = {
  getAll: () => OPERATORS,
  getOperatorOrder: () => {
    return {
      EQUALS: 100,
      NOT: 90,
      EXISTS: 80,
      NOT_EXISTS: 70,
      CONTAINS: 60,
      NOT_CONTAINS: 50,
      START_WITH: 40,
      END_WITH: 30,
      MATCH: 20,
      NOT_MATCH: 10,

      INFERIOR: 110,
      SUPERIOR: 105,
      DIFFERENT: 95

    };
  },
  getOperatorsForType: () => (type, options) => {
      if (['date', 'datetime'].includes(type) && options?.pastOnly) {
        return OPERATORS[type].filter(o => o !== 'FUTUR');
      }
      if (['date', 'datetime'].includes(type) && options?.futurOnly) {
        return OPERATORS[type].filter(o => o !== 'PAST');
      }
      return OPERATORS[type] || [];
  },
  isNegativeOperator: () => (operator) => negativeOperators.includes(operator),
  factorizableOperators: () => factorizableOperators,
  getPropertyType: () => property => getPropertyType(property),
  getPropertyLabel: () => property => getPropertyLabel(property),
  getEnumDescription: () => property => {
    return enumDescriptions.hasOwnProperty(removeFieldModifier(property)) ? enumDescriptions[removeFieldModifier(property)] : null;
  }
};

const actions = {


};

const mutations = {
  fetched(state, isFetched) {
    state.fetched = isFetched;
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
