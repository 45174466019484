
import dayjs from 'dayjs';
import { getTypeIcon, getTypeLink } from './typeConverter';

export const SEARCH_TYPE = {
    CUSTOMER: 'CUSTOMER',
    CONTACT: 'CONTACT',
    USER: 'USER',
    INTERACTION: 'INTERACTION',
    AGREEMENT: 'AGREEMENT',
    FEED_LIST: 'FEED_LIST',
};

export const SEARCH_ORDER_MODE = {
    ASC: 'ASC',
    DESC: 'DESC'
};

export const SEARCH_ORDER_VALUE = {
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
};

const state = {
};

const getters = {
}

const actions = {

  async search({}, { term, filters, order, size, page }) {
    try {
      const clientId = this.getters.currentClientId;
      const results = await this.getters.api.get('/search', {
          params: {
            q: term,
            type: filters?.join(','),
            orderBy: order && order.value != 'rank' ? order.value : undefined,
            orderMode: order && order.value != 'rank' ? order.mode : undefined,
            size: size || 50,
            page: page || 1
          },
          cache: false
        }
      );
      const data = results.data.map(fromAPI(clientId)).filter(s => !!s);
    
      return {
          total: results.metadata.pagination.total || 0,
          data: data.reduce((prev, cur) => {
            prev[cur.type] = prev[cur.type] || [];
            prev[cur.type].push(cur);
            return prev;
          }, {})
      };
  } catch (error) {
    console.error(error);
    throw 'A server error as occurred';
  }
}
};

const mutations = {
  reset(state) {
  }
};

function fromAPI(clientId) {
    return function (json) {
        try {
            if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
            if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
            if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
            const resolvedRoute = getTypeLink(clientId, json);
            if (!resolvedRoute) {
              return null;
            }
            return {
              type: json.type,
              options: json.options,
              icon: getTypeIcon(json.type),
              label: json.label,
              description: json.description,
              link: resolvedRoute.href ? resolvedRoute.href : '',
              createdAt: json.createdAt,
              updatedAt: json.updatedAt
            };
          }
          catch(error) {
            console.error(error);
          }
        
        return false;
    }  
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
