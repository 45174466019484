
import Vue from 'vue';
import dayjs from 'dayjs';
import { debounce } from '@/libs/utils/Promise';

const state = {
  fetched: {},
  usersTeams: {},
};

const getters = {
  getAll: (state, getters, store, rootGetters) => {
      return state.usersTeams[rootGetters.currentClientId] || [];
  },
  getFromId: (state, getters) => id => getters.getAll.find(a => a.id === id),
  getMembersFromId: (state, getters, store, rootGetters) => id => {
    const team = getters.getFromId(id);
    const members = team?.members || [];
    if (team?.teams?.length) {
        return getRecurseMembers(rootGetters.currentClientId, id, []);
    }
    return members;
  },
  getUserTeamsFromId: (state, getters) => userId => {
    const teams = getters.getAll.filter(t => (t.members || []).includes(userId));
    return teams;
  }
}

// Get all team members with sub teams members
function getRecurseMembers(clientId, teamId, processedTeams) {
    if (!state.usersTeams[clientId]) return [];
    if (processedTeams.includes(teamId)) return [];
    processedTeams.push(teamId);
    const team = state.usersTeams[clientId].find(a => a.id === teamId);
    const members = [...(team?.members || [])];
    if (team?.teams?.length) {
        for(const subTeamId of team.teams) {
            members.push(...getRecurseMembers(clientId, subTeamId, processedTeams).flat(10));
        }
    }
    return members;
}

const actions = {

  async ensureUsersTeams({ dispatch, state }) {
    const clientId = this.getters.currentClientId;
    if (!state.fetched[clientId]) {
      return await dispatch('fetchUsersTeams');
    }
    return state.usersTeams[clientId];
  },
  async fetchUsersTeams({ dispatch, commit }) {
    try {
        return await debounce('fetchUsersTeams', async () => {
            const clientId = this.getters.currentClientId;
            try {
                const data = (await this.getters.api.get('/users-teams')).map(fromAPI).sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                commit('fetched', { clientId, value: true });
                commit('setUsersTeams', { clientId, data });
                return data;
            }
            catch(ex) {
                console.error(ex);
            }
        });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { team }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.post(`/users-teams`, { data: toAPI(team) });
        const result = fromAPI(data);
        commit('setUsersTeam', { clientId, data: result });
        return result;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { teamId, team }) {
    try {
        const clientId = this.getters.currentClientId;
        const data = await this.getters.api.patch(`/users-teams/${teamId}`, { data: toAPI(team) });
        // TODO remove
        team.id = teamId;
        commit('setUsersTeam', { clientId, data: team });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { teamId }) {
    try {
        const clientId = this.getters.currentClientId;
        await this.getters.api.delete(`/users-teams/${teamId}`);
        commit('removeUsersTeam', { clientId, teamId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, { clientId, value }) {
    state.fetched[clientId] = value;
  },
  setUsersTeam(state, { clientId, data }) {
    const index = state.usersTeams[clientId].findIndex(c => c.id === data.id);
    if (index > -1) {
      Vue.set(state.usersTeams[clientId], index, { ...state.usersTeams[clientId][index], ...data});
    }
    else {
        state.usersTeams[clientId].push(data);
    }
  },
  setUsersTeams(state, { clientId, data }) {
    Vue.set(state.usersTeams, clientId, data);
  },
  removeUsersTeam(state, { clientId, teamId }) {
    const index = state.usersTeams[clientId].findIndex(c => c.id === teamId);
    if (index > -1) {
        state.usersTeams[clientId].splice(index, 1);
    }
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    return json;
}

function toAPI(usersTeam) {
    const json = {...usersTeam};
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
