<template>
    <div class="image-infos-wrapper" @click.prevent.stop v-click-outside="onCancel">
        <b-card>
            <b-form-group
                :label="$t('commons.editor.image.url.label')"
                label-for="input-var-url"
                class="w-100"
            >
                <b-form-input
                id="input-var-url"
                v-model="url"
                type="text"
                size="sm"
                :placeholder="$t('commons.editor.link.url.placeholder')"
                debounce="500"
                aria-describedby="input-var-url"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                :label="$t('commons.editor.image.title.label')"
                label-for="input-var-url"
                class="w-100"
            >
                <b-form-input
                id="input-var-title"
                v-model="title"
                type="text"
                size="sm"
                :placeholder="$t('commons.editor.image.title.placeholder')"
                debounce="500"
                aria-describedby="input-var-url"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                :label="$t('commons.editor.image.alt.label')"
                label-for="input-var-url"
                class="w-100"
            >
                <b-form-input
                id="input-var-title"
                v-model="alt"
                type="text"
                size="sm"
                :placeholder="$t('commons.editor.image.alt.placeholder')"
                debounce="500"
                aria-describedby="input-var-url"
                ></b-form-input>
            </b-form-group>
            <b-button variant="primary" size="sm" @click="onSave" class="mr-2">
                {{ $t('commons.action.ok') }}
            </b-button>
            <b-button v-if="canUndo" variant="danger" size="sm" @click="onRemove" class="mr-2">
                {{ $t('commons.action.remove') }}
            </b-button>
            <b-button variant="outline-danger" size="sm" @click="onCancel">
                {{ $t('commons.action.cancel') }}
            </b-button>
        </b-card>
    </div>
</template>

<script>
import i18n from '@/i18n';

export default {
    components: {
    },
  props: {
    value: {
        type: Object,
        required: false
    },
    canUndo: {
      type: Boolean,
      required: true,
      default: false
    },
  },

  data() {
    return {
      url: '',
      title: '',
      alt: ''
    }
  },
  watch: {
    value: function (val) {
      this.url = val?.url || '';
      this.title = val?.title || '';
      this.alt = val?.alt || '';
    }
  },
  created() {
      this.$t = this.$t || ((key) => {
            return i18n.t(key);
      });
      Object.defineProperty(this, '$i18n', {
        get: function get () { return i18n; }
      });
  },
  mounted() {
      if (this.value) {
        this.url = this.value?.url || '';
        this.title = this.value?.title || '';
        this.alt = this.value?.alt || '';
      }
  },
  methods: {
    onSave() {
        this.$emit('select', {
            url: this.url,
            title: this.title,
            alt: this.alt
        });
    },
    onRemove() {
        this.$emit('select', { url: null });
    },
    onCancel() {
        this.$emit('cancel');
    },
    reset() {
        this.url = null;
    }
  },
}
</script>

<style lang="scss">
.menu-item-link {
    display: flex;
    flex-direction: column;

    .menu-item-link-description {
        font-weight: 200;
        font-size: 12px;
    }
}
.image-infos-wrapper{
  position: relative;
  min-width: 480px;
}
</style>