var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "item d-flex align-items-center flex-row p-2 mt-2 -pointer position-relative",
      class: [
        {
          readed: _vm.notification.userNotification.readed,
          unreaded: !_vm.notification.userNotification.readed
        }
      ],
      on: { click: _vm.handleClick }
    },
    [
      !_vm.notification.parameters.component
        ? _c(
            "div",
            { staticClass: "mr-2 position-relative" },
            [
              _vm.notification.emitterUserId !== "SYSTEM"
                ? _c("user-avatar", {
                    attrs: {
                      userId: _vm.notification.emitterUserId,
                      showLabel: false,
                      width: "40"
                    }
                  })
                : _c("b-avatar", {
                    style: {
                      "background-color": "#FFFFFF",
                      color: "rgb(1,1,1)"
                    },
                    attrs: { size: "md", icon: "gear-fill" }
                  }),
              !_vm.notification.userNotification.readed
                ? _c("div", { staticClass: "upperbadge" }, [
                    _c("div", { staticClass: "mybadge" })
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.notification.parameters.component
        ? _c(
            "div",
            { staticClass: "d-flex flex-column flex-grow-1" },
            [
              _vm.resolvedComponent
                ? _c(_vm.resolvedComponent, { tag: "component" })
                : _c("p", { staticClass: "my-auto ml-2" }, [
                    _vm._v(_vm._s(_vm.$t("notifications.message.unknown")))
                  ])
            ],
            1
          )
        : _c("div", { staticClass: "d-flex flex-column flex-grow-1" }, [
            _c("span", [_vm._v(_vm._s(_vm.getMessage()))]),
            _c("span", { staticClass: "subtitle" }, [
              _vm._v(
                _vm._s(_vm.getReaded()) + " • " + _vm._s(_vm.getHourLeft())
              )
            ])
          ]),
      _c("b-avatar", {
        staticClass: "parameter-badge",
        attrs: {
          icon: "three-dots-vertical",
          size: "md",
          id: "notification-item-" + _vm._uid
        },
        nativeOn: {
          click: function($event) {
            $event.stopPropagation()
          }
        }
      }),
      _c(
        "b-popover",
        {
          attrs: {
            target: "notification-item-" + _vm._uid,
            placement: "bottomleft",
            "custom-class": "notification-popover m-0 p-0",
            show: _vm.showed
          },
          on: {
            "update:show": function($event) {
              _vm.showed = $event
            }
          }
        },
        [
          _c(
            "b-list-group",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.onClickOutside,
                  expression: "onClickOutside"
                }
              ]
            },
            [
              !_vm.notification.userNotification.readed
                ? _c(
                    "b-list-group-item",
                    {
                      staticClass: "py-2 px-3",
                      attrs: { button: "" },
                      on: {
                        click: function($event) {
                          return _vm.onSaveReaded(true)
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "envelope-open" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("notifications.popover.actions.markasread")
                          ) +
                          "\n            "
                      )
                    ],
                    1
                  )
                : _c(
                    "b-list-group-item",
                    {
                      staticClass: "py-2 px-3",
                      attrs: { button: "" },
                      on: {
                        click: function($event) {
                          return _vm.onSaveReaded(false)
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "envelope" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("notifications.popover.actions.markasunread")
                          ) +
                          "\n            "
                      )
                    ],
                    1
                  ),
              _vm.notification.userNotification.archived
                ? _c(
                    "b-list-group-item",
                    {
                      staticClass: "py-2 px-3",
                      attrs: { button: "" },
                      on: {
                        click: function($event) {
                          return _vm.onSaveArchived(false)
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2 rotate-180 ",
                        attrs: { icon: "save" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("notifications.popover.actions.unarchive")
                          ) +
                          "\n            "
                      )
                    ],
                    1
                  )
                : _c(
                    "b-list-group-item",
                    {
                      staticClass: "py-2 px-3",
                      attrs: { button: "" },
                      on: {
                        click: function($event) {
                          return _vm.onSaveArchived(true)
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "save" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("notifications.popover.actions.archive")
                          ) +
                          "\n            "
                      )
                    ],
                    1
                  ),
              _c(
                "b-list-group-item",
                {
                  staticClass: "py-2 px-3 text-danger",
                  attrs: { button: "" },
                  on: { click: _vm.onDeleteNotification }
                },
                [
                  _c("b-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "trash" }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("notifications.popover.actions.delete")) +
                      "\n            "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.notification.userNotification.archived
        ? _c("b-icon", {
            staticClass: "bookmark",
            attrs: { icon: "bookmark-fill" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }