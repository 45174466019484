<template>
    <div class="d-flex">
        <div class="header-btn-lg pr-0">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                        <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-lg" variant="link" right>
                            <template v-slot:button-content>
                                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <img width="42" class="rounded-circle" :src="avatar" alt="" v-b-tooltip.hover :title="userName">
                                </div>
                            </template>
                            <button type="button" tabindex="0" class="dropdown-item" @click="handleMyProfile">{{ $t('commons.header.myprofile') }}</button>
                            <!-- <button type="button" tabindex="0" class="dropdown-item">Settings</button> -->
                            <div tabindex="-1" class="dropdown-divider"></div>
                            <button v-if="user" type="button" tabindex="1" class="dropdown-item" @click="handleGoToDoc">{{ $t('global.link.documentation.title') }}</button>
                            <button v-if="user" type="button" tabindex="1" class="dropdown-item" @click="handleFeatureRequest">{{ $t('global.link.feature-request.title') }}</button>
                            <!-- <a v-if="user" class="dropdown-item -pointer" target="_blank" :href="VUE_APP_PUBLIC_ROADMAP_URL">{{ $t('global.link.roadmap.url.title') }}<b-icon class="ml-2" icon="box-arrow-up-right" /></a> -->
                            <button v-if="user" type="button" tabindex="1" class="dropdown-item" @click="handleRoadmap">{{ $t('global.link.roadmap.url.title') }}<b-icon class="ml-2" icon="box-arrow-up-right" /></button>
                            
                            <div tabindex="-1" class="dropdown-divider"></div>
                            <button type="button" tabindex="2" class="dropdown-item" @click="handleLogout">{{ $t('commons.header.logout') }}</button>
                        </b-dropdown>
                    </div>
                    <div class="widget-content-left  ml-3 header-user-info">
                        <div class="widget-heading">{{ userName }}</div>
                        <div class="widget-subheading" v-if="user">{{ subHeading }}</div>
                    </div>
                    <div v-if="badges" class="widget-content-left user-badges ml-1">
                        <img v-if="badges.expert" :src="expertLogo.path" :title="expertLogo.alt" :alt="expertLogo.alt" />
                        <img v-else-if="badges.advanced" :src="advancedLogo.path" :title="advancedLogo.alt" :alt="advancedLogo.alt" />
                        <img v-else-if="badges.essential" :src="essentialLogo.path" :title="essentialLogo.alt" :alt="essentialLogo.alt" />
                    </div>
                </div>
            </div>
        </div>
        <profile-modal v-if="user" :user="user" @close="onCloseProfile" />
        <feature-request-modal v-if="user" :user="user" />
    </div>
</template>

<script>
    import i18n from '@/i18n';
    import ProfileModal from '@/modules/settings/components/ProfileModal';
    import FeatureRequestModal from '@/modules/settings/components/FeatureRequestModal';

    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faAngleDown,
        faCalendarAlt,
        faTrashAlt,
        faCheck,
        faFileAlt,
        faCloudDownloadAlt,
        faFileExcel,
        faFilePdf,
        faFileArchive,
        faEllipsisH,
    } from '@fortawesome/free-solid-svg-icons'

    library.add(
        faAngleDown,
        faCalendarAlt,
        faTrashAlt,
        faCheck,
        faFileAlt,
        faCloudDownloadAlt,
        faFileExcel,
        faFilePdf,
        faFileArchive,
        faEllipsisH,
    );

    export default {
        components: {
            ProfileModal,
            FeatureRequestModal
        },
        data: () => ({
            VUE_APP_PUBLIC_ROADMAP_URL: process.env.VUE_APP_PUBLIC_ROADMAP_URL
        }),
        computed: {
            user() {
                return this.$store.getters['users/currentUser'];
            },
            avatar() {
                return this.user?.avatar || '/faces/anonymous.png';
            },
            userName() {
                const user = this.user;
                if (!user) return this.$t('commons.anonymous');
                return `${user?.firstName} ${user?.lastName}`;
            },
            subHeading() {
                const user = this.user;
                return user?.email;
            },
            essentialLogo() {
                let essentialLogo = this.$store.getters["onboarding/getVisuals"]?.find((logo) => logo.key === "ESSENTIAL");
                return essentialLogo;
            },
            //Get the Advanced Logo
            advancedLogo() {
                let advancedLogo = this.$store.getters["onboarding/getVisuals"]?.find((logo) => logo.key === "ADVANCED");
                return advancedLogo;
            },
            //Get the Expert Logo
            expertLogo() {
                let expertLogo = this.$store.getters["onboarding/getVisuals"]?.find((logo) => logo.key === "EXPERT");
                return expertLogo;
            },
            badges() {
                return this.user?.badges;
            }
        },
        watch: {
            '$route.query.show': async function() {
                if (this.$route.query.show === 'profile') this.handleMyProfile();
            }
        },
        methods: {
            handleMyProfile() {
                this.$nextTick(() => {
                    this.$bvModal.show('profile');
                    // this.$router.push({ name: 'profile' });
                });
            },
            onCloseProfile() {
                if (this.$route.query.show === 'profile') {
                    this.$router.replace({ query: {} });
                }
            },
            async handleRoadmap() {
                const url = await this.$store.dispatch('tools/fetchUrlRoadmap');
                if (url) window.open(url, '_blank').focus();
            },
            handleFeatureRequest() {
                this.$nextTick(() => {
                    this.$bvModal.show('featureRequest');
                });
            },
            async handleGoToDoc() {
                const language = this.$store.getters['configs/getForUser']('language') || i18n.locale;
                window.open(`https://docs.skalin.io/${language}/user/`, '_blank').focus();
            },
            handleLogout() {
                this.$store.dispatch('users/logout');
                this.$nextTick(() => {
                    this.$router.push({ name: 'logout' });
                });
            }
        }
    }


</script>
<style lang="scss" scoped>
.user-badges {
    img {
        transform: scale(0.65);
    }
}
</style>