
const state = {
    table: {}
};

const getters = {
    getTableConfig: (state, getters, store, rootGetters) => tableKey => {
        return rootGetters['configs/getForUserAll']?.table?.[tableKey];
    }
};

const actions = {
    async submitRequest({ dispatch }, { title, description, files }) {
        const requestFormData = new FormData();
        requestFormData.append('title', title);
        requestFormData.append('description', description);
        if (files?.length) {
            let idx = 0;
            for (const file of files) {
                if (idx > 2) continue;
                requestFormData.append(`attachments`, file);
            }
        }
        return await this.getters.api.post(`/support/submit-request`, {
            data: requestFormData
        });
    },
    async fetchUrlRoadmap({ dispatch }) {
        const url = await this.getters.api.get(`/support/roadmap-redirect`);
        return url;
    },
    async updateTableSettings({ commit }, { key, value} ) {
        try {
            const config = JSON.parse(JSON.stringify({ ...this.getters['configs/getForUserAll'] }));
            config.table = config.table || {};
            config.table[key] = { ...value };
            await this.dispatch("configs/updateUserConfig", {
                config
            });
          } catch (error) {
            throw 'A server error has occurred';
          }
    },
};

const mutations = {
    
};

export default {
    namespaced: true,
    getters,
    actions,
    state,
    mutations
};
