<template>
    <div class="d-flex" v-if="currentClientId && clients && clients.length > 1">
        <div class="header-btn-lg pr-0">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                        <b-dropdown toggle-class="" menu-class="p-1" variant="link" right>
                            <template v-slot:button-content>
                                <div class="widget-heading">{{ clientName }}</div>
                            </template>
                            <b-form-select v-model="clientId" :options="clients" class="m-0" placeholder="Client" @change="handleChangeClient" ></b-form-select>
                            <div v-if="$isInternal()" class="text-center mt-2">
                                <b-button size="sm" variant="outline-primary" @click="handleCreateClient">Create a client</b-button>
                            </div>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <create-client-modal @ok="onClientSave" />
    </div>
</template>

<script>
    import CreateClientModal from './CreateClientModal';

    export default {
        components: {
            CreateClientModal
        },
        data: () => ({
            clientId: null
        }),
        computed: {
            clients() {
                return this.$store.getters['clients/getAll']?.map(c => {
                    return {
                        value: c.id,
                        text: c.name
                    };
                });
            },
            currentClientId() {
                return this.$store.getters['clients/currentClientId'];
            },
            currentClient() {
                return this.$store.getters['clients/currentClient'];
            },
            clientName() {
                const currentClient = this.currentClient;
                return `${currentClient?.name} (${currentClient?.domain})`;
            }
        },
        watch: {
            currentClientId() {
                this.clientId = this.currentClientId;
            }
        },
        mounted() {
            this.clientId = this.currentClientId;
        },
        methods: {
            handleChangeClient() {
                this.$nextTick(() => {
                    this.$store.dispatch('clients/setCurrentClientId', { clientId: this.clientId });
                    if (this.$route.name === 'customers') {
                        document.location.reload();
                    }
                    else {
                        this.$router.push({ name: 'home' }).finally(() => document.location.reload());
                    }
                });
            },
            handleCreateClient() {
                this.$nextTick(() => {
                        this.$bvModal.show('createClient');
                });
            },
            async onClientSave(client) {
                
                await this.$store.dispatch('clients/add', { client });

                this.$bvToast.toast('Client created', {
                    title: this.$t('commons.ope.success'),
                    autoHideDelay: 5000,
                    appendToast: false
                });
            }
        }
    }


</script>
