import Vue from "vue";
import dayjs from 'dayjs';
import EventsBus from '@/libs/EventsBus';
import { getInstance } from "@/auth/authWrapper";

const  SKALIN_CLIENT_ID = ['aaaaaaaaaaaaaaaa', 'd0fd3591c03aff5c', '33544217290cd734'];

const OWNER_FILTER_CACHE_KEY = 'owner-filter';

const state = {
    currentUserId: null,
    currentUser: null,
    users: null,
    token: null,
    stats: null,
    ownerFiler: null,
    detailsStats: {}
};

const getters = {
    currentUserId: state => state.currentUserId,
    currentUser: (state) => {
        return state.currentUser;
    },
    token: state => state.token,
    getAll: state => {
        return state.users || [];
    },
    getFromId: (state, getters) => id => getters.getAll?.find(a => a.id === id),
    getNameFromId: (state, getters) => id =>  {
        const user  = getters.getFromId(id);
        if (!user) return '';
        return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.email}`;
    },
    getAvatarFromId: (state, getters) => id => getters.getFromId(id)?.avatar || '/faces/anonymous.png',
    getStats: (state) => {
        return state.stats;
    },
    getStatsFromId: (state, getters) => id => state.stats?.find(s => s.userId === id),
    getDetailsStats: (state) => id => state.detailsStats[id],
    getCurrentOwnerFilter:(state, getters, store, rootGetters) => {
        if (state.ownerFiler) return state.ownerFiler;
        const userFilter = sessionStorage.getItem(OWNER_FILTER_CACHE_KEY);
        if (userFilter === 'null') return null;
        try {
            const filter = JSON.parse(userFilter);
            if (filter) {
                switch(filter.mode) {
                    case 'USER': if (getters.getFromId(filter.value)) return filter;
                        break;
                    case 'TEAM': if (rootGetters['usersTeams/getFromId'](filter.value)) return filter;
                        break;
                }
            }
        }
        catch(ex) {
            console.error(ex);
        }
        return;
    },
    getUserLabel: state => user => {
        return user.firstName?.length ? `${user.firstName} ${user.lastName}` : `${user.email}`;
    },
};

const actions = {
    async grantUser({ commit, dispatch }, { user, token }) {
        if (!user) throw `User not defined`;
        if (token) commit("setToken", { token });
        commit("setCurrentUser", { user });
        await this.dispatch('clients/ensureClients');
    },
    async initCurrentUser({ commit, dispatch, getters }) {
        await this.dispatch('clients/ensureClients');
        const users = await dispatch('ensureUsers');
        const currentUser = users.find(u => u.email === getters.currentUser.email);
        if (!currentUser) throw `User not found`;
        const propToUpdate = {};
        if (getters.currentUser.firstName && !currentUser.firstName) propToUpdate.firstName = getters.currentUser.firstName;
        if (getters.currentUser.lastName && !currentUser.lastName) propToUpdate.lastName = getters.currentUser.lastName;
        if (getters.currentUser.language && !currentUser.language) propToUpdate.language = getters.currentUser.language;
        if (getters.currentUser.avatar !== currentUser.avatar) propToUpdate.avatar = getters.currentUser.avatar;
        if (Object.keys(propToUpdate).length) {
            dispatch('update', { userId: currentUser.id, user: { ...currentUser, ...propToUpdate } });
        }

        commit("setCurrentUser", { user: { ...getters.currentUser, ...currentUser, ...propToUpdate } });
        try {
            const clientId = this.getters.currentClientId;
            const sessionId = this.getters.sessionId;
            EventsBus.emit('setCurrentUser', { user: getters.currentUser, clientId, sessionId });
        }
        catch(ex) {
            console.error(ex);
        }
    },
    logout({ commit, dispatch }) {
        commit("setToken", { token: null });
        commit("setCurrentUser", { user: null });
        const authService = getInstance();
        authService.logout();
    },
    async ensureUsers({ dispatch, state }) {
        if (!state.users) {
            return await dispatch("fetchUsers");
        }
        return state.users;
    },
    async fetchUsers({ dispatch, commit }) {
        try {
            const clientId = this.getters.currentClientId;
            const data = (await this.getters.api.get("/users")).map(fromAPI);
            commit("setUsers", { data: data?.filter(u =>  SKALIN_CLIENT_ID.includes(clientId) || !u.internal) });
            return data;
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async add({ commit }, { user, role = this.getters.roles.CSM }) {
        try {
            const goal = {
                goalProfileId: user.goalProfileId,
                goalStartAt: user.goalStartAt ? dayjs(user.goalStartAt).format('YYYY-MM-DD') : null
            };
            const data = await this.getters.api.post(`/users`, {
                data: {
                    ...toAPI(user),
                    ...goal,
                    role
                }
            });
            const model = fromAPI(data);
            model.clientRole = role;
            model.goalProfileId = goal.goalProfileId;
            model.goalStartAt = goal.goalStartAt;
            model.badges = {};
            commit("setUser", { user: model });
            return model;
        } catch (error) {
            if (error.response?.status === 403 && error.response.data?.data?.data === 'No remaining seats') {
                throw 'No remaining seats';
            }
            throw "A server error has occurred";
        }
    },
    async update({ dispatch, getters, commit }, { userId, user }) {
        try {
            const oldUser = getters.getFromId(userId);
            const data = await this.getters.api.patch(`/users/${userId}`, {
                data: toAPI(user)
            });
            if (user.goalProfileId !== oldUser.goalProfileId || user.goalStartAt !== oldUser.goalStartAt) {
                await dispatch('updateGoal', { userId, goalProfileId: user.goalProfileId, goalStartAt: user.goalStartAt })
            }
            // TODO remove
            user.id = userId;
            commit("setUser", { user });
            if (userId === getters.currentUserId) {
                commit("setCurrentUser", { user: { ...getters.currentUser, ...user }});
            }
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async updateRole({ getters, commit }, { userId, role }) {
        try {
            await this.getters.api.post(`/users/${userId}/add-role`, {
                data: { role }
            });
            const user = getters.getFromId(userId);
            user.clientRole = role;
            commit("setUser", { user });
            if (userId === getters.currentUserId) {
                this.commit("clients/setCurrentRole", { role });
            }
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async updateGoal({ getters, commit }, { userId, goalProfileId, goalStartAt }) {
        try {
            const data = {
                goalProfileId
            };
            if (goalProfileId && goalStartAt) {
                data.goalStartAt = dayjs(goalStartAt).format('YYYY-MM-DD');
            }
            await this.getters.api.post(`/users/${userId}/add-goal`, {
                data
            });
            const user = getters.getFromId(userId);
            user.goalProfileId = goalProfileId;
            user.goalStartAt = goalStartAt;
            commit("setUser", { user });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async remove({ commit }, { userId }) {
        try {
            await this.getters.api.delete(`/users/${userId}`);
            commit("removeUser", { userId });
        } catch (error) {
            throw "A server error has occurred";
        }
    },
    async fetchUsersStats({ dispatch, commit }, { ctx }) {
        try {
            const query = {
                pagination: ctx
            };
          const data = (await this.getters.api.get('/users/stats', query) || []).sort((a, b) => {
            // Sort by MRR
            return b.customers.mrr - a.customers.mrr;
          });
          commit('setUsersStats', { data });
          return data;
        } catch (error) {
          throw 'A server error has occurred';
        }
      },
    async fetchUserStats({ dispatch, commit }, { userId, ctx }) {
        try {
            const query = {
                pagination: ctx
            };
          const data = (await this.getters.api.get(`/users/${userId}/stats`, query) || []).sort((a, b) => {
            // Sort by MRR
            return b.customers.mrr - a.customers.mrr;
          });
          commit('setUserStats', { userId, data });
          return data;
        } catch (error) {
          throw 'A server error has occurred';
        }
    },
    async import({ dispatch }, { users }) {
        const result = {
            count: 0,
            created: 0,
            updated: 0,
            deleted: 0,
            error: 0
        };
        try {
            EventsBus.emit('users/import/start', {
                count: users.length
            });
            const actualUsers = await dispatch('fetchUsers');
            for (const user of users.filter(c => !c.deleted)) {
                const found = actualUsers.find(c => c.email === user.email);
                if (found) {
                    const updated = { ...found };
                    let role = null;
                    if (user.additionalEmails) updated.additionalEmails = user.additionalEmails;
                    if (user.description) updated.description = user.description;
                    if (user.firstName) updated.firstName = user.firstName;
                    if (user.lastName) updated.lastName = user.lastName;
                    if (user.language) updated.language = user.language;
                    if (user.jobTitle) updated.jobTitle = user.jobTitle;
                    if (user.clientRole?.length && updated.clientRole !== user.clientRole) role = user.clientRole;
                    try {
                        await dispatch('update', { userId: updated.id, user: updated });
                        if (role) {
                            await this.$store.dispatch('users/updateRole', { userId: updated.id, role });
                        }
                        result.updated++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                else {
                    try {
                        await dispatch('add', { user, role: user.clientRole });
                        result.created++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                result.count++;
                EventsBus.emit('users/import/process', user);
                console.info(user)
            }
            for (const user of users.filter(c => !!c.deleted)) {
                const found = actualUsers.find(c => c.email === user.email);
                if (found) {
                    try {
                        await dispatch('remove', { userId: found.id });
                        result.deleted++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                result.count++;
                EventsBus.emit('users/import/process', user);
            }
            
          } catch (error) {
            throw 'A server error has occurred';
          }
          return result;
      },
      updateCurrentOwnerFilter({ commit }, value) {
        commit('setCurrentOwnerFilter', value);
      },
};

const mutations = {
    setToken(state, { token }) {
        state.token = token;
    },
    setCurrentUser(state, { user }) {
        state.currentUser = user;
        state.currentUserId = user?.id;
        if (localStorage) localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
    },
    addCurrentUserBadge(state, { badge }) {
        Vue.set(state.currentUser.badges, badge, true);
        if (localStorage) localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
        const cur = state.users.find(c => c.id === state.currentUser.id);
        if (cur) {
            Vue.set(cur.badges, badge, true);
        }
    },
    setUsers(state, { data }) {
        state.users = data;
    },
    setUser(state, { user }) {
        const index = state.users.findIndex(c => c.id === user.id);
        if (index > -1) {
            Vue.set(state.users, index, { ...state.users[index], ...user });
        } else {
            state.users.push(user);
        }
    },
    removeUser(state, { userId }) {
        const index = state.users.findIndex(c => c.id === userId);
        if (index > -1) {
            state.users.splice(index, 1);
        }
    },
    setUsersStats(state, { data }) {
        state.stats = data;
    },
    setUserStats(state, { userId, data }) {
        state.detailsStats[userId] = data;
    },
    reset(state) {
        state.users = null;
        state.stats = null;
    },
    setCurrentOwnerFilter(state, value) {
        state.ownerFiler = value;
        if (value) sessionStorage.setItem(OWNER_FILTER_CACHE_KEY, JSON.stringify(value));
    }
};

function fromAPI(json) {
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    if (json.goalStartAt) json.goalStartAt = dayjs(json.goalStartAt).toDate();

    if (!json.avatar) {
        let id = '';
        if (json.firstName?.length) id += json.firstName.substring(0,1).toLowerCase();
        if (json.lastName?.length) id += json.lastName.substring(0,1).toLowerCase();
        if (!id.length) id = json.email.substring(0,1).toLowerCase();
        json.avatar = `https://cdn.auth0.com/avatars/${id}.png`;
    }
    return json;
}

function toAPI(user) {
    const json = {...user};
    if (!json.description) delete json.description;
    if (!json.stage) delete json.stage;
    delete json.internal;
    delete json.clients;
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    delete json.lastTouchTs;
    delete json.mrr;
    delete json.renewalDate;
    delete json.healthScore;
    delete json.clientRole;
    delete json.role;
    delete json.goalProfileId;
    delete json.goalStartAt;
    return json;
}

export default {
    namespaced: true,
    getters,
    actions,
    state,
    mutations
};
