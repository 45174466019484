import { VueRenderer } from '@tiptap/vue-2'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import VariableList from './VariableList.vue';

export default {
  items: ({ query }) => {
    return [
      {label:'Sample', value: 'lesamplea'}
    ]; //.filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
  },
  target: () => {
    this.element?.id;
  },
  disabled: () => {
      return false;
  },

  render: () => {
    let component
    let popup

    return {
      onStart: props => {
        component = new VueRenderer(VariableList, {
          // using vue 2:
          editor: props.editor,
          parent: this,
          propsData: props,
          // using vue 3:
          //   props,
          //   editor: props.editor,
        });
        popup = tippy(component.element, {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.getElementsByClassName('ProseMirror')?.[0].parentElement?.parentElement,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom',
          theme: 'light',
          delay: [200, 0],
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        popup[0]?.setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0]?.hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        if (popup) popup[0]?.destroy()
        component.destroy()
      },
    }
  },
}