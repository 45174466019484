export default class ErrorService {
    constructor() {
      // this.initHandler();
    }
  
    static onError(error) {
        if (error.message?.match(/Unexpected token '<'/i)) {
            // eslint-disable-next-line no-console
            console.error('ChunkLoadError');
            ErrorService.reload('ChunkLoadError');
        }
        const response = error.response;
        if (response && response.status >= 400 && response.status < 405) {
          // You can handle this differently
          return false;
        }
    }
  
    static onWarn(error) {
    }
  
    static onInfo(error) {
    }

    static reload(kind) {
        setTimeout(() => {
            const canReload = !sessionStorage.getItem(`force-reload`);
            if (canReload) {
                sessionStorage.setItem(`force-reload`, true);
                location.reload();
            }
        }, 1000);
    }
  
    static onDebug(error) {
      const response = error.response;
      if (response && response.status >= 400 && response.status < 405) {
        // You can handle this differently
        return false;
      }
    }
  
    static initHandler() {
      const scope = this;
      window.onerror = (message, url, lineNo, columnNo, error) => {
        if (error) {
          scope.onError(error);
        //   console.log(message, url, lineNo, columnNo, error);
        }
      };
      setTimeout(() => {
        sessionStorage.removeItem(`force-reload`);
      }, 10000);
    }
  
    static displayErrorAlert(message) {
    }
  
    static sentryLogEngine(error) {
      // Implement Sentry Engine here
    //   console.log(error, "Sentry");
    }
  }