<template>
    <div class="app-header header-shadow">
        <div class="logo-src"/>
        <div class="app-header__content">
            <div class="app-header-left">
                <h1>{{ title }}</h1>
                <SearchBox/>
                <!-- <b-breadcrumb v-if="breadcrumb && breadcrumb.length" :items="breadcrumb"></b-breadcrumb> -->
            </div>
            <div class="app-header-right">
                <ClientsArea/>
                <UserArea/>
            </div>
        </div>
        <div class="app-header__mobile-menu">
            <div>
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active' : !isCollapsed }" @click="toggleMobile('closed-sidebar-open')">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-header__menu">
            <span>
                <b-button class="btn-icon btn-icon-only" variant="primary" size="sm" v-bind:class="{ 'active' : isOpenMobileMenu }" @click="toggleMobile2('header-menu-open')">
                    <div class="btn-icon-wrapper">
                        <font-awesome-icon icon="ellipsis-v"/>
                    </div>
                </b-button>
            </span>
        </div>
    </div>
</template>

<script>

    import SearchBox from './Header/SearchBox';
    import UserArea from './Header/HeaderUserArea';
    import ClientsArea from './Header/HeaderClientsArea';
    import EventsBus from '@/libs/EventsBus';
    
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faEllipsisV,
    } from '@fortawesome/free-solid-svg-icons'
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faEllipsisV,
    );
    export default {
        name: "Header",
        components: {
            SearchBox,
            UserArea,
            ClientsArea,
            'font-awesome-icon': FontAwesomeIcon,
        },

        data() {
            return {
                isCollapsed: false,
                isOpenMobileMenu: false,
            }
        },
        props: {
            breadcrumb: Array,
            collapsed: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            title() {
                return this.$route.meta?.title ? this.$route.meta?.title() : '';
            }
        },
        watch: {
            collapsed(value){
                if (this.isCollapsed !== value) {
                    this.handleMenuClick();
                }
            }
        },
        mounted() {
            EventsBus.on('menu-click', this.handleMenuClick);
            this.isCollapsed = this.collapsed;
            this.handleMenuClick();
        },
        destroyed() {
            EventsBus.off('menu-click', this.handleMenuClick);
        },
        methods: {
            handleMenuClick() {
                const windowWidth = document.documentElement.clientWidth;
                if (windowWidth < '992') {
                    this.toggleMobile('closed-sidebar-open');
                }
            },
            toggleMobile(className) {
                const el = document.body;
                this.isCollapsed = !this.isCollapsed;

                if (!this.isCollapsed) {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
                this.$emit('update:collapsed', this.isCollapsed);
            },

            toggleMobile2(className) {
                const el = document.body;
                this.isOpenMobileMenu = !this.isOpenMobileMenu;

                if (this.isOpenMobileMenu) {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
        }
    };
</script>
<style lang="scss" scoped>
h1 {
    margin: 0 1rem 0 0.5rem;
    color: var(--primary);
    font-size: 1.5rem;
}
</style>