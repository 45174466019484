<template>
  <div id="updater" class="updater">
    <b-alert
      v-if="showAlert"
      show
      class="position-fixed fixed-bottom rounded-0 alert-updater"
      style="z-index: 2000;"
      variant="success"
      dismissible
    >
        <div v-if="!updating" class="d-flex flex-row align-items-center justify-content-center">
            <b-img src="https://picsum.photos/50/50" thumbnail center fluid rounded="circle" />
            <span class="ml-3">{{ $t('sw.notif.refresh.message') }}</span>
            <div class="ml-3 updater-link-wrapper">
                <b-btn variant="outline-primary" size="sm" @click="refreshApp">{{  $t('sw.notif.refresh.reload') }}</b-btn>
            </div>
        </div>
        <div v-else class="d-flex flex-row align-items-center justify-content-center">
            <div class="wrapper-img">
                <div class="spinner-border text-primary"></div>
                <b-img src="https://picsum.photos/50/50" thumbnail center fluid rounded="circle" />
            </div>
            <span class="ml-3 install">{{ $t('sw.notif.refresh.installing') }}</span>
        </div>
    </b-alert>
  </div>
</template>
<script>
import EventsBus from '@/libs/EventsBus';

const RELOAD_TIMEOUT = 10 * 1000; // 10 s

export default {
    components: {
    },
    data() {
        return {
            // refresh variables
            showAlert: false,
            refreshing: false,
            registration: null,
            updating: false,
            updateExists: false,
            timerForceRefresh: null,
        }
    },
    props: {
    },
    computed: {
    },
    created() {
        // Listen service worker appUpdated event after "updated"
        document.addEventListener('appUpdated', this.startUpdating, { once: true });
        // Listen actvition change when skipWaiting (or refresh) fired
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            console.info('refreshing')
            if (this.refreshing) return;
            if (this.timerForceRefresh) clearTimeout(this.timerForceRefresh);
            this.refreshing = true;
            // Here the actual reload of the page occurs
            this.foreceRefresh();
        });
        setTimeout(() => {
            if (this.updateExists) return;
            localStorage.removeItem("appUpdate");
        }, 10000);
  },
  methods: {
    startUpdating(e) {
        this.registration = e?.detail;
        this.updateExists = true;
        this.updating = false;
        console.info(localStorage.getItem('appUpdate'));
        if (localStorage.getItem('appUpdate')) {
            this.refreshApp();
        }
        else {
            localStorage.setItem("appUpdate", true);
        }
        // Show refresh UI message
        this.showUpdatetingMessage();
    },
    showUpdatetingMessage() {
        this.showAlert = true;
    },
    refreshApp () {
        // Throw "refresh_app" event
        EventsBus.emit('refresh_app');
        this.$emit('refresh_app');
        this.updating = true;
        this.updateExists = false;
        // console.info(this.registration);
        this.timerForceRefresh = setTimeout(this.foreceRefresh.bind(this), RELOAD_TIMEOUT);

        if (!this.registration || !this.registration.waiting) return;
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage('skipWaiting');
    },
    foreceRefresh() {
        localStorage.removeItem("appUpdate");
        // Here the actual reload of the page occurs
        window.location.reload();
    },
    hideModal() {
        this.showAlert = false;
    }
  },
  beforeDestroy() {
    EventsBus.$off('update_app');
  }
}
</script>

<style lang="scss">
@import "../../assets/layout/_layout-variables.scss";

.alert-updater {
  margin-left: $app-sidebar-width;
  min-height: $app-header-height;
  line-height: $app-header-height / 2 + 5;
}
.closed-sidebar {
  .alert-updater {
      margin-left: $app-sidebar-width-collapsed;
  }
}

#updater {
    .alert {
        margin-bottom: 0;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .spinner-border {
        position:absolute;
        width: 50px;
        height: 50px;
        margin: 0 !important;
    }
    img {
        width: 50px;
        height: 50px;
        margin: 0 !important;
    }

    .install {
        font-size: 1rem;
        text-align: center;
    }
}
.updater-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modal-footer.updater-footer {
    display: none;
}
</style>
