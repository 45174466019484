<template>
  <div>
<!-- Add Client modal-->
        <b-modal id="createClient"
            :title="'Create client'"
            size="lg"
            :ok-title="'Create'"
            :input-class="'dd'"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <p class="my-4">To create a new client, enter its name and domain</p>
             <b-form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    id="input-group-1"
                    :label="$t('settings.global.account.form.name.label')"
                    label-for="input-1"
                >
                    <b-form-input
                    id="input-client-name"
                    v-model="form.name"
                    type="text"
                    size="lg"
                    required
                    placeholder="Client name"
                    debounce="500"
                    :state="$v.form.name.$dirty ? !$v.form.name.$invalid : null"
                    aria-describedby="input-client-name-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-client-name-feedback">
                    Enter at least 3 letters
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    id="input-group-1"
                    :label="$t('settings.global.account.form.domain.label')"
                    label-for="input-client-domain"
                >
                    <b-form-input
                    id="input-client-domain"
                    v-model="form.domain"
                    type="text"
                    required
                    placeholder="Client domain"
                    debounce="500"
                    :state="$v.form.domain.$dirty ? !$v.form.domain.$invalid : null"
                    aria-describedby="input-client-domain-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-client-domain-feedback">
                    Enter at least 3 letters and valid domain
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    id="input-plan-group"
                    :label="$t('settings.global.account.form.plan.label')"
                >
                    <b-form-select
                        id="input-plan"
                        v-model="form.plan"
                        :options="plans"
                        required
                        placeholder="Plan"
                        :state="$v.form.plan.$dirty ? !$v.form.plan.$invalid : null"
                    ></b-form-select>
                    <b-form-invalid-feedback id="input-client-plan-feedback">
                    Select a client plan
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    id="input-group-currency"
                    :label="$t('settings.global.account.form.currency.label')"
                    label-for="input-member-currency"
                >
                    <b-form-select id="input-member-currency" v-model="form.currency" :options="currencies" :placeholder="$t('settings.global.account.form.currency.label')"></b-form-select>
                </b-form-group>
            </b-form>
        </b-modal>
  </div>
</template>

<script>
  import { getCurrenciesOptions } from '@/libs/utils/Currency';
  import { checkIsValidDomain } from '@/libs/utils/Validator';
  import { required, minLength } from 'vuelidate/lib/validators';
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';

  import {library} from '@fortawesome/fontawesome-svg-core'
  import {
    faTrashAlt,
    faCheck,
    faEnvelopeOpen,
    faPhone
  } from '@fortawesome/free-solid-svg-icons'
  import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

  library.add(
    faTrashAlt,
    faCheck,
    faEnvelopeOpen,
    faPhone
  );

  export default {
    components: {
      VuePerfectScrollbar,
      'font-awesome-icon': FontAwesomeIcon
    },
    props: {
    },
    data: () => ({
        form: {
          name: '',
          domain: '',
          plan: 1,
          currency: 'EUR'
        },
        plans: [
          { value: 0, text: 'Trial' },
          { value: 1, text: 'Start' },
          { value: 2, text: 'Scale' }
      ],
      currencies: getCurrenciesOptions()
    }),
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(3)
            },
            domain: {
                required,
                minLength: minLength(3),
                checkIsValidDomain
            },
            plan: {
                required
            }
        },
    },
    computed: {
        
    },
    methods: {
        checkFormValidity() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        resetModal() {
            this.$v.$reset();
            this.form.name = this.contact?.name || '';
            this.form.domain = this.contact?.domain || '';
            this.form.plan = this.contact?.plan || 1;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.$emit('ok', { ...this.form });
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('createClient');
                this.resetModal();
            // this.$bvModal.hide('modal-prevent-closing')
            });
        }
    }


  }
</script>
<style lang="scss" scoped>
.icon-wrapper {
    cursor: pointer;
}
.description {
    min-height: 150px;
}
</style>